<template>

  <section class="sessao-cadastro">

    <div class="container-formulario">

      <div class="cabecalho-formulario">
        <h1 class="text-xl text-corBase font-bold">Dados Pessoais:</h1>

        <router-link to="/pessoa-fisica/lista" class="text-xl text-corBase font-bold">
          <i class="fas fa-chevron-left"></i>
        </router-link>
      </div>

      <form @submit.prevent="cadastrarPessoa">

        <pessoa-fisica-dados :dadosPessoaFisica="dadosPessoaFisica"/>


        <enderecos-campos :dadosEndereco="dadosEndereco"/>

        <telefones-campos :dadosTelefone="dadosTelefone"/>

        <emails-campos :dadosEmail="dadosEmail"/>

        <div class="flex w-full justify-end">
          <button class="btn-enviar" type="submit">Cadastrar</button>
        </div>

      </form>

    </div>

    <mensagem-sucesso v-if="sucesso === true" mensagem="Pessoa Fisíca Cadastrada com Sucesso!"
                      rotas="/pessoa-fisica/lista"/>
  </section>

</template>

<script>
import axios from "axios";
import EnderecosCampos from "@/components/campos_cadastro/EnderecosCampos";
import PessoasCampos from "@/components/campos_cadastro/PessoasCampos";
import TelefonesCampos from "@/components/campos_cadastro/TelefonesCampos";
import PessoaFisicaDados from "@/components/campos_cadastro/PessoaFisicaDados";
import EmailsCampos from "@/components/campos_cadastro/EmailsCampos";
import MensagemSucesso from "@/components/toasts/toast_sucesso/MensagemSucesso";

export default {
  name: 'CadastroPessoaFisica',

  components: {
    TelefonesCampos,
    EnderecosCampos,
    PessoasCampos,
    PessoaFisicaDados,
    EmailsCampos,
    MensagemSucesso

  },

  data() {
    return {

      dadosPessoaFisica: {
        nome_razao: '',
        tipo_pessoa_geral: '',
        cpf: '',
        rg: '',
        orgao_expeditor: '',
        nascimento: '',
      },

      dadosEndereco: [
        {
          logradouro: '',
          numero: '',
          complemento: '',
          cep: '',
          bairro: '',
          cidade: '',
          uf: '',
          referencia: '',
          tipo_endereco: '',
        }
      ],

      dadosTelefone: [
        {
          ddd: '',
          telefone: '',
          tipo_telefone: '',
        }
      ],

      dadosEmail: [{
        email_pessoa: '',
        tipo_email: '',
      }],

      sucesso: false,
    }
  },

  computed: {
    minhaEmpresa() {
      return this.$store.state.minhaEmpresa;
    },
    isadmin() {
      return this.$store.state.isAdminUser
    },
  },

  methods: {

    async cadastrarPessoa() {
      try {
        const data = {
          nome_razao: this.dadosPessoaFisica.nome_razao,
          nome: this.dadosPessoaFisica.nome_razao,
          cpf: this.dadosPessoaFisica.cpf.replace(/[^0-9]/g, ''),
          rg: this.dadosPessoaFisica.rg ? this.dadosPessoaFisica.rg.replace(/[^0-9]/g, '') : null,
          orgao_expeditor: this.dadosPessoaFisica.orgao_expeditor || null,
          nascimento: this.dadosPessoaFisica.nascimento || null,
          tipo_pessoa_geral: this.dadosPessoaFisica.tipo_pessoa_geral || null,

          enderecos: this.dadosEndereco,
          telefones: this.dadosTelefone,
          emails: this.dadosEmail,


        };

        const token = await this.$store.dispatch('getAuthTokenCookie')
        await this.$store.dispatch('fetchURLrequest')

        const response = await axios.post('pessoas/', data, {
          headers: {
            'Authorization': `Token ${token}`,
            'Content-Type': 'application/json'
          }
        });
        console.log(response)
        this.sucesso = true
      } catch (error) {
        if (error.response) {
          for (const property in error.response.data) {
            this.errors.push(`${property}: ${error.response.data[property]}`)
          }
        } else if (error.message) {
          this.errors.push('Something went wrong. Please try again!')
        }
      }
    },

  }

}
</script>

<style lang="scss">
//estilo sessao
.sessao-cadastro {
  @apply flex justify-center
  md:justify-end md:mr-10
}

//estilo btn cadastrar
.btn-enviar {
  @apply bg-ativo hover:bg-screenCor border border-screenCor hover:border-ativo
  text-white hover:text-ativo py-2 px-4 mt-2
}

// cabeçalho estilo
.cabecalho-formulario {
  @apply flex justify-between mb-2
}

.titulo-cadastro {
  @apply text-xl text-corBase font-bold
}

// estilo formulario
.container-formulario {
  @apply flex flex-col bg-white p-4 mt-4 rounded-3xl
  w-[94%] mx-auto
  md:w-[89%] md:ml-20 md:mt-24 md:overflow-y-scroll md:h-[80vh] md:mb-10
  lg:w-[92%] lg:overflow-y-scroll lg:h-[80vh] lg:left-[80px] lg:top-20
  scrollbar-thin scrollbar-thumb-corBase/40

}

.container-campos {
  @apply md:flex md:gap-3
}

.campos-mobile {
  @apply flex flex-col mt-2 w-full
}

.campos-dasktop-select {
  @apply flex flex-col mt-2 w-80
}

.label-campos {
  @apply text-base text-corBase font-bold mt-2
}

.input-campos {
  @apply w-full bg-white border-2 border-corBase/5 border-b-corBase/20 p-2 focus:border-b-corBase/60
  text-corBase focus:border-b-corBase/80 outline-none focus:transition focus:duration-500
}


</style>