<template>

  <div class="container-formulario">
    <galeria v-if="galeria === true" @fechar_galeria="abrir_galeria" @objetoClicado="atualizarFileInput"/>
    <!-- TILO DO FORMULARIO + BOTAO DE VOLTAR -->
    <div class="cabecalho-formulario">
      <h1 class="text-xl text-corBase font-bold">Dados Chapa:</h1>

      <router-link to="/produtos/chapas" class="text-xl text-corBase font-bold">
        <i class="fas fa-chevron-left"></i>
      </router-link>
    </div>

    <!-- Escopo do formulario de cadastro mobile-->
    <form @submit.prevent="cadastrarChapa" enctype="multipart/form-data">
      <!--        PARTE DE DADOS DO PRODUTO-->

      <template class="flex flex-col gap-3 my-3 w-full">
        <div class="div-produtos">
          <div class="flex flex-col w-[40vw]">
            <label class="text-corBase text-xl">Adicione uma imagem:</label>
            <div class="imagePreviewWrapper" :style="{ 'background-image': `url(${previewImage.url_documento})` }">
            </div>
            <div class="flex gap-3 mt-4">
              <button class="selecionar-arquivo" @click.prevent="abrir_galeria">
                Pesquisar
              </button>

            </div>

          </div>

          <div class="flex flex-col w-full gap-[14px] ml-4 mr-1">
            <div class="campo-chapa">
              <label class="text-xl text-corBase font-bold">Nome Especifico:</label>
              <input type="text" v-model="nome_especifico" class="input-form-chapa" autofocus>
            </div>
            <div class="div-produtos ">

              <div class="flex flex-col gap-1 w-full">
                <label class="text-xl text-corBase font-bold">Produto Base:</label>
                <Select2 v-model="produto_base"
                         class="w-full z-10"
                         :options="lista_produto_por_fornecedor"
                         :settings="{ width: '100%'}"
                />
              </div>
            </div>


            <div class="flex gap-3 w-full mt-3">

              <div class="campo-chapa">
                <label class="text-xl text-corBase">Tipo Chapa:</label>
                <Select2 v-model="tipo_chapas"
                         class="w-full z-10"
                         :options="lista_tipo_chapa"
                         :settings="{ width: '100%'}"
                />
              </div>

              <div class="campo-chapa">
                <label class="text-xl text-corBase">Grupo Aplicação Chapa:</label>
                <Select2 v-model="grupo_aplicacao_chapa"
                         class="w-full z-10"
                         :options="lista_grupo_aplicacao_chapa"
                         :settings="{ width: '100%'}"
                />
              </div>

            </div>

            <div class="flex gap-3 w-full mt-3">
              <div class="campo-chapa">
                <label class="text-xl text-corBase">Cor Chapa:</label>
                <Select2 v-model="cor_chapas"
                         class="w-full z-10"
                         :options="lista_cor_chapa"
                         :settings="{ width: '100%'}"
                />
              </div>
              <div class="campo-chapa">
                <label class="text-xl text-corBase">Altura:</label>
                <input class="input-form-chapa" type="number" v-model="altura_chapa">
              </div>

              <div class="campo-chapa">
                <label class="text-xl text-corBase">Largura:</label>
                <input class="input-form-chapa" type="number" v-model="largura_chapa">
              </div>
            </div>
          </div>
        </div>

        <div class="div-produtos">


        </div>
        <div class="flex justify-between ">
          <div class="flex w-full justify-end">
            <button class="btn-enviar" type="submit">Salvar</button>
          </div>
        </div>
      </template>


    </form>

  </div>

  <mensagem-sucesso v-if="sucesso === true" mensagem="Chapa Cadastrada com Sucesso!"
                    rotas="/produtos/chapas"></mensagem-sucesso>
</template>

<script>
import axios from "axios";
import Galeria from '@/components/midais/ged/Galeria'
import Select2 from "vue3-select2-component";
import MensagemSucesso from "@/components/toasts/toast_sucesso/MensagemSucesso";

export default {
  name: "CadastroChapas",

  components: {
    Galeria,
    Select2,
    MensagemSucesso
  },


  data() {
    return {
      sucesso: false,
      previewImage: {},

      lista_produto_por_fornecedor: [],
      produto_base: '',
      lista_grupo_aplicacao_chapa: [],
      grupo_aplicacao_chapa: '',
      lista_tipo_chapa: [],
      tipo_chapas: '',
      lista_cor_chapa: [],
      cor_chapas: '',

      // campos do formulario
      altura_chapa: '',
      largura_chapa: '',
      nome_especifico: '',

      proximo: false,
      galeria: false
    }
  },

  created() {
    this.getFornecedor()
    this.getProdutoPorFornecedor()
    this.getGrupoAplicacaoChapa()
    this.getCorChapas()
    this.getTipoChapas()
  },

  computed: {
    minhaEmpresa() {
      return this.$store.state.minhaEmpresa;
    },

    isadmin() {
      return this.$store.state.isAdminUser
    },

  },

  methods: {

    atualizarFileInput(objeto) {
      console.log('objeto que chegou:' + objeto)
      console.log(objeto)
      this.previewImage = objeto; // ou a propriedade correta do objeto que deseja atribuir
    },
    abrir_galeria() {
      this.galeria = !this.galeria
    },

    trocar_de_pagina() {
      this.proximo = !this.proximo
      console.log("ui cliquei")
    },

    handleImagemUpload(event) {
      this.fileInput = event.target.files[0];
    },

    async cadastrarChapa() {
      const token = await this.$store.dispatch('getAuthTokenCookie')

      await this.$store.dispatch('fetchURLrequest')

      const data = new FormData();
      data.append('ativo', 'True')
      data.append('nome_especifico', this.nome_especifico);
      data.append('altura_chapa', this.altura_chapa);
      data.append('largura_chapa', this.largura_chapa);
      data.append('cor_chapas', this.cor_chapas);
      data.append('tipo_chapas', this.tipo_chapas);
      data.append('chapa_base', this.produto_base);
      data.append('grupo_aplicacao_chapa', this.grupo_aplicacao_chapa);
      data.append('imagem', this.previewImage.id);


      this.$store.dispatch("setLoading", true);
      await axios.post('produtos/chapa/', data, {
        headers: {
          'Authorization': `Token ${token}`,
          'Content-Type': 'multipart/form-data'
        }
      })
          .then(response => {
            console.log(response.data.results);
            this.sucesso = true
            this.$store.dispatch("setLoading", false);
          })
          .catch(error => {
            console.log(error);
            this.$store.dispatch("setLoading", false);
          })

    },

    async getProdutoPorFornecedor() {
      const token = await this.$store.dispatch('getAuthTokenCookie')

      await this.$store.dispatch('fetchURLrequest')

      await axios.get('produtos/produto-por-fornecedor', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            console.log(response.data.results)
            this.lista_produto_por_fornecedor = response.data.results.map(item => ({
              id: item.id,
              text: `${item.produto_base.nome_base} - ${item.produto_base.codigo_base}`,
              img: item.produto_base.imagem
            }))

          })
          .catch(error => {
            console.log(error)
          })

    },

    async getCorChapas() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get('produtos/cor-chapa', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            console.log(response.data.results)
            this.lista_cor_chapa = response.data.results.map(item => ({
              id: item.id,
              text: item.nome
            }))
          })
          .catch(error => {
            console.log(error)
          })
    },

    async getTipoChapas() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get('produtos/tipo-chapa', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            console.log(response.data.results)
            this.lista_tipo_chapa = response.data.results.map(item => ({
              id: item.id,
              text: item.nome
            }))
          })
          .catch(error => {
            console.log(error)
          })
    },

    async getGrupoAplicacaoChapa() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get('produtos/grupo-aplicacao-chapa', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            console.log(response.data.results)
            this.lista_grupo_aplicacao_chapa = response.data.results.map(item => ({
              id: item.id,
              text: item.nome
            }))
          })
          .catch(error => {
            console.log(error)
          })
    },


  }
}
</script>

<style lang="scss">
.imagePreviewWrapper {
  @apply w-full h-[300px] mt-2 bg-contain bg-center bg-no-repeat block cursor-pointer border border-corBase/10 bg-white
}

//protudo
.div-produtos {
  @apply flex gap-3 my-3 w-full
}

//chapa
.campo-chapa {
  @apply flex flex-col gap-1 w-full
}

.input-form-chapa {
  @apply w-full bg-white border-2 border-corBase/5 border-b-corBase/20 focus:border-b-corBase/60 px-1 py-1 text-corBase shadow-sm shadow-corBase/20
  text-corBase focus:border-b-corBase/80 outline-none focus:transition focus:duration-500
}

.selecionar-arquivo {
  @apply bg-screenCor p-1.5 w-full border-2 border-b-corBase text-corBase text-center shadow-sm shadow-corBase/20
}
</style>