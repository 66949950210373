<template>
  <div className="container-formulario">
    <h1>{{ quadro.nome }}</h1>
    <quadro-componente :quadro="quadro" @dadosQuadroObtidos="getColunasQuadro"/>
  </div>
</template>

<script>
import QuadroComponente from "@/components/kanban/QuadroComponente";
import axios from "axios";

export default {
  name: 'QuadroView',
  components: {
    QuadroComponente
  },
  data() {
    return {
      quadro: {}
    }
  },
  created() {
    this.getQuadroKanban()
  },
  methods: {
    async getQuadroKanban() {
      try {
        this.$store.dispatch("setLoading", true);
        const token = await this.$store.dispatch('getAuthTokenCookie');
        await this.$store.dispatch('fetchURLrequest');

        const response = await axios.get('kanban/quadro/', {
          headers: {
            'Authorization': `Token ${token}`
          }
        });

        this.quadro = response.data.results[0];
        // Após obter os dados do quadro, emitir o evento 'dadosQuadroObtidos'
        this.$emit('dadosQuadroObtidos');
        this.$store.dispatch("setLoading", false);
      } catch (error) {
        console.error('Erro ao obter quadro:', error);
      }
    },
    getColunasQuadro() {
      console.log("Evento 'dadosQuadroObtidos' emitido pelo componente pai.");
    }
  }
}
</script>
