<template>

  <cadastro-pessoa-fisica />

</template>

<script>
import CadastroPessoaFisica from "@/components/tipos_de_cadastros/CadastroPessoaFisica";

export default {
  name: 'HomeView',

  components: {
    CadastroPessoaFisica,
  }

}
</script>

<style lang="scss"></style>