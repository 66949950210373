<template>
  <!--  TEMPLATE PARA MOBILE-->
  <section class="container-agenda-mobile">

    <!-- Cabecalho para agenda-->
    <div class="cabecalho-mobile-agenda">
      <div class="flex justify-between">
        <h1 class="text-xl text-corBase">Agenda Geral</h1>
        <button><i class="fas fa-chevron-left text-xl text-corBase"></i></button>
      </div>
      <!-- COntainer dos filtros e botoes -->
      <div class="container-filtros-mobile">
        <input type="date" class="inpute">
        <div class="flex gap-2 w-full">
          <select class="inpute" v-model="opcaoSelecionada">
            <option value="">Escolha Agenda</option>
            <option value="minha_agenda">Minha Agenda</option>
            <option value="grupo">Grupo</option>
          </select>

          <select class="inpute" v-if="opcaoSelecionada === 'grupo'">
            <option value="">Escolha um grupo</option>
            <option value="montadores">Montadores</option>
            <option value="orcamento">Orçamento</option>
            <option value="medidores">Medidores</option>
          </select>
        </div>
      </div>
    </div>

    <!--Container tabela agenda-->
    <div class="container-tabela-mobile">
      <div class="agenda-dia-mobile">
        <div class="info-dia-mobile">
          <h2 class="text-2xl -mb-2">14</h2>
          <span class="text-xs">Quarta</span>
        </div>

        <div class="container-tarefas-mobile">

          <button class="campo-tarefa-mobile">
            <h3>testando info da descricao</h3>
            <i class="fas fa-user"></i>

          </button>
        </div>
      </div>

    </div>


  </section>

  <!--TEMPLATE PARA COMPUTADOR-->
  <!--  TEMPLATE PARA MENU E FILTROS-->
  <section class="sessao-cabecalho hidden md:block">
    <!--    TITULO -->
    <div class="flex justify-between items-center">
      <h1 class="text-2xl text-corBase">Agenda Semanal</h1>

      <router-link to="dashboard"><i class="fas fa-chevron-left text-corBase text-2xl"></i></router-link>
    </div>

    <!--    SELECIONAR DATA-->
    <div class="flex gap-3 mt-2">
      <input type="date" class="inpute w-48" v-model="data_filtro">


      <select class="inpute" v-model="opcaoSelecionada" @change="selecionarOpcao">
        <option value="">Escolha Agenda</option>
        <option value="minha_agenda">Minha Agenda</option>
        <option value="grupo">Grupo</option>
      </select>

      <Select2 v-model="grupo" v-if="opcaoSelecionada == 'grupo'" class="w-[40%] z-10 input-select2 "
               :options="lista_grupo_operacao" :settings="{
            width: '40%',
            templateResult: formatarResultado
          }" @select="getListaTarefasGrupo(this.grupo)"/>


    </div>

    <!--    BOTOES -->
    <div class="flex mt-2">
      <button class="btn-cabecalho" @click="abrir_fechar">Adicionar Tarefa</button>

    </div>

  </section>


  <!--  TEMPLATE ESCOPO AGENDA SEMANAL-->
  <section class="sessao-escopo-agenda" v-if="!descricao">
    <!-- APARECE QUANDO NAO TEM NENHUMA AGENDA SELECIONADA  -->
    <div v-if="opcaoSelecionada == ''" class="flex w-full items-center justify-center">
      <h1 class="text-4xl text-corBase"> Aguandando A escolha de uma Agenda!</h1>
    </div>


    <table class="border border-corBase" v-if="opcaoSelecionada == 'minha_agenda' || opcaoSelecionada == 'grupo'">
      <thead class="container-head-tabela">
      <tr>
        <th v-for="day in daysOfWeek" :key="day" class="container-titulo-coluna">{{ day }}</th>
      </tr>
      </thead>


      <tbody :key="opcaoSelecionada" v-if="opcaoSelecionada === 'minh_agenda'">

      <tr v-for="time in times" :key="time">
        <td v-for="day in weekDays" :key="day">
          <div v-for="task in tarefasFiltradasSemana" :key="task.id">
            <button class="btn-estilo-tabela"
                    v-if="task && task.data_hora_inicio && task.data_hora_inicio.split('T')[1].substring(0, 5) === time.substring(0, 5) && new Date(task.data_hora_inicio).getDate() === day"
            >
              {{ task.data_hora_inicio.split('T')[1].substring(0, 5) }}
            </button>
          </div>
        </td>
      </tr>
      </tbody>

      <tbody :key="opcaoSelecionada" v-else-if="opcaoSelecionada === 'grupo'">
      <tr v-for="time in times" :key="time">
        <td v-for="day in weekDays" :key="day">
          <div v-for="task in tarefasGrupoPorSemana" :key="task.id">
            <button class="btn-estilo-tabela"
                    v-if="task && task.data_hora_inicio && task.data_hora_inicio.split('T')[1].substring(0, 5) === time.substring(0, 5) && new Date(task.data_hora_inicio).getDate() === day"
            >
              {{ task.data_hora_inicio.split('T')[1].substring(0, 5) }}
            </button>
          </div>
        </td>
      </tr>
      </tbody>


    </table>
  </section>

  <!-- APARECE QUANDO É SELECIONADO UM HORARIO NA LISTA DE TAREFAS POR HORARIOS E DIAS  -->
  <descricao-por-horario v-if="descricao"/>


  <NovaTarefa :grupo="grupo" v-if="isOpen"/>


</template>

<script>
import NovaTarefa from "@/components/agenda_componentes/NovaTarefa";
import DescricaoPorHorario from "@/components/agenda_componentes/DescricaoPorHorario";
import axios from "axios";
import Select2 from "vue3-select2-component";

export default {
  name: "AgendaGeral",
  components: {
    NovaTarefa,
    DescricaoPorHorario,
    Select2
  },
  data() {
    return {
      daysOfWeek: ['Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado', 'Domingo'],
      times: ['07:00-03:00', '08:00-03:00', '09:00-03:00', '10:00-03:00', '11:00-03:00', '12:00-03:00',
        '13:00-03:00', '14:00-03:00', '15:00-03:00', '16:00-03:00', '17:00-03:00', '18:00-03:00', '19:00-03:00',
        '20:00-03:00'],
      isOpen: false,
      descricao: false,
      opcaoSelecionada: '',
      lista_tarefas: [],
      data_filtro: '',
      diasSemana: [],
      tarefas: '',
      tarefasFiltradasData: [],
      tarefasFiltradasSemana: [],
      lista_grupo_operacao: [],
      lista_tarefas_grupo: [],
      tarefasGrupoPorSemana: [],
      grupo: '',
      idGrupo: '',
      sugestaoGrupo: [],
      horarios_da_semana: [],
      weekDays: [],
      error: [],
    }
  },

  created() {
    this.getGrupoOperacao()
  },

  computed: {
    minhaEmpresa() {
      return this.$store.state.minhaEmpresa;
    },
  },


  methods: {

    abrir_fechar() {
      this.isOpen = !this.isOpen
    },

    abrirDescricao() {
      this.descricao = !this.descricao
    },

    // formata o grupo operacao para por no select2
    formatarResultado(resultado) {
      if (!resultado.id) {
        return resultado.text
      }

      return resultado.text
    },

    async getGrupoOperacao() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      axios.get('agenda_tarefa/grupooperacao/', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            this.lista_grupo_operacao = response.data.map(item => ({
              id: item.id,
              text: item.nome
            }));
            const dataAtual = new Date()
            console.log(dataAtual);
            console.log(this.lista_grupo_operacao)
          })
          .catch(error => {
            console.log(error)
          })
    },


    async getMinhaListaTarefas() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get('agenda_tarefa/tarefas', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then((response) => {
            this.lista_tarefas = response.data
            console.log(this.lista_tarefas);
            this.filtrarData()
          })
          .catch(error => {
            console.log(error);
          });
    },

    async getListaTarefasGrupo(grupo) {
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest')

      try {
        const response = await axios.get('agenda_tarefa/tarefas', {
          headers: {
            'Authorization': `Token ${token}`
          }
        });
        this.lista_tarefas_grupo = response.data.filter(item => {
          return item.acoes_operacao.grupo_operacao.id === grupo;
        });

        this.filtrarData()
        this.filtrarTarefasDaSemanaAtualGrupo()
        console.log(this.lista_tarefas_grupo);
      } catch (error) {
        console.log(error);
      }
    },


    selecionarOpcao() {
      this.tarefasFiltradasData = []; // Redefinir as tarefas filtradas por data
      this.tarefasFiltradasSemana = []; // Redefinir as tarefas filtradas por semana
      this.tarefasGrupoPorSemana = []; // Redefinir as tarefas do grupo por semana

      if (this.opcaoSelecionada === 'minha_agenda') {
        this.getMinhaListaTarefas();
        this.filtrarTarefasDaSemanaAtual();
      }
    },

    // filtro por data
    filtrarTarefasPorData(listaTarefas, data) {
      return listaTarefas.filter(tarefa => {
        const dataTarefa = tarefa.data_hora_inicio.split("T")[0];
        return dataTarefa === data;
      });
    },

    filtrarData() {
      console.log(this.data_filtro)
      this.tarefasFiltradasData = this.filtrarTarefasPorData(this.lista_tarefas, this.data_filtro);
      console.log(this.tarefasFiltradasData);
    },


    // filtro por semana
    filtrarTarefasDaSemana(listaTarefas) {
      const dataAtual = new Date();
      const diaDaSemana = dataAtual.getDay();
      const dataInicioSemana = new Date(dataAtual);
      dataInicioSemana.setDate(dataInicioSemana.getDate() - diaDaSemana);
      const dataFimSemana = new Date(dataAtual);
      dataFimSemana.setDate(dataFimSemana.getDate() + (6 - diaDaSemana));
      const tarefasDaSemana = listaTarefas.filter(tarefa => {
        const dataTarefa = new Date(tarefa.data_hora_inicio.split("T")[0]);
        return dataTarefa >= dataInicioSemana && dataTarefa <= dataFimSemana;
      });
      return tarefasDaSemana;
    },


    filtrarTarefasDaSemanaAtual() {
      this.tarefasFiltradasSemana= []
      this.tarefasFiltradasSemana = this.filtrarTarefasDaSemana(this.lista_tarefas);
      console.log(this.tarefasFiltradasSemana);
      this.getWeekDays()
      console.log('sou eu')
    },
    filtrarTarefasDaSemanaAtualGrupo() {
      this.tarefasGrupoPorSemana = this.filtrarTarefasDaSemana(this.lista_tarefas_grupo);
      console.log(this.tarefasGrupoPorSemana);
      this.getWeekDays();
      console.log('sou eu');
    },


    // get nos dias da semana
    getWeekDays() {
      const currentDate = new Date();
      const firstDayOfWeek = new Date(currentDate);
      const lastDayOfWeek = new Date(currentDate);

      // Encontrar o primeiro dia da semana (segunda-feira)
      firstDayOfWeek.setDate(currentDate.getDate() - currentDate.getDay() + 1);

      // Encontrar o último dia da semana (domingo)
      lastDayOfWeek.setDate(currentDate.getDate() + (7 - currentDate.getDay()));

      let currentDay = new Date(firstDayOfWeek);

      while (currentDay <= lastDayOfWeek) {
        this.weekDays.push(currentDay.getDate());
        currentDay.setDate(currentDay.getDate() + 1);
      }

      return this.weekDays;
    },
  }
}
</script>

<style lang="scss">
// CSS PARA A AGENDA MOBILE
.container-agenda-mobile {
  @apply flex flex-col p-2 md:hidden
}

.cabecalho-mobile-agenda {
  @apply flex flex-col bg-white p-2 gap-3 rounded-lg
}

.container-filtros-mobile {
  @apply flex flex-col gap-1 w-36
}

.container-tabela-mobile {
  @apply mt-2 bg-white rounded-lg
}

.agenda-dia-mobile {
  @apply flex justify-between p-2 gap-6
}

.info-dia-mobile {
  @apply text-center
}

.container-tarefas-mobile {
  @apply p-1 w-full flex flex-col h-[25vh] gap-2 overflow-y-scroll
}

.campo-tarefa-mobile {
  @apply bg-corBarraLateral px-2 py-1 flex items-center justify-between text-screenCor
}

// CSS AGENDA DeSKTOP
.sessao-cabecalho {
  @apply absolute md:top-[17%] md:left-[7%] md:w-[90%] bg-white p-4 rounded-lg lg:top-[13%]
}

.inpute {
  @apply bg-screenCor border-2 border-b-corBase px-2 py-1 text-corBase shadow-sm shadow-corBase/20
}

.btn-cabecalho {
  @apply px-4 py-1 bg-corBase text-screenCor text-xl
}


// CSS TABELA DESKTOP

.sessao-escopo-agenda {
  @apply absolute md:top-[46%] md:left-[7%] md:w-[90%] md:h-[50%]
  bg-white p-4 rounded-lg flex lg:top-[36%] hidden md:block md:flex
  overflow-y-scroll scrollbar-thin scrollbar-thumb-corBase/20
}

.container-head-tabela {
  @apply p-2 border border-corBase
}

.container-titulo-coluna {
  @apply w-48 text-corBase text-xl text-center
}

.btn-estilo-tabela {
  @apply flex justify-between w-full p-1 bg-corBarraLateral text-screenCor
}

</style>