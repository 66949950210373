<template>


  <div class="container-formulario">

    <!-- Cabeçalho lista de pessoas depois de 768 pixels-->
    <div class="container-cabecalho flex justify-between mb-4">
      <div>
        <h1 class="text-xl text-corBase font-bold">Unidades de Medida</h1>
      </div>

      <router-link to="/produtos" class="text-xl text-corBase font-bold">
        <i class="fas fa-chevron-left"></i>
      </router-link>

    </div>

    <div class="flex items-center gap-3 mb-10">

      <div class="flex gap-8">
        <div>
          <button class="bg-corBase hover:bg-screenCor border border-screenCor hover:border-corBase
  text-white hover:text-corBase py-2 px-4" @click="getListaUnidadeMedida">Pesquisar
          </button>
        </div>
      </div>
      <div v-if="isadmin === true">
        <button @click="$router.push(`/unidade-medida-cadastro`)" class="bg-ativo hover:bg-screenCor border border-screenCor hover:border-ativo
  text-white hover:text-ativo py-2 px-4 ">Adicionar
        </button>
      </div>
    </div>

    <!-- Container da exibiçao da lista de pessoas -->
    <div class="container-modelo-tabela">
      <table class="container-tabela overflow-auto">
        <thead class="cabecalho-tabela">
        <tr>
          <th scope="col" class="p-4">
            <div class="flex items-center">
              <input type="checkbox" class="checkbox" v-model="checkAll" @change="selectAll">

              <label class="sr-only">checkbox</label>
            </div>
          </th>
          <th scope="col" class="px-6 py-3">
            #
          </th>
          <th class="px-6 py-3">
            Codigo
          </th>
          <th class="px-6 py-3">
            Descrição
          </th>
          <th class="px-6 py-3">
            Produtos Permitidos
          </th>
          <th scope="col" class="px-6 py-3">
            Status
          </th>
          <th scope="col" class="px-6 py-3">
            Editar
          </th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(unidade, index) in listaUnidadeMedidaPaginada" :key="index" class="escopo-tabela">

          <td class="w-4 p-4">
            <div class="flex items-center">
              <input :id="'checkbox-table-' + (index + 2)" type="checkbox" class="checkbox"
                     v-model="unidade.selected">
              <label class="sr-only">checkbox</label>
            </div>
          </td>
          <th scope="row"
              class="px-6 py-2 font-medium text-xs text-gray-900 whitespace-nowrap dark:text-white">
            {{ index + 1 }}
          </th>
          <td class="px-6 py-2 text-xs">
            {{ unidade.codigo }}
          </td>
          <td class="px-6 py-2 text-xs">
            {{ unidade.nome }}
          </td>
          <td class="px-6 py-2 text-xs">
            <span v-for="produtos_permitidos in unidade.produtos_do_sistema">
              {{ produtos_permitidos.nome + ', ' }}
            </span>
          </td>


          <template v-if="unidade.ativo === true">
            <td class="px-1 sm:px-6  py-1 sm:py-4">
              <i class="fa-solid fa-circle-check  text-ativo"></i>
            </td>
          </template>
          <template v-if="unidade.ativo === false">
            <td class="px-1 sm:px-6  py-1 sm:py-4">
              <i class="fa-solid fa-circle-xmark text-inativo"></i>
            </td>
          </template>

          <td @click.prevente="getProdutosPermitidos(unidade.id)" class="px-6 py-2 text-xs">
            <editar-unidade-medida
                :unidade="unidade"
                :pagina-atual="paginaAtual"
                :lista-produtos-permitidos="lista_produtos_permitidos"
            />
          </td>

        </tr>
        </tbody>
      </table>

      <div class="paginacao">
        <button @click.prevent="paginaAnterior" class="btn-paginacao">
          <i class="fas fa-chevron-left"></i>
        </button>
        <template v-for="pagina in paginasExibidas" :key="pagina">
      <span
          @click.prevent="irParaPagina(pagina)"
          :class="{'numero-paginacao': true, 'numero-paginacao-ativa': pagina === paginaAtual}"
      >
        {{ pagina }}
      </span>
        </template>
        <button @click.prevent="proximaPagina" class="btn-paginacao">
          <i class="fas fa-chevron-right"></i>
        </button>
      </div>
    </div>
  </div>

</template>

<script>
import axios from "axios";
import EditarUnidadeMedida from "@/components/midais/Editar/produtos/EditarUnidadeMedida";

export default {
  name: "ListaUnidadeMedida",
  components: {
    EditarUnidadeMedida
  },

  data() {
    return {
      lista_unidade_medida: [],
      lista_produtos_permitidos: [],
      produtos_permitidos: [],
      checkAll: false,
      // lista usada para salvar esse retorno depois de dividido
      listaUnidadeMedidaPaginada: [],
      // varival que inicializa o numero de paginas
      paginaAtual: 1,
      itemsPorPagina: 5,

      error: [],
    }
  },

  computed: {
    totalPaginas() {
      return Math.ceil(this.lista_unidade_medida.length / this.itemsPorPagina);
    },
    minhaEmpresa() {
      return this.$store.state.minhaEmpresa;
    },

    isadmin() {
      return this.$store.state.isAdminUser
    },

    paginasExibidas() {
      const paginas = [];
      const paginasVisiveis = 5; // Número de páginas visíveis de cada vez

      let inicio = Math.max(1, this.paginaAtual - Math.floor(paginasVisiveis / 2));
      let fim = inicio + paginasVisiveis - 1;

      if (inicio + paginasVisiveis > this.totalPaginas) {
        inicio = Math.max(1, this.totalPaginas - paginasVisiveis + 1);
        fim = this.totalPaginas;
      }

      for (let i = inicio; i <= fim; i++) {
        paginas.push(i);
      }

      return paginas;
    },
  },

  created() {
    this.getProdutosDoSistema()
  },

  methods: {
    async getProdutosPermitidos(idUnidade) {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get(`/produtos/unidade-medida/${idUnidade}`, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            this.produtos_permitidos = response.data.map(item => ({
              id: item.produtos_do_sistema.id
            }))
          })
          .catch(error => {
            console.log(error)
          })
    },
    irParaPagina(pagina) {
      this.getListaUnidadeMedida()
      if (pagina >= 1 && pagina <= this.totalPaginas) {
        this.paginaAtual = pagina;
        this.paginarTabela();
      }
    },

    selectAll() {
      // Atualiza o estado dos demais checkboxes
      const checkboxes = document.querySelectorAll('.escopo-tabela input[type="checkbox"]');
      checkboxes.forEach((checkbox) => {
        checkbox.checked = this.checkAll;
      });
    },


    async getListaUnidadeMedida() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      this.$store.dispatch("setLoading", true);
      await axios.get(`/produtos/unidade-medida`, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            this.lista_unidade_medida = response.data
            console.log(this.lista_unidade_medida)
            this.$store.dispatch("setLoading", false);
            this.paginarTabela()
          })
          .catch(error => {
            console.log(error)
            this.$store.dispatch("setLoading", false);
          })
    },
    paginaAnterior() {
      if (this.paginaAtual > 1) {
        this.paginaAtual--;
        this.paginarTabela();
      }
    },
    proximaPagina() {
      if (this.paginaAtual < this.totalPaginas) {
        this.paginaAtual++;
        this.paginarTabela();
      }
    },

    // exibe apenas 10 items da tabela e salva o resto
    paginarTabela() {
      const startIndex = (this.paginaAtual - 1) * this.itemsPorPagina;
      const endIndex = startIndex + this.itemsPorPagina;
      this.listaUnidadeMedidaPaginada = this.lista_unidade_medida.slice(startIndex, endIndex);
    },


    async getProdutosDoSistema() {
      try {
        const token = await this.$store.dispatch('getAuthTokenCookie');
        await this.$store.dispatch('fetchURLrequest')
        const response = await axios.get(`produtos/produtos-sistema/`, {
          headers: {
            'Authorization': `Token ${token}`,
          }
        });
        this.lista_produtos_permitidos = response.data.map(item => ({
          id: item.id,
          text: item.nome
        }))
      } catch (error) {
        console.log(error)
      }
    }


  }
}
</script>

<style lang="scss"></style>