<template>
    <div>
        <h2 class="text-lg text-corBase font-bold">Dados</h2>
        <div class="grid grid-cols-2 gap-4">
            <div>
                <label>Nome</label>
                <input v-model="dadosVidro.nome" type="text" class="input-form">
            </div>
            <div>
                <label>Data de Criação</label>
                <input v-model="dadosVidro.criado_em_formatado" type="date" class="input-form" disabled>
            </div>
            <div>
                <label>Margem Lucro ML (%)</label>
                <input v-model="dadosVidro.margem_lucro_ml" type="number" min="0" class="input-form">
            </div>
            <div>
                <label>Margem Lucro Balcão (%)</label>
                <input v-model="dadosVidro.margem_lucro_balcao" type="number" min="0" class="input-form">
            </div>
            <div>
                <label>Padrão</label>
                <input type="checkbox" v-model="dadosVidro.padrao">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'DadosVidro',
    props: {
        dadosVidro: Object
    }
};
</script>

<style scoped>
.input-form {
    border: 1px solid #ccc;
    padding: 8px;
    width: 100%;
}
</style>