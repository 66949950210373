<template>

  <dashboard-estoque />

</template>

<script>
import DashboardEstoque from "@/components/estoque/DashboardEstoque";
export default {
  name: "EstoqueDashboard",
  components: {
    DashboardEstoque,
  }
}
</script>

<style scoped>

</style>