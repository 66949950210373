<template>
  <div class="paginacao">
    <button
      v-if="showPrevious"
      class="btn-paginacao"
      @click="changePage(current - 1)"
    >
      <i class="fas fa-chevron-left"></i>

    </button>
    <button
      v-for="(page, index) in pages"
      :key="page"
      class="item"
      :class="{ 'btn-paginacao': true, 'pagina-atual': page === current }"
      @click="changePage(index + 1)"
    >
      {{ page }}
    </button>
    <button 
      v-if="showNext" 
      class="btn-paginacao" 
      @click="changePage(current + 1)"
    >
      <i class="fas fa-chevron-right"></i>
    </button>
  </div>
</template>

<script>
export default {
  name: 'Pagination',
  props: {
    offset: {
      type: [String, Number],
      default: 0,
    },
    total: {
      type: [String, Number],
      required: true,
    },
    limit: {
      type: [String, Number],
      default: 10,
    },
  },
  computed: {
    showPrevious() {
      return this.current > 1;
    },
    showNext() {
      return this.current < this.pages.length;
    },
    current() {
      return this.offset
    },
    pages() {
      const qty = Math.ceil(this.total / this.limit);
      if (qty <= 1) return [1];
      return Array.from(Array(qty).keys(), (i) => i + 1);
    },
  },
  methods: {
    changePage(offset) {
      this.$emit('change-page', offset);
    },
  },

};
</script>