<template>


  <div class="container-formulario">
    <galeria v-if="galeria === true" @fechar_galeria="abrir_galeria" @objetoClicado="atualizarFileInput"/>

    <!-- TILO DO FORMULARIO + BOTAO DE VOLTAR -->
    <div class="cabecalho-formulario">
      <h1 class="text-xl text-corBase font-bold">Dados Servico:</h1>

      <router-link to="/produtos/servico" class="text-xl text-corBase font-bold">
        <i class="fas fa-chevron-left"></i>
      </router-link>
    </div>

    <!-- Escopo do formulario de cadastro mobile-->
    <form @submit.prevent="cadastrarServico" enctype="multipart/form-data">
      <!--        PARTE DE DADOS DO PRODUTO-->

      <template class="flex flex-col gap-3 my-3 w-full">
        <div class="div-produtos">
          <div class="flex flex-col w-[40vw]">
            <label class="text-corBase text-xl">Adicione uma imagem:</label>
            <div class="imagePreviewWrapper" :style="{ 'background-image': `url(${previewImage.url_documento})` }">
            </div>
            <div class="flex gap-3 mt-4">
              <button class="selecionar-arquivo" @click.prevent="abrir_galeria">
                Pesquisar
              </button>

            </div>

          </div>

          <div class="flex flex-col w-full gap-[14px] ml-4 mr-1">
            <div class="campo-chapa">
              <label class="text-corBase text-xl font-bold">Nome Especifico:</label>
              <input type="text" v-model="nome_especifico" class="input-form-chapa" required autofocus>
            </div>

            <div class="div-produtos ">
              <div class="flex flex-col gap-1 w-full">
                <div class="campo-chapa">
                  <label class="text-corBase text-xl font-bold">Produto Base:</label>
                  <select class="input-form-chapa" required autofocus v-model="produto_base">
                    <option v-for="produto in lista_produto_base" :value="produto.id" :key="produto.id">
                      {{ produto.nome_base }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="flex flex-col gap-1 w-full">
                <div class="campo-chapa">
                  <label class="text-corBase text-xl">Fornecedor:</label>
                  <select class="input-form-chapa" required autofocus v-model="fornecedor">
                    <option v-for="fornecedor in lista_fornecedor" :value="fornecedor.id" :key="fornecedor.id">
                      {{ fornecedor.nome_razao }}
                    </option>
                  </select>
                </div>
              </div>
            </div>

            <div class="flex gap-3 w-full mt-3">
              <div class="campo-chapa">
                <label class="text-corBase text-xl">Tipo Servico:</label>
                <select class="input-form-chapa" required autofocus v-model="tipo_servico">
                  <option v-for="tipo_servico in lista_tipo_servico" :value="tipo_servico.id" :key="tipo_servico.id">
                    {{ tipo_servico.nome }}
                  </option>
                </select>
              </div>
              <div class="campo-chapa">
                <label class="text-corBase text-xl">Grupo Aplicação Servico:</label>
                <select class="input-form-chapa" required autofocus v-model="grupo_aplicacao_servico">
                  <option v-for="grupo_aplicacao_servico in lista_grupo_aplicacao_servico"
                          :value="grupo_aplicacao_servico.id" :key="grupo_aplicacao_servico.id">
                    {{ grupo_aplicacao_servico.nome }}
                  </option>
                </select>
              </div>
            </div>

            <div class="flex gap-3 w-full mt-3">
              <div class="campo-chapa">
                <label class="text-corBase text-xl">TIpo Pessoa Servico:</label>
                <select class="input-form-chapa" required autofocus v-model="tipo_pessoa_servico">
                  <option v-for="tipo_pessoa_servico in lista_tipo_pessoa_servico" :value="tipo_pessoa_servico.id"
                          :key="tipo_pessoa_servico.id">
                    {{ tipo_pessoa_servico.descricao }}
                  </option>
                </select>
              </div>

              <div class="campo-chapa">
                <label class="text-corBase text-xl">Tempo Médio:</label>
                <input type="time" class="input-form-chapa" v-model="tempo_medio_servico">
              </div>

              <div class="campo-chapa">
                <label class="text-corBase text-xl">Custo Médio:</label>
                <input type="time" class="input-form-chapa" v-model="custo_medio_servico">
              </div>

            </div>

          </div>
        </div>

        <div class="div-produtos">


          <div class="flex flex-col w-full gap-[12px] ml-4 mr-1">

            <div class="flex gap-3 w-full mt-3">
              <div class="campo-chapa">
                <label class="text-corBase text-xl">Codigo Fornecedor:</label>
                <input class="input-form-chapa" type="text" v-model="codigo_fornecedor">
              </div>

              <div class="campo-chapa">
                <label class="text-corBase text-xl">Codigo de barras:</label>
                <input class="input-form-chapa" type="text" v-model="codigo_barras">
              </div>
            </div>

            <div class="flex gap-3 w-full mt-3">
              <div class="campo-chapa">
                <label class="text-corBase text-xl">Peso por metro:</label>
                <input type="number" class="input-form-chapa" v-model="peso_por_metro">
              </div>
              <div class="campo-chapa">
                <label class="text-corBase text-xl">Preço medio:</label>
                <input type="number" class="input-form-chapa" v-model="preco_medio">
              </div>
              <div class="campo-chapa">
                <label class="text-corBase text-xl">Preço base:</label>
                <input type="number" class="input-form-chapa" v-model="preco_base">
              </div>
            </div>

            <div class="flex gap-3 w-full mt-3">
              <div class="campo-chapa">
                <label class="text-corBase text-xl">Ultimo preço:</label>
                <input type="number" class="input-form-chapa" v-model="ultimo_preco_compra">
              </div>
              <div class="campo-chapa">
                <label class="text-corBase text-xl">Preço de Custo:</label>
                <input type="number" class="input-form-chapa" v-model="preco_custo">
              </div>
              <div class="campo-chapa">
                <label class="text-corBase text-xl">Data Atl Preço:</label>
                <input type="date" class="input-form-chapa" v-model="data_atualizacao_preco">
              </div>
            </div>


            <div class="flex gap-3 w-full mt-3">
              <div class="campo-chapa">
                <label class="text-corBase text-xl">ipi:</label>
                <input type="number" class="input-form-chapa" v-model="ipi">
              </div>

              <div class="campo-chapa">
                <label class="text-corBase text-xl">icms:</label>
                <input type="number" class="input-form-chapa" v-model="icms">
              </div>
              <div class="campo-chapa">
                <label class="text-corBase text-xl">Margem Adicional:</label>
                <input type="number" class="input-form-chapa" v-model="margem_adicional">
              </div>
              <div class="campo-chapa">
                <label class="text-corBase text-xl">Transporte:</label>
                <input type="number" class="input-form-chapa" v-model="transporte">
              </div>
            </div>
          </div>
        </div>
        <div class="flex justify-between ">
          <div class="flex w-full justify-end">
            <button class="btn-enviar" type="submit">Salvar</button>
          </div>
        </div>

      </template>

    </form>


  </div>


</template>

<script>
import axios from "axios";
import Galeria from "@/components/midais/ged/Galeria";


export default {
  name: "CadastroServico",

  components: {
    Galeria
  },

  data() {
    return {
      sucesso: false,
      galeria: false,
      previewImage: {},

      lista_produto_base: [],
      lista_fornecedor: [],
      lista_tipo_pessoa_servico: [],
      lista_tipo_servico: [],
      lista_grupo_aplicacao_servico: [],

      nome_especifico: '',
      codigo_fornecedor: '',
      codigo_barras: '',
      produto_base: '',
      fornecedor: '',
      peso_por_metro: '',
      preco_medio: '',
      preco_base: '',
      ultimo_preco_compra: '',
      preco_custo: '',
      data_atualizacao_preco: '',
      ipi: '',
      icms: '',
      transporte: '',
      margem_adicional: '',
      tipo_pessoa_servico: '',
      tipo_servico: '',
      grupo_aplicacao_servico: '',
      unidade_medida_embalagem: '',
      quantidade_embalagem: '',
      tempo_medio_servico: '',
      custo_medio_servico: '',


      proximo: false,
    }
  },

  created() {
    this.getFornecedor()
    this.getProdutoBase()
    this.getGrupoAplicacaoServico()
    this.getTipoPessoaServico()
    this.getTipoServico()
  },

  computed: {
    minhaEmpresa() {
      return this.$store.state.minhaEmpresa;
    },

    isadmin() {
      return this.$store.state.isAdminUser
    },
  },

  methods: {
    atualizarFileInput(objeto) {
      console.log('objeto que chegou:' + objeto)
      console.log(objeto)
      this.previewImage = objeto; // ou a propriedade correta do objeto que deseja atribuir
    },
    abrir_galeria() {
      this.galeria = !this.galeria
    },
    trocar_de_pagina() {
      this.proximo = !this.proximo
    },
    handleImagemUpload(event) {
      this.fileInput = event.target.files[0];
    },

    async cadastrarServico() {
      const token = await this.$store.dispatch('getAuthTokenCookie')

      await this.$store.dispatch('fetchURLrequest')

      const data = new FormData();
      data.append('ativo', true)
      data.append('nome_especifico', this.nome_especifico)
      data.append('codigo_fornecedor', this.codigo_fornecedor)
      data.append('codigo_barras', this.codigo_barras)
      data.append('peso', this.peso_por_metro)
      data.append('preco_medio', this.preco_medio)
      data.append('preco_base', this.preco_base)
      data.append('ultimo_preco_compra', this.ultimo_preco_compra)
      data.append('preco_custo', this.preco_custo)
      data.append('data_atualizacao_preco_base', this.data_atualizacao_preco)
      data.append('ipi', this.ipi)
      data.append('icms', this.icms)
      data.append('transporte', this.transporte)
      data.append('margem_adicional', this.margem_adicional)
      data.append('produto_base', this.produto_base)
      data.append('fornecedor', this.fornecedor)
      data.append('tipo_pessoa', this.tipo_pessoa_servico)
      data.append('tipo_servico', this.tipo_servico)
      data.append('grupo_servico', this.grupo_aplicacao_servico)
      data.append('tempo_medio_servico', this.tempo_medio_servico)
      data.append('custo_medio_servico', this.custo_medio_servico)

      this.$store.dispatch("setLoading", true);
      await axios.post('produtos/servico/', data, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            console.log(response.data.results);
            this.$store.dispatch("setLoading", false);
          })
          .catch(error => {
            console.log(error);
            this.$store.dispatch("setLoading", false);
          })

    },


    async getProdutoBase() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get('produtos/produto', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            console.log(response.data.results)
            this.lista_produto_base = response.data.results
          })
          .catch(error => {
            console.log(error)
          })

    },

    async getFornecedor() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get('pessoas/fornecedor', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            console.log(response.data.results)
            console.log("AQUI");
            this.lista_fornecedor = response.data.results
          })
          .catch(error => {
            console.log(error)
          })
    },

    async getTipoPessoaServico() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get('pessoas/tipo-pessoas', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            console.log(response.data.results)
            this.lista_tipo_pessoa_servico = response.data.results
          })
          .catch(error => {
            console.log(error)
          })
    },

    async getTipoServico() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get('produtos/tipo-servico', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            console.log(response.data.results)
            this.lista_tipo_servico = response.data.results
          })
          .catch(error => {
            console.log(error)
          })
    },

    async getGrupoAplicacaoServico() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get('produtos/grupo-aplicacao-servico', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            console.log(response.data.results)
            this.lista_grupo_aplicacao_servico = response.data.results
          })
          .catch(error => {
            console.log(error)
          })
    },


    selectImage() {
      this.$refs.fileInput.click()
    },

    pickFile() {
      let input = this.$refs.fileInput
      let file = input.files
      if (file && file[0]) {
        let reader = new FileReader
        reader.onload = e => {
          this.previewImage = e.target.result
        }
        reader.readAsDataURL(file[0])
        this.$emit('input', file[0])
      }
    }
  }
}
</script>

<style lang="scss"></style>