<template>

  <!-- Lista de pessoas até 768 pixels-->

  <div class="container-formulario">

    <!-- Cabeçalho lista de pessoas depois de 768 pixels-->
    <div class="container-cabecalho flex justify-between mb-4">
      <div>
        <h1 class="text-xl text-corBase font-bold">Componentes</h1>
      </div>

      <router-link to="/produtos" class="text-xl text-corBase font-bold">
        <i class="fas fa-chevron-left"></i>
      </router-link>

    </div>
    <div class="flex items-end gap-8 mb-6">
      <!--      <div class="w-full">-->
      <!--        <label class="text-xl text-corBase">Cor:</label>-->
      <!--        <Select2 v-model="cor_componente"-->
      <!--                 class="w-full z-10"-->
      <!--                 :options="lista_cor_componente"-->
      <!--                 :settings="{ width: '100%'}"-->
      <!--        />-->
      <!--      </div>-->
      <!--      <div class="w-full">-->
      <!--        <label class="text-xl text-corBase">Tipo:</label>-->
      <!--        <Select2 v-model="tipo_componente"-->
      <!--                 class="w-full z-10"-->
      <!--                 :options="lista_tipo_componente"-->
      <!--                 :settings="{ width: '100%'}"-->
      <!--        />-->
      <!--      </div>-->
      <!--      <div class="w-full">-->
      <!--        <label class="text-xl text-corBase">Grupo Aplicacao:</label>-->
      <!--        <Select2 v-model="grupo_aplicacao_componente"-->
      <!--                 class="w-full z-10"-->
      <!--                 :options="lista_grupo_aplicacao_componente"-->
      <!--                 :settings="{ width: '100%'}"-->
      <!--        />-->
      <!--      </div>-->
      <button class="bg-corBase hover:bg-screenCor border border-screenCor hover:border-corBase
  text-white hover:text-corBase py-2 px-4" @click="getListaComponente">Pesquisar
      </button>
    </div>
    <div class="mb-8">
      <div>
        <button @click="$router.push(`/componente-acessorio/cadastro`)" class="bg-ativo hover:bg-screenCor border border-screenCor hover:border-ativo
  text-white hover:text-ativo py-2 px-4 ">Adicionar
        </button>
      </div>
    </div>

    <!-- Container da exibiçao da lista de pessoas -->
    <div class="container-modelo-tabela">
      <table class="container-tabela overflow-auto">
        <thead class="cabecalho-tabela">
        <tr>
          <th scope="col" class="p-4">
            <div class="flex items-center">
              <input type="checkbox" class="checkbox" v-model="checkAll" @change="selectAll">

              <label class="sr-only">checkbox</label>
            </div>
          </th>
          <th scope="col" class="px-6 py-3">
            #
          </th>
          <th class="px-6 py-3">
            Nome
          </th>
          <th class="px-6 py-3">
            Tipo
          </th>
          <th scope="col" class="px-6 py-3">
            Fornecedor
          </th>
          <th scope="col" class="px-6 py-3">
            Cor
          </th>
          <th scope="col" class="px-6 py-3">
            Produto Base
          </th>
          <th scope="col" class="px-6 py-3">
            Grupo de Aplicacao
          </th>
          <th scope="col" class="px-6 py-3">
            Status
          </th>
          <th scope="col" class="px-6 py-3">
            Editar
          </th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(componente, index) in ListaComponentesPaginada" :key="index" class="escopo-tabela">
          <td class="w-4 p-4">
            <div class="flex items-center">
              <input :id="'checkbox-table-' + (index + 2)" type="checkbox" class="checkbox"
                     v-model="componente.selected">
              <label class="sr-only">checkbox</label>
            </div>
          </td>
          <th scope="row" class="px-6 py-2 font-medium text-xs text-gray-900 whitespace-nowrap dark:text-white">
            {{ index + 1 }}
          </th>
          <td class="px-6 py-2 text-xs">
            {{ componente.nome_especifico }}
          </td>
          <td class="px-6 py-2 text-xs">
            {{ componente.tipo_componentes.nome }}
          </td>
          <td class="px-6 py-2 text-xs">
            {{ componente.fornecedor.nome_razao }}
          </td>
          <td class="px-6 py-2 text-xs">
            {{ componente.cor_componentes.nome }}
          </td>
          <td class="px-6 py-2 text-xs">
            {{ componente.produto_base.nome_base }}
          </td>
          <td class="px-6 py-2 text-xs">
            {{ componente.grupo_aplicacao.nome }}
          </td>
          <template v-if="componente.ativo === true">
            <td class="px-6 py-2 text-xs">
              <i class="fa-solid fa-circle-check  text-ativo ml-3"></i>
            </td>
          </template>
          <template v-if="componente.ativo === false">
            <td class="px-6 py-2">
              <i class="fa-solid fa-circle-xmark text-inativo ml-3"></i>
            </td>
          </template>

          <td class="px-6 py-2 text-xs">
            <editar-componente-acessorio :dadosComponente="componente"></editar-componente-acessorio>
          </td>

        </tr>
        </tbody>
      </table>
      <div class="paginacao">
        <button @click.prevent="paginaAnterior" class="btn-paginacao">
          <i class="fas fa-chevron-left"></i>
        </button>
        <template v-for="pagina in paginasExibidas" :key="pagina">
      <span
          @click.prevent="irParaPagina(pagina)"
          :class="{'numero-paginacao': true, 'numero-paginacao-ativa': pagina === paginaAtual}"
      >
        {{ pagina }}
      </span>
        </template>
        <button @click.prevent="proximaPagina" class="btn-paginacao">
          <i class="fas fa-chevron-right"></i>
        </button>
      </div>
    </div>
  </div>

</template>

<script>
import axios from "axios";
import EditarComponenteAcessorio from "@/components/midais/Editar/produtos/EditarComponenteAcessorio";
import Select2 from "vue3-select2-component";

export default {
  name: "ListaComponentes",

  components: {
    EditarComponenteAcessorio,
    Select2
  },

  data() {
    return {
      checkAll: false,
      // lista usada para salvar o retorno da requisição do get
      listaPessoas: [],
      // lista usada para salvar esse retorno depois de dividido
      ListaComponentesPaginada: [],
      // varival que inicializa o numero de paginas
      paginaAtual: 1,
      itemsPorPagina: 5,

      // filtros
      lista_cor_componente: [],
      cor_componente: '',
      lista_tipo_componente: [],
      tipo_componente: '',
      lista_grupo_aplicacao_componente: [],
      grupo_aplicacao_componente: '',
      produto_base: '',


      lista_componentes: [],

      error: []
    }
  },

  created() {
    console.log(this.isadmin)
    this.getTipoComponente()
    this.getCorComponente()
    this.getGrupoAplicacaoComponente()
  },

  computed: {
    isadmin() {
      return this.$store.state.isAdminUser
    },

    // faz a divisao das paginas na exibição para que sejam até 10 items
    totalPaginas() {
      return Math.ceil(this.lista_componentes.length / this.itemsPorPagina);
    },

    minhaEmpresa() {
      return this.$store.state.minhaEmpresa;
    },

    paginasExibidas() {
      const paginas = [];
      const paginasVisiveis = 5; // Número de páginas visíveis de cada vez

      let inicio = Math.max(1, this.paginaAtual - Math.floor(paginasVisiveis / 2));
      let fim = inicio + paginasVisiveis - 1;

      if (inicio + paginasVisiveis > this.totalPaginas) {
        inicio = Math.max(1, this.totalPaginas - paginasVisiveis + 1);
        fim = this.totalPaginas;
      }

      for (let i = inicio; i <= fim; i++) {
        paginas.push(i);
      }

      return paginas;
    },
  },

  methods: {
    irParaPagina(pagina) {
      console.log("esta é a pagina" + pagina)
      if (pagina >= 1 && pagina <= this.totalPaginas) {
        this.paginaAtual = pagina;
        this.paginarTabela();
      }
    },
    selectAll() {
      // Atualiza o estado dos demais checkboxes
      const checkboxes = document.querySelectorAll('.escopo-tabela input[type="checkbox"]');
      checkboxes.forEach((checkbox) => {
        checkbox.checked = this.checkAll;
      });
    },

    async getListaComponente() {
      const token = await this.$store.dispatch('getAuthTokenCookie')

      await this.$store.dispatch('fetchURLrequest')

      this.$store.dispatch("setLoading", true);
      await axios.get(`produtos/componente-acessorio/?cor_componentes_id=${this.cor_componente}&tipo_componentes_id=${this.tipo_componente}&grupo_aplicacao_id=${this.grupo_aplicacao_componente}`, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            console.log(response.data)
            this.lista_componentes = response.data
            this.$store.dispatch("setLoading", false);
          })
          .catch(error => {
            console.log(error)
            this.$store.dispatch("setLoading", false);
          })

    },

    paginaAnterior() {
      if (this.paginaAtual > 1) {
        this.paginaAtual--;
        this.paginarTabela();
      }
    },
    proximaPagina() {
      if (this.paginaAtual < this.totalPaginas) {
        this.paginaAtual++;
        this.paginarTabela();
      }
    },

    // exibe apenas 10 items da tabela e salva o resto
    paginarTabela() {
      const startIndex = (this.paginaAtual - 1) * this.itemsPorPagina;
      const endIndex = startIndex + this.itemsPorPagina;
      this.ListaComponentesPaginada = this.lista_componentes.slice(startIndex, endIndex);
    },


    // requisiçõe sde filtro
    async getCorComponente() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')
      await axios.get('produtos/cor-componente', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            console.log(response.data)
            this.lista_cor_componente = response.data.map(item => ({
              id: item.id,
              text: item.nome
            }))
          })
          .catch(error => {
            console.log(error)
          })
    },

    async getTipoComponente() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')
      await axios.get('produtos/tipo-componente', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            console.log(response.data)
            this.lista_tipo_componente = response.data.map(item => ({
              id: item.id,
              text: item.nome
            }))
          })
          .catch(error => {
            console.log(error)
          })
    },

    async getGrupoAplicacaoComponente() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')
      await axios.get('produtos/grupo-aplicacao-componente', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            console.log(response.data)
            this.lista_grupo_aplicacao_componente = response.data.map(item => ({
              id: item.id,
              text: item.nome
            }))
          })
          .catch(error => {
            console.log(error)
          })
    },

  }
}
</script>

<style lang="scss">

</style>