<template>

  <div class="container-formulario">
    <div class="container-cabecalho flex justify-between mb-4">
      <div>
        <h1 class="text-xl text-corBase font-bold">Inventário</h1>
      </div>

      <router-link to="/estoque/inventarios" class="text-xl text-corBase font-bold">
        <i class="fas fa-chevron-left"></i>
      </router-link>

    </div>

    <div class="flex flex-col md:flex-row border w-full gap-3 p-2">

      <div class="flex flex-col gap-2 w-full ">
        <label class="font-bold text-corBase">Código Base</label>
        <input v-model="filtro_codigo"
                 class="w-full z-10"
                 :settings="{ width: '100%'}"
        />
      </div>

      <div class="flex flex-col gap-2 w-full ">
        <label class="font-bold text-corBase">Tipo Produto</label>
        <Select2 v-model="tipo_produto"
                 class="w-full z-10"
                 :options="lista_tipo_produto"
                 :settings="{ width: '100%'}"
        />
      </div>

      <div class="flex flex-col gap-2 w-full ">
        <label class="font-bold text-corBase">Produto</label>
        <Select2 v-model="produto"
                 class="w-full z-10"
                 :options="lista_tipo_produto_base"
                 :settings="{ width: '100%'}"
        />
      </div>

      <div class="flex flex-col gap-2 w-full">
        <label class="font-bold text-corBase">Linha</label>
        <Select2 v-model="linha_produto"
                 class="w-full z-10"
                 :options="Lista_linha_produto_base"
                 :settings="{ width: '100%'}"
        />
      </div>

      <div class="flex flex-col gap-2 w-full">
        <label class="font-bold text-corBase">Cor Base:</label>
        <Select2 v-model="cor_base"
                 class="w-full z-10"
                 :options="lista_cor_produto_base"
                 :settings="{ width: '100%'}"
        />
      </div>

    </div>

    <div class=" flex w-full justify-between px-24 py-4">
      <button @click.prevent="getListProdutosInventario" class="btn-enviar">Listar</button>
      <button @click.prevent="getCsvProdutosInventario" class="btn-enviar">Gerar CSV</button>
    </div>
    <input type="file" @change="handleFileUpload">

    <div class="mt-8">
      <table class="container-tabela overflow-auto">
        <thead class="cabecalho-tabela">
        <tr>
          <th class="px-6 py-3">
            COD. Fornecedor
          </th>
          <th class="px-6 py-3">
            Descrição
          </th>
          <th class="px-6 py-3">
            QTD. Atual
          </th>
          <th scope="col" class="px-6 py-3">
            QTD. Inventario
          </th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(produto, index) in csv" :key="index" class="escopo-tabela">
          <td class="px-6 py-2 text-xs">
            {{ produto.codigo_fornecedor }}
          </td>
          <td class="px-6 py-2 text-xs">
            {{ produto.nome_especifico }}
          </td>
          <td class="px-6 py-2 text-xs text-center">
            {{ produto.quantidade }}
          </td>
          <td class="px-6 py-2 text-xs ">
            <input
                class="px-4"
                type="number"
                v-model="produto.quantidade_inventario"
            >
          </td>


        </tr>
        </tbody>
      </table>

      <button @click.prevent="postListProdutosInventario(csv)">Salvar</button>
    </div>
    <mensagem-sucesso v-if="sucesso === true" mensagem="Pré inventário criado com sucesso!"
                      rotas="/estoque/inventarios"/>
  </div>

</template>

<script>
import axios from "axios";
import Select2 from "vue3-select2-component";
import MensagemSucesso from "@/components/toasts/toast_sucesso/MensagemSucesso.vue";

export default {
  name: "Inventario",

  components: {
    Select2,
    MensagemSucesso
  },

  data() {
    return {
      lista_tipo_produto: [],
      lista_tipo_produto_base: [],
      Lista_linha_produto_base: [],
      lista_cor_produto_base: [],
      produto: '',
      tipo_produto: '',
      linha_produto: '',
      cor_base: '',
      produtos: [],
      csv: [],
      inventario: null,
      sucesso: false,
      filtro_codigo: ''
    }
  },

  created() {
    this.getListaCor()
    this.getTipoProdutoBase();
    this.getProdutoTipoBase();
    this.getListaLinha();
  },

  methods: {

    async handleFileUpload(event) {
      const file = event.target.files[0];
      const reader = new FileReader();

      // Evento de conclusão de leitura do arquivo
      reader.onload = async (e) => {
        try {
          // Processar os dados do CSV
          const csvData = [];

          // Dividir o conteúdo do CSV em linhas
          const lines = e.target.result.split('\n');

          // Iterar sobre as linhas, começando da segunda linha (índice 1)
          for (let i = 1; i < lines.length; i++) {
            // Dividir cada linha em colunas usando a vírgula como delimitador
            const columns = lines[i].split(',');

            // Verificar se há colunas suficientes
            if (columns.length >= 4) {
              // Criar o objeto com as propriedades necessárias
              const csvObject = {
                codigo_fornecedor: columns[0],
                nome_especifico: columns[1],
                quantidade: parseFloat(columns[2]),
                quantidade_inventario: parseFloat(columns[3])
              };

              // Adicionar o objeto ao array csvData
              csvData.push(csvObject);
            }
          }

          // Atualize this.csv com os dados do CSV processado
          this.csv = csvData;

          // Exemplo de como usar os dados processados:
          console.log(this.csv);
        } catch (error) {
          console.error('Erro ao processar o arquivo CSV:', error);
        }
      };

      // Ler o conteúdo do arquivo como texto
      reader.readAsText(file);
    },


    async getCsvProdutosInventario() {
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest');
      this.$store.dispatch('setLoading', true)

      const response = await axios.get(`produtos/download-csv?ativo=True&codigo_base=${this.filtro_codigo}&cor_base_id=${this.cor_base}&linha_id=${this.linha_produto}&produto_id=${this.produto}&tipo_id=${this.tipo_produto}&is_lista=False`, {
        headers: {
          'Authorization': `Token ${token}`
        },
        responseType: 'blob' // Define o tipo de resposta como blob (Binary Large OBject)
      });
      this.$store.dispatch('setLoading', false)
      console.log(response.data)
      // Cria um URL temporário para o arquivo blob
      const url = window.URL.createObjectURL(new Blob([response.data]));

      // Cria um link <a> para iniciar o download
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'produtos.csv'); // Define o nome do arquivo
      document.body.appendChild(link);

      // Simula um clique no link para iniciar o download
      link.click();

      // Remove o link após o download
      document.body.removeChild(link);
    },


    async getListProdutosInventario() {
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest');
      this.$store.dispatch('setLoading', true)

      try {
        const result = await axios.get(`produtos/produto-por-fornecedor?ativo=True&page_size=99999&codigo_base=${this.filtro_codigo}&cor_base=${this.cor_base}&linha=${this.linha_produto}&tipo_produto=${this.tipo_produto}&produto=${this.produto}`, {
          headers: {
            'Authorization': `Token ${token}`
          }
        });
        console.log(result.data)
        this.csv = result.data.results
        this.$store.dispatch('setLoading', false)
      } catch (err) {
        console.error(err)
      }
    },

    // async getProduto() {
    //   this.csv = await this.getListProdutosInventario(`produtos/produto-por-fornecedor?cor_base=${this.cor_base}&linha=${this.linha_produto}&tipo_produto=${this.tipo_produto}&produto=${this.produto}`)
    //   console.log(this.csv)
    // },

    async postListProdutosInventario(data) {
      try {
        this.sucesso = false
        const token = await this.$store.dispatch('getAuthTokenCookie');
        await this.$store.dispatch('fetchURLrequest');

        const requestData = {
          tipo_inventario: "pre_inventario",
          produtos: data
        };

        const result = await axios.post('produtos/inventario/', requestData, {
           headers: {
             'Authorization': `Token ${token}`,
             'Content-Type': 'application/json'  // Definindo o tipo de conteúdo como JSON
           }
         });

        this.sucesso = true
        this.produtos = []
        this.csv = []
        this.inventario = null

        console.log(requestData);
      } catch (error) {
        console.error('Erro ao enviar requisição:', error);
      }
    },

    async getTipoProdutoBase() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get('produtos/produtos-sistema', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            console.log(response.data)
            this.lista_tipo_produto = response.data.results.map(item => ({
              id: item.id,
              text: item.nome
            }))
          })
    },
    async getProdutoTipoBase() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get('produtos/tipo-produto-base', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            console.log(response.data)
            this.lista_tipo_produto_base = response.data.results.map(item => ({
              id: item.id,
              text: item.nome
            }))
          })
    },
    async getListaProdutos() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      try {
        const result = await axios.get(`produtos/produto-por-fornecedor?tipo_produto=${this.tipo_produto}&produto=${this.produto}&cor_base=${this.cor_base}`, {
          headers: {
            'Authorization': `Token ${token}`
          }
        })
        this.produtos = result.data.results.map(item => ({
          id: item.id,
          text: item.nome
        }))
        console.log(this.produtos)
      } catch (err) {
        console.error(err)
      }
    },
    async getListaLinha() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      try {
        const result = await axios.get(`produtos/linha-produto-base/`, {
          headers: {
            'Authorization': `Token ${token}`
          }
        })
        this.Lista_linha_produto_base = result.data.results.map(item => ({
          id: item.id,
          text: item.nome
        }))
      } catch (err) {
        console.error(err)
      }
    },
    async getListaCor() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      try {
        const result = await axios.get(`produtos/cor-produto-base/`, {
          headers: {
            'Authorization': `Token ${token}`
          }
        })
        this.lista_cor_produto_base = result.data.results.map(item => ({
          id: item.id,
          text: item.nome
        }))
      } catch (err) {
        console.error(err)
      }
    },

  }
}
</script>

<style lang="scss">

</style>