<template>

  <div class="container-formulario">
    <galeria v-if="galeria === true" @fechar_galeria="abrir_galeria" @objetoClicado="atualizarFileInput"/>
    <!-- TILO DO FORMULARIO + BOTAO DE VOLTAR -->
    <div class="cabecalho-formulario">
      <h1 class="text-xl text-corBase font-bold">Dados Produto Base:</h1>

      <router-link to="/produtos/produto-base" class="text-xl text-corBase font-bold">
        <i class="fas fa-chevron-left"></i>
      </router-link>
    </div>

    <!-- Escopo do formulario de cadastro mobile-->
    <form @submit.prevent="cadastrarProdutoBase" enctype="multipart/form-data">
      <!-- PARTE DE DADOS DO PRODUTO -->
      <div class="div-produtos">
        <div class="flex flex-col w-[40vw]">
          <label class="text-corBase text-xl font-bold">Adicione uma imagem:</label>
          <div class="imagePreviewWrapper" :style="{ 'background-image': `url(${previewImage.url_documento})` }">
          </div>
          <div class="flex gap-3 mt-4">
            <button class="selecionar-arquivo" @click.prevent="abrir_galeria">
              Pesquisar
            </button>

          </div>

        </div>


        <div class="flex flex-col w-full gap-[14px] ml-4 mr-1">
          <div class="flex items-center gap-3 w-full mt-3">
            <div class="campo-chapa">
              <label class="text-xl text-corBase font-bold">Produto:</label>
              <v-select
                  label="nome"
                  :options="lista_tipo_produto_base"
                  v-model="produto"
                  @input="fetchTypeProducts"
              />
            </div>
            <div class="campo-chapa">
              <label class="text-xl text-corBase font-bold">Linha:</label>
              <v-select
                  label="nome"
                  :options="lista_linha_produto_base"
                  v-model="linha"
                  @input="fetchLinhas"
              />
            </div>
          </div>
          <div class="campo-chapa">
            <label class="text-xl text-corBase font-bold">Descrição:</label>
            <input type="text" v-model="nome_base" class="input-form-chapa" autofocus>
          </div>
          <div class="div-produtos ">
            <div class="flex flex-col gap-1 w-full">
              <label class="text-xl text-corBase font-bold">Código:</label>
              <input type="text" v-model="codigo_base" class="input-form-chapa" autofocus>
            </div>
            <div class="flex flex-col gap-1 w-full">
              <label class="text-xl text-corBase font-bold">Peso KG:</label>
              <input type="text" v-model="peso_base" class="input-form-chapa" autofocus>
            </div>
          </div>


          <div class="flex items-center gap-3 w-full mt-3">
            <div class="campo-chapa">
              <label class="text-xl text-corBase">Unidade Medida:</label>
              <v-select
                  label="nome"
                  :options="lista_unidade_medida"
                  v-model="unidade_medida"
                  @input="fetchUnidadeDeMedida"
              />
            </div>
            <unidade-medida-modal/>

          </div>

          <div class="flex items-center gap-3 w-full mt-3">
            <div class="campo-chapa">
              <label class="text-xl text-corBase">Tipo:</label>
              <v-select
                  label="nome"
                  :options="lista_produtos_sistema"
                  v-model="tipo_produto"
                  @input="fetchProdutoSistema"
              />
            </div>

            <div v-if="tipo_produto" class="campo-chapa">
              <label class="text-xl text-corBase">Cor Base:</label>
              <v-select
                  label="nome"
                  :options="lista_cores"
                  v-model="cor_produto"
                  @input="fetchCores"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="flex w-full justify-end">
        <button class="btn-enviar" type="submit">Cadastrar</button>
      </div>
    </form>

  </div>

  <mensagem-sucesso v-if="sucesso === true" mensagem="Produto Base Cadastrado com Sucesso!"
                    rotas="/produtos/produto-base"></mensagem-sucesso>

  <messagem-erro-base v-if="erro === true" mensagem="Produto com Código Base já Existe!"
                      rotas="/produtos/produto-base"></messagem-erro-base>
</template>

<script>
import axios from "axios";
import Galeria from '@/components/midais/ged/Galeria'
import vSelect from "vue-select";
import MensagemSucesso from "@/components/toasts/toast_sucesso/MensagemSucesso";
import UnidadeMedidaModal from "@/components/midais/produto/UnidadeMedidaModal";
import MessagemErroBase from "@/components/toasts/toast_erro/MessagemErroBase";

export default {
  name: "CadastroProdutoBase",

  components: {
    vSelect,
    MensagemSucesso,
    MessagemErroBase,
    Galeria,
    UnidadeMedidaModal
  },


  data() {
    return {
      sucesso: false,
      erro: false,
      previewImage: {},
      lista_unidade_medida: [],
      lista_produtos_sistema: [],
      lista_cores: [],
      lista_tipo_produto_base: [],
      lista_linha_produto_base: [],
      linhasFiltradas: [],
      cor_produto: null,
      tipo_produto: null,
      unidade_medida: null,
      codigo_auxiliar: '',
      codigo_mercado: '',
      codigo_base: '',
      nome_base: '',
      peso_base: '',
      linha: null,
      produto: null,

      galeria: false
    }
  },

  computed: {
    minhaEmpresa() {
      return this.$store.state.minhaEmpresa;
    },

    isadmin() {
      return this.$store.state.isAdminUser
    },

  },


  methods: {
    async fetchTypeProducts(searchInput) {
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest');

      if (!searchInput) {
        this.lista_tipo_produto_base = [];
        return;
      }

      axios.get('produtos/tipo-produto-base', {
        params: {
          nome: this.produto
        },
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            console.log(response.data);
            this.lista_tipo_produto_base = response.data.results;
            // this.filteredOptionsTipoProdutos = this.lista_tipo_produto_base.map(item => item.nome);
          })
          .catch(error => {
            console.log(error);
          });
    },

    handleSelect(value) {
      this.getListaCores(value.id)
    },

    async fetchProdutoSistema(searchInput) {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      if (!searchInput) {
        this.lista_produtos_sistema = [];
        return;
      }

      axios.get('produtos/produtos-sistema', {
        params: {
          nome: this.tipo_produto
        },
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            console.log(response.data);
            this.lista_produtos_sistema = response.data.results;
            // this.produtoSistemaFiltrado = this.lista_produtos_sistema.map(item => item.nome);
          })
          .catch(error => {
            console.log(error);
          });

    },

    async fetchCores(searchInput) {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')
      if (!searchInput) {
        this.lista_cores = [];
        return;
      }

      axios.get(`produtos/cor-produto-base/`, {
        params: {
          nome: this.cor_produto,
          tipo_produto: this.tipo_produto
        },
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            console.log(response.data);
            this.lista_cores = response.data.results;
            // this.coresFiltradas = this.lista_cores.map(item => item.nome);
          })
          .catch(error => {
            console.log(error);
          });

    },

    atualizarFileInput(objeto) {
      console.log('objeto que chegou:' + objeto)
      console.log(objeto)
      this.previewImage = objeto;
    },
    abrir_galeria() {
      this.galeria = !this.galeria
    },
    handleImagemUpload(event) {
      this.fileInput = event.target.files[0];
    },

    async fetchUnidadeDeMedida(searchInput) {
      const token = await this.$store.dispatch('getAuthTokenCookie')

      await this.$store.dispatch('fetchURLrequest')

      if (!searchInput) {
        this.lista_unidade_medida = [];
        return;
      }

      axios.get('produtos/unidade-medida', {
        params: {
          nome: this.unidade_medida
        },
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            console.log(response.data);
            this.lista_unidade_medida = response.data.results;
            // this.unidadesFiltradas = this.lista_unidade_medida.map(item => item.nome);
          })
          .catch(error => {
            console.log(error);
          });

    },

    async fetchLinhas(searchInput) {
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest');

      if (!searchInput) {
        this.lista_linha_produto_base = [];
        return;
      }

      axios.get('produtos/linha-produto-base', {
        params: {
          nome: this.linha
        },
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            console.log(response.data);
            this.lista_linha_produto_base = response.data.results;
            // this.linhasFiltradas = this.lista_linha_produto_base.map(item => item.nome);
          })
          .catch(error => {
            console.log(error);
          });

    },

    async cadastrarProdutoBase() {
      const token = await this.$store.dispatch('getAuthTokenCookie')

      await this.$store.dispatch('fetchURLrequest')

      const data = new FormData
      data.append('codigo_base', this.codigo_base)
      data.append('nome_base', this.nome_base)
      data.append('unidade_medida', this.unidade_medida.id)
      if (this.previewImage.id) {
        data.append('imagem', this.previewImage.id)
      } else {
        data.append('imagem', "52c5d707-e072-468c-b37c-000d7c240ca7")
      }
      data.append('tipo_produto', this.tipo_produto.id)
      data.append('peso_base', this.peso_base)
      data.append('linha', this.linha.id)
      data.append('produto', this.produto.id)
      data.append('ativo', 'True')
      this.$store.dispatch("setLoading", true);
      this.sucesso = false
      await axios.post('produtos/produto/', data, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            console.log(response.data.results)
            this.sucesso = true
            this.codigo_mercado = ''
            this.codigo_base = ''
            this.ncm = ''
            this.passo = ''
            this.nome_base = ''
            this.unidade_medida = null
            this.peso_base = ''
            this.tipo_produto = null
            this.previewImage = {}
            this.$store.dispatch("setLoading", false);
          })
          .catch(error => {
            console.log(error)
            if (error.code == 'ERR_BAD_REQUEST') {
              this.erro = true
            }
            this.codigo_mercado = ''
            this.codigo_base = ''
            this.ncm = ''
            this.passo = ''
            this.nome_base = ''
            this.unidade_medida = null
            this.peso_base = ''
            this.tipo_produto = null
            this.$store.dispatch("setLoading", false);
          })

    }


  }
}
</script>

<style lang="scss">
.imagePreviewWrapper {
  @apply w-full h-[300px] mt-2 bg-contain bg-center bg-no-repeat block cursor-pointer border border-corBase/10 bg-white
}

//protudo
.div-produtos {
  @apply flex gap-3 my-3 w-full
}

//chapa
.campo-chapa {
  @apply flex flex-col gap-1 w-full
}

.input-form-chapa {
  @apply w-full bg-white border-2 border-corBase/5 border-b-corBase/20 focus:border-b-corBase/60 px-1 py-1 text-corBase shadow-sm shadow-corBase/20
  text-corBase focus:border-b-corBase/80 outline-none focus:transition focus:duration-500
}

.selecionar-arquivo {
  @apply bg-screenCor p-1.5 w-full border-2 border-b-corBase text-corBase text-center shadow-sm shadow-corBase/20
}
</style>