<template>
  <div class="container-modal-editar">
    <div class="sombra-modal">
      <div class="conteudo-modal-editar">
        <div class="flex justify-between py-4 border-b border-gray-200">
          <h1 class="text-2xl font-bold">Cadastro de Nova Coluna</h1>
          <button @click.prevent="fecharModal" class="text-gray-500 hover:text-red-500 focus:outline-none">
            <i class="fas fa-xmark"></i>
          </button>
        </div>
        <div class="w-full flex flex-col gap-8">
          <form class="w-full flex flex-col gap-8">
            <h1 class="text-2xl font-bold">{{ quadro.nome.toUpperCase() }}</h1>
            <div class="flex flex-col gap-2 w-full">
              <label>Nome Coluna</label>
              <input v-model="nome_coluna" type="text"/>
            </div>

          </form>
          <div class="">
            <button @click.prevent="createNewColumn" class="btn-enviar">Salvar</button>
          </div>
        </div>
      </div>
    </div>
  </div>


</template>

<script>
import axios from "axios";

export default {
  name: 'NovaColuna',

  data() {
    return {
      nome_coluna: '',
    }
  },

  props: {
    quadro: {
      type: Object,
      required: true
    }
  },

  methods: {
    fecharModal() {
      this.$emit('fechar')
    },

    async createNewColumn() {
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest');

      try {
        this.$store.dispatch("setLoading", true);
        const data = {
          nome: this.nome_coluna,
          quadro: this.quadro.id,
          ordem: 0,
        }
        const result = await axios.post('kanban/coluna/', data, {
          headers: {
            'Authorization': `token ${token}`
          }
        })
        console.log(result.data.results)
        this.$store.dispatch("setLoading", false);
      } catch(error) {
        console.error(error)
        this.$store.dispatch("setLoading", false);
      }
    }

  }
}
</script>

<style>

</style>