<template>
  <div class="container-modelo-tabela">
    <table class="container-tabela overflow-auto">
      <thead class="cabecalho-tabela">
      <tr>
        <th scope="col" class="px-3 py-3">
          #
        </th>
        <th scope="col" class="p-4">
          <div class="flex items-center">
            <input type="checkbox" class="checkbox" v-model="checkAll" @change="selectAll">

            <label class="sr-only">checkbox</label>
          </div>
        </th>
        <th class="px-3 py-3">
          Componente
        </th>
        <th class="px-3 py-3">
          Imagem
        </th>
        <th scope="col" class="px-3 py-3">
          Descrição
        </th>
        <th scope="col" class=" py-3">
          QTDE
        </th>
        <th scope="col" class=" py-3">
          Condição
        </th>
        <th scope="col" class=" py-3">
          Observação
        </th>
        <th scope="col" class=" py-3">
          Aplicação
        </th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(componente, index) in componentes" :key="index" class="escopo-tabela">
        <th scope="row" class="px-3 py-2 font-medium text-xs text-gray-900 whitespace-nowrap dark:text-white">
          {{ index + 1 }}
        </th>
        <td class="w-4 p-4">
          <div class="selec">
            <input :id="'checkbox-table-' + (index + 2)" type="checkbox" class="checkbox"
                    v-model="componente.selected">
            <label class="sr-only">checkbox</label>
          </div>
        </td>
        <td class="px-3 py-2 text-xs">
          {{ componente.componente_id.codigo_base }}
        </td>
        <td class="px-3 py-2 text-xs">
          <!-- <img class="w-12" :src="componente.componente_id.imagem.url_documento"> -->
        </td>
        <td class="px-3 w-60 py-2 text-xs">
          {{ componente.componente_id.nome_base }}
        </td>
        <td class=" py-2 text-xs">
          <input class="input-campos" v-model="componente.qtd_formula">
          <!--                  {{ componente.qtd_formula }}-->
        </td>
        <td class=" py-2 text-xs">
          <input class="input-campos" v-model="componente.condicao">
          <!--                  {{ componente.condicao }}-->
        </td>
        <td class=" py-2 text-xs">
          <input class="input-campos" v-model="componente.observacao">
          <!--                  {{ componente.observacao }}-->
        </td>
        <td class=" py-2 text-xs">
          <input class="input-campos" v-model="componente.aplicacao">
          <!--                  {{ componente.aplicacao }}-->
        </td>

      </tr>
      </tbody>
    </table>
    <table class="container-tabela overflow-auto" v-if="novosComponentes.length">
      <thead class="cabecalho-tabela">
        <th class="w-[28vw] px-6">
          Busque o Componente:
        </th>
        <th scope="col" class="py-3">
          QTDE
        </th>
        <th scope="col" class="py-3">
          Condição
        </th>
        <th scope="col" class="py-3">
          Observação
        </th>
        <th scope="col" class="py-3">
          Aplicação
        </th>
      </thead>
      <tbody>
        <tr v-for="(novoComponente, index) in novosComponentes" :key="index" class="escopo-tabela">
          <td class="px-6 py-2 text-xs">
            <Search
              v-model="componenteSelecionado"
              :options="componentesPesquisados"
              :labelKey="getLabelKey"
              valueKey="id"
              :nameKey="getNameKey"
              @select="onSelect"
              @shouldSearch="buscarComponentes"
              class="w-[25vw]"
            />
          </td>
          <td class="py-2 text-xs">
            <input class="input-campos" v-model="novoComponente.qtd_formula">
          </td>
          <td class="py-2 text-xs">
            <input class="input-campos" v-model="novoComponente.condicao" list="fixacao">
          </td>
          <td class="py-2 text-xs">
            <input class="input-campos" v-model="novoComponente.observacao">
          </td>
          <td class="py-2 text-xs">
            <input class="input-campos" v-model="novoComponente.aplicacao" list="lado">
            <datalist id="lado">
              <option value="A Definir"></option>
              <option value="Obra"></option>
              <option value="Fabrica"></option>
              <option value="Embalagem"></option>
              <option value="Expedicao"></option>
            </datalist>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="flex w-full justify-end itens-center mt-6 px-4 mb-5" v-if="novosComponentes.length">
      <button 
        class="btn-salvar" 
        @click.prevent="postNovoComponente">
          Adicionar
      </button>
    </div>

    <div class="flex justify-center" v-if="novosComponentes.length === 0">
      <button class="text-ativo" @click.prevent="adicionarComponente">
        <i class="fas fa-plus-circle"></i>
      </button>
    </div>
  </div>
  <div class="flex w-full justify-end itens-center mt-6">
    <button
      class="btn-cancelar" 
      @click.prevent="modal_confirmacao = true"
      v-if="checkSome || checkAll"
    >
      Excluir Selecionados
    </button>
    <button class="btn-cancelar" @click.prevent="getListaComponentes">Cancelar</button>
    <button class="btn-salvar" @click.prevent="atualizarComponentes">Salvar</button>
  </div>

  <MensagemConfirmacaoExclusao
    mensagem="Deseja mesmo excluir os itens selecionados?"
    :abrir="modal_confirmacao"
    @feharModal="feharModalConfirmacao"
    @confirmarExclusao="excluirSelecionados"
  />
</template>

<script>
import Search from '@/utils/Search.vue';
import axios from 'axios'
import MensagemConfirmacaoExclusao from '@/components/toasts/toast_notificacao/MensagemConfirmacaoExclusao.vue'

export default {
  name: "ComponenteProjeto",

  props: ['componentes', 'projeto_id'],

  components: {
    Search,
    MensagemConfirmacaoExclusao,
  },

  computed: {
    checkSome() {
      return this.componentes.some(componente => componente.selected)
    }
  },

  data() {
    return {
      novosComponentes: [],
      componenteSelecionado: {},
      componentesPesquisados: [],

      checkAll: false,
      modal_confirmacao: false,
    }
  },

  methods: {
    atualizarComponentes() {
      this.$emit('atualizarComponentes', this.componentes)
    },

    getLabelKey(option) {
      return option.codigo_base ? option.codigo_base : ''
    },

    getNameKey(option) {
      return option.nome_base ? option.nome_base : ''
    },


    onSelect(componente) {
      this.componenteSelecionado = componente
    },

    selectAll() {
      // Atualiza o estado dos demais checkboxes
      const checkboxes = document.getElementsByClassName('checkbox');
      checkboxes.forEach((checkbox) => {
        checkbox.checked = this.checkAll;
      });
    },

    excluirSelecionados() {
      if(this.checkAll) {
        this.deletarComponentes(this.componentes)
        this.modal_confirmacao = false
        this.checkAll = false
        return
      }
      const componentesADeletar = this.componentes.filter((componente) => {
        if(componente.selected){
          return componente
        }
      })

      this.deletarComponentes(componentesADeletar)
      this.modal_confirmacao = false
    },

    async deletarComponentes(componentesADeletar) {
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest')

      const componentesADeletarPromisses = []

      for(const componente of componentesADeletar){

        componentesADeletarPromisses.push(
          axios.delete(`projeto/projeto-componente/${componente.id}/`, {
            headers: {
              Authorization: `Token ${token}`
            }
          })
        )
      }

      await Promise.all(componentesADeletarPromisses)

      this.$emit('getListaComponentesProjeto')
    },


    async buscarComponentes(pesquisa) {
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest')

      const response = await axios.get(`produtos/produto/?tipo_produto=Componente&filtro_base=${pesquisa}&page_size=9999`, {
        headers: {
          Authorization: `Token ${token}`
        }
      })

      this.componentesPesquisados = response.data.results
    },

    adicionarComponente() {
      this.novosComponentes.push({
        projeto_id: this.projeto_id,
        componente_id: this.componenteSelecionado,
        qtd_formula: '',
        condicao: '',
        observacao: '',
        aplicacao: '',
      })
    },

    async postNovoComponente() {
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest')

      const novosComponentesPromisses = []

      for(const novoComponente of this.novosComponentes){
        novoComponente.componente_id = this.componenteSelecionado.id

        novosComponentesPromisses.push(
          axios.post('projeto/projeto-componente/', novoComponente, {
            headers: {
              Authorization: `Token ${token}`
            }
          })
        )
      }

      await Promise.all(novosComponentesPromisses)

      this.novosComponentes = []
      this.$emit('getListaComponentesProjeto')

    }
  }
}
</script>

<style scoped>

</style>