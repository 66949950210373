<template>
  <button class="ml-3 text-ativo" @click.prevent="modalPessoaCadastro">
    <i class="fas fa-plus-circle"></i>
  </button>

  <div class="container-modal-cadastro" v-if="modelPessoaFisica">
    <section class="flex justify-center block">

      <div class="container-formulario-mibile">

        <!-- TILO DO FORMULARIO + BOTAO DE VOLTAR -->
        <div class="cabecalho-formulario">
          <h1 class="text-xl text-corBase font-bold">Cadastro Pessoa Física</h1>

          <button @click="fecharModal"  class="text-xl text-corBase font-bold">
            <i class="fa-solid fa-xmark"></i>
          </button>
        </div>

        <!-- Escopo do formulario de cadastro mobile-->
        <form class="formulario" @submit.prevent="cadastrarPessoa">
          <label class="label-formulario">Nome Completo:</label>
          <input
              type="text"
              required
              class="campo-formulario"
              placeholder=""
              autofocus
              v-model="nome_completo"
          >

          <label class="label-formulario">Apelido:</label>
          <input
              type="text"
              required
              class="campo-formulario"
              placeholder=""
              autofocus
              v-model="apelido"
          >

          <label class="label-formulario">CPF:</label>
          <input
              type="text"
              required
              class="campo-formulario"
              placeholder="xxx.xxx.xxx-xx"
              autofocus
              v-model="cpf"
          >

          <label class="label-formulario">RG:</label>
          <input
              type="text"
              required
              class="campo-formulario"
              placeholder="xx.xxx.xxx-x"
              autofocus
              v-model="rg"
          >

          <label class="label-formulario">Data de Nascimento:</label>
          <input
              type="date"
              required
              class="campo-formulario"
              v-model="nascimento"
          >

          <label class="label-formulario">Tipo pessoa geral:</label>
          <div class="flex items-center">
            <select class="campo-formulario w-full" v-model="tipoPessoaID">
              <option>------</option>
              <option v-for="item in tipo_pessoa"
                      :value="item.id"
                      :key="item.id"
              >{{ item.descricao }}
              </option>
            </select>


            <tipo-pessoa-geral-modal/>

          </div>

          <!--        Cadastro do Endereço-->
          <div class="flex flex-col border-t border-corBase/30 mt-6">
            <h2 class="text-xl text-corBase font-bold mt-6">Endereço</h2>

            <label class="label-formulario">CEP:</label>
            <input type="text"
                   required
                   placeholder=""
                   class="campo-formulario"
                   v-model="cep"
            >

            <label class="label-formulario">Endereço:</label>
            <input type="text"
                   required
                   placeholder=""
                   class="campo-formulario"
                   v-model="rua"
            >

            <label class="label-formulario">Número:</label>
            <input type="number"
                   required
                   class="campo-formulario"
                   v-model="numero"
            >

            <label class="label-formulario">Apartamento:</label>
            <input type="text"
                   required
                   class="campo-formulario"
                   v-model="apartamento"
            >

            <label class="label-formulario">Referência:</label>
            <input type="text"
                   required
                   class="campo-formulario"
                   v-model="referencia"
            >

            <label class="label-formulario">Tipo de Endereço:</label>
            <div class="flex items-center">
              <select class="campo-formulario w-full" v-model="tipoEnderecoID">
                <option>------</option>
                <option v-for="item in tipo_endereco"
                        :value="item.id"
                        :key="item.id"
                >{{ item.descricao }}
                </option>
              </select>

              <tipo-endereco-modal/>
            </div>


          </div>

          <!--        Cadastro do Telefone-->
          <div class="flex flex-col border-t border-corBase/30 mt-6">
            <h2 class="text-xl text-corBase font-bold mt-6">Telefones</h2>

            <label class="label-formulario">Telefone:</label>
            <input type="tel"
                   required
                   class="campo-formulario"
                   v-model="numero_telefone"
            >

            <label class="label-formulario">Tipo de Telefone:</label>
            <div class="flex items-center">
              <select class="campo-formulario w-full" v-model="tipoTelefoneID">
                <option value="">------</option>
                <option v-for="item in tipo_telefone"
                        :key="item.id"
                        :value="item.id"
                >{{ item.descricao }}
                </option>
              </select>

              <tipo-telefone-modal/>
            </div>
          </div>
          <button class="btn-enviar mt-8 mx-auto" type="submit" @click="fecharModal">Cadastrar</button>
        </form>
      </div>
      <sucesso-cadastro-pessoa-fisica v-if="sucesso == true"/>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import TipoPessoaGeralModal from "@/components/midais/modaisCadastro/TipoPessoaGeralModal";
import TipoEnderecoModal from "@/components/midais/modaisCadastro/TipoEnderecoModal";
import TipoTelefoneModal from "@/components/midais/modaisCadastro/TipoTelefoneModal";
import SucessoCadastroPessoaFisica from "@/components/toasts/toast_sucesso/SucessoCadastroPessoaFisica";

export default {
  name: "PessoaFisicaModal",

  components: {
    TipoPessoaGeralModal,
    TipoEnderecoModal,
    TipoTelefoneModal,
    SucessoCadastroPessoaFisica,
  },

  data() {
    return {
      modelPessoaFisica: false,

      tipoPessoaID: '',
      tipoEnderecoID: '',
      tipoTelefoneID: '',
      nome_completo: '',
      apelido: '',
      cpf: '',
      rg: '',
      nascimento: '',
      tipo_pessoa: {},
      cep: '',
      rua: '',
      numero: '',
      apartamento: '',
      referencia: '',
      tipo_endereco: {},
      numero_telefone: '',
      tipo_telefone: {},
      mensagem: '',
      erro: '',
      sucesso: false,
    }
  },

  created() {
    this.getTipoPessoa();

    this.getTipoEndereco();

    this.getTipoTelefone();
  },

  methods: {
    modalPessoaCadastro() {
      this.modelPessoaFisica = !this.modelPessoaFisica
    },

    fecharModal() {
      this.modelPessoaFisica = !this.modelPessoaFisica
      console.log('fui clicado')
    },

    async getTipoPessoa() {
      const token = await this.$store.dispatch('getAuthTokenCookie'); // Obtém o token do localStorage
      await this.$store.dispatch('fetchURLrequest')
      await axios.get('/pessoas/tipo-pessoas', {
        headers: {
          'Authorization': `Token ${token}` // Define o cabeçalho Authorization com o token
        }
      })
          .then(response => {
            this.tipo_pessoa = response.data
          })
          .catch(error => {
            if (error.response) {
              for (const property in error.response.data) {
                this.errors.push(`${property}: ${error.response.data[property]}`)
              }
            } else if (error.message) {
              this.errors.push('Something went wrong. Please try again!')
            }
          })
    },

    async getTipoEndereco() {
      const token = await this.$store.dispatch('getAuthTokenCookie'); // Obtém o token do localStorage
      await this.$store.dispatch('fetchURLrequest')
      await axios.get('pessoas/enderecos/tipo-enderecos', {
        headers: {
          'Authorization': `Token ${token}` // Define o cabeçalho Authorization com o token
        }
      })
          .then(response => {
            this.tipo_endereco = response.data
            console.log(response.data)
          })
          .catch(error => {
            if (error.response) {
              for (const property in error.response.data) {
                this.errors.push(`${property}: ${error.response.data[property]}`)
              }
            } else if (error.message) {
              this.errors.push('Something went wrong. Please try again!')
            }
          })
    },

    async getTipoTelefone() {
      const token = await this.$store.dispatch('getAuthTokenCookie'); // Obtém o token do localStorage
      await this.$store.dispatch('fetchURLrequest')
      await axios.get('pessoas/telefones/tipo-telefones', {
        headers: {
          'Authorization': `Token ${token}` // Define o cabeçalho Authorization com o token
        }
      })
          .then(response => {
            this.tipo_telefone = response.data
            console.log(response.data)
          })
          .catch(error => {
            if (error.response) {
              for (const property in error.response.data) {
                this.errors.push(`${property}: ${error.response.data[property]}`)
              }
            } else if (error.message) {
              this.errors.push('Something went wrong. Please try again!')
            }
          })
    },

    async cadastrarPessoa() {
      try {
        const data = {
          nome_razao: this.nome_completo,
          nome: this.nome_completo,
          apelido: this.apelido,
          cpf: this.cpf,
          rg: this.rg,
          nascimento: this.nascimento,
          tipo_pessoa_geral: this.tipoPessoaID,
          endereco: this.rua,
          numero: this.numero,
          apartamento: this.apartamento,
          cep: this.cep,
          referencia: this.referencia,
          tipo_endereco: this.tipoEnderecoID,
          telefone: this.numero_telefone,
          tipo_telefone: this.tipoTelefoneID,
        };
        console.log(data)
        const token = await this.$store.dispatch('getAuthTokenCookie');
        await this.$store.dispatch('fetchURLrequest')
        const response = await axios.post('pessoas/', data, {
          headers: {
            'Authorization': `Token ${token}`
          }
        });
        console.log(response)
        this.sucesso = true
        await this.delay(2000);
        this.sucesso = false
      } catch (error) {
        if (error.response) {
          for (const property in error.response.data) {
            this.errors.push(`${property}: ${error.response.data[property]}`)
          }
        } else if (error.message) {
          this.errors.push('Something went wrong. Please try again!')
        }
      }
    },

    delay(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
  }
}
</script>

<style land="scss">
.container-modal-cadastro {
  @apply overflow-y-scroll h-[70vh] w-[95%] bg-screenCor p-4
}
</style>