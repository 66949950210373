<template>


  <div class="container-formulario">

    <!-- TILO DO FORMULARIO + BOTAO DE VOLTAR -->
    <div class="cabecalho-formulario">
      <h1 class="text-xl text-corBase font-bold">Dados Categoria:</h1>

      <router-link to="/lista-modelo-variacao" class="text-2xl text-corBase font-bold">
        <i class="fas fa-chevron-left"></i>
      </router-link>
    </div>

    <!-- Escopo do formulario de cadastro mobile-->
    <form @submit.prevent="cadastrarTipologia">
      <!--        PARTE DE DADOS DO PRODUTO-->
      <div class="flex flex-col md:flex-row gap-3 my-3 w-full ">

        <div class="campo-chapa mt-2">
          <label class="text-xl text-corBase font-bold">Nome:</label>
          <input type="text" v-model="nome" class="input-form-chapa" required autofocus>
        </div>

      </div>


      <div class="flex w-full justify-end">
        <button class="bg-ativo hover:bg-screenCor border border-screenCor hover:border-ativo
  text-white hover:text-ativo py-2 px-4 mt-2" type="submit">Salvar
        </button>
      </div>

    </form>
  </div>
  <toast-error v-if="mensageErroNome === true" mensagem="Modelo Variação já Existe no Sistema!"/>
  <toast-error v-if="mensageErroServidor === true" mensagem="Algo de Errado com o Nosso Servidor! "/>

  <MensagemSucesso v-if="sucesso==true" rotas="/lista-modelo-variacao"
                   mensagem="Categoria Cadastrada com sucesso!"/>

</template>

<script>
import axios from "axios";
import MensagemSucesso from "@/components/toasts/toast_sucesso/MensagemSucesso";
import Galeria from "@/components/midais/ged/Galeria";
import ToastError from "@/components/toasts/toast_erro/ToastError";


export default {
  name: "CadastroModelosVariacoes",

  components: {
    MensagemSucesso,
    Galeria,
    ToastError
  },


  data() {
    return {
      sucesso: false,
      mensageErroNome: false,
      mensageErroServidor: false,
      nome: '',
      codigo_aux: '',
      imagem: null,
      projetistas_sistemistas: [],
      lista_imagens: [],
    }
  },

  created() {
  },

  computed: {
    minhaEmpresa() {
      return this.$store.state.minhaEmpresa;
    },
    isadmin() {
      return this.$store.state.isAdminUser
    },
  },

  methods: {
    limpaInput() {
      if (sucesso === true) {
        this.nome = ''
      }
    },

    async cadastrarTipologia() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      const data = new FormData();
      data.append('ativo', 'True')
      data.append('descricao', this.nome)
      data.append('codigo_aux', this.codigo_aux)
      this.sucesso = false
      this.$store.dispatch("setLoading", true);
      await axios.post('projeto/modelo-variacao/', data, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            this.nome = ''
            this.sucesso = true
            this.$store.dispatch("setLoading", false);
          })
          .catch(async error => {
            const erro = error.response.data.descricao
            if (erro) {
              this.mensageErroNome = !this.mensageErroNome
              await this.delay(2000)
              this.mensageErroNome = !this.mensageErroNome
            } else {
              this.mensageErroServidor = !this.mensageErroServidor
              await this.delay(2000)
              this.mensageErroServidor = !this.mensageErroServidor
            }

            this.$store.dispatch("setLoading", false);

          })

    },

    delay(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },

  }

}
</script>

<style lang="scss"></style>