<template>
  <div class="min-h-screen bg-corBase md:bg-screenCor">
    <navbar v-if="!isLoginPage && !isHomePage"/>
    <sidebar v-if="!isLoginPage && !isHomePage"/>
    <router-view/>
    <loading :isLoading="isLoading"/>
  </div>
</template>

<script>
import './assets/tailwind.css';
import Navbar from '@/components/Navbar.vue';
import Sidebar from '@/components/Sidebar.vue';
import LandingPage from "@/views/LandingPage";
import Loading from "@/utils/Loading";

export default {
  name: 'App',

  components: {
    Navbar,
    Sidebar,
    LandingPage,
    Loading
  },

  beforeCreate() {
    this.$store.commit('initializeStore');
  },

  created() {

    // Verifica se há um token de autenticação no estado do aplicativo
    if (!this.$store.state.token) {
      // Navegue para a página de login
      this.$router.push('/login');
    } else {
      this.showFullscreenAlert = true;
    }
    console.log(this.$store.dispatch('getAuthTokenCookie'))
  },

  data() {
    return {
      showFullscreenAlert: true,
    };
  },

  methods: {
    abre_e_fecha() {
      this.showFullscreenAlert = !this.showFullscreenAlert;
    },

    requestFullscreen() {
      const element = document.documentElement;
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen();
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
      }
      this.abre_e_fecha()
    },
  },

  watch: {
    isLoading(newVal, oldVal) {
      if (newVal !== oldVal) {
        console.log(`O valor de isLoading mudou de ${oldVal} para ${newVal}`);
        // Adicione lógica adicional conforme necessário
      }
    },
  },

  computed: {
    isLoginPage() {
      return this.$route.path === '/login';
    },
    isHomePage() {
      return this.$route.path === '/';
    },

    isadmin() {
      return this.$store.state.isAdminUser
    },

    isLoading() {
      return this.$store.state.isLoading;
    },
  },
};
</script>

<style lang="scss">

</style>
