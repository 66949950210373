<template>
  <ReservaDeEstoqueComponentVue/>
</template>

<script>
import ReservaDeEstoqueComponentVue from '@/components/estoque/ReservaDeEstoqueComponent.vue'

export default {
  name: 'ReservaDeEstoque',

  components: {
    ReservaDeEstoqueComponentVue
  }
}
</script>

<style lang="scss" scoped>

</style>