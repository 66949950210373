<template>
  <div class="carrossel">
    <carrossel-image :slides="slides" :descricao="descricao" :class="carrossel-image"></carrossel-image>
  </div>
</template>

<script>
import CarrosselImage from "@/components/landing_page/carrossel/CarrosselImage.vue"
import VantagensSistema from "@/components/landing_page/VantagensSistema";

export default {
  name: "Carrossel",
  components:{
    VantagensSistema,
    CarrosselImage,
  },

  data(){
    return{
      /*Url fotos*/
      slides:["casa1","casa2","casa3","casa4"],
      /*Descricao das fotos*/
      descricao:["Maximize sua produtividade com nosso sistema.",
        "Melhoria no gerenciamento de projetos.",
        "Controle preciso do estoque",
        "Controle financeiro aprimorado"]
    }
  },
}
</script>

<style scoped>

template{
  @apply bg-white
}
.carrossel{
  @apply w-full h-[90vh] overflow-hidden relative
  sm:h-screen
}
</style>