<template>
  <a @click="alterarModalEditar()" href="#" class="font-medium text-corBase pl-2 hover:underline">
    <i class="fa-solid fa-pen-to-square mr-2"></i>Editar
  </a>
  <div v-if="statusModal === true">
    <div class="container-modal-editar">
      <div class="sombra-modal">
        <div class="conteudo-modal-editar">
          <form class="form-editar text-white" @submit.prevent="putDadosEditado">
            <div class="flex justify-end">
              <button @click.prevent="alterarModalEditar()">
                <i class="fas fa-close text-2xl text-corBase"></i>
              </button>
            </div>
            <h2 class="text-lg text-corBase font-bold mb-2">Editar Dados Cor Perfil</h2>
            <!--Campos da Pessoa-->
            <div>
              <div class="md:flex md:gap-12">
                <div class="flex flex-col mt-2 w-full justify-start">
                  <label class="text-base text-corBase font-bold mt-2">Nome:</label>
                  <input type="text" required class="input-campos text-base" v-model="cor.nome">
                </div>


                <div class="flex flex-col mt-2 w-full justify-start ">
                  <label class="text-base text-corBase font-bold mt-2">Ativo:</label>

                  <span @click.prevent="toggleAtivo" class="text-start pt-1 text-xl">
                    <i v-if="cor.ativo === true" class="fa-solid fa-circle-check text-ativo"></i>
                    <i v-else class="fa-solid fa-circle-xmark text-inativo"></i>
                  </span>
                </div>


              </div>

            </div>

            <!--Botão-->
            <div class="btn-formulario-modal">
              <button class="btn-formulario-salvar" type="submit">
                Salvar
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import ToastError from "@/components/toasts/toast_erro/ToastError";
import Select2 from "vue3-select2-component";

export default {
  name: "EditarCorPerfil",
  components: {
    ToastError,
    Select2
  },
  data() {
    return {
      statusModal: false,
      sucesso: false,
      erroNome: false,

    }
  },
  props: ["cor", "paginaAtual"],

  mounted() {
    window.addEventListener('keydown', this.onEscKeyPressed);

  },

  beforeDestroy() {
    window.removeEventListener('keydown', this.onEscKeyPressed);
  },

  watch: {
    statusModal(newStatus) {
      if (newStatus) {
        window.addEventListener('keydown', this.onEnterKeyPressed);
      } else {
        window.removeEventListener('keydown', this.onEnterKeyPressed);
      }
    }
  },

  methods: {
    toggleAtivo() {
      this.cor.ativo = !this.cor.ativo
    },
    alterarModalEditar() {
      this.statusModal = !this.statusModal;
      this.$parent.getListaCorPerfil()
    },

    onEscKeyPressed(event) {
      if (event.key === 'Escape') {
        this.statusModal = false
        this.$parent.getListaCorPerfil()
      }
    },

    onEnterKeyPressed(event) {
      if (event.key === 'Enter') {
        this.putDadosEditado()
      }
    },

    async putDadosEditado() {
      const dadosAtualizados = new FormData();
      dadosAtualizados.append('ativo', this.cor.ativo)
      dadosAtualizados.append('nome', this.cor.nome)

      try {
        const token = await this.$store.dispatch('getAuthTokenCookie');
        await this.$store.dispatch('fetchURLrequest')
        const response = await axios.put(`produtos/cor-perfil/${this.cor.id}/`, dadosAtualizados, {
          headers: {
            'Authorization': `Token ${token}`,
            'Content-Type': 'application/json'
          }
        });
        console.log(response);
        this.$parent.irParaPagina(this.paginaAtual)
        this.statusModal = false;

      } catch (error) {
        console.log(error)
      }
    },
    delay(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },


  }
}
</script>

<style lang="scss">

</style>