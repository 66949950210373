<template>

  <div class="">

  </div>

  <div class="container-modelo-tabela">
    <table class="container-tabela overflow-auto">
      <thead class="cabecalho-tabela">
      <tr>
        <th scope="col" class="px-3 py-3">
          #
        </th>
        <th scope="col" class="p-4">
          <div class="flex items-center">
            <input type="checkbox" class="checkbox" v-model="checkAll" @change="selectAll">

            <label class="sr-only">checkbox</label>
          </div>
        </th>
        <th class="px-3 py-3">
          Perfil
        </th>
        <th class="px-3 py-3">
          Foto
        </th>
        <th scope="col" class="px-3 py-3">
          Descrição
        </th>
        <th scope="col" class="px-3 py-3">
          Peso
        </th>
        <th scope="col" class="py-3">
          Corte
        </th>
        <th scope="col" class="py-3">
          QTDE/Fórmula
        </th>
        <th scope="col" class="py-3">
          Medida/Fórmula
        </th>
        <th scope="col" class="py-3">
          Posição
        </th>
        <th scope="col" class="py-3">
          Condição
        </th>
        <th scope="col" class="py-3">
          Observação
        </th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(perfil, index) in perfis" :key="index" class="escopo-tabela">
        <th scope="row" class="px-3 py-2 font-medium text-xs text-gray-900 whitespace-nowrap dark:text-white">
          {{ index + 1 }}
        </th>
        <td class="w-4 p-4">
          <div class="selec">
            <input :id="'checkbox-table-' + (index + 2)" type="checkbox" class="checkbox"
                    v-model="perfil.selected">
            <label class="sr-only">checkbox</label>
          </div>
        </td>
        <td class="px-3 py-2 text-xs">
          {{ perfil.perfil_id.codigo_base }}
        </td>
        <td class="px-3 py-2 text-xs">
          <img :src="perfil.perfil_id.imagem_perfil_adicional">
        </td>
        <td class="px-3 w-60 py-2 text-xs">
          {{ perfil.perfil_id.nome_base }}
        </td>
        <td class="px-3 py-2 text-xs">
          {{ perfil.perfil_id.peso_base }}
        </td>
        <td class="py-2 text-xs">
          <input class="input-campos" v-model="perfil.corte">
        </td>
        <td class="py-2 text-xs">
          <input class="input-campos" v-model="perfil.qtd_formula">
        </td>
        <td class="py-2 text-xs">
          <input class="input-campos" v-model="perfil.medida_formula">
        </td>
        <td class="py-2 text-xs">
          <input class="input-campos" v-model="perfil.posicao">
        </td>
        <td class="py-2 text-xs">
          <input class="input-campos" v-model="perfil.condicao">
        </td>
        <td class="py-2 text-xs">
          <input class="input-campos" v-model="perfil.observacao">
        </td>

      </tr>
      </tbody>
    </table>

    <table class="container-tabela overflow-auto" v-if="novosPerfis.length">
      <thead class="cabecalho-tabela">
        <th class="w-[28vw] px-6">
          Busque o Perfil:
        </th>
        <th scope="col" class="py-3">
          Corte
        </th>
        <th scope="col" class="py-3">
          QTDE/Fórmula
        </th>
        <th scope="col" class="py-3">
          Medida/Fórmula
        </th>
        <th scope="col" class="py-3">
          Posição
        </th>
        <th scope="col" class="py-3">
          Condição
        </th>
        <th scope="col" class="py-3">
          Observação
        </th>
      </thead>
      <tbody>
        <tr v-for="(novoPerfil, index) in novosPerfis" :key="index" class="escopo-tabela">
          <td class="px-6 py-2 text-xs">
            <Search
              v-model="perfilSelecionado"
              :options="perfisPesquisados"
              :labelKey="getLabelKey"
              valueKey="id"
              :nameKey="getNameKey"
              @select="onSelect"
              @shouldSearch="buscarPerfis"
              class="w-[25vw]"
            />
          </td>
          <td class="py-2 text-xs w-[8vw]">
            <input class="input-campos" v-model="novoPerfil.corte" list="corte">
            <datalist id="corte">
              <option value="45/45"></option>
              <option value="45/90"></option>
              <option value="90/45"></option>
              <option value="90/90"></option>
            </datalist>
          </td>
          <td class="py-2 text-xs">
            <input class="input-campos" v-model="novoPerfil.qtd_formula">
          </td>
          <td class="py-2 text-xs">
            <input class="input-campos" v-model="novoPerfil.medida_formula">
          </td>
          <td class="py-2 text-xs">
            <input class="input-campos" v-model="novoPerfil.posicao" list="posicao">
            <datalist id="posicao">
              <option value="H"></option>
              <option value="L"></option>
              <option value="V"></option>
            </datalist>
          </td>
          <td class="py-2 text-xs">
            <input class="input-campos" v-model="novoPerfil.condicao">
          </td>
          <td class="py-2 text-xs">
            <input class="input-campos" v-model="novoPerfil.observacao">
          </td>
        </tr>
      </tbody>
    </table>
    <div class="flex w-full justify-end itens-center mt-6 px-4 mb-5" v-if="novosPerfis.length">
      <button 
        class="btn-salvar" 
        @click.prevent="postNovoPerifl">
          Adicionar
      </button>
    </div>

    <div class="flex justify-center" v-if="!novosPerfis.length">
      <button class="text-ativo" @click.prevent="adicionarPerfil">
        <i class="fas fa-plus-circle"></i>
      </button>
    </div>
  </div>
  <div class="flex w-full justify-end itens-center mt-6">
    <button
      class="btn-cancelar" 
      @click.prevent="modal_confirmacao = true"
      v-if="checkSome || checkAll"
    >
      Excluir Selecionados
    </button>
    <button class="btn-cancelar" @click.prevent="getListaPerfis">Cancelar</button>
    <button class="btn-salvar" @click.prevent="atualizarPerfis">Salvar</button>
  </div>

  <MensagemConfirmacaoExclusao
    mensagem="Deseja mesmo excluir os itens selecionados?"
    :abrir="modal_confirmacao"
    @feharModal="feharModalConfirmacao"
    @confirmarExclusao="excluirSelecionados"
  />

</template>

<script>
import axios from 'axios'
import Search from '@/utils/Search.vue'
import MensagemConfirmacaoExclusao from '@/components/toasts/toast_notificacao/MensagemConfirmacaoExclusao.vue'

export default {
  name: "PerfilProjeto",

  props: ['perfis', 'projeto_id'],

  data() {
    return {
      novosPerfis: [],
      perfilSelecionado: [],
      perfisPesquisados: [],

      checkAll: false,
      modal_confirmacao: false,
    }
  },

  components: {
    Search,
    MensagemConfirmacaoExclusao,
  },

  computed: {
    checkSome() {
      return this.perfis.some(perfil => perfil.selected)
    }
  },

  methods: {
    atualizarPerfis() {
      this.$emit('atualizarPerfis', this.perfis)
    },

    getLabelKey(option) {
      return option.codigo_base ? option.codigo_base : ''
    },

    getNameKey(option) {
      return option.nome_base ? option.nome_base : ''
    },

    onSelect(perfil) {
      this.perfilSelecionado = perfil
    },

    selectAll() {
      // Atualiza o estado dos demais checkboxes
      const checkboxes = document.getElementsByClassName('checkbox');
      checkboxes.forEach((checkbox) => {
        checkbox.checked = this.checkAll;
      });
    },

    excluirSelecionados() {
      if(this.checkAll) {
        this.deletarPerfis(this.perfis)
        this.modal_confirmacao = false
        this.checkAll = false
        return
      }
      const perfisADeletar = this.perfis.filter((perfil) => {
        if(perfil.selected){
          return perfil
        }
      })

      this.deletarPerfis(perfisADeletar)
      this.modal_confirmacao = false
    },

    async deletarPerfis(perfisADeletar) {
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest')

      const perfisADeletarPromisses = []

      for(const perfil of perfisADeletar){

        perfisADeletarPromisses.push(
          axios.delete(`projeto/projeto-perfil/${perfil.id}/`, {
            headers: {
              Authorization: `Token ${token}`
            }
          })
        )
      }

      await Promise.all(perfisADeletarPromisses)

      this.$emit('getListaPerfisProjeto')
    },

    adicionarPerfil() {
      this.novosPerfis.push({
        projeto_id: this.projeto_id,
        perfil_id: '',
        corte: '',
        qtd_formula: '',
        medida_formula: '',
        posicao: '',
        condicao: '',
        observacao: ''
      })
    },

    async buscarPerfis(pesquisa) {
      try {
        const token = await this.$store.dispatch('getAuthTokenCookie');
        await this.$store.dispatch('fetchURLrequest')
  
        const response = await axios.get(`produtos/produto/?tipo_produto=Perfil&filtro_base=${pesquisa}&page_size=9999`, {
          headers: {
            Authorization: `Token ${token}`
          }
        })
  
        this.perfisPesquisados = response.data.results
      } catch (error) {
        console.error(error)
      }
    }, 

    async postNovoPerifl() {
      try {
        const token = await this.$store.dispatch('getAuthTokenCookie');
        await this.$store.dispatch('fetchURLrequest')
  
        const novosPerfisPromisses = []
  
        for(const novoPerfil of this.novosPerfis){
          novoPerfil.perfil_id = this.perfilSelecionado.id
  
          novosPerfisPromisses.push(
            axios.post('projeto/projeto-perfil/', novoPerfil, {
              headers: {
                Authorization: `Token ${token}`
              }
            })
          )
        }
  
        await Promise.all(novosPerfisPromisses)
  
        this.novosPerfis = []
        this.$emit('getListaPerfisProjeto')
      } catch (error) {
        console.error(error)
      }

    }
  }
}
</script>

<style scoped>

</style>