<template>
  <a @click="alterarModalEditar()" href="#" class="font-medium text-corBase hover:underline">
    <i class="fa-solid fa-pen-to-square mr-2"></i>Editar
  </a>
  <div v-if="statusModal">
    <div class="container-modal-editar">
      <div class="sombra-modal">
        <div class="conteudo-modal-editar">
          <form class="form-editar text-white">
            <div class="flex justify-end">
              <button @click.prevent="alterarModalEditar()">
                <i class="fas fa-close text-2xl text-corBase"></i>
              </button>
            </div>
            <h2 class="text-lg text-corBase font-bold mb-2">Editar Dados Pessoais</h2>
            <!--Campos da Pessoa-->
            <div>
              <div class="container-campos">
                <div class="campos campos-mobile label-campos-popup">
                  <label class="label-campos label-campos-popup">CPF:</label>
                  <input type="text" required class="input-campos" autofocus placeholder="xxx.xxx.xxx-xx"
                         v-mask="'###.###.###-##'" v-model="dados.pessoais.cpf">
                </div>

                <div class="campos campos-mobile label-campos-popup">
                  <label class="label-campos label-campos-popup">Nascimento:</label>
                  <input type="date" required class="input-campos" v-model="dados.pessoais.nascimento">
                </div>

                <div class="campos campos-mobile label-campos-popup">
                  <label class="label-campos label-campos-popup">RG:</label>
                  <input type="text" required class="input-campos" placeholder="xx.xxx.xxx-x" v-mask="'##.###.###-#'"
                         v-model="dados.pessoais.rg">
                </div>

                <div class="campos campos-mobile label-campos-popup">
                  <label class="label-campos label-campos-popup">Órgão expeditor:</label>
                  <input type="text" required class="input-campos" v-model="dados.pessoais.orgao_expeditor">
                </div>

              </div>

              <div class="container-campos">
                <div class="campos campos-mobile label-campos-popup">
                  <label class="label-campos label-campos-popup">Nome:</label>
                  <input type="text" required class="input-campos"
                         v-on:input="dados.pessoais.nome_razao = dados.pessoais.nome_razao.toUpperCase()"
                         v-model="dados.pessoais.nome_razao">
                </div>
                <div class="campos campos-mobile label-campos-popup">
                  <label class="label-campos label-campos-popup">Tipo pessoa geral:</label>
                  <div class="flex items-center">
                    <select class="input-campos w-full" v-model="dados.tipo_pessoa_geral"
                            @input="$emit('update:tipo_pessoa_geral', $event.target.value)">
                      <option>----</option>
                      <option v-for="item in dados.tipo_pessoa" :value="item.id" :key="item.id">{{ item.descricao }}
                      </option>
                    </select>

                  </div>
                </div>
              </div>
            </div>
            <!--Campos de endereço-->
            <div>
              <div class="container-campos mt-10 ">
                <h2 class="titulo-cadastro">Enderecos</h2>
              </div>

              <div class="container-campos">
                <div class="flex flex-col mt-2 w-full md:w-60">
                  <label class="label-campos">CEP:</label>
                  <input type="text" required placeholder="" class="input-campos" v-model="dados.endereco.cep"
                         @input="buscarCepAutomaticamente">
                </div>

                <div class="campos campos-mobile">
                  <label class="label-campos">Logadouro:</label>
                  <input type="text" required class="input-campos" placeholder="" v-model="dados.endereco.logadouro">
                </div>

                <div class="flex flex-col mt-2 w-full md:w-60">
                  <label class="label-campos">Número:</label>
                  <input type="number" required class="input-campos" placeholder="" v-model="dados.endereco.numero">
                </div>
                <div class="flex flex-col mt-2 w-full md:w-[500px]">
                  <label class="label-campos">UF:</label>
                  <div class="">
                    <select class="input-campos" v-model="dados.endereco.uf">
                      <option>------</option>
                      <option value="AC">Acre</option>
                      <option value="AL">Alagoas</option>
                      <option value="AP">Amapá</option>
                      <option value="AM">Amazonas</option>
                      <option value="BA">Bahia</option>
                      <option value="CE">Ceará</option>
                      <option value="DF">Distrito Federal</option>
                      <option value="ES">Espírito Santo</option>
                      <option value="GO">Goiana</option>
                      <option value="MA">Maranhão</option>
                      <option value="MT">Mato Grosso</option>
                      <option value="MS">Mato Grosso do Sul</option>
                      <option value="MG">Minas Gerais</option>
                      <option value="PA">Pará</option>
                      <option value="PB">Paraíba</option>
                      <option value="PR">Paraná</option>
                      <option value="PE">Pernanbuco</option>
                      <option value="PI">Piauí</option>
                      <option value="RJ">Rio de Janeiro</option>
                      <option value="RN">Rio Grande do Norte</option>
                      <option value="RS">Rio Grande do Sul</option>
                      <option value="RO">Rondônia</option>
                      <option value="RR">Roraima</option>
                      <option value="SC">Santa Catarina</option>
                      <option value="SP">São Paulo</option>
                      <option value="SE">Sergipe</option>
                      <option value="TD">Tocantins</option>

                    </select>
                  </div>
                </div>
              </div>

              <div class="container-campos  ">

                <div class="campos-mobile">
                  <label class="label-campos">Cidade:</label>
                  <input type="text" required class="input-campos" placeholder="" v-model="dados.endereco.cidade">
                </div>

                <div class="campos-mobile">
                  <label class="label-campos">Complemento:</label>
                  <input type="text" required class="input-campos" placeholder="" v-model="dados.endereco.complemento">
                </div>

              </div>

              <div class=" container-campos ">

                <div class="campos-mobile">
                  <label class="label-campos">Referência:</label>
                  <input type="text" required class="input-campos" placeholder="" v-model="dados.endereco.referencia">
                </div>
                <div class="campos-mobile">
                  <label class="label-campos">Bairro:</label>
                  <input type="text" required class="input-campos" placeholder="" v-model="dados.endereco.bairro">
                </div>

                <div class="campos-mobile">
                  <label class="label-campos">Tipo de Endereço:</label>
                  <div class="flex">
                    <select class="input-campos" v-model="dados.endereco.tipo_endereco.id">
                      <option>------</option>
                      <option v-for="item in dados.tipo_endereco" :value="item.id" :key="item.id">{{ item.descricao }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>

            </div>
            <!--Campos de E-mail e Telefone-->
            <div>
              <div class="container-campos mt-10 ">
                <h2 class="titulo-cadastro">Contatos</h2>
              </div>
              <div class=" container-campos">
                <div class="flex flex-col mt-2 w-full md:w-80">
                  <label class="label-campos">Tipo de Telefone:</label>
                  <div class="flex">
                    <select class="input-campos" v-model="dados.telefone.tipo_telefone.id">
                      <option value="">------</option>
                      <option v-for="item in dados.tipo_telefone" :key="item.id" :value="item.id">{{ item.descricao }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="flex flex-col mt-2 w-full md:w-32">
                  <label class="label-campos">DDD:</label>
                  <input type="text" required class="input-campos" placeholder="" v-model="dados.telefone.ddd">
                </div>

                <div class="campos campos-mobile">
                  <label class="label-campos">Telefone:</label>
                  <input type="text" required class="input-campos" placeholder="" v-mask="'#####-####'"
                         v-model="dados.telefone.telefone">
                </div>
              </div>

              <div class="container-campos">
                <div class="flex flex-col mt-2 w-full md:w-80">
                  <label class="label-campos">Tipo de Email:</label>
                  <div class="flex">
                    <select class="input-campos" v-model="dados.email.tipo_email">
                      <option value="">------</option>
                      <option v-for="item in dados.tipo_email" :key="item.id" :value="item.id">{{ item.descricao }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="campos campos-mobile">
                  <label class="label-campos">Email:</label>
                  <input type="email" required class="input-campos" placeholder="" v-model="dados.email.email_pessoa">
                </div>
              </div>
            </div>
            <!--Botão-->
            <div class="btn-formulario-modal">
              <button class="btn-formulario-salvar" @click.prevent="putDadosEditado">
                Salvar
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "EditarDados",
  data() {
    return {
      statusModal: false,
      dados: {
        pessoais: {},
        endereco: {},
        telefone: {},
        email: {},
        tipo_pessoa_geral: '',
        tipo_pessoa: {},
        tipo_endereco: {},
        tipo_email: {},
        tipo_telefone: {}
      }
    }
  },
  props: ["dadosPessoa"],
  created() {
    console.log(this.dadosPessoa)
    this.dados.pessoais = this.dadosPessoa.pessoa_fisica
    this.dados.endereco = this.dadosPessoa.endereco_pessoa[0]
    this.dados.tipo_pessoa_geral = this.dadosPessoa.tipo_pessoa_geral_id
    this.dados.email = this.dadosPessoa.pessoa_fisica.email_pessoa[0]
    this.dados.telefone = this.dadosPessoa.pessoa_fisica.telefone_pessoa[0]
    this.getTipoPessoa()
    this.getTipoEndereco()
    this.getTipoEmail()
    this.getTipoTelefone()
  },
  methods: {
    alterarModalEditar() {
      this.statusModal = !this.statusModal;
    },

    async putDadosEditado() {
      const dadosAtualizados = {
        nome_razao: this.dados.pessoais.nome_razao,
        nome: this.dados.pessoais.nome_razao,
        cpf: this.dados.pessoais.cpf.replace(/[^0-9]/g, ''),
        rg: this.dados.pessoais.rg.replace(/[^0-9]/g, ''),
        orgao_expeditor: this.dados.pessoais.orgao_expeditor,
        nascimento: this.dados.pessoais.nascimento,

        logadouro: this.dados.endereco.logadouro,
        numero: this.dados.endereco.numero,
        complemento: this.dados.endereco.complemento,
        cep: this.dados.endereco.cep.replace(/[^0-9]/g, ''),
        bairro: this.dados.endereco.bairro,
        cidade: this.dados.endereco.cidade,
        uf: this.dados.endereco.uf,
        referencia: this.dados.endereco.referencia,
        tipo_endereco: this.dados.endereco.tipo_endereco.id,

        ddd: this.dados.telefone.ddd,
        telefone: this.dados.telefone.telefone.replace(/[^0-9]/g, ''),
        tipo_telefone: this.dados.telefone.tipo_telefone.id,

        email_pessoa: this.dados.email.email_pessoa,
        tipo_email: this.dados.email.tipo_email,

        tipo_pessoa_geral: this.dados.tipo_pessoa_geral,
      };

      try {
        const token = await this.$store.dispatch('getAuthTokenCookie');
        await this.$store.dispatch('fetchURLrequest')
        const response = await axios.put(`pessoas/update/${this.dados.pessoais.id}/`, dadosAtualizados, {
          headers: {
            'Authorization': `Token ${token}`,
            'Content-Type': 'application/json'
          }
        });
        console.log(response);
        this.sucesso = true;
        await this.delay(2000);
        this.sucesso = false;

      } catch (error) {
        console.log(error)
      }
    },
    async getTipoPessoa() {
      const token = await this.$store.dispatch('getAuthTokenCookie'); // Obtém o token do localStorage
      await this.$store.dispatch('fetchURLrequest')
      await axios.get('pessoas/tipo-pessoas', {
        headers: {
          'Authorization': `Token ${token}` // Define o cabeçalho Authorization com o token
        }
      })
          .then(response => {
            this.dados.tipo_pessoa = response.data
          })
          .catch(error => {
            if (error.response) {
              for (const property in error.response.data) {
                this.errors.push(`${property}: ${error.response.data[property]}`)
              }
            } else if (error.message) {
              this.errors.push('Something went wrong. Please try again!')
            }
          })
    },
    async getTipoEndereco() {
      const token = await this.$store.dispatch('getAuthTokenCookie'); // Obtém o token do localStorage
      await this.$store.dispatch('fetchURLrequest')
      await axios.get('pessoas/enderecos/tipo-enderecos', {
        headers: {
          'Authorization': `Token ${token}` // Define o cabeçalho Authorization com o token
        }
      })
          .then(response => {
            this.dados.tipo_endereco = response.data
          })
          .catch(error => {
            if (error.response) {
              for (const property in error.response.data) {
                this.errors.push(`${property}: ${error.response.data[property]}`)
              }
            } else if (error.message) {
              this.errors.push('Something went wrong. Please try again!')
            }
          })
    },
    async getTipoEmail() {
      const token = await this.$store.dispatch('getAuthTokenCookie'); // Obtém o token do localStorage
      await this.$store.dispatch('fetchURLrequest')
      await axios.get('pessoas/email/tipo-email', {
        headers: {
          'Authorization': `Token ${token}` // Define o cabeçalho Authorization com o token
        }
      })
          .then(response => {
            this.dados.tipo_email = response.data
          })
          .catch(error => {
            if (error.response) {
              for (const property in error.response.data) {
                this.errors.push(`${property}: ${error.response.data[property]}`)
              }
            } else if (error.message) {
              this.errors.push('Something went wrong. Please try again!')
            }
          })
    },
    async getTipoTelefone() {
      const token = await this.$store.dispatch('getAuthTokenCookie'); // Obtém o token do localStorage
      await this.$store.dispatch('fetchURLrequest')
      await axios.get('pessoas/telefones/tipo-telefones', {
        headers: {
          'Authorization': `Token ${token}` // Define o cabeçalho Authorization com o token
        }
      })
          .then(response => {
            this.dados.tipo_telefone = response.data
          })
          .catch(error => {
            if (error.response) {
              for (const property in error.response.data) {
                this.errors.push(`${property}: ${error.response.data[property]}`)
              }
            } else if (error.message) {
              this.errors.push('Something went wrong. Please try again!')
            }
          })
    },
  }
}
</script>

<style>
.container-modal-editar {
  @apply fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full h-full
}

.conteudo-modal-editar {
  @apply bg-screenCor p-6 w-[75%] shadow-xl shadow-screenCor/30 fixed top-1/2 left-1/2
  transform -translate-x-1/2 -translate-y-1/2  h-[80vh] overflow-y-scroll scrollbar-thin scrollbar-thumb-screenCor/40
}

.form-editar {
  @apply text-corBase
}

</style>