<template>


  <div class="container-formulario">
    <!-- TILO DO FORMULARIO + BOTAO DE VOLTAR -->
    <div class="cabecalho-formulario">
      <h1 class="text-xl text-corBase font-bold">Dados Produto Por fornecedor</h1>

      <router-link to="/produtos/produto-por-fornecedor" class="text-xl text-corBase font-bold">
        <i class="fas fa-chevron-left"></i>
      </router-link>
    </div>

    <!-- Escopo do formulario de cadastro mobile-->
    <form @submit.prevent="cadastrarProdutoBase" enctype="multipart/form-data">
      <div class="campo-chapa mt-8">
        <label class="text-xl text-corBase font-bold">Nome Especifico:</label>
        <input type="text"
               v-model="nome_especifico"
               v-on:input="nome_especifico = nome_especifico.toUpperCase()"
               class="input-form-chapa"
               autofocus
        >
      </div>
      <div class="div-produtos ">
        <div class="flex flex-col gap-1 w-full">
          <label class="text-xl text-corBase font-bold">Codigo Fornecedor:</label>
          <input type="text" v-model="codigo_fornecedor" class="input-form-chapa" autofocus>
        </div>
        <div class="flex flex-col gap-1 w-full">
          <label class="text-xl text-corBase font-bold">Produto Base:</label>
          <v-select
              label="codigo_base"
              :options="listaProdutosBase"
              v-model="produto_base"
              @input="fetchProdutoBase"
          >
            <template #option="{nome_base, codigo_base}">
              <strong>{{ codigo_base }}</strong> - <span>{{ nome_base }}</span>
            </template>
          </v-select>

        </div>
        <div class="campo-chapa">
          <label class="text-xl text-corBase">Fornecedor:</label>
          <v-select
              :getOptionLabel="fornecedor => fornecedor.pessoa.nome_razao"
              :options="lista_fornecedores"
              v-model="fornecedor"
              @input="fetchFornecedor"
          >
            <template #option="{ pessoa }">
              <span>{{ pessoa.nome_razao }}</span>
            </template>
          </v-select>

        </div>
      </div>
      <div class="div-produtos ">
        <div class="flex flex-col gap-1 w-full">
          <label class="text-xl text-corBase font-bold">Peso:</label>
          <input type="text" v-model="peso" class="input-form-chapa" autofocus>
        </div>
        <div class="flex flex-col gap-1 w-full">
          <label class="text-xl text-corBase font-bold">Preço de Custo:</label>
          <input type="text" v-model="preco_custo" class="input-form-chapa" autofocus>
        </div>
        <div class="flex flex-col gap-1 w-full">
          <label class="text-xl text-corBase font-bold">NCM:</label>
          <input type="text" v-model="ncm" class="input-form-chapa" autofocus>
        </div>
      </div>

      <div class="flex w-full justify-end">
        <button class="btn-enviar" type="submit">Cadastrar</button>
      </div>
    </form>

  </div>

  <mensagem-sucesso v-if="sucesso === true" mensagem="Produto por Fornecedor Cadastrado com Sucesso!"
                    rotas="/produtos/produto-por-fornecedor"></mensagem-sucesso>
</template>

<script>
import axios from "axios";
import Select2 from "vue3-select2-component";
import vSelect from "vue-select";
import MensagemSucesso from "@/components/toasts/toast_sucesso/MensagemSucesso";
import debounce from 'lodash/debounce';

export default {
  name: "CadastroProdutoPorFornecedor",

  components: {
    Select2,
    MensagemSucesso,
    vSelect
  },


  data() {
    return {
      listaProdutosBase: [],
      produto_base: '',
      sucesso: false,
      lista_produtos_base: [],
      produto_base_info: '',
      produto_base_id: '',
      lista_fornecedores: [],
      fornecedor: '',
      nome_especifico: '',
      codigo_fornecedor: '',
      peso: '',
      ultimo_preco_compra: '',
      preco_custo: '',
      ipi: '',
      icms: '',
      ncm: '',
      codigo_filtro: '',


    }
  },

  created() {
  },

  computed: {
    minhaEmpresa() {
      return this.$store.state.minhaEmpresa;
    },

    isadmin() {
      return this.$store.state.isAdminUser
    },

  },

  methods: {

    async getProdutoId() {
      this.$store.dispatch('fetchURLrequest');
      const token = localStorage.getItem('token');

      let codigo = this.produto_base_info.split(' - ')
      let codigo_filtro = codigo[0]

      await axios.get(`produtos/produto?codigo_base=${codigo_filtro}`, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            this.produto_base_id = response.data[0].id
          })
          .catch(error => {
            console.log(error)
          })

    },

    async fetchProdutoBase(searchInput) {
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest');

      if (!searchInput) {
        this.listaProdutosBase = [];
        return;
      }
      const filtro = searchInput.target.value
      debounce(() => {
        axios.get(`produtos/produto?filtro_base=${filtro}`, {
          headers: {
            'Authorization': `Token ${token}`
          }
        })
            .then(response => {
              this.listaProdutosBase = response.data.results
            })
            .catch(error => {
              console.error(error)
            })
      }, 1000)();
    },

    async fetchFornecedor(searchInput) {
      this.$store.dispatch('fetchURLrequest');
      const token = localStorage.getItem('token');

      if (!searchInput) {
        this.lista_fornecedores = [];
        return;
      }

      const filtro = searchInput.target.value
      debounce(() => {
        axios.get(`/pessoas/fornecedor/`, {
          params: {
            nome: filtro
          },
          headers: {
            'Authorization': `Token ${token}`
          }
        })
            .then(response => {
              this.lista_fornecedores = response.data.results
            })
            .catch(error => {
              console.error(error)
            })
      }, 1000)();


    },

    async cadastrarProdutoBase() {
      const token = await this.$store.dispatch('getAuthTokenCookie')

      await this.getProdutoId()

      await this.$store.dispatch('fetchURLrequest')

      const data = new FormData
      data.append('ativo', 'True')
      data.append('nome_especifico', this.nome_especifico)
      data.append('codigo_fornecedor', this.codigo_fornecedor)
      data.append('produto_base', this.produto_base.id)
      data.append('fornecedor', this.fornecedor.id)
      data.append('peso', this.peso)
      data.append('preco_custo', this.preco_custo)
      data.append('ncm', this.ncm)

      this.$store.dispatch("setLoading", true);
      this.sucesso = false
      await axios.post('produtos/produto-por-fornecedor/', data, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            this.sucesso = true
            this.nome_especifico = ''
            this.codigo_fornecedor = ''
            this.produto_base_id = ''
            this.fornecedor = ''
            this.peso = ''
            this.preco_custo = ''
            this.ncm = ''
            this.lista_produtos_base = []
            this.$store.dispatch("setLoading", false);
          })
          .catch(error => {
            console.log(error)
            this.$store.dispatch("setLoading", false);
          })
    }


  }
}
</script>

<style lang="scss">
.imagePreviewWrapper {
  @apply w-full h-[300px] mt-2 bg-contain bg-center bg-no-repeat block cursor-pointer border border-corBase/10 bg-white
}

//protudo
.div-produtos {
  @apply flex gap-3 my-3 w-full
}

//chapa
.campo-chapa {
  @apply flex flex-col gap-1 w-full
}

.input-form-chapa {
  @apply w-full bg-white border-2 border-corBase/5 border-b-corBase/20 focus:border-b-corBase/60 px-1 py-1 text-corBase shadow-sm shadow-corBase/20
  text-corBase focus:border-b-corBase/80 outline-none focus:transition focus:duration-500
}

.selecionar-arquivo {
  @apply bg-screenCor p-1.5 w-full border-2 border-b-corBase text-corBase text-center shadow-sm shadow-corBase/20
}
</style>