<template>
    <div class="container-formulario">
        <!-- Cabeçalho -->
        <div class="container-cabecalho flex justify-between mb-4">
            <div>
                <h1 class="text-xl text-corBase font-bold">Tabela de Cor do Perfil</h1>
            </div>

            <router-link :to="`/alterar-perfil/${this.$route.params.id_tabela}`" class="text-xl text-corBase font-bold">
                <i class="fas fa-chevron-left"></i>
            </router-link>
        </div>

        <!-- Adicionando uma div para rolagem lateral -->
        <div class="container-modelo-tabela">
            <table class="container-tabela overflow-auto">
                <thead class="cabecalho-tabela">
                    <tr>
                        <th class="py-2 px-4 bg-gray-200 border-b">Ações</th>
                        <th class="py-2 px-4 bg-gray-200 border-b">Cor</th>
                        <th class="py-2 px-4 bg-gray-200 border-b">Valor Adicional (R$)</th>

                    </tr>
                </thead>
                <tbody>
                    <!-- Exibir os produtos existentes -->
                    <tr v-for="linha in listaLinhas" :key="linha.id">
                        <td class="py-2 px-4 border-b flex gap-2">
                            <button @click="deleteLinha(linha.id)" class="text-gray-500" title="Excluir Cor">
                                <i class="fas fa-times"></i>
                            </button>
                            <button @click="abrirModalEditarProduto(linha)" class="text-gray-500" title="Editar Cor">
                                <i class="fas fa-paperclip"></i>
                            </button>
                        </td>
                        <td class="py-2 px-4 border-b">{{ linha.cor_perfil.nome }}</td>
                        <td class="py-2 px-4 border-b">R$ {{ parseFloat(linha.acrescimo).toFixed(2) }}</td>
                    </tr>

                    <!-- Linha de input para adicionar novo produto -->
                    <tr v-if="adicionarProdutoAtivo">
                        <td class="py-2 px-4 border-b"></td>
                        <td class="py-2 px-4 border-b">
                            <Select2 v-model="novaCor.cor" :options="cores" class="w-full"
                                :settings="{ width: '100%' }" />
                        </td>
                        <td class="py-2 px-4 border-b">
                            <input v-model="novaCor.acrescimo" type="number" min="0" class="input-form">
                        </td>
                    </tr>

                    <!-- Modal inline de edição de produto -->
                    <tr v-if="editarProdutoAtivo">
                        <td class="py-2 px-4 border-b"></td>
                        <td class="py-2 px-4 border-b">
                            <input v-model="corSelecionada.cor_perfil.nome" type="text" class="input-form" readonly />
                        </td>
                        <td class="py-2 px-4 border-b">
                            <input v-model="corSelecionada.acrescimo" type="number" min="0" class="input-form">
                        </td>
                    </tr>


                </tbody>
            </table>
        </div>

        <!-- Botão de Adicionar Produto -->
        <div class="flex justify-end mt-4" v-if="adicionarProdutoAtivo">
            <button class="btn-salvar" @click.prevent="adicionarProduto" :disabled="!produtoPreenchido">
                Adicionar Produto
            </button>
        </div>

        <!-- Botão para adicionar novo produto -->
        <div class="flex justify-center mt-4">
            <button class="text-ativo" @click.prevent="abrirLinhaAdicionarProduto">
                <i class="fas fa-plus-circle"></i>
            </button>
        </div>

        <!-- Botão de salvar as edições no modal de edição -->
        <div class="flex justify-end mt-4" v-if="editarProdutoAtivo">
            <button class="btn-salvar" @click.prevent="salvarProdutoEditado">Salvar Alterações</button>
        </div>

        <!-- Botões da paginação -->
        <Pagination v-if="listaLinhas.length > 0" :offset="paginaAtual" :total="countItens" :limit="5"
            @change-page="mudarPagina" />
    </div>

    <SucessoRapido v-if="sucesso === true" mensagem="Alteração feita com sucesso." />
    <ErroRapido v-if="erro === true" mensagem="Este produto já existe na tabela." />
</template>

<script>
import Select2 from "vue3-select2-component";
import SucessoRapido from "@/components/toasts/toast_sucesso/SucessoRapido.vue";
import ErroRapido from '@/components/toasts/toast_erro/ErroRapido.vue';
import Pagination from '@/utils/Pagination.vue';
import axios from 'axios'

export default {
    name: 'AlterarLinha',
    components: {
        Select2,
        SucessoRapido,
        ErroRapido,
        Pagination
    },
    data() {
        return {
            listaLinhas: [],
            cores: [],
            sucesso: false,
            erro: false,
            adicionarProdutoAtivo: false,
            novaCor: {
                cor: '',
                acrescimo: 0
            },
            editarProdutoAtivo: false,
            corSelecionada: {},
            paginaAtual: 1,
            countItens: 0,
        }
    },
    methods: {
        // Buscar cores já precificadas
        async buscarCoresPerfil(pagina) {
            const token = await this.$store.dispatch('getAuthTokenCookie');
            await this.$store.dispatch('fetchURLrequest');
            try {
                const response = await axios.get(`precificacao/itens-perfil-acrescimo-linha/?linha_aplicacao=${this.$route.params.id.replace(/\//g, '')}&id_tabela_preco=${this.$route.params.id_tabela}&page=${pagina}`, {
                    headers: {
                        Authorization: `Token ${token}`
                    }
                });
                this.listaLinhas = response.data.results;
                this.countItens = response.data.count
                console.log(this.listaLinhas)
                this.countItens = response.data.count;
            } catch (error) {
                console.error('Erro ao buscar a lista de cores deste perfil:', error);
            }
        },

        // Buscar lista de todas as cores
        async buscarCores() {
            const token = await this.$store.dispatch('getAuthTokenCookie');
            await this.$store.dispatch('fetchURLrequest');
            try {
                const response = await axios.get(`produtos/cor-perfil/?page_size=999`, {
                    headers: {
                        Authorization: `Token ${token}`
                    }
                });
                this.cores = response.data.results.map(cor => {
                    return { id: cor.id, text: cor.nome };
                });
            } catch (error) {
                console.error('Erro ao buscar a lista de cores deste perfil:', error);
            }
        },

        // Função para Adicionar a Linha na Tabela
        async adicionarProduto() {
            if (this.produtoPreenchido) {
                const token = await this.$store.dispatch('getAuthTokenCookie');
                await this.$store.dispatch('fetchURLrequest');
                console.log(this.novaCor)
                try {
                    const response = await axios.post('/precificacao/itens-perfil-acrescimo-linha/', {
                        tabela_preco_perfil: this.$route.params.id_tabela,
                        linha_aplicacao: this.$route.params.id.replace(/\//g, ''),
                        cor_perfil: this.novaCor.cor,
                        acrescimo: this.novaCor.acrescimo
                    }, {
                        headers: {
                            Authorization: `Token ${token}`,
                        },
                    });

                    this.buscarCoresPerfil(this.paginaAtual);

                    this.novoProduto = {
                        perfil: '',
                        cor_perfil: '',
                        acrescimo: 0
                    };

                    this.adicionarProdutoAtivo = false;

                    this.sucesso = true
                    setTimeout(() => {
                        this.sucesso = false;
                    }, 3000);
                } catch (error) {
                    if (error.response.data[0] === "Este item já existe para esta tabela de preço.") {
                        this.erro = true
                        setTimeout(() => {
                            this.erro = false;
                        }, 3000);
                    } else {
                        console.error("Erro ao adicionar produto:", errorMessage);
                    }
                }
            }
        },

        async deleteLinha(id) {
            const token = await this.$store.dispatch('getAuthTokenCookie');
            await this.$store.dispatch('fetchURLrequest');
            try {
                // Realiza a exclusão da cor
                await axios.patch(`precificacao/itens-perfil-acrescimo-linha/${id}/`, { ativo: 'False' }, {
                    headers: {
                        Authorization: `Token ${token}`
                    }
                });

                // Atualiza a lista de produtos da tabela após exclusão
                this.buscarCoresPerfil(1);

                this.sucesso = true
                setTimeout(() => {
                    this.sucesso = false;
                }, 3000);
            } catch (error) {
                console.error("Erro ao excluir a cor:", error);
            }
        },

        abrirModalEditarProduto(cor) {
            console.log(cor)
            this.corSelecionada = {
                ...cor,
            };
            this.editarProdutoAtivo = true;
        },

        fecharModal() {
            this.editarProdutoAtivo = false;
        },

        async salvarProdutoEditado() {
            try {
                const token = await this.$store.dispatch('getAuthTokenCookie');
                await this.$store.dispatch('fetchURLrequest');

                // Requisição para salvar as alterações do produto editado
                await axios.put(
                    `precificacao/itens-perfil-acrescimo-linha/${this.corSelecionada.id}/`,
                    {
                        tabela_preco_perfil: this.$route.params.id_tabela,
                        linha_aplicacao: this.$route.params.id.replace(/\//g, ''),
                        cor_perfil: this.corSelecionada.cor_perfil.id,
                        acrescimo: this.corSelecionada.acrescimo
                    },
                    {
                        headers: {
                            Authorization: `Token ${token}`,
                        },
                    }
                );

                this.buscarCoresPerfil(this.paginaAtual)

                this.sucesso = true
                setTimeout(() => {
                    this.sucesso = false;
                }, 3000);

                this.fecharModal();
            } catch (error) {
                console.error('Erro ao salvar o produto editado:', error);
            }
        },

        abrirLinhaAdicionarProduto() {
            this.adicionarProdutoAtivo = true;
        },

        mudarPagina(pagina) {
            this.paginaAtual = pagina;
            this.buscarCoresPerfil(pagina);
        }

    },
    created() {
        this.buscarCoresPerfil(1)
        this.buscarCores()
    },
    computed: {
        produtoPreenchido() {
            return (
                this.novaCor.cor !== '' &&
                this.novaCor.acrescimo > 0
            );
        },
    },
};
</script>

<style scoped>
.input-form {
    border: 1px solid #ccc;
    padding: 8px;
    width: 100%;
}

table {
    width: 100%;
    border-collapse: collapse;
}

th,
td {
    padding: 8px;
    text-align: left;
}

thead th {
    background-color: #f8f9fa;
}

tr:hover {
    background-color: #f1f1f1;
}

.overflow-x-auto {
    overflow-x: auto;
}
</style>