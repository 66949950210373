import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './assets/tailwind.css';
import axios from 'axios';
import '@fortawesome/fontawesome-free/css/all.css';
import VueTheMask from 'vue-the-mask';
import 'vue-select/dist/vue-select.css';

axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';

createApp(App)
  .use(store)
  .use(router, axios)
  .use(VueTheMask)
  .mount('#app');
