<template>
  <dashboard-ged />

</template>

<script>
import DashboardGed from "@/components/ged/DashboardGed";

export default {
  name: "DashboardGedView.vue",
  components: {
    DashboardGed
  }
}

</script>

<style lang="scss">

</style>