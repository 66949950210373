<template>
  <div class="flex w-full mt-6">
    <div class="overflow-x-auto w-full">
      <table class="w-full border border-collapse">
        <thead>
          <tr class="bg-gray-200">
            <th class="py-1 px-2 border text-sm">
              COD. Fornecedor [XML]
            </th>
            <th class="py-1 px-2 border text-sm">Descrição Produto [XML]</th>
            <th class="py-1 px-2 border text-sm">Produto [FORNECEDOR]</th>
            <th class="py-1 px-2 border text-sm">Quantidade [XML]</th>
            <th class="py-1 px-2 border text-sm">Preço Custo [XML]</th>
            <th class="py-1 px-2 border text-sm">NCM [XML]</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(produto, index) in produtosXml"
            :key="produto.id"
            :class="index % 2 === 0 ? 'bg-white' : 'bg-gray-100'"
          >
            <td class="p-2 border text-xs font-bold">
              {{ produto.codigo_fornecedor_XML }}
            </td>
            <td class="p-2 border w-full text-xs font-bold">
              {{ produto.nome }}
            </td>
            <td class="p-2 border text-center text-xs font-bold campo-chapa w-96">
              <div v-if="produto.codigo_fornecedor_vinculado">
                {{ produto.codigo_fornecedor_vinculado }}
              </div>
              <div v-else>
                <SearchProxy
                  :produtoPesquisadoProxy="produtoSelecionado"
                  :produtosPesquisadosProxy="produtosPesquisados"
                  :produtoAtual="produtosXml[index]"
                  @select="onSelect"                        
                />
              </div>
            </td>  
            <!-- <td class="p-2 border text-xs font-bold">
              <input
                class="border p-2 rounded-md focus:outline-none focus:border-corBase"
                type="text"
                v-model="produto.nome_especifico"
              />
            </td> -->
            <td class="p-2 border text-center text-xs font-bold">
              <div>
                {{ produto.estoque }}
              </div> 
            </td>
            <td class="p-2 border text-center text-xs font-bold">
              <div>
                {{ produto.preco_custo }}
              </div>
            </td>
            <td class="p-2 border text-center text-xs font-bold">
              <div>
                {{ produto.ncm }}
              </div>
            </td>
          </tr>
        </tbody>
        <div>
        </div>
      </table>  
      <div class="flex w-full justify-end">
        <button
          @click.prevent="vincularProdutosPorFonecedor"
          v-if="produtosXml.length > 0"
          class="btn-enviar"
        >
          Vincular
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import NovoProdutoBaseModal from "@/components/midais/produto/NovoProdutoBaseModal";
import vSelect from "vue-select";
import Search from '@/utils/Search.vue'
import SearchProxy from './SearchProxy.vue';

export default {
  name: "VincularEstoqueProduto",
  components: {
    NovoProdutoBaseModal,
    vSelect,
    Search,
    SearchProxy,
  },
  props: ["fornecedor", "produtosXml", "abrir", "listaProdutoFornecedor", 'produtosVinculados'],

  data() {
    return {
      produtosBase: [],
      produtoSelecionado: {},
      searchText: "",
      produtosPesquisados: []
    };
  },

  computed: {
    filteredProdutos() {
      return this.produtosBase.filter((produto) => {
        return (
          produto.codigo_fornecedor
            .toLowerCase()
            .includes(this.searchText.toLowerCase()) ||
          produto.nome_especifico
            .toLowerCase()
            .includes(this.searchText.toLowerCase())
        );
      });
    },
  },

  methods: {
    proximaEtapa() {
      this.$emit("proximaEtapa")
    },

    exibirProdutoBase(codigoFornecedor) {
      return this.listaProdutoFornecedor.some(
        (produtoFornecedor) =>
          produtoFornecedor.codigo_fornecedor === codigoFornecedor
      );
    },


    onSelect(produto) {
      this.produtoSelecionado = produto;
    },

    addProdutosVinculados(novosVinculos) {
      this.$emit('addProdutosVinculados', novosVinculos)
    },

   async vincularProdutosPorFonecedor() {
     try{
        const checkProdutosVinculados = this.produtosXml.filter(produto => produto.codigo_fornecedor_vinculado === '')

        if(checkProdutosVinculados.length > 0){
          throw new Error('Existem produtos ainda não vinculados!')
        }

        const produtosAVincular = this.produtosXml
          .filter(produtoXML => !this.produtosVinculados
          .some(produtoVinculado => produtoVinculado.codigo_fornecedor_produto_por_fornecedor === produtoXML.codigo_fornecedor_vinculado)) 

        const data = {
          produtosAVincular: produtosAVincular
        }

        const token = await this.$store.dispatch("getAuthTokenCookie");
        await this.$store.dispatch("fetchURLrequest");

        const response = await axios.post(
          `produtos/vinculo-xml-produto-fornecedor/`,
          data,
          {
            headers: {
              Authorization: `Token ${token}`,
            },
          }
        )

        this.addProdutosVinculados(response.data.produtosVinculadosComSucesso)

        //this.proximaEtapa()
      }catch(e){
        console.error(e.message)
      }
    },
  },
};
</script>

<style scoped>
</style>