<template>
  <div v-for="column in columns" :key="column.id" class="w-1/3 border rounded p-4">
    <h2 class="text-lg font-bold mb-4">{{ column.nome }}</h2>
    <draggable :list="cartoesPorColuna[column.id]" group="cartao" itemKey="id" tag="ul"
               @end="onCardMovedCustom(column.id)">
      <template #item="{element: cartao, index}">
        <CartaoComponente :cartao="cartao" @click="openDetalhesModal(cartao)"/>
      </template>
    </draggable>

    <button class="btn-enviar" @click.prevent="novoCartao(column)">Novo Cartao</button>

    <DetalhesCartaoComponente v-if="detalhesModalOpen" :cartao="cartaoSelecionado" @fechar="fecharDetalhesModal"/>
  </div>
  <novo-cartao :coluna="coluna" v-if="modalNovoCartao==true" @fechar="fecharModalNovoCartao" />
</template>

<script>
import draggable from 'vuedraggable';
import CartaoComponente from "@/components/kanban/CartaoComponente";
import DetalhesCartaoComponente from "@/components/kanban/DetalhesCartaoComponente";
import axios from "axios";
import NovoCartao from "@/components/kanban/Modais/NovoCartao";

export default {
  name: 'ColunaComponente',
  components: {
    draggable,
    CartaoComponente,
    DetalhesCartaoComponente,
    NovoCartao
  },
  props: {
    columns: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      cartoesPorColuna: {},
      detalhesModalOpen: false,
      cartaoSelecionado: null,
      modalNovoCartao: false,
      coluna: {}
    }
  },
  async created() {
    await this.getCartoesPorColuna();
  },
  watch: {
    columns: {
      handler(newColumns, oldColumns) {
        newColumns.forEach(column => {
          this.getCartoesPorColuna(column.id);
        });
      },
      immediate: true
    }
  },
  methods: {
    novoCartao(coluna){
      this.coluna = coluna
      this.fecharModalNovoCartao()
    },
    fecharModalNovoCartao() {
      this.modalNovoCartao = !this.modalNovoCartao
    },
    async getCartoesPorColuna(columnId) {
      try {
        this.$store.dispatch("setLoading", true);
        const token = await this.$store.dispatch('getAuthTokenCookie');
        await this.$store.dispatch('fetchURLrequest');

        const response = await axios.get(`kanban/cartao?coluna=${columnId}`, {
          headers: {
            'Authorization': `Token ${token}`
          }
        });

        this.cartoesPorColuna[columnId] = response.data.results;
        this.$store.dispatch("setLoading", false);
      } catch (error) {
        console.error('Erro ao obter cartões da coluna:', error);
      }
    },
    async onCardMovedCustom(columnId) {
      try {
        this.$store.dispatch("setLoading", true);
        const cartoes = this.cartoesPorColuna[columnId];

        if (cartoes && cartoes.length > 0) {
          const card = cartoes[0];
          const newColumnId = columnId;
          card.coluna = newColumnId;
          await axios.put(`kanban/cartao/${card.id}/`, card);

          console.log('Card moved to column:', newColumnId);
          this.$store.dispatch("setLoading", false);
        } else {
          console.log('Nenhum cartão foi movido.');
          this.$store.dispatch("setLoading", false);
        }
      } catch (error) {
        console.error('Erro ao mover o cartão:', error);
      }
    },
    openDetalhesModal(cartao) {
      this.cartaoSelecionado = cartao;
      this.detalhesModalOpen = true;
    },
    fecharDetalhesModal() {
      this.detalhesModalOpen = false;
      this.cartaoSelecionado = null;
    }
  }
}
</script>
