<template>
  <div class="container-formulario">

    <div class="cabecalho-dashboard">
      <h1>Orçamentos</h1>

      <button @click="$router.push(`/dashboard`)" class="text-2xl">
        <i class="fas fa-chevron-left mr-1 "></i>
      </button>
    </div>

    <div class="flex flex-wrap">
      <button class="escopo-dashboard-desktop" @click="$router.push(`/lista-orcamentos`)">

        <div class="opcao-dashboard">
          <div class="cacebalho-opcao">
            <h2 class="titulo-opcao">Orçamentos</h2>
            <i class="fa-solid fa-industry"></i>
          </div>

          <div class="descricao-opcao">
            <p class="mt-6">Veja aqui seus orçamentos</p>
          </div>
        </div>

      </button>

      <button class="escopo-dashboard-desktop" @click="$router.push(`/orcamentos/cadastro`)">

        <div class="opcao-dashboard">
          <div class="cacebalho-opcao">
            <h2 class="titulo-opcao">Incluir Orçamento</h2>
            <i class="fa-solid fa-industry"></i>
          </div>

          <div class="descricao-opcao">
            <p class="mt-6">Cadastre novos orçamentos</p>
          </div>
        </div>

      </button>

    </div>

  </div>
</template>

<script>
export default {
  name: 'OrcamentoDashboard',

  data() {
    return {
      
    }
  },
}
</script>