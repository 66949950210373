
<template>

  <div class="container-formulario">

    <!-- TILO DO FORMULARIO + BOTAO DE VOLTAR -->
    <div class="cabecalho-formulario">
      <h1 class="text-xl font-bold text-corBase">Dados Linha:</h1>

      <router-link to="/linha-sistema" class="text-xl text-corBase font-bold">
        <i class="fas fa-chevron-left"></i>
      </router-link>
    </div>

    <!-- Escopo do formulario de cadastro mobile-->
    <form @submit.prevent="cadastrarTipologia">
      <!--        PARTE DE DADOS DO PRODUTO-->
      <div class="flex flex-col gap-3 my-3 w-full md:flex-row md:w-full ">
        <div class="campo-chapa">
          <label class="text-xl font-bold text-corBase">Nome</label>
          <input type="text" v-model="nome" class="input-form-chapa" required>
        </div>
        <div class="campo-chapa">
          <label class="text-xl font-bold text-corBase">Bitola</label>
          <input type="number" v-model="bitola" class="input-form-chapa">
        </div>

        <div class="campo-chapa">
          <label class="text-xl font-bold text-corBase">Projetistas</label>
          <Select2 required v-model="projetistasistemista"
                   class="w-full z-10"
                   :options="projetistas_sistemistas"
                   :settings="{ width: '100%'}"
          />
        </div>

      </div>


      <div class="flex w-full justify-end">
        <button class="btn-enviar" type="submit">Salvar</button>
      </div>

    </form>
  </div>
  <menssagem-erro v-if="erroMensagem === true" mensagem="Erro Ao Cadastrar"
                  rotas="/linha-sistema/cadastro"></menssagem-erro>
  <MensagemSucesso v-if="sucesso==true" rotas="/linha-sistema" mensagem="Linha Cadastrada com Sucesso!"/>

</template>

<script>
import axios from "axios";
import MensagemSucesso from "@/components/toasts/toast_sucesso/MensagemSucesso";
import Select2 from "vue3-select2-component";
import MenssagemErro from "@/components/toasts/toast_erro/MenssagemErro";

export default {
  name: "CadastroLinhasSistemas",
  components: {
    MensagemSucesso,
    Select2,
    MenssagemErro
  },

  data() {
    return {
      sucesso: false,
      erroMensagem: false,
      nome: '',
      codigo: '',
      bitola: '',
      projetistasistemista: [],
      projetistas_sistemistas: []
    }
  },

  created() {
    this.getListaProjetistaSistemista()
  },

  computed: {
    minhaEmpresa() {
      return this.$store.state.minhaEmpresa;
    },
    isadmin() {
      return this.$store.state.isAdminUser
    },
  },

  methods: {


    async cadastrarTipologia() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')
      console.log(this.projetistasistemista)
      //:TODO

      // const projetistasistemistasDict = this.projetistasistemista.map(item => {
      //   return {id: item};
      // });

      const data = {
        ativo: true,
        codigo: this.codigo,
        linha: this.nome,
        bitola: this.bitola,
        projetistasistemistas: this.projetistasistemista
      }
      this.$store.dispatch("setLoading", true);
      this.sucesso = false
      await axios.post('projeto/linha-sistema/', data, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            console.log(response.data.results);
            this.sucesso = true
            this.nome = ''
            this.projetistasistemista = []
            this.bitola = ''
            this.$store.dispatch("setLoading", false);
          })
          .catch(error => {
            console.log(error);
            this.erroMensagem = true
            this.$store.dispatch("setLoading", false);
          })


    },

    async getListaProjetistaSistemista() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get('projeto/projetista-sistemista/?page_size=999', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            this.projetistas_sistemistas = response.data.results.map(item => ({
              id: item.id,
              text: item.nome
            }));
            console.log(response.data)
          })
          .catch(error => {
            console.log(error)
          })
    }


  }

}
</script>

<style lang="scss"></style>