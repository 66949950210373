<template>
  <!-- Erro ao realizar login-->
  <div class="posicao-toast-erro" value="erro_login">
    <div class="container-toast-erro">
      <i class="fas fa-xmark-circle icone-toast-erro"></i>
      <h2 class="texto-toast-erro">
        {{ mensagem }}
      </h2>
    </div>
  </div>


</template>

<script>
export default {
  name: "ToastError",

  props: ['mensagem']
}
</script>

<style lang="scss">

.posicao-toast-erro {
  @apply absolute right-0 top-0 m-5 z-[100000]
}

.container-toast-erro {
  @apply flex items-center bg-red-500 border-l-4 border-red-700 py-2 px-3 shadow-md mb-2
}

.texto-toast-erro {
  @apply text-white max-w-xs
}

.icone-toast-erro {
  @apply text-white mr-3 text-2xl
}

</style>