<template>


  <div class="container-formulario">
    <galeria v-if="galeria === true" @fechar_galeria="abrir_galeria" @objetoClicado="atualizarFileInput"/>
    <!-- TILO DO FORMULARIO + BOTAO DE VOLTAR -->
    <div class="cabecalho-formulario">
      <h1 class="text-xl text-corBase font-bold">Dados Uso Consumo:</h1>

      <router-link to="/produtos/uso-consumo" class="text-xl text-corBase font-bold">
        <i class="fas fa-chevron-left"></i>
      </router-link>
    </div>

    <!-- Escopo do formulario de cadastro mobile-->
    <form @submit.prevent="cadastrarUsoConsumo" enctype="multipart/form-data">
      <!--        PARTE DE DADOS DO PRODUTO-->

      <template class="flex flex-col gap-3 my-3 w-full">
        <div class="div-produtos">
          <div class="flex flex-col w-[40vw]">
            <label class="text-corBase text-xl font-bold">Adicione uma imagem:</label>
            <div class="imagePreviewWrapper" :style="{ 'background-image': `url(${previewImage.url_documento})` }">
            </div>
            <div class="flex gap-3 mt-4">
              <button class="selecionar-arquivo" @click.prevent="abrir_galeria">
                Pesquisar
              </button>

            </div>

          </div>

          <div class="flex flex-col w-full gap-[14px] ml-4 mr-1">
            <div class="campo-chapa">
              <label class="font-bold text-xl text-corBase">Nome Especifico:</label>
              <input type="text" v-model="nome_especifico" class="input-form-chapa" required autofocus>
            </div>

            <div class="div-produtos ">
              <div class="flex flex-col gap-1 w-full">
                <label class="text-xl text-corBase font-bold">Produto Base:</label>
                <Select2 v-model="produto_base"
                         class="w-full z-10"
                         :options="lista_produto_por_fornecedor"
                         :settings="{ width: '100%'}"
                />
              </div>

            </div>


            <div class="flex gap-3 w-full mt-3">
              <div class="campo-chapa">
                <label class="text-xl text-corBase">Tipo:</label>
                <Select2 v-model="tipo_uso_consumo"
                         class="w-full z-10"
                         :options="lista_tipo_uso_consumo"
                         :settings="{ width: '100%'}"
                />
              </div>
              <div class="campo-chapa">
                <label class="text-xl text-corBase">Grupo Aplicacao:</label>
                <Select2 v-model="grupo_aplicacao_uso_consumo"
                         class="w-full z-10"
                         :options="lista_grupo_aplicacao_uso_consumo"
                         :settings="{ width: '100%'}"
                />
              </div>
            </div>

            <div class="flex gap-3 w-full mt-3">
              <div class="campo-chapa">
                <label class="text-xl text-corBase">Cor:</label>
                <Select2 v-model="cor_uso_consumo"
                         class="w-full z-10"
                         :options="lista_cor_uso_consumo"
                         :settings="{ width: '100%'}"
                />
              </div>

            </div>


          </div>

        </div>

        <div class="flex justify-between ">
          <div class="flex w-full justify-end">
            <button class="btn-enviar" type="submit">Salvar</button>
          </div>
        </div>
      </template>


    </form>

  </div>

  <mensagem-sucesso v-if="sucesso === true" mensagem="Uso COnsumo cadastrado com Sucesso!"
                    rotas="/produtos/uso-consumo"/>


</template>

<script>
import axios from "axios";
import Select2 from "vue3-select2-component";
import MensagemSucesso from "@/components/toasts/toast_sucesso/MensagemSucesso";
import Galeria from "@/components/midais/ged/Galeria";

export default {
  name: "CadastroUsoConsumo",

  components: {
    Select2,
    MensagemSucesso,
    Galeria
  },


  data() {
    return {
      sucesso: false,
      galeria: false,
      previewImage: {},

      lista_produto_por_fornecedor: [],
      lista_cor_uso_consumo: [],
      lista_tipo_uso_consumo: [],
      lista_grupo_aplicacao_uso_consumo: [],

      nome_especifico: '',
      produto_base: '',
      cor_uso_consumo: '',
      tipo_uso_consumo: '',
      grupo_aplicacao_uso_consumo: '',
      unidade_medida_embalagem: '',
      quantidade_embalagem: '',

      proximo: false,

    }
  },

  created() {
    this.getFornecedor()
    this.getProdutoPorFornecedor()
    this.getGrupoAplicacaoUsoConsumo()
    this.getCorUsoConsumo()
    this.getTipoUsoConsumo()
  },

  computed: {
    minhaEmpresa() {
      return this.$store.state.minhaEmpresa;
    },
  },

  methods: {
    atualizarFileInput(objeto) {
      console.log('objeto que chegou:' + objeto)
      console.log(objeto)
      this.previewImage = objeto; // ou a propriedade correta do objeto que deseja atribuir
    },
    abrir_galeria() {
      this.galeria = !this.galeria
    },
    trocar_de_pagina() {
      this.proximo = !this.proximo
    },

    async cadastrarUsoConsumo() {
      const token = await this.$store.dispatch('getAuthTokenCookie')

      await this.$store.dispatch('fetchURLrequest')

      const data = new FormData();
      data.append('ativo', 'true')
      data.append('nome_especifico_uso_consumo', this.nome_especifico)
      data.append('uso_consumo_base', this.produto_base)
      data.append('cor_uso_consumo', this.cor_uso_consumo)
      data.append('tipo_uso_consumo', this.tipo_uso_consumo)
      data.append('grupo_aplicacao_uso_consumo', this.grupo_aplicacao_uso_consumo)
      data.append('imagem', this.previewImage.id)

      this.$store.dispatch("setLoading", true);
      await axios.post('produtos/uso-consumo/', data, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            console.log(response.data);
            this.sucesso = true
            this.$store.dispatch("setLoading", false);
          })
          .catch(error => {
            console.log(error);
            this.$store.dispatch("setLoading", false);
          })

    },

    async getProdutoPorFornecedor() {
      const token = await this.$store.dispatch('getAuthTokenCookie')

      await this.$store.dispatch('fetchURLrequest')

      await axios.get('produtos/produto-por-fornecedor', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            console.log(response.data)
            this.lista_produto_por_fornecedor = response.data.map(item => ({
              id: item.id,
              text: `${item.produto_base.nome_base} - ${item.produto_base.codigo_base}`,
              img: item.produto_base.imagem
            }))

          })
          .catch(error => {
            console.log(error)
          })

    },

    async getFornecedor() {
      try {
        const listaFornecedor = await this.$store.dispatch('fetchListaFornecedor')
        this.lista_fornecedor = listaFornecedor.map(item => ({
          id: item.id,
          text: item.nome_razao
        }))

      } catch (error) {
        console.log(error)
      }
    },

    async getCorUsoConsumo() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get('produtos/cor-uso-consumo', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            console.log(response.data)
            this.lista_cor_uso_consumo = response.data.map(item => ({
              id: item.id,
              text: item.nome
            }))
          })
          .catch(error => {
            console.log(error)
          })
    },

    async getTipoUsoConsumo() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get('produtos/tipo-uso-consumo', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            console.log(response.data)
            this.lista_tipo_uso_consumo = response.data.map(item => ({
              id: item.id,
              text: item.nome
            }))
          })
          .catch(error => {
            console.log(error)
          })
    },

    async getGrupoAplicacaoUsoConsumo() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get('produtos/grupo-aplicacao-uso-consumo', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            console.log(response.data)
            this.lista_grupo_aplicacao_uso_consumo = response.data.map(item => ({
              id: item.id,
              text: item.nome
            }))
          })
          .catch(error => {
            console.log(error)
          })
    },

  }
}
</script>

<style lang="scss">

</style>