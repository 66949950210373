<template>

  <div class="container-formulario">

    <!-- TILO DO FORMULARIO + BOTAO DE VOLTAR -->
    <div class="cabecalho-formulario">
      <h1 class="text-xl text-corBase">Dados Tipo Pessoa:</h1>

      <router-link to="/tipo-pessoa" class="text-xl text-corBase font-bold">
        <i class="fas fa-chevron-left"></i>
      </router-link>
    </div>

    <!-- Escopo do formulario de cadastro mobile-->
    <form @submit.prevent="cadastrarTipoPessoa">
      <!--        PARTE DE DADOS DO PRODUTO-->
      <div class="div-produtos">
        <div class="campo-chapa">
          <label class="font-bold text-corBase">Nome</label>
          <input type="text" v-model="descricao" class="input-form-chapa" required autofocus>
        </div>
        <div class="campo-chapa">
          <label class="font-bold text-corBase">Sigla</label>
          <input type="text" v-model="sigla" class="input-form-chapa" required>
        </div>
      </div>


      <div class="flex w-full justify-end">
        <button class="btn-enviar" type="submit">Cadastrar</button>
      </div>
    </form>

  </div>
  <MensagemSucesso v-if="sucesso==true" rotas="/tipo-pessoa" mensagem="Tipo pessoa cadastrado com sucesso!"/>

</template>

<script>
import axios from "axios";
import MensagemSucesso from "@/components/toasts/toast_sucesso/MensagemSucesso";

export default {
  name: "TipoPessoa",

  components: {
    MensagemSucesso,
  },

  data() {
    return {
      sucesso: false,
      descricao: '',
      sigla: '',
    }
  },

  created() {
  },

  computed: {
    minhaEmpresa() {
      return this.$store.state.minhaEmpresa;
    },
    isadmin() {
      return this.$store.state.isAdminUser
    },
  },

  methods: {


    async cadastrarTipoPessoa() {
      const token = await this.$store.dispatch('getAuthTokenCookie')

      await this.$store.dispatch('fetchURLrequest')

      const data = new FormData();
      data.append('descricao', this.descricao)
      data.append('sigla', this.sigla)
      data.append('ativo', 'True')
      this.sucesso = false
      await axios.post('pessoas/tipo-pessoas', data, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            console.log(response.data.results);
            this.sucesso = true
          })
          .catch(error => {
            console.log(error);
          })

    },


  }

}
</script>

<style lang="scss">

</style>