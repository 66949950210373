<template>

  <details>
    <summary class="mt-4">
      <h2 class="bg-screenCor hover:bg-screenCor border border-corBase hover:border-corBase
  text-corBase hover:text-corBase py-2 px-4 ">Telefones
      </h2>
    </summary>

    <button @click.prevent="adicionarTelefone" class="bg-ativo hover:bg-screenCor border border-screenCor hover:border-ativo
  text-white hover:text-ativo py-2 px-4 mt-2 mr-2">Adicionar
    </button>

    <div class="border border-gray-200 p-2 mt-3" v-for="(telefone, index) in dadosTelefone" :key="index">
      <div class="container-campos ">
        <div class="campos campos-mobile campos-dasktop-select">
          <label class="text-base text-corBase mt-2">Tipo de Telefone:</label>
          <div class="flex">
            <select class="input-campos" v-model="telefone.tipo_telefone">
              <option value="">------</option>
              <option v-for="item in lista_tipo_telefone"
                      :key="item.id"
                      :value="item.id"
              >{{ item.descricao }}
              </option>
            </select>
            <tipo-telefone-modal/>
          </div>
        </div>
        <div class="campos campos-mobile campos-dasktop-select">
          <label class="text-base text-corBase mt-2">DDD:</label>
          <input type="text"

                 class="input-campos"
                 placeholder=""
                 v-model="telefone.ddd"
          >
        </div>

        <div class="campos campos-mobile">
          <label class="text-base text-corBase mt-2">Telefone:</label>
          <input type="text"

                 class="input-campos"
                 placeholder=""
                 v-mask="'#####-####', '#####-###'"
                 v-model="telefone.numero_telefone"
          >
        </div>

      </div>

    </div>

  </details>


</template>
<script>
import TipoTelefoneModal from "@/components/midais/modaisCadastro/TipoTelefoneModal";
import TipoEmailModal from "@/components/midais/modaisCadastro/TipoEmailModal";
import axios from "axios";

export default {
  name: "TelefonesCampos",

  components: {
    TipoTelefoneModal,
    TipoEmailModal
  },
  data() {
    return {
      lista_tipo_telefone: [],
    }
  },
  computed: {
    isadmin() {
      return this.$store.state.isAdminUser
    },
    minhaEmpresa() {
      return this.$store.state.minhaEmpresa;
    },
  },
  props: ['dadosTelefone'],

  created() {
    this.getListaTipoTelefone()
  },

  methods: {
    adicionarTelefone() {
      this.dadosTelefone.push({
        ddd: '',
        telefone: '',
        tipo_telefone: '',
        email_pessoa: '',
        tipo_email: '',
      });
    },
    removerTelefone(index) {
      this.dadosTelefone.splice(index, 1);
    },

    async getListaTipoTelefone() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get('pessoas/telefones/tipo-telefones', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
      .then(response => {
        this.lista_tipo_telefone = response.data.results;
      })
      .catch(error => {
        console.log(error)
      })
    }
  }

}
</script>

<style scoped></style>