<template>
  <div class="container-formulario pb-10">
    <!-- Cabeçalho lista de pessoas depois de 768 pixels-->
    <div class="container-cabecalho flex justify-between mb-4">
      <div>
        <h1 class="text-xl text-corBase font-bold">Entrada/Saida Estoque</h1>
      </div>

      <router-link
        to="/estoque/entrada-saida"
        class="text-xl text-corBase font-bold"
      >
        <i class="fas fa-chevron-left"></i>
      </router-link>
    </div>

    <div class="w-full border p-4 flex flex-col md-flex-row gap-6">
      <div class="flex flex-col gap-2 w-full md:w-[20%]">
        <label class="font-bold text-corBase">Tipo da Movimentação</label>
        <select class="input-form-chapa" v-model="tipo_movimentacao">
          <option value="entrada manual">Entrada Manual</option>
          <option value="saida">Saida</option>
        </select>
      </div>
    </div>
    <!-- se for entrada manual -->
    <div
      v-if="tipo_movimentacao === 'entrada manual'"
      class="mt-4 w-full border p-4 flex flex-col gap-6"
    >
      <div
        class="border border-gray-200 p-2 mt-3"
        v-for="(produto, index) in produtos"
        :key="index"
      >
        <div class="flex flex-col md:flex-row w-full">
          <div class="campo-chapa">
            <label class="text-xl text-corBase">Produto:</label>
            <v-select
              label="nome_especifico"
              v-model="produto.produto"
              :reduce="(option) => option.id"
              :options="lista_produto_fornecedor"
              @input="fetchProdutoFornecedor"
            >
              <template #option="{ nome_especifico, codigo_fornecedor }">
                <strong>{{ codigo_fornecedor }}</strong> -
                <span>{{ nome_especifico }}</span>
              </template>
            </v-select>
          </div>

          <div class="campo-chapa">
            <label class="text-xl text-corBase">Quantidade:</label>
            <input
              class="input-form-chapa"
              type="number"
              v-model="produto.qtd_produtos"
            />
          </div>
          <div class="campo-chapa">
            <label class="text-xl text-corBase">Preço de Custo:</label>
            <input
              class="input-form-chapa"
              type="number"
              v-model="produto.preco_custo"
            />
          </div>
        </div>
      </div>
      <button
        @click.prevent="adicionarProduto"
        class="bg-ativo hover:bg-screenCor border border-screenCor hover:border-ativo text-white hover:text-ativo py-2 px-4 mt-2 mr-2"
      >
        Adicionar Produto
      </button>
    </div>

    <!-- se for saida manual -->
    <div v-if="tipo_movimentacao === 'saida'">
      <div
        class="border border-gray-200 p-2 mt-3"
        v-for="(produto, index) in produtos"
        :key="index"
      >
        <div class="flex flex-col md:flex-row w-full">
          <div class="campo-chapa">
            <label class="text-xl text-corBase">Produto:</label>
            <v-select
              label="nome_especifico"
              v-model="produto.produto"
              :reduce="(option) => option.id"
              :options="lista_produto_fornecedor"
              @input="fetchProdutoFornecedor"
            >
              <template #option="{ nome_especifico, codigo_fornecedor }">
                <strong>{{ codigo_fornecedor }}</strong> -
                <span>{{ nome_especifico }}</span>
              </template>
            </v-select>
          </div>

          <div class="campo-chapa">
            <label class="text-xl text-corBase">Quantidade:</label>
            <input
              class="input-form-chapa"
              type="number"
              v-model="produto.qtd_produtos"
            />
          </div>

          <div class="campo-chapa">
            <label class="text-xl text-corBase">Motivo Saída: </label>
            <input
              class="input-form-chapa"
              type="text"
              v-model="motivo_saida"
              required
              mix="10"
              max="255"
            />
          </div>
        </div>
      </div>
      <button
        @click.prevent="adicionarProduto"
        class="bg-ativo hover:bg-screenCor border border-screenCor hover:border-ativo text-white hover:text-ativo py-2 px-4 mt-2 mr-2"
      >
        Adicionar Produto
      </button>
    </div>

    <button
      v-if="tipo_movimentacao === 'entrada manual'"
      @click.prevent="entradaManual"
      class="bg-ativo hover:bg-screenCor border border-screenCor hover:border-ativo text-white hover:text-ativo py-2 px-4 mt-2 mr-2"
    >
      Dar Entrada
    </button>
    <button
      v-if="tipo_movimentacao === 'saida'"
      @click.prevent="saidaManual"
      class="bg-ativo hover:bg-screenCor border border-screenCor hover:border-ativo text-white hover:text-ativo py-2 px-4 mt-2 mr-2"
    >
      Dar Saida
    </button>
    <mensagem-sucesso
      v-if="sucesso === true && tipo_movimentacao === 'entrada manual'"
      mensagem="Entrada Realizada com Sucesso!"
      rotas="/estoque/entrada-saida"
    />
    <mensagem-sucesso
      v-if="sucesso === true && tipo_movimentacao === 'saida'"
      mensagem="Saida Realizada com Sucesso!"
      rotas="/estoque/entrada-saida"
    />
  </div>
</template>

<script>
import vSelect from "vue-select";
import axios from "axios";
import MensagemSucesso from "@/components/toasts/toast_sucesso/MensagemSucesso";
import debounce from "lodash/debounce";

export default {
  name: "EntradaSaidaEstoque",
  components: {
    MensagemSucesso,
    vSelect,
  },

  data() {
    return {
      tipo_movimentacao: "",
      numero_nota: "",
      lista_fornecedor: [],
      lista_produto_fornecedor: [],
      fornecedor: "",
      data_emissao_nf: "",
      motivo_saida: '',
      produtos: [],
      sucesso: false,
    };
  },

  methods: {
    adicionarProduto() {
      this.produtos.push({
        produto: "",
        qtd_produto: 0,
        preco_custo: 0,
        motivo_saida: ''
      });
    },

    async getFornecedores() {
      const token = await this.$store.dispatch("getAuthTokenCookie");
      await this.$store.dispatch("fetchURLrequest");

      await axios
        .get("/pessoas/fornecedor", {
          headers: {
            Authorization: `Token ${token}`,
          },
        })
        .then((response) => {
          this.lista_fornecedor = response.data.results.map((item) => ({
            id: item.id,
            text: item.pessoa.pessoa_juridica.nome_razao,
          }));
        });
    },

    async fetchProdutoFornecedor(searchInput) {
      const token = await this.$store.dispatch("getAuthTokenCookie");
      await this.$store.dispatch("fetchURLrequest");

      if (!searchInput) {
        this.lista_produto_fornecedor = [];
        return;
      }
      const filtro = searchInput.target.value;
      debounce(() => {
        axios
          .get(`produtos/produto-por-fornecedor?filtro_base=${filtro}`, {
            headers: {
              Authorization: `Token ${token}`,
            },
          })
          .then((response) => {
            console.log(response)
            this.lista_produto_fornecedor = response.data.results.map(
              (item) => ({
                id: item.id,
                nome_especifico: item.nome_especifico,
                codigo_fornecedor: item.codigo_fornecedor,
              })
            );
          })
          .catch((error) => {
            console.error(error);
          });
      }, 1000)();
    },

    async entradaManual() {
      const token = await this.$store.dispatch("getAuthTokenCookie");
      await this.$store.dispatch("fetchURLrequest");
      const data = {
        tipo_movimentacao: "ENTRADA",
        motivo_movimentacao: "ENTRADA MANUAL",
        numero_nf: "",
        modelo_nf: "",
        serie_nf: "",
        chave_acesso_nf: "",
        total_nf: 0,
        produtos: this.produtos.map((item) => ({
          produto: item.produto,
          qtd_produtos: item.qtd_produtos,
          preco_custo: item.preco_custo,
        })),
      };
      this.$store.dispatch("setLoading", true);
      this.sucesso = false;

      await axios
        .post("produtos/movimentacao/", data, {
          headers: {
            Authorization: `Token ${token}`,
          },
        })
        .then((response) => {
          console.log(response.data);
          this.$store.dispatch("setLoading", false);
          this.produtos = [];
          this.sucesso = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async saidaManual() {
      const token = await this.$store.dispatch("getAuthTokenCookie");
      await this.$store.dispatch("fetchURLrequest");
      
      const data = {
        tipo_movimentacao: "SAIDA",
        motivo_movimentacao: "SAIDA MANUAL",
        motivo_saida: this.motivo_saida,
        produtos: this.produtos.map((item) => ({
          produto: item.produto,
          qtd_produtos: item.qtd_produtos,
          preco_custo: item.preco_custo,
        })),
        total_nf: 0,
      };
      this.$store.dispatch("setLoading", true);
      this.sucesso = false;

      await axios
        .post("produtos/movimentacao/", data, {
          headers: {
            Authorization: `Token ${token}`,
          },
        })
        .then((response) => {
          console.log(response.data);
          this.$store.dispatch("setLoading", false);
          this.produtos = [];
          this.sucesso = true;
        })
        .catch((err) => {
          this.$store.dispatch("setLoading", false);
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss">
</style>