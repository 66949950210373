<template>
  <cadastros-db />
</template>

<script>
import CadastrosDb from "@/components/dashboards_components/CadastrosDB";
export default {
  name: "Cadastros",
  components: {
    CadastrosDb,
  }

}
</script>

<style lang="scss">

</style>