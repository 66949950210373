<template>
  <div class="fundo-modal" v-if="abrir==true">
    <div class="modal">

      <div class="secao-mensagem-sucesso-modal">
        <p class="mensagem-sucesso-modal">{{ mensagem }}</p>
        <div class="fundo-icone-deu-certo"><i class="fa-solid fa-x icone-deu-certo"></i></div>
      </div>

      <div class="secao-botao">
        <button class="botao-modal botao-modal-voltar" @click.prevent="feharModal()">Não</button>
        <button type="button" class="botao-modal botao-modal-continuar" @click.prevent="confirmarExclusao()">Sim</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MensagemConfirmacaoExclusao',

  data () {
    return {
      
    }
  },

  props: {
    mensagem: String,
    abrir: Boolean,
  },

  computed: {
    abrir() {
      return this.abrir
    }
  },

  methods:{
    feharModal() {
      this.$emit('feharModal', false)
    },

    confirmarExclusao() {
      this.$emit('confirmarExclusao')
    }
  }
}
</script>

<style scoped lang="scss">
.fundo-modal{
  @apply absolute flex justify-center items-center bg-black bg-opacity-10 left-0 top-0 w-screen h-screen z-[1000]
}

.modal{
  @apply absolute z-[1000] flex flex-col justify-center gap-20 items-center bg-white w-72 h-96 py-6 px-4 rounded-xl shadow-xl
}

.secao-icone-fechar{
  @apply w-full flex justify-end
}

.icone-fechar{
  @apply text-corBase text-2xl cursor-pointer
}

.secao-mensagem-sucesso-modal{
  @apply flex flex-col items-center justify-center
}

.mensagem-sucesso-modal{
  @apply flex flex-col items-center text-corBase font-bold text-center text-lg
}

.fundo-icone-deu-certo{
  @apply flex justify-center items-center mt-8 bg-inativo w-20 h-20 rounded-full
}

.icone-deu-certo{
  @apply text-white text-5xl
}

.secao-botao{
  @apply w-full flex justify-between
}

.botao-modal{
  @apply flex  justify-center items-center w-24 h-8 bg-white text-sm font-medium rounded-md
}

.botao-modal-voltar{
  @apply bg-inativo text-white
}

.botao-modal-continuar{
  @apply bg-ativo text-white
}
</style>