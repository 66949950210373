<template>
  <section class="sessao-cadastro">
    <div class="container-formulario">

      <div class="cabecalho-formulario">
        <h1 class="text-xl text-corBase font-bold">Dados da Reserva:</h1>

        <router-link to="/estoque/reservas" class="text-xl text-corBase font-bold">
          <i class="fas fa-chevron-left"></i>
        </router-link>
      </div>

      <form @submit.prevent="adicionarReserva">

        <div class="flex flex-col gap-3 md:flex md:flex-row md:gap-3">
          <div class="flex flex-col mt-2 w-full">
            <label class="text-base text-corBase font-bold mt-2">Título (Nome da Obra):</label>
            <input 
              type="text"
              required 
              class="input-campos" 
              v-model="titulo_reserva"
            >
          </div>
        </div>

        <details>
          <summary class="mt-4">
            <h2 class="bg-screenCor hover:bg-screenCor border border-corBase hover:border-corBase
        text-corBase hover:text-corBase py-2 px-4 ">Produtos da Reserva
            </h2>
          </summary>
          <div>
            <div
              class="border border-gray-200 p-2 mt-3"
              v-for="(produto, index) in produtos"
              :key="index"
            >
              <div class="flex flex-col md:flex-row w-full">
                <div class="campo-chapa">
                  <label class="text-xl text-corBase">Produto:</label>
                  <vSelect
                    label="nome_especifico"
                    v-model="produto.id"
                    :reduce="(option) => option.id"
                    :options="listaProdutosFornecedor"
                    @input="fetchProdutoFornecedor"
                  >

                  <template #option="{ nome_especifico, codigo_fornecedor }">
                    <strong>{{ codigo_fornecedor }}</strong> -
                    <span>{{ nome_especifico }}</span>
                  </template>
                  </vSelect>
                </div>

                <div class="campo-chapa">
                  <label class="text-xl text-corBase">Quantidade:</label>
                  <input
                    class="input-form-chapa"
                    type="number"
                    v-model="produto.qtd_produto"
                  />
                </div>
              </div>
            </div>
            <button
              @click.prevent="adicionarProduto"
              class="bg-ativo hover:bg-screenCor border border-screenCor hover:border-ativo text-white hover:text-ativo py-2 px-4 mt-2 mr-2"
            >
              Adicionar Produto
            </button>
          </div>
        </details>

        <div class="flex w-full justify-end">
          <button class="btn-enviar" type="submit">Adicionar</button>
        </div>

      </form>

    </div>

    <mensagem-sucesso v-if="sucesso === true" mensagem="Reserva adicionada com Sucesso!"
                      rotas="/estoque/reservas"/>
  </section>
  
</template>

<script>
import axios from 'axios'
import MensagemSucesso from '../toasts/toast_sucesso/MensagemSucesso.vue'
import vSelect from "vue-select";
import debounce from "lodash/debounce";
export default {
  name: 'AdicionarReservaEstoque',

  data() {
    return {
      titulo_reserva: '',
      produtos: [],
      listaProdutosFornecedor: [],
      reserva: {},
      sucesso: false
    }
  },

  components: {
    MensagemSucesso,
    vSelect
  },

  methods: {
    async adicionarReserva() {
      try{
        const token = await this.$store.state.token
        await this.$store.dispatch('fetchURLrequest')

        const response = await axios.post('/produtos/reserva-de-estoque/', {
            titulo_reserva: this.titulo_reserva
          }, {
            headers: {
              Authorization: `Token ${token}`
            }
          })

          const reserva = response.data

          if(this.produtos.length > 0){
            this.adicionarProdutoReservado(reserva.id)
          }
          this.sucesso = true
      }catch(e){
        console.error(e.menssage)
      }
    },

    async adicionarProdutoReservado(reservaId) {
      try{
        const token = await this.$store.state.token
        await this.$store.dispatch('fetchURLrequest')  

        const produtosReservadosPromisses = []

        for(const produto of this.produtos) {
          produtosReservadosPromisses.push(
            axios.post('/produtos/produto-reservado/', {
              qtd_produto: produto.qtd_produto,
              reserva: reservaId,
              produto: produto.id
            },
            {
              headers: {
                Authorization: `Token ${token}`
              }
            })
          )
        }

        await Promise.all(produtosReservadosPromisses)
        
      }catch(e){
        console.error(e.menssage)
      }
    },

    adicionarProduto() {
      this.produtos.push({
        produto_id: "",
        qtd_produto: 0,
      });
    },

    async fetchProdutoFornecedor(searchInput) {
      const token = await this.$store.dispatch("getAuthTokenCookie");
      await this.$store.dispatch("fetchURLrequest");

      if (!searchInput) {
        this.listaProdutosFornecedor = [];
        return;
      }
      const filtro = searchInput.target.value;
      debounce(() => {
        axios
          .get(`produtos/produto-por-fornecedor?filtro_base=${filtro}`, {
            headers: {
              Authorization: `Token ${token}`,
            },
          })
          .then((response) => {
            this.listaProdutosFornecedor = response.data.results.map(
              (item) => ({
                id: item.id,
                nome_especifico: item.nome_especifico,
                codigo_fornecedor: item.codigo_fornecedor,
              })
            );
          })
          .catch((error) => {
            console.error(error);
          });
      }, 1000)();
    },
  }
}
</script>

<style lang="scss" scoped>

</style>