<template>

  <div class="container-formulario">

    <!-- Cabeçalho lista de pessoas depois de 768 pixels-->
    <div class="container-cabecalho flex justify-between mb-4">
      <div>
        <h1 class="text-xl text-corBase font-bold">Tipos de Chapas</h1>
      </div>

      <router-link to="/tipos" class="text-xl text-corBase font-bold">
        <i class="fas fa-chevron-left"></i>
      </router-link>

    </div>

    <div class="flex items-center gap-3 mb-10">

      <div class="flex gap-8">
        <div>
          <button class="bg-corBase hover:bg-screenCor border border-screenCor hover:border-corBase
  text-white hover:text-corBase py-2 px-4" @click="getListaTipoChapa">Pesquisar
          </button>
        </div>
      </div>
      <div v-if="isadmin === true">
        <button @click="$router.push(`/tipo-chapa/cadastro`)" class="bg-ativo hover:bg-screenCor border border-screenCor hover:border-ativo
  text-white hover:text-ativo py-2 px-4 ">Adicionar
        </button>
      </div>
    </div>

    <!-- Container da exibiçao da lista de pessoas -->
    <div class="container-modelo-tabela">
      <table class="container-tabela overflow-auto">
        <thead class="cabecalho-tabela">
        <tr>
          <th scope="col" class="p-4">
            <div class="flex items-center">
              <input type="checkbox" class="checkbox" v-model="checkAll" @change="selectAll">

              <label class="sr-only">checkbox</label>
            </div>
          </th>
          <th scope="col" class="px-6 py-3">
            #
          </th>
          <th class="px-6 w-full py-3">
            Nome
          </th>
          <th scope="col" class="px-6 py-3">
            Status
          </th>
          <th scope="col" class="px-10 py-3">
            Ação
          </th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(tipo, index) in listaTipoChapaPaginada" :key="index" class="escopo-tabela">

          <td class="w-4 p-4">
            <div class="flex items-center">
              <input :id="'checkbox-table-' + (index + 2)" type="checkbox" class="checkbox"
                     v-model="tipo.selected">
              <label class="sr-only">checkbox</label>
            </div>
          </td>
          <th scope="row"
              class="px-6 py-2 font-medium text-xs text-gray-900 whitespace-nowrap dark:text-white">
            {{ index + 1 }}
          </th>
          <td class="px-6 py-2 text-xs">
            {{ tipo.nome }}
          </td>

          <template v-if="tipo.ativo === true">
            <td class="px-1 sm:px-6  py-1 sm:py-4 text-center">
              <i class="fa-solid fa-circle-check  text-ativo"></i>
            </td>
          </template>
          <template v-if="tipo.ativo === false">
            <td class="px-1 sm:px-6  py-1 sm:py-4 text-center">
              <i class="fa-solid fa-circle-xmark text-inativo"></i>
            </td>
          </template>

          <td class="px-6 py-2 text-xs">
            <editar-tipo-chapa :tipo="tipo" :paginaAtual="paginaAtual"/>
          </td>

        </tr>
        </tbody>
      </table>

      <div class="paginacao">
        <button @click.prevent="paginaAnterior" class="btn-paginacao">
          <i class="fas fa-chevron-left"></i>
        </button>
        <template v-for="pagina in paginasExibidas" :key="pagina">
      <span
          @click.prevent="irParaPagina(pagina)"
          :class="{'numero-paginacao': true, 'numero-paginacao-ativa': pagina === paginaAtual}"
      >
        {{ pagina }}
      </span>
        </template>
        <button @click.prevent="proximaPagina" class="btn-paginacao">
          <i class="fas fa-chevron-right"></i>
        </button>
      </div>
    </div>
  </div>

</template>

<script>
import axios from "axios";
import EditarTipoChapa from "@/components/midais/Editar/tipo/EditarTipoChapa";
import EditarTipoEmail from "@/components/midais/Editar/tipo/EditarTipoEmail";

export default {
  name: "ListaTipoChapas",

  components: {
    EditarTipoEmail,
    EditarTipoChapa
  },

  data() {
    return {
      lista_tipo_chapa: [],

      checkAll: false,
      // lista usada para salvar esse retorno depois de dividido
      listaTipoChapaPaginada: [],
      // varival que inicializa o numero de paginas
      paginaAtual: 1,
      itemsPorPagina: 5,

      error: [],
    }
  },

  computed: {
    totalPaginas() {
      return Math.ceil(this.lista_tipo_chapa.length / this.itemsPorPagina);
    },
    minhaEmpresa() {
      return this.$store.state.minhaEmpresa;
    },

    isadmin() {
      return this.$store.state.isAdminUser
    },

    paginasExibidas() {
      const paginas = [];
      const paginasVisiveis = 5; // Número de páginas visíveis de cada vez

      let inicio = Math.max(1, this.paginaAtual - Math.floor(paginasVisiveis / 2));
      let fim = inicio + paginasVisiveis - 1;

      if (inicio + paginasVisiveis > this.totalPaginas) {
        inicio = Math.max(1, this.totalPaginas - paginasVisiveis + 1);
        fim = this.totalPaginas;
      }

      for (let i = inicio; i <= fim; i++) {
        paginas.push(i);
      }

      return paginas;
    },
  },

  created() {

  },

  methods: {

    selectAll() {
      // Atualiza o estado dos demais checkboxes
      const checkboxes = document.querySelectorAll('.escopo-tabela input[type="checkbox"]');
      checkboxes.forEach((checkbox) => {
        checkbox.checked = this.checkAll;
      });
    },


    async getListaTipoChapa() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      this.$store.dispatch("setLoading", true);
      await axios.get(`/produtos/tipo-chapa`, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            this.lista_tipo_chapa = response.data
            console.log(this.lista_tipo_chapa)
            this.$store.dispatch("setLoading", false);
            this.paginarTabela()
          })
          .catch(error => {
            console.log(error)
            this.$store.dispatch("setLoading", false);
          })
    },
    paginaAnterior() {
      if (this.paginaAtual > 1) {
        this.paginaAtual--;
        this.paginarTabela();
      }
    },
    proximaPagina() {
      if (this.paginaAtual < this.totalPaginas) {
        this.paginaAtual++;
        this.paginarTabela();
      }
    },

    // exibe apenas 10 items da tabela e salva o resto
    paginarTabela() {
      const startIndex = (this.paginaAtual - 1) * this.itemsPorPagina;
      const endIndex = startIndex + this.itemsPorPagina;
      this.listaTipoChapaPaginada = this.lista_tipo_chapa.slice(startIndex, endIndex);
    },


    irParaPagina(pagina) {
      console.log("esta é a pagina" + pagina)
      if (pagina >= 1 && pagina <= this.totalPaginas) {
        this.paginaAtual = pagina;
        this.paginarTabela();
      }
    },

  }
}
</script>

<style lang="scss"></style>