<template>

  <!-- modal cadastro até 768 pixels-->

  <button class="ml-3 text-ativo" @click.prevent="modalEmail">
    <i class="fas fa-plus-circle"></i>
  </button>

  <div class="container-modal-cadastro" v-if="modalTipoEmail">
    <div class="sombra-modal">
      <div class="conteudo-modal">
        <form class="formulario-modal" @submit.prevent="cadastrarTipoEmail">
          <div class="flex justify-end">
            <button @click.prevent="fecharModal">
              <i class="fas fa-close text-2xl"></i>
            </button>
          </div>
          <h2 class="titulo-formulario">Cadastro Tipo Email</h2>

          <label class="label-formulario-modal">Descrição:</label>
          <input type="text"
                 required
                 class="input-campos"
                 v-model="descricao"
          >

          <div class="btn-formulario-modal">
            <button
                class="btn-formulario"
                type="submit"
            >
              Cadastrar
            </button>
          </div>
        </form>
      </div>
    </div>

  </div>

</template>

<script>
import axios from "axios";


export default {
  name: "TipoTelefoneModal",
  data() {
    return {
      modalTipoEmail: false,
      descricao: '',
    }
  },

  methods: {
    modalEmail() {
      this.modalTipoEmail = !this.modalTipoEmail
    },

    fecharModal() {
      this.modalTipoEmail = !this.modalTipoEmail
      console.log('fui clicado')
    },

    async cadastrarTipoEmail() {
      try {
        const tipo_email = {descricao: this.descricao}

        const token = await this.$store.dispatch('getAuthTokenCookie');
        await this.$store.dispatch('fetchURLrequest')
        const response = await axios.post('pessoas/email/tipo-email', tipo_email, {
          headers: {
            'Authorization': `Token ${token}`
          }
        });
        this.fecharModal()
      } catch (error) {
        this.erro = error.response.data.erro;
        console.error(this.erro);
      }
    }
  }
}
</script>
