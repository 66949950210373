<template>


  <div class="container-formulario">

    <div class="cabecalho-dashboard">
      <h1>Cadastros Grupos de Aplicação</h1>

      <button @click="$router.push(`/cadastros`)" class="text-2xl">
        <i class="fas fa-chevron-left mr-1 "></i>
      </button>
    </div>

    <div class="flex flex-wrap">

      <button @click="$router.push('/lista-grupo-aplicacao-chapa')" class="escopo-dashboard-desktop">

        <div class="opcao-dashboard">
          <div class="cacebalho-opcao">
            <h2 class="titulo-opcao">Chapas</h2>
            <i class="fas fa-user"></i>
          </div>

          <div class="descricao-opcao">
            <p class="mt-6">Cadastre as pessoas físicas presentes em seu sistema!</p>
          </div>
        </div>

      </button>

      <button class="escopo-dashboard-desktop" @click="$router.push('/lista-grupo-aplicacao-componente')">

        <div class="opcao-dashboard">
          <div class="cacebalho-opcao">
            <h2 class="titulo-opcao">Componentes</h2>
            <i class="fa-solid fa-industry"></i>
          </div>

          <div class="descricao-opcao">
            <p class="mt-6">Cadastre as empresas presentes em seu sistema!</p>
          </div>
        </div>

      </button>

      <button class="escopo-dashboard-desktop" @click="$router.push(`/lista-grupo-aplicacao-servico`)">

        <div class="opcao-dashboard">
          <div class="cacebalho-opcao">
            <h2 class="titulo-opcao">Serviço </h2>
            <i class="fas fa-users"></i>
          </div>

          <div class="descricao-opcao">
            <p class="mt-6">Veja os relatórios em tempo real!</p>
          </div>
        </div>

      </button>

      <button class="escopo-dashboard-desktop" @click="$router.push(`/lista-grupo-aplicacao-uso-consumo`)">

        <div class="opcao-dashboard">
          <div class="cacebalho-opcao">
            <h2 class="titulo-opcao">Uso Consumo</h2>
            <i class="fas fa-user-friends"></i>
          </div>

          <div class="descricao-opcao">
            <p class="mt-6">Veja os relatórios em tempo real!</p>
          </div>
        </div>

      </button>

    </div>


  </div>


</template>

<script>
export default {
  name: "DashboardCadastroGrupoAplicacoes",
}
</script>

<style lang="scss">

</style>