<template>

  <preview-image v-if="preview_imagem" @closed="closed_preview_image" :imagem="preview_imagem"/>
  <div class="container-formulario">

    <!-- Cabeçalho lista de pessoas depois de 768 pixels-->
    <div class="container-cabecalho flex justify-between mb-4">
      <div>
        <h1 class="text-xl text-corBase font-bold">Projetos</h1>
      </div>

      <router-link to="/projetos" class="text-xl text-corBase font-bold">
        <i class="fas fa-chevron-left"></i>
      </router-link>

    </div>

    <div class="flex gap-4 mb-6 items-end w-[100%]">
      <div class="w-full">
        <label class="text-corBase">Sistemista</label>
        <Select2 v-model="sistemista"
                 class="w-full z-10"
                 :options="lista_sistemista"
                 :settings="{ width: '100%'}"
        />
      </div>
      <div class="w-full">
        <label class="text-corBase">Tipologia</label>
        <Select2 v-model="modelo"
                 class="w-full z-10"
                 :options="list_modelo"
                 :settings="{ width: '100%'}"
        />
      </div>
      <div class="w-full">
        <label class="text-corBase">Linha</label>
        <Select2 v-model="linha"
                 class="w-full z-10"
                 :options="lista_linha"
                 :settings="{ width: '100%'}"
        />
      </div>
      <div class="w-full">
        <label class="text-corBase">Status</label>
        <Select2 v-model="status"
                 class="w-full z-10"
                 :options="lista_status"
                 :settings="{ width: '100%'}"
        />
      </div>
      <div class="w-full">
        <input 
          type="text" 
          class="input-form-chapa" 
          v-model="codigo_projeto"
          placeholder="Código"
        >
      </div>
      <div>
        <button class="btn-add-fisica" @click="getListaProjetos">Pesquisar</button>
      </div>
    </div>


    <!-- Container da exibiçao da lista de pessoas -->
    <div
        class="w-full flex flex-wrap rounded-sm border border-corBase/10 h-[48vh] overflow-y-scroll scrollbar-thin scrollbar-thumb-corBase/20">

      <div class="container-cards-produtos" v-for="(projeto, index) in listaProjetos" :key="index">
        <div class="flex">
          <div class="w-52 border border-corBase/40">
            <div class="flex justify-between px-4 py-1 border border-b-corBase/40">
              <input type="checkbox" name="" id="" class=" w-6">
              <div class="flex bg-corBase divide-x rounded">
                <button><i class="fas fa-wrench text-sm text-white px-2 py-1"></i></button>
                <button><i class="fas fa-lock text-sm text-white px-2 py-1"></i></button>
              </div>
            </div>

            <div class="px-4 py-1">
              <h4 class="font-bold text-sm">{{ projeto.codigo }}</h4>
            </div>

            <!-- <div>
              <button @click.prevent="openPreviewImage(projeto.tipologia.imagem.url_documento)">
                <img :src="projeto.tipologia.imagem.url_documento">
              </button>
            </div> -->

            <div class="text-center text-sm py-2">
              <p>{{ projeto.descricao_fixa }}</p>
            </div>

            <div class="flex flex-wrap gap-3 justify-center px-4 mb-2">
              <button 
                class="bg-corBarraLateral text-white text-xs px-2 py-1"
                @click.prevent="incluirItemOrcamento(projeto.id, projeto.descricao_fixa)"
              >
                {{ projeto.tipologia.nome }}
              </button>
            </div>

          </div>
        </div>
      </div>

      <div class="container-cards-produtos" v-for="(projeto, index) in listaProjetosPublic" :key="index">
        <div class="flex">
          <div class="w-52 border border-corBase/40">
            <div class="flex justify-between px-4 py-1 border border-b-corBase/40">
              <input type="checkbox" name="" id="" class=" w-6">
              <p class="text-xs font-bold">MANAGERGLASS</p>
              <div class="flex bg-corBase divide-x rounded">
                <button><i class="fas fa-wrench text-sm text-white px-2 py-1"></i></button>
                <button><i class="fas fa-lock text-sm text-white px-2 py-1"></i></button>
              </div>
            </div>

            <div class="px-4 py-1">
              <h4 class="font-bold text-sm">{{ projeto.codigo }}</h4>
            </div>

            <!-- <div>
              <button @click.prevent="openPreviewImage(projeto.tipologia.imagem.url_documento)">
                <img :src="projeto.tipologia.imagem.url_documento">
              </button>
            </div> -->

            <div class="text-center text-sm py-2">
              <p>{{ projeto.descricao_fixa }}</p>
            </div>

            <div class="flex flex-wrap gap-3 justify-center px-4 mb-2">
              <router-link to="/incluir-item-orcamento/"
                           class="bg-corBarraLateral text-white text-xs px-2 py-1">{{ projeto.tipologia.nome }}
              </router-link>
            </div>

          </div>
        </div>
      </div>

    </div>
  </div>

</template>

<script>
import axios from "axios";
import Select2 from "vue3-select2-component";
import PreviewImage from "@/components/midais/ged/PreviewImage";

export default {
  name: "SelecionarProjetoParaOrcamento",

  components: {
    Select2,
    PreviewImage
  },

  data() {
    return {
      projeto: '',

      checkAll: false,
      // lista usada para salvar o retorno da requisição do get
      listaProjetos: [],
      listaProjetosPublic: [],
      // lista usada para salvar esse retorno depois de dividido
      listaProjetosPaginada: [],
      // varival que inicializa o numero de paginas
      paginaAtual: 1,

      // filtros
      list_modelo: [],
      lista_linha: [],
      lista_status: [],
      lista_sistemista: [],
      modelo: '',
      linha: '',
      status: '',
      sistemista: '',
      codigo_projeto: '',
      preview_imagem: '',

      error: [],
    }
  },

  computed: {
    isadmin() {
      return this.$store.state.isAdminUser
    },

    minhaEmpresa() {
      return this.$store.state.minhaEmpresa;
    },
  },

  created() {
    this.getListaTipologia()
    this.getListaLinha()
    this.getListaStatus()
    this.getListaSistemista()
  },

  computed: {
    orcamentoId() {
      return this.$route.params.id
    }
  },

  methods: {
    closed_preview_image() {
      this.preview_imagem = ''
    },
    openPreviewImage(url) {
      this.preview_imagem = url
    },

    selectAll() {
      // Atualiza o estado dos demais checkboxes
      const checkboxes = document.querySelectorAll('.escopo-tabela input[type="checkbox"]');
      checkboxes.forEach((checkbox) => {
        checkbox.checked = this.checkAll;
      });
    },

    incluirItemOrcamento(projetoId, descricaoItemOrcamento) {
      this.$store.state.projetoIdAtual = projetoId
      this.$store.state.descricaoItemOrcamento = descricaoItemOrcamento
      this.$router.push('/incluir-item-orcamento/')
    },

    // busca a lista de projetos
    async getListaProjetos() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      this.$store.dispatch("setLoading", true);
      await axios.get(
        `/projeto/projeto/?sistemista=${this.sistemista}&modelo=${this.modelo}&linha=${this.linha}&status=${this.status}&codigo=${this.codigo_projeto}`, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            this.listaProjetos = response.data.current_tenant
            this.listaProjetosPublic = response.data.public_tenant
            this.$store.dispatch("setLoading", false);
          })
          .catch(error => {
            console.log(error)
            this.$store.dispatch("setLoading", false);
          })
    },

    // busca a lista de tipologias do sistema
    async getListaTipologia() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get('projeto/modelo-variacao/?page_size=999', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            this.list_modelo = response.data.results.map(item => ({
              id: item.id,
              text: item.descricao
            }));
          })
          .catch(error => {
            console.log(error)
          })
    },

    // busca a lista de linhas do sistema
    async getListaLinha() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get('projeto/linha-sistema/?page_size=999', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            this.lista_linha = response.data.results.map(item => ({
              id: item.id,
              text: item.linha
            }));
          })
          .catch(error => {
            console.log(error)
          })
    },

    async getListaStatus() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get('projeto/status-projeto/?page_size=999', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          this.lista_status = response.data.results.map(item => ({
            id: item.id,
            text: item.descricao
          }));
        })
          .catch(error => {
            console.log(error)
          })
    },

    async getListaSistemista() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get('projeto/projetista-sistemista/?page_size=999', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            this.lista_sistemista = response.data.results.map(item => ({
              id: item.id,
              text: item.nome
            }));
          })
          .catch(error => {
            console.log(error)
          })
    },

    // atualiza o valor da pagina no botao de voltar ao anterior
    paginaAnterior() {
      if (this.paginaAtual > 1) {
        this.paginaAtual--;
        this.paginarTabela();
      }
    },

    // atualiza o valor da pagina no botao de ir pra proxima pagina
    proximaPagina() {
      if (this.paginaAtual < this.totalPaginas) {
        this.paginaAtual++;
        this.paginarTabela();
      }
    },

    // exibe apenas 10 items da tabela e salva o resto
    paginarTabela() {
      const itemsPerPage = 5;
      const startIndex = (this.paginaAtual - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      this.listaProjetosPaginada = this.listaProjetos.filter((projeto, index) => {
        return index >= startIndex && index < endIndex;
      });
    },

  }
}
</script>

<style lang="scss">

.container-cards-produtos {
  @apply flex flex-wrap px-3 py-3
}

</style>