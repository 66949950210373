<template>


  <div class="flex flex-col justify-start">
    <input type="file" @change="handleFileChange" ref="fileInputRef"/>
    <button class="w-24" @click="clearFileInput" v-if="fileInput">Limpar</button>
  </div>

  <div class="container mx-auto py-4">
    <h1 class="text-2xl font-bold mb-4">Entrada de Produtos</h1>

    <div class="flex w-full">
      <div class="overflow-x-auto w-full">
        <table v-if="modal == false" class="w-full border border-collapse">
          <thead>
          <tr class="bg-gray-200">
            <th class="py-1 px-2 border text-center text-sm">Índice</th>
            <th class="py-1 px-2 border text-sm">COD. Fornecedor [XML]</th>
            <th class="py-1 px-2 border text-sm">Nome Produto [XML]</th>
            <th class="py-1 px-2 border text-sm">Nome Produto [BASE]</th>
            <th class="py-1 px-2 border text-sm">Código Produto [BASE]</th>
            <th class="py-1 px-2 border text-sm">Quantidade [XML]</th>
            <th class="py-1 px-2 border text-sm">Pesquisar Produto [BASE]</th>
            <th class="py-1 px-2 border text-sm">Adicionar Produto</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(produto, index) in produtos" :key="produto.id" class="bg-white">
            <td class="p-2 border text-center">{{ index }}</td>
            <td class="p-2 border">{{ produto.codigo_fornecedor }}</td>
            <td class="p-2 border">{{ produto.nome }}</td>
            <td class="p-2 border">
              <div v-for="produto_fornecedor in lista_produtos_fornecedor" :key="produto_fornecedor.id">
                {{
                  produto_fornecedor.codigo_fornecedor === produto.codigo_fornecedor ? produto_fornecedor.produto_base.nome_base : ''
                }}
              </div>
            </td>
            <td class="p-2 border text-center">
              <div v-for="produto_fornecedor in lista_produtos_fornecedor" :key="produto_fornecedor.id">
                {{
                  produto_fornecedor.codigo_fornecedor === produto.codigo_fornecedor ? produto_fornecedor.produto_base.codigo_base : ''
                }}
              </div>
            </td>
            <td class="p-2 border">
              <div>
                {{ produto.estoque }}
              </div>
            </td>
            <td class="p-2 border">
              <novo-produto-fornecedor :produto="produto" :fornecedor="fornecedor" @getlista="getListaPorProduto"/>
            </td>
            <td class="p-2 border">
              <button @click.prevent="abrir_modal(produto)"
                      class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded w-full">
                Adicionar
              </button>
            </td>
          </tr>
          </tbody>
        </table>
        <novo-perfil v-if="modal==true" :produto="novo_produto"/>
      </div>
    </div>

  </div>


</template>

<script>
import axios from 'axios'
import Select2 from "vue3-select2-component";
import NovoProdutoFornecedor from "@/components/midais/importacoes/NovoProdutoFornecedor";
import NovoPerfil from "@/components/midais/importacoes/NovoPerfil";

export default {
  name: "ImportarcaoPerfil",
  components: {
    Select2,
    NovoProdutoFornecedor,
    NovoPerfil
  },

  data() {
    return {
      modal: false,
      fileInput: null,
      emitente: null,
      produtos: [],
      lista_produtos_fornecedor: [],
      fornecedor: {},
      produto_nome: '',
      novo_produto: {}
    };
  },

  created() {
    this.getListaPorProduto()
  },
  methods: {
    abrir_modal(produto) {
      this.modal = !this.modal
      this.novo_produto = produto
    },
    pesquisarProdutoFornecedor(index) {
      // Coloque aqui a lógica para pesquisar o produto por fornecedor com base no índice da iteração.
    },
    handleFileChange(event) {
      this.fileInput = event.target; // Atualize o estado do input file

      const file = event.target.files[0];
      const reader = new FileReader();

      reader.onload = () => {
        this.parseXML(reader.result);
      };

      reader.readAsText(file);
    },

    clearFileInput() {
      this.fileInput.value = ''; // Limpa o valor do input file
      this.fileInput = null; // Redefine a variável de estado do input file
      // Limpar os dados também
      this.emitente = null;
      this.produtos = [];
    },

    parseXML(xmlData) {
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(xmlData, "text/xml");

      const emitenteCnpjElement = xmlDoc.querySelector("CNPJ");
      const emitenteNomeElement = xmlDoc.querySelector("xNome");

      if (emitenteNomeElement) {
        this.emitente = {
          cnpj: emitenteCnpjElement.textContent,
        };
      }
      this.getFornecedor(this.emitente.cnpj)

      const produtoElements = xmlDoc.querySelectorAll("prod");

      const produtos = [];

      produtoElements.forEach((produtoElement, index) => {
        const codigoElement = produtoElement.querySelector("cProd");
        const nomeElement = produtoElement.querySelector("xProd");
        const QuantidadeElement = produtoElement.querySelector("qCom");
        const ValorProdutoElement = produtoElement.querySelector("vProd");
        if (nomeElement) {
          produtos.push({
            codigo_fornecedor: codigoElement.textContent,
            nome: nomeElement.textContent,
            estoque: QuantidadeElement.textContent,
            preco_custo: ValorProdutoElement.textContent

          });
        }
      });

      this.produtos = produtos;
      console.log(this.emitente)
      console.log(this.produtos)
    },
async getListaPorProduto() {
      const token = await this.$store.dispatch('getAuthTokenCookie')

      await this.$store.dispatch('fetchURLrequest')

      await axios.get(`produtos/produto-por-fornecedor`, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            this.lista_produtos_fornecedor = response.data
            console.log("teste")
            console.log(this.lista_produtos_fornecedor)
          })
          .catch(error => {
            console.log(error.message)
          })
    },


    async getFornecedor(cnpj) {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')
      axios.get(`pessoas/fornecedor/`, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            console.log(`esse é o cnpj=> ${cnpj}`)
            this.fornecedor = response.data.find(item => {
              if (item.pessoa && item.pessoa.pessoa_juridica && item.pessoa.pessoa_juridica.cnpj) {
                return item.pessoa.pessoa_juridica.cnpj === cnpj;
              }
              return false;
            });
            console.log(response.data)
            console.log(this.fornecedor)
          })
          .catch(error => {
            console.log(error.message)
          })
    }

  },
}
</script>

<style lang="scss">

.container-importacao {
  @apply fixed top-1/2 right-[50%] z-[1000] w-full h-full
}

.container-importacao {
  @apply w-full flex justify-between items-start bg-red-400
}

.container-lado-esquerdo {
  @apply w-full flex flex-col justify-start bg-red-300
}

.container-lado-direitoo {
  @apply w-full flex flex-col justify-start bg-yellow-500
}
</style>
