<template>
  <div class="container-formulario">

    <!-- Cabeçalho lista de pessoas depois de 768 pixels-->
    <div class="container-cabecalho flex justify-between mb-4">
      <div>
        <h1 class="text-xl text-corBase font-bold">Lista Inventarios</h1>
      </div>

      <router-link to="/estoque" class="text-xl text-corBase font-bold">
        <i class="fas fa-chevron-left"></i>
      </router-link>

    </div>
    <div class="mb-8 flex gap-6">
      <div>
        <button @click.prevent="getListaInventarios" class="bg-corBase hover:bg-screenCor border border-screenCor hover:border-corBase
  text-white hover:text-corBase py-2 px-4 ">Listar Inventarios
        </button>
      </div>
      <div>
        <button @click="$router.push(`/estoque/pre-inventario`)" class="bg-ativo hover:bg-screenCor border border-screenCor hover:border-ativo
  text-white hover:text-ativo py-2 px-4 ">Pré Inventario
        </button>
      </div>
    </div>

    <!-- Container da exibiçao da lista de pessoas -->
    <div class="container-modelo-tabela">
      <table class="container-tabela overflow-auto">
        <thead class="cabecalho-tabela">
        <tr>
          <th scope="col" class="p-4">
            <div class="flex items-center">
              <input type="checkbox" class="checkbox" v-model="checkAll" @change="selectAll">

              <label class="sr-only">checkbox</label>
            </div>
          </th>
          <th scope="col" class="px-6 py-3">
            #
          </th>
          <th class="px-6 py-3">
            Nome
          </th>
          <th class="px-6 py-3">
            Tipo
          </th>
          <th scope="col" class="px-6 py-3">
            Altura
          </th>
          <th scope="col" class="px-6 py-3">
            Largura
          </th>
          <th scope="col" class="px-6 py-3">
            Produto Base
          </th>
          <th scope="col" class="px-6 py-3">
            Inventario Final?
          </th>
          <th scope="col" class="px-6 py-3">
            Editar/Finalizar
          </th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(inventario, index) in listaInventarios" :key="index" class="escopo-tabela">
          <td class="w-4 p-4">
            <div class="flex items-center">
              <input :id="'checkbox-table-' + (index + 2)" type="checkbox" class="checkbox" v-model="inventario.selected">
              <label class="sr-only">checkbox</label>
            </div>
          </td>
          <th scope="row" class="px-6 py-2 font-medium text-xs text-gray-900 whitespace-nowrap dark:text-white">
            {{ index + 1 }}
          </th>
          <td class="px-6 py-2 text-xs">
            {{ inventario.tipo_produto.nome }}
          </td>
          <td class="px-6 py-2 text-xs">
<!--            {{ inventario.tipo_chapas.nome }}-->
          </td>
          <td class="px-6 py-2 text-xs">
<!--            {{ inventario.altura_chapa }}-->
          </td>
          <td class="px-6 py-2 text-xs">
<!--            {{ inventario.largura_chapa }}-->
          </td>
          <td class="px-6 py-2 text-xs">
<!--            {{ inventario.grupo_aplicacao_chapa.nome }}-->
          </td>
          <template v-if="inventario.pre_inventario === false">
            <td class="px-6 py-2 text-xs">
              <i class="fa-solid fa-circle-check  text-ativo ml-3"></i>
            </td>
          </template>
          <template v-if="inventario.pre_inventario === true">
            <td class="px-6 py-2">
              <i class="fa-solid fa-circle-xmark text-inativo ml-3"></i>
            </td>
          </template>
          <td class="px-6 py-2 text-xs">
            <inventario-final :inventario="inventario" :pagina-atual="paginaAtual"/>
          </td>

        </tr>
        </tbody>
      </table>
      <div class="paginacao">
        <button @click.prevent="paginaAnterior" class="btn-paginacao">
          <i class="fas fa-chevron-left"></i>
        </button>
        <span v-for="pagina in paginasExibidas" :key="pagina">
          <button @click.prevent="irParaPagina(pagina)"
                  :class="{ 'btn-paginacao': true, 'pagina-atual': pagina === paginaAtual }">
            {{ pagina }}
          </button>
        </span>
        <button @click.prevent="proximaPagina" class="btn-paginacao">
          <i class="fas fa-chevron-right"></i>
        </button>
      </div>
    </div>
  </div>


</template>

<script>
import axios from "axios";
import Select2 from "vue3-select2-component";
import InventarioFinal from "@/components/estoque/inventario/modal/InventarioFinal";

export default {
  name: "ListaInventarios",

  components: {
    InventarioFinal,
    Select2
  },

  data() {
    return {
      checkAll: false,
      // lista usada para salvar o retorno da requisição do get
      listaInventarios: [],
      // lista usada para salvar esse retorno depois de dividido
      listaInventariosPaginada: [],
      // varival que inicializa o numero de paginas
      paginaAtual: 0,
      next_page: null,
      totalDePaginas: 0,
      itemsPorPagina: 5,

      showInventario: false,
      error: []
    }
  },

  created() {
  },

  computed: {
    isadmin() {
      return this.$store.state.isAdminUser
    },

    minhaEmpresa() {
      return this.$store.state.minhaEmpresa;
    },

     paginasExibidas() {
      const paginas = [];
      const paginasVisiveis = 5; // Número de páginas visíveis de cada vez

      let inicio = Math.max(1, this.paginaAtual - Math.floor(paginasVisiveis / 2));
      let fim = inicio + paginasVisiveis - 1;

      if (inicio + paginasVisiveis > this.totalDePaginas) {
        inicio = Math.max(1, this.totalDePaginas - paginasVisiveis + 1);
        fim = this.totalDePaginas;
      }

      for (let i = inicio; i <= fim; i++) {
        paginas.push(i);
      }

      return paginas;
    },
  },

  methods: {
    async getListaInventarios() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')
      
      this.$store.dispatch('setLoading', true)
      
      await axios.get(`produtos/lista-inventario`, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
      .then(response => {
        this.listaInventarios = response.data.results
      })

      this.$store.dispatch('setLoading', false)

    },

    proximaPagina() {
      this.getListaProdutos();
    },

    paginaAnterior() {
      this.paginaAtual -= 1
      this.getListaProdutos()
    },

    async irParaPagina(pagina) {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      console.log(pagina)
      this.paginaAtual = pagina
      await axios.get(`produtos/lista-inventario?page=${this.paginaAtual}`, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            this.listaInventarios = response.data.results
            console.log(this.listaInventarios)
            this.totalDePaginas = response.data.count / 5
          })
    }



  }
}
</script>

<style lang="scss">

</style>