<template>

  <div class="container-formulario">

    <!-- Cabeçalho lista de pessoas depois de 768 pixels-->
    <div class="container-cabecalho flex justify-between mb-4">
      <div>
        <h1 class="text-xl text-corBase font-bold">Entrada</h1>
      </div>

      <router-link to="/estoque/entrada-nota-fiscal" class="text-xl text-corBase font-bold">
        <i class="fas fa-chevron-left"></i>
      </router-link>

    </div>

    <div class="flex flex-col">
      <label>Import XML</label>
      <input type="file" @change="handleFileChange" ref="fileInputRef"/>
    </div>

    <div class="w-full flex flex-col border p-2 mt-6">
      <div class="flex w-full gap-10">
        <div class="flex flex-col">
          <label class="font-bold text-corBase">N° NF-e</label>
          <input v-model="n_nfe" class="border-b outline-none focus:border-b-corBase"/>
        </div>
        <div class="flex flex-col">
          <label class="font-bold text-corBase">Modelo</label>
          <input v-model="modelo" class="border-b outline-none focus:border-b-corBase"/>
        </div>
        <div class="flex flex-col">
          <label class="font-bold text-corBase">Série</label>
          <input v-model="serie" class="border-b outline-none focus:border-b-corBase"/>
        </div>
      </div>
      <div class="flex w-full gap-10 mt-2">
        <div class="flex flex-col">
          <label class="font-bold text-corBase">Chave Acesso</label>
          <input v-model="chave_aceeso" class="border-b outline-none w-[420px] focus:border-b-corBase"/>
        </div>
      </div>
    </div>

    <div class="w-full flex flex-col border p-2 mt-6">
      <div class="flex w-full gap-10">
        <div class="flex flex-col w-[30%]">
          <label class="font-bold text-corBase">Razão</label>
          <input v-model="fornecedor.nome" class="border-b outline-none w-full focus:border-b-corBase"/>
        </div>
        <div class="flex flex-col">
          <label class="font-bold text-corBase">Cnpj</label>
          <input v-model="fornecedor.cnpj" class="border-b outline-none focus:border-b-corBase"/>
        </div>
        <div class="flex flex-col">
          <label class="font-bold text-corBase">Inscrição Estadual</label>
          <input v-model="fornecedor.ie" class="border-b outline-none focus:border-b-corBase"/>
        </div>
      </div>
    </div>

    <div class="flex w-full mt-6">
      <div class="overflow-x-auto w-full">
        <table class="w-full border border-collapse">
          <thead>
          <tr class="bg-gray-200">
            <th class="py-1 px-2 border text-sm">COD. Fornecedor [XML]</th>
            <th class="py-1 px-2 border text-sm">Nome Produto [XML]</th>
            <th class="py-1 px-2 border text-sm">Produto [BASE]</th>
            <th class="py-1 px-2 border text-sm">Quantidade [XML]</th>
            <th class="py-1 px-2 border text-sm">Preço Custo</th>
            <th class="py-1 px-2 border text-center text-sm">Confirmação</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(produto, index) in produtos" :key="produto.id" class="bg-white">
            <td class="p-2 border">{{ produto.codigo_fornecedor }}</td>
            <td class="p-2 border">{{ produto.nome }}</td>
            <td class="p-2 border text-center">
              <div>
                <input
                    v-model="produto.codigo_filtro"
                    @input="filtrarProdutos(produto.codigo_filtro, produto, index)"
                    placeholder="Digite o código ou nome"
                    list="sugestoes"
                    class="border p-2 rounded-md focus:outline-none focus:border-corBase"
                />
                <datalist id="sugestoes">
                  <option v-for="base in produtosBase" :key="base.id"
                          :value="`${base.codigo_base} - ${base.nome_base}`"></option>
                </datalist>
              </div>
            </td>
            <td class="p-2 border text-center">
              <div>
                {{ produto.estoque }}
              </div>
            </td>
            <td class="p-2 border text-center">
              <div>
                {{ produto.preco_custo }}
              </div>
            </td>
            <td class="p-2 border">
              <button
                  @click.prevent="addProdutoArray(produto.codigo_filtro)"
                  class="bg-red-600 w-full h-full text-white"
              >OK
              </button>
            </td>
          </tr>
          </tbody>
        </table>
        <div class="flex w-full justify-end">
          <button @click.prevent="addEntrada" v-if="produtos.length > 0" class="btn-enviar">Adicionar Entrada</button>
        </div>
        {{ produtos_entrada }}
      </div>
    </div>


  </div>
</template>

<script>
import axios from "axios";
import Select2 from "vue3-select2-component";
import moment from 'moment-timezone';

export default {
  name: "EntradaFormComponente",

  components: {
    Select2,
  },

  data() {
    return {
      produtosBase: [],
      fileInput: null,
      lista_produtos_fornecedor: [],
      produtos: [],
      produtos_entrada: [],
      fornecedor_req: {},

      n_nfe: '',
      chave_aceeso: '',
      modelo: '',
      serie: '',
      dataEmissao: '',
    }
  },

  methods: {

    addProdutoArray(codigo_filtro) {
      this.$store.dispatch('fetchURLrequest');
      const token_local = localStorage.getItem('token');

      const response = axios.get(`produtos/produto-por-fornecedor?produto_base=${codigo_filtro.split(' - ')[0]}`, {
        headers: {
          'Authorization': `Token ${token_local}`
        }
      })

      console.log(response.data)
    },

    handleFileChange(event) {
      this.fileInput = event.target; // Atualize o estado do input file

      const file = event.target.files[0];
      const reader = new FileReader();

      reader.onload = () => {
        this.parseXML(reader.result);
      };

      reader.readAsText(file);
    },

    parseXML(xmlData) {
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(xmlData, "text/xml");
      // Seleciona o elemento com o seletor "infNFe"
      const infNFeElement = xmlDoc.querySelector("infNFe");

      if (infNFeElement) {
        // Obtém o valor do atributo "id" do elemento
        const idDoElemento = infNFeElement.getAttribute("Id");

        // Atribui o valor à variável n_nfe
        this.chave_aceeso = idDoElemento;
        console.log(this.chave_aceeso)

        // Agora, this.n_nfe contém o valor do atributo "id" do elemento infNFe
      } else {
        console.error("Elemento infNFe não encontrado");
      }

      const modelo_nfe = xmlDoc.querySelector("mod")
      this.modelo = modelo_nfe.textContent

      const serie_nfe = xmlDoc.querySelector("serie")
      this.serie = serie_nfe.textContent

      const numero_nfe = xmlDoc.querySelector("nNF")
      this.n_nfe = numero_nfe.textContent

      const data_emissao = xmlDoc.querySelector("dhEmi")


      this.dataEmissao = data_emissao.textContent


      const emitenteCnpjElement = xmlDoc.querySelector("CNPJ");
      const emitenteNomeElement = xmlDoc.querySelector("xNome");

      if (emitenteNomeElement) {
        this.emitente = {
          cnpj: emitenteCnpjElement.textContent,
        };
      }
      this.getFornecedor(this.emitente.cnpj)

      const produtoElements = xmlDoc.querySelectorAll("prod");

      const produtos = [];

      produtoElements.forEach((produtoElement, index) => {
        const codigoElement = produtoElement.querySelector("cProd");
        const nomeElement = produtoElement.querySelector("xProd");
        const QuantidadeElement = produtoElement.querySelector("qCom");
        let ValorProdutoElement = produtoElement.querySelector("vUnCom");
        ValorProdutoElement = Number(ValorProdutoElement.textContent)
        if (nomeElement) {
          produtos.push({
            id: '',
            codigo_filtro: '',
            codigo_fornecedor: codigoElement.textContent,
            nome: nomeElement.textContent,
            estoque: QuantidadeElement.textContent,
            preco_custo: ValorProdutoElement.toFixed(2)

          });
        }
      });

      this.produtos = produtos;
      console.log(this.emitente)
      console.log(this.produtos)
    },

    async getListaPorProduto() {
      const token = await this.$store.dispatch('getAuthTokenCookie')

      await this.$store.dispatch('fetchURLrequest')

      await axios.get(`produtos/produto`, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            this.lista_produtos_fornecedor = response.data.map(item => ({
                  id: item.id,
                  text: `${item.codigo_base} - ${item.nome_base}`
                })
            )
            console.log('Aqui')
            console.log(this.lista_produtos_fornecedor)
          })
          .catch(error => {
            console.log(error.message)
          })
    },

    async getFornecedor(cnpj) {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')
      axios.get(`pessoas/fornecedor/`, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            this.fornecedor_req = response.data.find(item => {
              if (item.pessoa && item.pessoa.pessoa_juridica && item.pessoa.pessoa_juridica.cnpj) {
                return item.pessoa.pessoa_juridica.cnpj === cnpj;
              }
              return false;
            });
            this.fornecedor.nome = this.fornecedor_req.pessoa.nome_razao
            this.fornecedor.cnpj = this.fornecedor_req.pessoa.pessoa_juridica.cnpj
            this.fornecedor.ie = this.fornecedor_req.pessoa.pessoa_juridica.inscricao_estadual
            console.log(response.data)
            console.log(this.fornecedor)
          })
          .catch(error => {
            console.log(error.message)
          })
    },


    async addEntrada() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      const totalQuantidade = this.produtos.reduce((total, produto) => {
        console.log(produto.preco_custo);
        return total + parseFloat(produto.preco_custo);
      }, 0);

      this.$store.dispatch("setLoading", true);
      const data = {
        entrada: {
          numero_nf: this.n_nfe,
          modelo_nf: this.modelo,
          serie_nf: this.serie,
          chave_acesso_nf: this.chave_aceeso,
          data_emissao_nf: this.dataEmissao,
          fornecedor: this.fornecedor_req.id,
          total_nf: totalQuantidade.toFixed(2),
          produtos: this.produtos.map(produto => ({
            codigo_fornecedor: produto.codigo_fornecedor
          })),
        },


      };

      await axios.post('/produtos/entrada-nfe/', data, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            console.log(response.data)
            this.$store.dispatch("setLoading", false);
          })
          .catch(error => {
            console.log(error)
            this.$store.dispatch("setLoading", false);
          })

    }
  }
}
</script>

<style scoped>

</style>