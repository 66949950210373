<template>
 <div>
   <!--Conteudo para telas maiores-->
   <div class="computador">
     <article class="conteudo tipo-1">
       <!--Section do texto-->
       <section class="texto-conteudo">
         <!--Titulo-->
         <h2 class="titulo-conteudo">Sobre a empresa</h2>
         <!--Descricao-->
         <p class="descricao-conteudo">
           Nosso objetivo é fornecer um software de gestão especializado que aumente a produtividade, otimize o gerenciamento de projetos e impulsione o crescimento dos nossos clientes. Queremos oferecer soluções personalizadas e suporte técnico excepcional para ajudar as empresas a melhorar sua eficiência operacional, maximizar a lucratividade e fortalecer sua posição competitiva.
         </p>
       </section>
       <!--Foto lateral direita-->
       <div class="div-foto-conteudo"><img src="@/assets/imagens/fotos/casa2sf.jpg"></div>
     </article>

     <article class="conteudo tipo-2">
       <!--Section do texto-->
       <section class="texto-conteudo">
         <!--Titulo-->
         <h2 class="titulo-conteudo">Valores da empresa</h2>
         <!--Descricao-->
         <p class="descricao-conteudo">
          Excelência. Inovação. Confiança. Parceria. Orientação ao cliente. Responsabilidade social e ambiental. Aprendizado contínuo. Foco no resultado.
         </p>
       </section>


     </article>
   </div>
   <!--Conteudo para telas menores-->
   <div class="celular">
     <article>
        <!--Texto-->
        <div class="div-texto">
          <section>
            <!--Titulo-->
            <h2>Sobre a empresa</h2>
            <!--Descricao-->
            <p>Nosso objetivo é fornecer um software de gestão especializado que aumente a produtividade, otimize o gerenciamento de projetos e impulsione o crescimento dos nossos clientes. Queremos oferecer soluções personalizadas e suporte técnico excepcional para ajudar as empresas a melhorar sua eficiência operacional, maximizar a lucratividade e fortalecer sua posição competitiva.</p>
          </section>
          <section>
            <!--Titulo-->
            <h2>Valores da empresa</h2>
            <!--Descricao-->
            <p>Excelência. Inovação. Confiança. Parceria. Orientação ao cliente. Responsabilidade social e ambiental. Aprendizado contínuo. Foco no resulta</p>
          </section>
        </div>
        <!--Foto superior mobile-->
        <figure>
          <img src="@/assets/imagens/fotos/casa2sf.jpg" alt="">
        </figure>
        <!--Foto Inferior mobile-->
        <figure>
          <img src="@/assets/imagens/fotos/casa2sf.jpg" alt="">
        </figure>
     </article>
   </div>
 </div>
</template>

<script>
export default {
  name: "Conteudo"
}
</script>

<style scoped>
/*Celular*/
.celular{
  @apply block
  sm:hidden
}
/*Div conteudo mobile*/
.celular>article{
  @apply w-full h-[200vh] flex flex-col justify-between relative
}
/*Foto mobile*/
.celular>article>figure>img{
  @apply w-full h-[40vh] rounded-2xl object-cover
}
.div-texto{
  @apply absolute flex flex-col justify-between px-6 py-6 bg-white shadow-2xl w-full h-[140vh] top-[50%] translate-y-[-50%] rounded-2xl divide-y-2 divide-gray-500
}
/*Div do texto*/
.div-texto>section{
  @apply h-[50%] flex flex-col justify-center text-center
}
/*Titulo texto*/
.div-texto>section>h2{
  @apply font-bold text-lg mb-6 text-[#164E63]
}
/*Descricao texto*/
.div-texto>section>p{
  @apply text-base
}

/*Computador*/

.computador{
  @apply hidden
  sm:block
}
/*Div conteudo*/
.conteudo{
  @apply flex justify-center relative px-6 my-4
  lg:my-6
}
/*Div conteudo background fixo*/
.tipo-2{
  background-image:url("@/assets/imagens/fotos/casa2.jpg");
  @apply h-64 flex justify-end bg-center bg-cover bg-fixed
  lg:h-96
}

/*Div do texto do conteudo*/
.texto-conteudo{
  @apply w-1/2 p-6 flex flex-col justify-center
  lg:py-12
}
/*Titulo texto*/
.titulo-conteudo{
  @apply text-lg font-bold mb-6 text-[#164E63]
  lg:text-2xl
}
/*Descricao texto*/
.descricao-conteudo{
  @apply font-medium
  sm:text-sm
  lg:text-base
  xl:text-lg
}
/*Titulo texto da div fixa*/
.tipo-2>.texto-conteudo>.titulo-conteudo{
  @apply text-white
}
/*Descricao texto da div fixa*/
.tipo-2>.texto-conteudo>.descricao-conteudo{
  @apply text-white
}
/*Div da foto lateral computador*/
.div-foto-conteudo{
  @apply w-1/2 my-auto
}
/*Foto lateral computador*/
.div-foto-conteudo>img{
  @apply object-cover w-full
  sm:h-40
  md:h-60
  xl:h-96
}

</style>