<template>
  <div v-if="abrir !== false" class="container-modal-editar">
    <div class="sombra-modal">
      <div class="conteudo-modal-editar">
        <div class="flex justify-end">
          <button @click.prevent="fechar()">
            <i class="fas fa-close text-2xl text-corBase"></i> 
          </button>
        </div>

        <div>
          <div class="cabecalho-formulario">
            <h1 class="text-xl text-corBase font-bold">Lista de Valores para a Variável</h1>
          </div>

          <div class="container-modelo-tabela">
            <table class="container-tabela overflow-auto">
              <thead class="cabecalho-tabela text-center">
                <tr>
                  <th scope="col" class="px-6 py-3">
                    #
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Variável
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Nome
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Valor
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Descrição
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Usar Foto
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Novo Nome Projeto
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Condição
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr 
                  v-for="(opcao, index) in opcoesFiltradas" 
                  :key="index" 
                  class="escopo-tabela text-center"
                >
                  <th scope="row" class="px-6 py-2 font-medium text-xs text-gray-900 whitespace-nowrap dark:text-white">
                    {{ index + 1 }}
                  </th>
                  <td class="px-6 py-2 text-xs">
                    {{ opcao.variavel.variavel }}
                  </td>
                  <td class="px-6 py-2 text-xs">
                    {{ opcao.variavel.descricao }}
                  </td>
                  <td class="px-6 py-2 text-xs">
                    {{ opcao.valor }}
                  </td>
                  <td class="px-6 py-2 text-xs">
                    {{ opcao.descricao }}
                  </td>
                  <td class="py-2">
                    <input 
                      type="checkbox" 
                      class="w-full text-corBase border border-corBase/40"
                      v-model="opcao.usar_foto"
                    >
                  </td>
                  <td class="px-6 py-2 text-xs">
                    {{ opcao.novo_nome_projeto }}
                  </td>
                  <td class="px-6 py-2 text-xs">
                    {{ opcao.condicao }}
                  </td>
                </tr>
              </tbody>
            </table>
            <table class="container-tabela overflow-auto" v-if="novasOpcoes.length">
              <thead class="cabecalho-tabela text-center">
                <tr>
                  <th scope="col" class="px-6 py-3">
                    Variável
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Nome
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Valor
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Descrição
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Usar Foto
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Novo Nome Projeto
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Condição
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(opcao, index) in novasOpcoes" :key="index" class="escopo-tabela text-center">
                  <td class="px-6 py-2 text-xs">
                    {{ variavel.variavel }}
                  </td>
                  <td class="px-6 py-2 text-xs">
                    {{ variavel.descricao }}
                  </td>
                  <td class="px-6 py-2 text-xs">
                    <input 
                      type="text" 
                      class="w-full p-1 text-corBase border border-corBase/40"
                      v-model="opcao.valor"
                    >
                  </td>
                  <td class="px-6 py-2 text-xs">
                    <input 
                      type="text" 
                      class="w-full p-1 text-corBase border border-corBase/40"
                      v-model="opcao.descricao"
                    >
                  </td>
                  <td class="py-2">
                    <input 
                      type="checkbox" 
                      class="w-full text-corBase border border-corBase/40"
                      v-model="opcao.usar_foto"
                    >
                  </td>
                  <td class="px-6 py-2 text-xs">
                    <input 
                      type="text" 
                      class="w-full p-1 text-corBase border border-corBase/40"
                      v-model="opcao.novo_nome_projeto"
                    >
                  </td>
                  <td class="px-6 py-2 text-xs">
                    <input 
                      type="text" 
                      class="w-full p-1 text-corBase border border-corBase/40"
                      v-model="opcao.condicao"
                    >
                  </td>
                </tr>
              </tbody>
            </table>

            <div class="flex w-full justify-end itens-center mt-6 px-4 mb-5" v-if="novasOpcoes.length">
              <button 
                class="btn-salvar" 
                @click.prevent="postNovaOpcao">
                  Adicionar
              </button>
            </div>

            <div class="flex justify-center" v-if="!novasOpcoes.length">
              <button class="text-ativo" @click.prevent="adicionarOpcao">
                <i class="fas fa-plus-circle"></i>
              </button>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
  
</template>
s
<script>
import axios from 'axios'

export default{
  name: 'ModalOpcoesVariaveis',

  data() {
    return {
      novasOpcoes: []
    }
  },

  props: ['abrir', 'variavel', 'opcoesVariavel'],

  computed: {
    opcoesFiltradas() {
      return this.opcoesVariavel.filter(opcao => opcao.variavel.id === this.variavel.id);
    }
  },

  methods: {
    fechar() {
      this.novasOpcoes = []
      // this.opcoesVariavel = []
      this.$emit("fechar")
    },

    adicionarOpcao() {
      this.novasOpcoes.push({
        variavel: this.variavel.id,
        valor: '',
        descricao: '',
        usar_foto: false,
        novo_nome_projeto: '',
        condicao: ''
      })
    },

    async postNovaOpcao() {
      try {
        const token = await this.$store.dispatch('getAuthTokenCookie');
        await this.$store.dispatch('fetchURLrequest')

        const novasOpcoesPromisses = []

        for(const novaOpcao of this.novasOpcoes) {
          novasOpcoesPromisses.push(
            axios.post('projeto/opcao-variavel-local/', novaOpcao, {
              headers: {
                Authorization: `Token ${token}`
              }
            })
          )
        }

        await Promise.all(novasOpcoesPromisses)

        this.novasOpcoes = []
        this.$emit('getListaOpcoesVariavel')

      } catch (error) {
        console.error(error)
      }
    },
  }
} 

</script>

<style scoped lang="scss">

</style>
