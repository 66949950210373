<template>
    <div>
        <h2 class="text-lg text-corBase font-bold">Produtos da Tabela</h2>

        <!-- Adicionando uma div para rolagem lateral -->
        <div class="container-modelo-tabela">
            <table class="container-tabela overflow-auto">
                <thead class="cabecalho-tabela">
                    <tr>
                        <th class="py-2 px-4 bg-gray-200 border-b">Ações</th>
                        <th class="py-2 px-4 bg-gray-200 border-b">Nome do Produto</th>
                        <th class="py-2 px-4 bg-gray-200 border-b">Valor de Custo</th>
                        <th class="py-2 px-4 bg-gray-200 border-b">% Margem Adicional</th>
                        <th class="py-2 px-4 bg-gray-200 border-b">% Margem Adicional Balcão</th>
                        <th class="py-2 px-4 bg-gray-200 border-b">Valor Final de Venda</th>
                        <th class="py-2 px-4 bg-gray-200 border-b">Valor Final de Venda Balcão</th>
                        <th class="py-2 px-4 bg-gray-200 border-b">Usuário Atualização</th>
                        <th class="py-2 px-4 bg-gray-200 border-b">Data de Atualização</th>
                    </tr>
                </thead>
                <tbody>
                    <!-- Exibir os produtos existentes -->
                    <tr v-for="produto in produtosTabela" :key="produto.id">
                        <td class="py-2 px-4 border-b flex gap-2">
                            <button @click="deleteProduto(produto.id)" class="text-gray-500" title="Excluir Componente">
                                <i class="fas fa-times"></i>
                            </button>
                            <button @click="abrirModalEditarProduto(produto)" class="text-gray-500"
                                title="Editar Componente">
                                <i class="fas fa-paperclip"></i>
                            </button>
                        </td>
                        <td class="py-2 px-4 border-b whitespace-nowrap">{{
                            produto.componente.componente_base.nome_especifico }}</td>
                        <td class="py-2 px-4 border-b">R$ {{
                            parseFloat(produto.componente.componente_base.preco_custo).toFixed(2) }}</td>
                        <td class="py-2 px-4 border-b">{{ parseFloat(produto.margem_lucro_ml).toFixed(2) }}%</td>
                        <td class="py-2 px-4 border-b">{{ parseFloat(produto.margem_lucro_balcao).toFixed(2) }}%</td>
                        <td class="py-2 px-4 border-b">R$ {{ calcularValorFinalProdutoExistente(produto) }}</td>
                        <td class="py-2 px-4 border-b">R$ {{ calcularValorFinalBalcaoProdutoExistente(produto) }}</td>
                        <td class="py-2 px-4 border-b">{{ produto.criado_por }}</td>
                        <td class="py-2 px-4 border-b">{{ formatarDataProdutos(produto.criado_em) }}</td>
                    </tr>

                    <!-- Linha de input para adicionar novo produto -->
                    <tr v-if="adicionarProdutoAtivo">
                        <td class="py-2 px-4 border-b"></td>
                        <td class="py-2 px-4 border-b">
                            <Select2 v-model="novoProduto.componente" :options="listaProdutos" class="w-full"
                                :settings="{ width: '100%' }" />
                        </td>
                        <td class="py-2 px-4 border-b">
                            <input v-model="novoProduto.custo" type="number" min="0" class="input-form">
                        </td>
                        <td class="py-2 px-4 border-b">
                            <input v-model="novoProduto.margem_lucro_ml" type="number" min="0" class="input-form">
                        </td>
                        <td class="py-2 px-4 border-b">
                            <input v-model="novoProduto.margem_lucro_balcao" type="number" min="0" class="input-form">
                        </td>
                        <td class="py-2 px-4 border-b">{{ calcularValorFinal(novoProduto) }}</td>
                        <td class="py-2 px-4 border-b">{{ calcularValorFinalBalcao(novoProduto) }}</td>
                        <td class="py-2 px-4 border-b">Usuário Atual</td>
                        <td class="py-2 px-4 border-b">{{ formatarDataProdutos(new Date()) }}</td>
                    </tr>

                    <!-- Modal inline de edição de produto -->
                    <tr v-if="editarProdutoAtivo">
                        <td class="py-2 px-4 border-b"></td>
                        <td class="py-2 px-4 border-b">
                            <Select2 v-model="produtoSelecionado.componente" :options="listaProdutos" class="w-full"
                                :settings="{ width: '100%' }" />
                        </td>
                        <td class="py-2 px-4 border-b">
                            <input v-model="produtoSelecionado.custo" type="number" min="0" class="input-form"
                                readonly />
                        </td>
                        <td class="py-2 px-4 border-b">
                            <input v-model="produtoSelecionado.margem_lucro_ml" type="number" min="0"
                                class="input-form" />
                        </td>
                        <td class="py-2 px-4 border-b">
                            <input v-model="produtoSelecionado.margem_lucro_balcao" type="number" min="0"
                                class="input-form" />
                        </td>
                        <td class="py-2 px-4 border-b">{{ calcularValorFinal(produtoSelecionado) }}</td>
                        <td class="py-2 px-4 border-b">{{ calcularValorFinalBalcao(produtoSelecionado) }}</td>
                        <td class="py-2 px-4 border-b">Usuário Atual</td>
                        <td class="py-2 px-4 border-b">{{ formatarDataProdutos(new Date()) }}</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <!-- Botão de Adicionar Produto -->
        <div class="flex justify-end mt-4" v-if="adicionarProdutoAtivo">
            <button class="btn-salvar" @click.prevent="adicionarProduto" :disabled="!produtoPreenchido">Adicionar
                Produto</button>
        </div>

        <!-- Botão de salvar as edições no modal de edição -->
        <div class="flex justify-end mt-4" v-if="editarProdutoAtivo">
            <button class="btn-salvar" @click.prevent="salvarProdutoEditado">Salvar Alterações</button>
        </div>

        <!-- Botão para adicionar novo produto -->
        <div class="flex justify-center mt-4">
            <button class="text-ativo" @click.prevent="abrirLinhaAdicionarProduto">
                <i class="fas fa-plus-circle"></i>
            </button>
        </div>

        <!-- Botões da paginação -->
        <Pagination v-if="produtosTabela.length > 0" :offset="paginaAtual" :total="countItens" :limit="5"
            @change-page="mudarPagina" />
    </div>

    <SucessoRapido v-if="sucesso === true" mensagem="Alteração feita com sucesso." />
</template>

<script>
import Select2 from "vue3-select2-component";
import Pagination from "@/utils/Pagination.vue";
import SucessoRapido from "@/components/toasts/toast_sucesso/SucessoRapido.vue";
import formatarData from "@/utils/FormatarData";
import axios from 'axios';

export default {
    name: 'ProdutosTabela',
    components: {
        Select2,
        Pagination,
        SucessoRapido
    },
    props: {
        produtosTabela: Array,
        listaProdutos: Array,
        adicionarProdutoAtivo: Boolean,
        paginaAtual: Number,
        countItens: Number,
        novoProduto: Object,
        produtoPreenchido: Boolean,
        dadosComponente: Object
    },
    data() {
        return {
            editarProdutoAtivo: false,
            produtoSelecionado: {},
            sucesso: false,
        };
    },
    created() {
        console.log(this.dadosComponente)
    },
    methods: {
        abrirModalEditarProduto(produto) {
            this.produtoSelecionado = {
                ...produto,
                componente: produto.componente.id,
                custo: produto.componente.componente_base.preco_custo,
            };
            this.editarProdutoAtivo = true;
        },

        fecharModal() {
            this.editarProdutoAtivo = false;
        },

        async salvarProdutoEditado() {
            try {
                const token = await this.$store.dispatch('getAuthTokenCookie');
                await this.$store.dispatch('fetchURLrequest');

                await axios.put(`precificacao/itens-tabelas-componente/${this.produtoSelecionado.id}/`, {
                    tabela_preco_componente: this.dadosComponente.id,
                    componente: this.produtoSelecionado.componente,
                    custo: this.produtoSelecionado.custo,
                    margem_lucro_ml: this.produtoSelecionado.margem_lucro_ml,
                    margem_lucro_balcao: this.produtoSelecionado.margem_lucro_balcao,
                    valor_final_venda_ml: this.calcularValorFinal(this.produtoSelecionado),
                    valor_final_venda_balcao: this.calcularValorFinalBalcao(this.produtoSelecionado)
                }, {
                    headers: {
                        Authorization: `Token ${token}`
                    }
                });

                this.sucesso = true
                setTimeout(() => {
                    this.sucesso = false;
                }, 3000);
                this.$emit('atualizarProdutosTabela');
                this.fecharModal();
            } catch (error) {
                console.error('Erro ao salvar o produto editado:', error);
            }
        },

        async deleteProduto(id) {
            try {
                const token = await this.$store.dispatch('getAuthTokenCookie');
                await this.$store.dispatch('fetchURLrequest');
                await axios.patch(`precificacao/itens-tabelas-componente/${id}/`, { ativo: false }, {
                    headers: {
                        Authorization: `Token ${token}`
                    }
                });

                this.sucesso = true
                setTimeout(() => {
                    this.sucesso = false;
                }, 3000);
                this.$emit('atualizarProdutosTabela');
            } catch (error) {
                console.error('Erro ao desativar o produto:', error);
            }
        },

        calcularValorFinal(produto) {
            const valor = parseFloat(produto.custo) + parseFloat(produto.custo) * (parseFloat(produto.margem_lucro_ml) / 100);
            return parseFloat(valor).toFixed(2);
        },

        calcularValorFinalBalcao(produto) {
            const valor = parseFloat(produto.custo) + parseFloat(produto.custo) * (parseFloat(produto.margem_lucro_balcao) / 100);
            return parseFloat(valor).toFixed(2);
        },

        calcularValorFinalProdutoExistente(produto) {
            const custo = parseFloat(produto.componente.componente_base.preco_custo) || 0;
            const margemLucroML = parseFloat(produto.margem_lucro_ml) || 0;
            const valor = custo + custo * (margemLucroML / 100);
            return valor.toFixed(2);
        },

        calcularValorFinalBalcaoProdutoExistente(produto) {
            const custo = parseFloat(produto.componente.componente_base.preco_custo) || 0;
            const margemLucroBalcao = parseFloat(produto.margem_lucro_balcao) || 0;
            const valor = custo + custo * (margemLucroBalcao / 100);
            return valor.toFixed(2);
        },

        formatarDataProdutos(dataISO) {
            return formatarData(dataISO);
        },

        abrirLinhaAdicionarProduto() {
            this.$emit('abrirLinhaAdicionarProduto');
        },

        adicionarProduto() {
            this.$emit('adicionarProduto');
        },

        mudarPagina(pagina) {
            this.$emit('mudarPagina', pagina);
        }
    }
};
</script>

<style scoped>
.input-form {
    border: 1px solid #ccc;
    padding: 8px;
    width: 100%;
}

table {
    width: 100%;
    border-collapse: collapse;
}

th,
td {
    padding: 8px;
    text-align: left;
}

thead th {
    background-color: #f8f9fa;
}

tr:hover {
    background-color: #f1f1f1;
}
</style>
