<template>
  <div class="container-campos">
    <div class="flex flex-col mt-2 w-[35%] ">
      <label class="label-campos">CNPJ:</label>
      <input
          type="text"
          required
          class="input-campos"
          placeholder="xx.xxx.xxx/xxxx-xx"
          v-mask="'##.###.###/####-##'"
          autofocus
          v-model="dadosPessoaJuridica.cnpj"
          @input="buscarCnpjAutomaticamente"
      >
    </div>

    <div class="campos campos-mobile">
      <label class="label-campos">Razão Social:</label>
      <input
          type="text"
          required
          class="input-campos"
          placeholder=""
          autofocus
          v-model="dadosPessoaJuridica.razao_social"
      >
    </div>
  </div>

  <div class="container-campos">
    <div class="campos campos-mobile">
      <label class="label-campos">Nome Fantasia:</label>
      <input
          type="text"
          required
          class="input-campos"
          placeholder=""
          autofocus
          v-model="dadosPessoaJuridica.nome_fantasia"
      >
    </div>

    <div class="campos campos-mobile">
      <label class="label-campos">Responsável:</label>

      <div class="flex items-center">
        <select class="input-campos" v-model="dadosPessoaJuridica.responsavel">
          <option>------</option>
          <option v-for="responsavel in responsaveis" :key="responsavel.id"
                  :value="responsavel">
            {{ responsavel.nome_razao }}

          </option>
        </select>
        <pessoa-fisica-modal/>
      </div>
    </div>

  </div>

  <div class="container-campos">
    <div class="campos campos-mobile">
      <label class="label-campos">Incrição Estadual:</label>
      <input
          type="text"
          required
          class="input-campos"
          placeholder=""
          autofocus
          v-model="dadosPessoaJuridica.iE"
      >
    </div>

    <div class="campos campos-mobile">
      <label class="label-campos">Suframa:</label>
      <input
          type="text"
          class="input-campos"
          placeholder=""
          autofocus
          v-model="dadosPessoaJuridica.suframa"
      >
    </div>

    <div class="campos campos-mobile">
      <label class="label-campos">Situação Fiscal:</label>
      <div class="flex items-center">
        <select class="input-campos" v-model="dadosPessoaJuridica.situacao_fiscal">
          <option value="">------</option>
          <option value="LR">Lucro Real</option>
          <option value="LP">Lucro Presumido</option>
          <option value="SN">Simples Nacional</option>
          <option value="SE">Simples Nascional, exesso sublimite de receita bruta</option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
import TipoPessoaGeralModal from "@/components/midais/modaisCadastro/TipoPessoaGeralModal";
import PessoaFisicaModal from "@/components/midais/modaisCadastro/PessoaFisicaModal";
import axios from "axios";

export default {
  name: "PessoaJuridicaCampos",


  components: {
    TipoPessoaGeralModal,
    PessoaFisicaModal,
  },

  props: ['dadosPessoaJuridica'],

  data() {
    return {
      responsaveis: []
    }
  },

  computed: {
    isadmin() {
      return this.$store.state.isAdminUser
    },
    minhaEmpresa() {
      return this.$store.state.minhaEmpresa;
    },
  },

  created() {
    this.getResponsaveis()
  },

  methods: {

    async getResponsaveis() {

      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get('pessoas/', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            this.responsaveis = response.data.results.filter(item => item.pessoa_fisica !== null)
          })
          .catch(error => {
            console.log(error)
          })
    },

    async buscarCnpjAutomaticamente() {
      if (this.dadosPessoaJuridica.cnpj.length === 18) {
        await this.buscarCnpj(this.dadosPessoaJuridica.cnpj);
      }
    },

    async buscarCnpj(cnpj) {
      // Fazer a chamada à API de CEP aqui
      // Por exemplo:
      const token = await this.$store.state.token
      await this.$store.dispatch('fetchURLrequest')

      const cnpjSemMascara = this.dadosPessoaJuridica.cnpj.replace(/[^0-9]/g, '')
      await axios.get(`cnpj/${cnpjSemMascara}`, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            console.log(response.data.results)
            // Processar a resposta da API e atualizar os campos de endereço
            this.dadosPessoaJuridica.nome_fantasia = response.data.results.fantasia
            this.dadosPessoaJuridica.razao_social = response.data.results.nome

          })
          .catch(error => {
            console.error('Erro ao buscar CEP:', error);
          });
    },

  }
}
</script>

<style scoped>

</style>