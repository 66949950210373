<template>
    <div>
        <h2 class="text-lg text-corBase font-bold">Tratamento das Linhas</h2>

        <!-- Adicionando uma div para rolagem lateral -->
        <div class="container-modelo-tabela">
            <table class="container-tabela overflow-auto">
                <thead class="cabecalho-tabela">
                    <tr>
                        <th class="py-2 px-4 bg-gray-200 border-b">Ações</th>
                        <th class="py-2 px-4 bg-gray-200 border-b">Linha</th>
                    </tr>
                </thead>
                <tbody>
                    <!-- Exibir as cores e preços existentes -->
                    <tr v-for="linha in listaLinhas" :key="linha.id">
                        <td class="py-2 px-4 border-b flex gap-2">
                            <router-link :to="`/alterar-linha/${this.$route.params.id}/${linha.id}`" class="text-gray-500" title="Cores da Linha">
                                <i class="fa-solid fa-palette"></i>
                            </router-link>
                        </td>
                        <td class="py-2 px-4 border-b">{{ linha.nome }}</td>
                    </tr>

                    <!-- Linha de input para adicionar nova cor e preço -->
                    <tr v-if="adicionarCorAtivo">
                        <td class="py-2 px-4 border-b">
                            <input v-model="novaCor.nome" type="text" placeholder="Nome da Cor" class="input-form">
                        </td>
                        <td class="py-2 px-4 border-b">
                            <input v-model="novaCor.preco" type="number" min="0" step="0.01" placeholder="Preço"
                                class="input-form">
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <!-- Botão de Adicionar Cor -->
        <div class="flex justify-end mt-4" v-if="adicionarCorAtivo">
            <button class="btn-salvar" @click.prevent="adicionarCor" :disabled="!corPreenchida">
                Adicionar Cor
            </button>
        </div>

        <!-- Botões da paginação -->
        <Pagination v-if="listaLinhas.length > 0" :offset="paginaAtual" :total="countItens" :limit="5"
            @change-page="mudarPagina" />
    </div>
</template>

<script>
import axios from 'axios';
import Pagination from '@/utils/Pagination.vue';

export default {
    name: 'TratamentoLinha',
    components: {
        Pagination
    },
    data() {
        return {
            coresPerfis: [],
            listaLinhas: [],
            adicionarCorAtivo: false,
            novaCor: {
                nome: '',
                preco: 0
            },
            countItens: 0,
            paginaAtual: 1,
        };
    },
    methods: {
        // Função para buscar as Linhas para o Select2
        async buscarListaLinhasPerfil() {
            const token = await this.$store.dispatch('getAuthTokenCookie');
            await this.$store.dispatch('fetchURLrequest');
            try {
                const response = await axios.get('/produtos/linha-aplicacao/', {
                    headers: {
                        Authorization: `Token ${token}`
                    }
                });
                console.log(response.data.results)
                this.listaLinhas = response.data.results
            } catch (error) {
                console.error("Erro ao buscar lista de linhas de perfil:", error);
            }
        },

        async adicionarCor() {
            if (this.corPreenchida) {
                const token = await this.$store.dispatch('getAuthTokenCookie');
                await this.$store.dispatch('fetchURLrequest');
                try {
                    const response = await axios.post('produtos/cor-perfil/', {
                        nome: this.novaCor.nome,
                        preco: this.novaCor.preco
                    }, {
                        headers: {
                            Authorization: `Token ${token}`
                        }
                    });

                    this.buscarCoresPerfil(1); // Atualiza a lista após adicionar uma nova cor

                    // Resetar o formulário de adição de cor
                    this.novaCor = {
                        nome: '',
                        preco: 0
                    };

                    this.adicionarCorAtivo = false; // Fechar a linha de adição após adicionar a cor
                } catch (error) {
                    console.error('Erro ao adicionar cor:', error);
                }
            }
        },

        mudarPagina(pagina) {
            this.paginaAtual = pagina;
            this.buscarCoresPerfil(pagina);
        }
    },
    computed: {
        corPreenchida() {
            return this.novaCor.nome !== '' && this.novaCor.preco > 0;
        }
    },
    created() {
        this.buscarListaLinhasPerfil()
    }
};
</script>

<style scoped>
.input-form {
    border: 1px solid #ccc;
    padding: 8px;
    width: 100%;
}

table {
    width: 100%;
    border-collapse: collapse;
}

th,
td {
    padding: 8px;
    text-align: left;
}

thead th {
    background-color: #f8f9fa;
}

tr:hover {
    background-color: #f1f1f1;
}

.overflow-x-auto {
    overflow-x: auto;
}
</style>
