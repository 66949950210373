<template>

  <div class="container-formulario">

    <!-- Cabeçalho lista de pessoas depois de 768 pixels-->
    <div class="container-cabecalho flex justify-between mb-4">
      <div>
        <h1 class="text-xl text-corBase font-bold">Cores Produto Base</h1>
      </div>

      <router-link to="/cores" class="text-xl text-corBase font-bold">
        <i class="fas fa-chevron-left"></i>
      </router-link>

    </div>

    <div class="flex items-center gap-3 mb-10">

      <div class="flex gap-8">
        <div>
          <button class="bg-corBase hover:bg-screenCor border border-screenCor hover:border-corBase
  text-white hover:text-corBase py-2 px-4" @click="getListaCorPadrao">Pesquisar
          </button>
        </div>
      </div>
      <div v-if="isadmin === true">
        <button @click="$router.push(`/cadastro-cores-padrao`)" class="bg-ativo hover:bg-screenCor border border-screenCor hover:border-ativo
  text-white hover:text-ativo py-2 px-4 ">Adicionar
        </button>
      </div>
    </div>

    <!-- Container da exibiçao da lista de pessoas -->
    <div class="container-modelo-tabela">
      <table class="container-tabela overflow-auto">
        <thead class="cabecalho-tabela">
        <tr>
          <th scope="col" class="p-4">
            <div class="flex items-center">
              <input type="checkbox" class="checkbox" v-model="checkAll" @change="selectAll">

              <label class="sr-only">checkbox</label>
            </div>
          </th>
          <th scope="col" class="px-6 py-3">
            #
          </th>
          <th class="px-6 py-3 w-full">
            Nome
          </th>
          <th class="px-6 py-3 w-full">
            Produto
          </th>
          <th scope="col" class="px-6 py-3">
            Status
          </th>
          <th scope="col" class="px-10 py-3">
            Ação
          </th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(cor, index) in lista_cores_padrao" :key="index" class="escopo-tabela">

          <td class="w-4 p-4">
            <div class="flex items-center">
              <input :id="'checkbox-table-' + (index + 2)" type="checkbox" class="checkbox"
                     v-model="cor.selected">
              <label class="sr-only">checkbox</label>
            </div>
          </td>
          <th scope="row"
              class="px-6 py-2 font-medium text-xs text-gray-900 whitespace-nowrap dark:text-white">
            {{ index + 1 }}
          </th>
          <td class="px-6 py-2 text-xs">
            {{ cor.nome }}
          </td>

          <template v-if="cor.ativo === true">
            <td class="px-1 sm:px-6  py-1 sm:py-4 text-center">
              <i class="fa-solid fa-circle-check  text-ativo"></i>
            </td>
          </template>
          <template v-if="cor.ativo === false">
            <td class="px-1 sm:px-6  py-1 sm:py-4 text-center">
              <i class="fa-solid fa-circle-xmark text-inativo"></i>
            </td>
          </template>

          <td class="px-6 py-2 text-xs">
<!--            <editar-cor-perfil :cor="cor" :paginaAtual="paginaAtual"/>-->
          </td>

        </tr>
        </tbody>
      </table>

      <div class="paginacao">
        <button @click.prevent="paginaAnterior" class="btn-paginacao">
          <i class="fas fa-chevron-left"></i>
        </button>
        <span v-for="pagina in paginasExibidas" :key="pagina">
          <button @click.prevent="irParaPagina(pagina)"
                  :class="{ 'btn-paginacao': true, 'pagina-atual': pagina === paginaAtual }">
            {{ pagina }}
          </button>
        </span>
        <button @click.prevent="proximaPagina" class="btn-paginacao">
          <i class="fas fa-chevron-right"></i>
        </button>
      </div>
    </div>

  </div>

</template>

<script>
import axios from "axios";

export default {
  name: "ListaCoresPadraoSistema",

  // components: {
  //   EditarCorPadrao
  // },

  data() {
    return {
      lista_cores_padrao: [],

      checkAll: false,
      // lista usada para salvar esse retorno depois de dividido
      listaCoresPaginada: [],
      // varival que inicializa o numero de paginas
      paginaAtual: 0,
      next_page: null,
      totalDePaginas: 0,

      error: [],
    }
  },

  computed: {
    minhaEmpresa() {
      return this.$store.state.minhaEmpresa;
    },

    isadmin() {
      return this.$store.state.isAdminUser
    },

    paginasExibidas() {
      const paginas = [];
      const paginasVisiveis = 5; // Número de páginas visíveis de cada vez

      let inicio = Math.max(1, this.paginaAtual - Math.floor(paginasVisiveis / 2));
      let fim = inicio + paginasVisiveis - 1;

      if (inicio + paginasVisiveis > this.totalDePaginas) {
        inicio = Math.max(1, this.totalDePaginas - paginasVisiveis + 1);
        fim = this.totalDePaginas;
      }

      for (let i = inicio; i <= fim; i++) {
        paginas.push(i);
      }

      return paginas;
    },
  },

  created() {

  },

  methods: {

    selectAll() {
      // Atualiza o estado dos demais checkboxes
      const checkboxes = document.querySelectorAll('.escopo-tabela input[type="checkbox"]');
      checkboxes.forEach((checkbox) => {
        checkbox.checked = this.checkAll;
      });
    },

    async getListaCorPadrao() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')
      if (this.paginaAtual === 0) {
        await axios.get(`/produtos/cor-produto-base?page=1`, {
          headers: {
            'Authorization': `Token ${token}`
          }
        })
            .then(response => {
              this.lista_cores_padrao = response.data.results
              console.log(this.lista_cores_padrao)
              this.totalDePaginas = response.data.count / 5
              console.log(this.totalDePaginas)
              this.paginaAtual += 1
            })
      } else {
        await axios.get(`/produtos/cor-produto-base?page=${this.paginaAtual}`, {
          headers: {
            'Authorization': `Token ${token}`
          }
        })
            .then(response => {
              this.lista_cores_padrao = response.data.results
              console.log(this.lista_cores_padrao)
              this.totalDePaginas = response.data.count / 5
              console.log(this.totalDePaginas)
              this.paginaAtual += 1
            })
      }

    },

    proximaPagina() {
      this.getListaProdutos();
    },

    paginaAnterior() {
      this.paginaAtual -= 1
      this.getListaProdutos()
    },

    async irParaPagina(pagina) {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      console.log(pagina)
      this.paginaAtual = pagina
      await axios.get(`/produtos/cor-produto-base?page=${this.paginaAtual}`, {
          headers: {
            'Authorization': `Token ${token}`
          }
        })
            .then(response => {
              this.lista_cores_padrao = response.data.results
              console.log(this.lista_cores_padrao)
              this.totalDePaginas = response.data.count / 5
            })
    }

  }
}
</script>

<style lang="scss"></style>