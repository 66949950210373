<template>
  <div class="escurecendo-tela">

    <section class="container-nova-tarefa">

      <div class="titulo-nova-tarefa">
        <h1 class="text-xl">Cadastro Nova Tarefa</h1>
        <button ><i class="fas fa-xmark text-3xl"></i></button>
      </div>

      <!--    CONTAINER DOS CAMPOS FORMULARIO PARA NOVA TAREFA-->
      <form>
        <div class="linha-form-tarefa">
          <div class="campo-form-tarefa w-[20%]">
            <label class="label-form-tarefa">Agenda:</label>
            <select class="input-tarefa" v-model="tipo_agenda">
              <option value="">Tipo da agenda</option>
              <option value="interna">Interna</option>
              <option value="externa">Externa</option>
            </select>
          </div>
        </div>
        <div class="linha-form-tarefa">
          <div class="campo-form-tarefa w-[70%]">
            <label class="label-form-tarefa">Interessado:</label>
            <Select2 v-model="interessado"
                     class="input-select2 "
                     :options="lista_clientes"
                     :settings="{ width: '100%'}"
            />
          </div>
          <div class="campo-form-tarefa  w-[20%]">
            <label class="label-form-tarefa">Novo cliente:</label>
            <button class="btn-cadastro-cliente-novo">Cadastrar</button>
          </div>
        </div>

        <div class="linha-form-tarefa">
          <div class="campo-form-tarefa w-[30%]">
            <label class="label-form-tarefa">Grupo de Operação:</label>
            <input type="text" class="input-tarefa" :value="grupoNome" @input="grupo = $event.target.value"/>
          </div>
          <div class="campo-form-tarefa   w-[30%]">
            <label class="label-form-tarefa">Ação:</label>
            <Select2 v-model="acoes"
                     class="input-select2"
                     :options="lista_acoes"
                     :settings="{ width: '100%'}"
            />

          </div>
          <div class="campo-form-tarefa   w-[30%]">
            <label class="label-form-tarefa">Responsavel:</label>
            <input type="text" class="input-tarefa" v-model="responsavel">
          </div>
        </div>

        <div class="linha-form-tarefa w-[40%]">
          <div class="campo-form-tarefa">
            <label class="label-form-tarefa">Inicio:</label>
            <input type="datetime-local" class="input-tarefa" v-model="inicio">
          </div>
          <div class="campo-form-tarefa">
            <label class="label-form-tarefa">Fim:</label>
            <input type="datetime-local" class="input-tarefa" v-model="fim">
          </div>
        </div>

        <div class="linha-form-tarefa">
          <div class="campo-form-tarefa w-full">

            <label>Descrição:</label>
            <textarea class="w-full h-20" v-model="descricao"></textarea>

          </div>
        </div>

        <div class="linha-form-tarefa">
          <div class="campo-form-tarefa w-[40%]">
            <label>Atividades:</label>
            <Select2 v-model="atividades"
                     class="input-select2"
                     :options="lista_atividades"
                     :settings="{ width: '100%'}"
            />
          </div>
        </div>

        <div class="flex justify-end mt-8">
          <button class="btn-cancelar">Cancelar</button>
          <button class="btn-salvar" @click.prevent="postTarefa">Salvar</button>
        </div>


      </form>
    </section>


  </div>

</template>

<script>
import LandingPage from "@/views/LandingPage";
import axios from "axios";
import Select2 from 'vue3-select2-component';

export default {
  name: "NovaTarefa",
  components: {
    LandingPage,
    Select2,
  },

  data() {
    return {
      lista_clientes: [],
      interessado: '',
      sugestoes: [],
      sugestoes_atividades: [],
      tipo_agenda: '',
      lista_atividades: [],
      lista_acoes: [],
      grupo_operacao: '',
      atividades: [],
      responsavel: '',
      inicio: '',
      fim: '',
      descricao: '',
      acoes: '',
      status: 'A fazer',


      lista_grupo_operacao: [],

      grupoNome: '',
    }
  },

  props: {
    grupo: {
      type: Object,
      default: ''
    },
  },

  created() {
    this.getClientes()
    this.getAtividades()
    this.getAcoes()
    this.getGrupoOperacao()
  },

  computed: {
    minhaEmpresa() {
      return this.$store.state.minhaEmpresa;
    },

    grupoNome() {
      if (this.grupo && this.lista_grupo_operacao.length > 0) {
        const grupoEncontrado = this.lista_grupo_operacao.find(item => item.id === this.grupo);
        return grupoEncontrado ? grupoEncontrado.text : '';
      }
      return '';
    }
  },


  methods: {
    myChangeEvent(val) {
      console.log(val);
    },
    mySelectEvent({id, text}) {
      console.log({id, text})
    },

    async getGrupoOperacao() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      axios.get('agenda_tarefa/grupooperacao/', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            this.lista_grupo_operacao = response.data.map(item => ({
              id: item.id,
              text: item.nome
            }));
            console.log(this.lista_grupo_operacao)
          })
          .catch(error => {
            console.log(error)
          })
    },


    async getClientes() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      axios.get('pessoas', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            this.lista_clientes = response.data.map(item => {
              if (item.pessoa_fisica) {
                return {
                  id: item.pessoa_fisica.id,
                  text: item.pessoa_fisica.nome_razao
                };
              } else if (item.pessoa_juridica) {
                return {
                  id: item.pessoa_juridica.id,
                  text: item.pessoa_juridica.nome_razao
                };
              } else {
                return {
                  id: null,
                  text: ''
                };
              }
            });

            console.log(this.lista_clientes);
          })
          .catch(error => {
            console.log(error);
            console.log('Ocorreu um erro.');
          });
    },


    async getAtividades() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      axios.get('agenda_tarefa/atividades/', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            this.lista_atividades = response.data.map(item => ({
              id: item.id,
              text: item.nome // Atualize a chave para "text"
            }));
            console.log(this.lista_atividades)
          })
          .catch(error => {
            console.log(error)
          })
    },

    async getAcoes() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      axios.get('agenda_tarefa/acoesoperacao/', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            this.lista_acoes = response.data.map(item => ({
              text: item.nome,
              id: item.id // Atualize a chave para "text"
            }));
            console.log(this.lista_acoes)
          })
          .catch(error => {
            console.log(error)
          })
    },


    async postTarefa() {
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest')
      const data = {
        descricao: this.descricao,
        data_hora_inicio : this.inicio, // correção: data_hora_inicio => inicio
        data_hora_fim: this.fim, // correção: data_hora_fim => fim
        pessoa_alocada: this.responsavel, // campo v-model ausente no formulário, verifique se é necessário
        status_tarefa: 'dbf05087-53ab-4d1c-a7e7-aed72536bf6e', // correção: status_tarefa => status
        acoes_operacao: this.acoes, // correção: acoes_operacao => acaoSelecionada
        cliente_tarefa: this.interessado, // correção: cliente_tarefa => interessado
        atividades: [this.atividades], // IDs das atividades relacionadas à tarefa
        tipo_tarefa: this.tipo_agenda, // campo v-model ausente no formulário, verifique se é necessário
      };

      console.log(data);

      await axios.post('agenda_tarefa/tarefas/', data, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            console.log(response.status);
            console.log(response.data);
          })
          .catch(error => {
            console.log(error);
          });
    },


    // metodo put para atualizar as tarefas
    async putTarefa() {
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest')
      const data = {
        descricao: 'Nova descrição da tarefa',
        status_tarefa: 'dbf05087-53ab-4d1c-a7e7-aed72536bf6e',  // ID do novo status da tarefa
        atividades: ['50eb6c89-2312-47ad-adbf-236a7bc9b682'],  // IDs das novas atividades relacionadas à tarefa
      };

      console.log(data);

      await axios.put('agenda_tarefa/tarefas/3a4c7621-6a2b-4c47-bde6-1d90bc160109/', data, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            console.log(response.status);
            console.log(response.data);
          })
          .catch(error => {
            console.log(error);
          });
    },


  }

}
</script>

<style lang="scss">
.select2-container {
  z-index: 10003 !important;
}

.select2-dropdown {
  z-index: 10004 !important;
}


.escurecendo-tela {
  @apply absolute top-0 left-0 w-full h-screen bg-screenCor/50 z-[10000]
}

.container-nova-tarefa {
  @apply fixed top-[13%] left-[6%] w-[90%] h-[86vh] z-[10001] bg-screenCor border border-corBase
  rounded-lg p-4 overflow-y-scroll scrollbar-thin scrollbar-thumb-corBase/20
}

.titulo-nova-tarefa {
  @apply flex justify-between text-corBase
}

.linha-form-tarefa {
  @apply flex justify-between mt-4
}

.campo-form-tarefa {
  @apply flex flex-col
}

.label-form-tarefa {
  @apply text-corBase
}

.btn-cadastro-cliente-novo {
  @apply px-8 py-1 bg-corBase text-screenCor
}

.input-tarefa {
  @apply w-full bg-white border-2 border-b-corBase p-1
  text-corBase shadow-sm shadow-corBase/20
}

.btn-cancelar {
  @apply bg-inativo text-white px-4 py-1 mr-4
}

.btn-salvar {
  @apply bg-ativo text-white px-4 py-1
}

.autocomplete-list {
  @apply absolute z-50 bg-screenCor mt-16 text-corBase
}

// estilo pro select2
.input-select2 .select2-selection{
  @apply w-full bg-white border-2 border-b-corBase p-1 h-9
  text-corBase shadow-sm shadow-corBase/20 rounded-none
}
</style>