<template>
  <div class="bg-slate-300 p-4 m-3 mt-6 mb-6">
    <div>
      <div class="flex justify-between mb-6">
        <h1 class="font-bold">Produto Fornecedor: </h1>
        <input 
          type="text"
          :value="perfil.perfil_base_fornecedor.nome_especifico"
          class="w-[26vw] text-center"
        >
      </div>
      <div class="px-4">
        <div>
          <h1 class="font-bold">Informações Gerais</h1>
        </div>
        <div class="flex justify-between">
          <div>
            <h2>Perfil: </h2>
            <input 
              type="text" 
              class="w-[25vw] text-center"
              :value="perfil.nome_perfil_especifico"
              readonly
            >
          </div>
          <div>
            <h2>Cor: </h2>
            <input 
              type="text"
              class="w-[25vw] text-center"
              :value="perfil.cor_perfil.nome"
              readonly
            >
          </div>
        </div>
        <div class="flex justify-between mb-6">
          <div>
            <h2>Tipo: </h2>
            <input 
              type="text"
              class="w-[25vw] text-center"
              :value="perfil.tipo_perfil.nome"
              readonly
            >
          </div>
          <div>
            <h2>Linha Aplicação: </h2>
            <input 
              type="text"
              class="w-[25vw] text-center"
              :value="perfil.linha_aplicacao.nome"
              readonly
            >
          </div>
        </div>
        <div>
          <h1 class="font-bold">Medidas</h1>
        </div>
        <div class="flex justify-between mb-6">
          <div>
            <h2>Comprimento: </h2>
            <input 
              type="text"
              class="w-[17vw] text-center"
              :value="perfil.comprimento_padrao"
              readonly
            >
          </div>
          <div>
            <h2>Sucata Máxima: </h2>
            <input 
              type="text"
              class="w-[17vw] text-center"
              :value="perfil.sucata_maxima"
              readonly
            >
          </div>
          <div>
            <h2>Perda por tratamento: </h2>
            <input 
              type="text"
              class="w-[17vw] text-center"
              :value="perfil.perda_tratamento"
              readonly
            >
          </div>
        </div>
        <div>
          <h1 class="font-bold">Forças</h1>
        </div>
        <div class="flex justify-between">
          <div>
            <h2>Jx: </h2>
            <input 
              type="text"
              class="w-[12vw] text-center"
              :value="perfil.Jx"
              readonly
            >
          </div>
          <div>
            <h2>Jy: </h2>
            <input 
              type="text"
              class="w-[12vw] text-center"
              :value="perfil.Jy"
              readonly
            >
          </div>
          <div>
            <h2>Wx: </h2>
            <input 
              type="text"
              class="w-[12vw] text-center"
              :value="perfil.Wx"
              readonly
            >
          </div>
          <div>
            <h2>Wy: </h2>
            <input 
              type="text"
              class="w-[12vw] text-center"
              :value="perfil.Wy"
              readonly
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ExibicaoConferenciaPerfis',

  props: ["perfil"],

  data() {
    return{

    }
  },
}
</script>

<style lang="scss" scoped>
textarea:focus, input:focus{
    outline: none;
}
</style>