<template>
  <div>
    <div
      v-for="(perfil, index) in perfis"
      :key="index"
    >
      <ExibicaoConferenciaPerfis
        :perfil="perfil"
        v-if="perfil !== undefined"
      />
      <div v-else>
        <div class="bg-slate-300 p-4 m-3 mt-6 mb-6">
          <div class="flex justify-between mb-6">
            <h1 class="font-bold">Produto Fornecedor: </h1>
            <input 
              type="text"
              :value="produtosXml[index].nome"
              class="w-[26vw] text-center"
            >
          </div>
          <button>
            <i class="fas fa-plus"></i>
            Cadastrar Perfil
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ExibicaoConferenciaPerfis from './ExibicaoConferenciaPerfis.vue'
import axios from 'axios'

export default {
  name: 'ConferenciaPerfis',

  components: {
    ExibicaoConferenciaPerfis
  },

  props: {
    produtosXml: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      perfis: [],
    }
  },

  watch: {
    produtosXml: {
      handler(newValue) {
        this.getPerfisPorCodFornecedor(newValue)
      },
      deep: true,
      immediate: true
    }
  },

  methods: {
    async getPerfisPorCodFornecedor(produtos) {

      const token = await this.$store.dispatch("getAuthTokenCookie");
      await this.$store.dispatch("fetchURLrequest"); 

      const perfisPromises = []

      for(const produto of produtos){
        if(produto.codigo_fornecedor_vinculado.trim()){
          perfisPromises.push(
            axios.get(`/produtos/perfil/?codigo_fornecedor=${produto.codigo_fornecedor_vinculado}&page_size=999`, {
              headers: {
                Authorization: `Token ${token}`
              }
            })
          )
        }
      }

      const response = await Promise.all(perfisPromises)
      const result = response.map(response => response.data.results[0])
      this.perfis = result
    },
  }
}
</script>

<style lang="scss" scoped>

</style>