<template>

  <div class="container-formulario">

    <!-- Cabeçalho lista de pessoas depois de 768 pixels-->
    <div class="container-cabecalho flex justify-between mb-4">
      <div>
        <h1 class="text-xl text-corBase font-bold">Categorias GED</h1>
      </div>

      <router-link to="/ged" class="text-xl text-corBase font-bold">
        <i class="fas fa-chevron-left"></i>
      </router-link>

    </div>
    <div class="mb-8">
      <button @click="$router.push(`/ged/cadastro-categoria`)" class="btn-add-fisica mr-2">Adicionar</button>
    </div>

    <!-- Container da exibiçao da lista de pessoas -->
    <div class="container-modelo-tabela">
      <table class="container-tabela overflow-auto">
        <thead class="cabecalho-tabela">
        <tr>
          <th scope="col" class="p-4">
            <div class="flex items-center">
              <input type="checkbox" class="checkbox" v-model="checkAll" @change="selectAll">

              <label class="sr-only">checkbox</label>
            </div>
          </th>
          <th scope="col" class="px-6 py-3">
            #
          </th>
          <th class="px-6 py-3">
            Nome
          </th>
          <th scope="col" class="px-6 py-3">
            Status
          </th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(categoria, index) in lista_categoria" :key="index" class="escopo-tabela">
          <td class="w-4 p-4">
            <div class="flex items-center">
              <input :id="'checkbox-table-' + (index + 2)" type="checkbox" class="checkbox"
                     v-model="categoria.selected">
              <label class="sr-only">checkbox</label>
            </div>
          </td>
          <th scope="row" class="px-6 py-2 font-medium text-xs text-gray-900 whitespace-nowrap dark:text-white">
            {{ index + 1 }}
          </th>
          <td class="px-6 py-2 text-xs">
            {{ categoria.nome }}
          </td>
          <template v-if="categoria.ativo !== null">
            <template v-if="categoria.ativo === true">
              <td class="px-1 sm:px-6  py-1 sm:py-4">
                <i class="fa-solid fa-circle-check  text-ativo"></i>
              </td>
            </template>
            <template v-if="categoria.ativo === false">
              <td class="px-1 sm:px-6  py-1 sm:py-4">
                <i class="fa-solid fa-circle-xmark text-inativo"></i>
              </td>
            </template>
          </template>

        </tr>
        </tbody>
      </table>

      <div class="paginacao">
        <button @click="paginaAnterior" class="btn-paginacao"><i class="fas fa-chevron-left"></i></button>
        <span class="numero-paginacao">{{ paginaAtual - 1 }}</span>
        <span class="numero-paginacao">{{ paginaAtual }}</span>
        <span class="numero-paginacao">{{ paginaAtual + 1 }}</span>
        <button @click="proximaPagina" class="btn-paginacao"><i class="fas fa-chevron-right"></i></button>
      </div>
    </div>
  </div>

</template>

<script>
import Select2 from "vue3-select2-component";

export default {
  name: "ListaCategorias",

  components: {
    Select2
  },

  data() {
    return {

      checkAll: false,
      // lista usada para salvar o retorno da requisição do get
      lista_categoria: [],
      // lista usada para salvar esse retorno depois de dividido
      listaImagemPaginada: [],
      // varival que inicializa o numero de paginas
      paginaAtual: 1,

      error: [],
    }
  },

  computed: {
    minhaEmpresa() {
      return this.$store.state.minhaEmpresa;
    },
  },

  created() {
    this.getListaGategoria()
  },

  methods: {

    selectAll() {
      // Atualiza o estado dos demais checkboxes
      const checkboxes = document.querySelectorAll('.escopo-tabela input[type="checkbox"]');
      checkboxes.forEach((checkbox) => {
        checkbox.checked = this.checkAll;
      });
    },

    // atualiza o valor da pagina no botao de voltar ao anterior
    paginaAnterior() {
      if (this.paginaAtual > 1) {
        this.paginaAtual--;
        this.paginarTabela();
      }
    },

    // atualiza o valor da pagina no botao de ir pra proxima pagina
    proximaPagina() {
      if (this.paginaAtual < this.totalPaginas) {
        this.paginaAtual++;
        this.paginarTabela();
      }
    },

    // exibe apenas 10 items da tabela e salva o resto
    paginarTabela() {
      const itemsPerPage = 5;
      const startIndex = (this.paginaAtual - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      this.listaImagemPaginada = this.listaImagem.filter((imagem, index) => {
        return index >= startIndex && index < endIndex;
      });
    },

    async getListaGategoria() {
      try {

        this.$store.dispatch("setLoading", true);
        const listaCategoria = await this.$store.dispatch('fetchListaCategoriasGed')
        this.lista_categoria = listaCategoria

        this.$store.dispatch("setLoading", false);

      } catch (error) {
        console.log(error)
        this.$store.dispatch("setLoading", false);
      }
    },


  }
}
</script>

<style lang="scss">


</style>