<template>

  <cadastro-imgaem-componente />

</template>

<script>
import CadastroImgaemComponente from "@/components/ged/CadastroImgaemComponente";

export default {
  name: "CadastroImagem",
  components: {
    CadastroImgaemComponente
  }
}
</script>

<style lang="scss">

</style>