<template>

  <div class="container-formulario">

    <!-- Cabeçalho lista de pessoas depois de 768 pixels-->
    <div class="container-cabecalho flex justify-between mb-4">
      <div>
        <h1 class="text-xl text-corBase font-bold">Tipologias</h1>
      </div>

      <router-link to="/projetos" class="text-xl text-corBase font-bold">
        <i class="fas fa-chevron-left"></i>
      </router-link>

    </div>

    <div class="flex gap-8 mb-6 items-end w-[70%]">
      <div class="w-full">
        <input 
          type="text" 
          class="input-form-chapa" 
          v-model="nome_linha"
          placeholder="Nome"
        >

      </div>
      <div class="w-full">
        <input 
          type="text" 
          class="input-form-chapa" 
          v-model="modelo"
          placeholder="Categoria"
        >

      </div>
      <div>
        <button class="btn-add-fisica" @click="getListaTipologias(paginaAtual)">Pesquisar</button>
      </div>
      <div v-if="isadmin === true">
        <button @click="$router.push(`/tipologia/cadastro`)" class="bg-ativo hover:bg-screenCor border border-screenCor hover:border-ativo
  text-white hover:text-ativo py-1 rounded-sm px-4">Adicionar
        </button>
      </div>
    </div>


    <!-- Container da exibiçao da lista de pessoas -->
    <div class="container-modelo-tabela">
      <table class="container-tabela overflow-auto">
        <thead class="cabecalho-tabela">
        <tr>
          <th scope="col" class="p-4">
            <div class="flex items-center">
              <input type="checkbox" class="checkbox" v-model="checkAll" @change="selectAll">

              <label class="sr-only">checkbox</label>
            </div>
          </th>
          <th scope="col" class="px-6 py-3">
            #
          </th>
          <th class="px-6 text-start text-base w-full">
            Nome
          </th>
          <th class="px-10 text-start text-base w-full">
            Imagem
          </th>
          <th class="px-16 text-start text-base w-full">
            Categoria
          </th>
          <th class="px-10 text-base py-3">
            Linha
          </th>
          <th scope="col" class="px-6 py-3 text-base" v-if="isadmin === true">
            Status
          </th>
          <th scope="col" class="px-6 py-3 text-base" v-if="isadmin === true">
            Ação
          </th>
        </tr>
        </thead>
        <tbody>

        <!-- <template> -->
          <tr v-for="(tipologias, index) in lista_tipologia" :key="index" class="escopo-tabela">

            <td class="w-4 p-4">
              <div class="flex items-center">
                <input :id="'checkbox-table-' + (index + 2)" type="checkbox" class="checkbox"
                       v-model="tipologias.selected">
                <label class="sr-only">checkbox</label>
              </div>
            </td>
            <th scope="row"
                class="px-6 py-2 font-medium text-xs text-gray-900 whitespace-nowrap dark:text-white">
              {{ index + 1 }}
            </th>
            <td class="px-6 py-2 text-base">
              {{ tipologias.nome }}
            </td>
            <td class="px-6 py-2 text-base flex justify-center">
              <img 
                :src="tipologias.imagem.url_documento" 
                alt="imagem-tipologia" 
                class="w-10 "
              >
            </td>
            <td class="px-6 py-2 text-base text-center">
              {{ tipologias.modelo.descricao }}
            </td>
            <td class="px-4 py-2 text-base text-center">
            <span v-for="(linhaSistema, linhaIndex) in tipologias.linhasistema" :key="linhaIndex">
              {{ linhaSistema.linha + ' ' }}
            </span>
            </td>

            <template v-if="tipologias.ativo === true && isadmin === true">
              <td class="px-1 sm:px-6  py-1 sm:py-4 text-base text-center">
                <i class="fa-solid fa-circle-check  text-ativo"></i>
              </td>
            </template>
            <template v-if="tipologias.ativo === false && isadmin === true">
              <td class="px-1 sm:px-6  py-1 sm:py-4 text-base text-center">
                <i class="fa-solid fa-circle-xmark text-inativo"></i>
              </td>
            </template>

            <td v-if="isadmin === true">
              <editar-tipologia
                :tipologia="tipologias" 
                :paginaAtual="paginaAtual"
              />
            </td>

          </tr>

        <!-- </template> -->

        <!-- <template v-else>

          <tr v-for="(tipologias, index) in lista_tipologia" :key="index" class="escopo-tabela">

            <td class="w-4 p-4">
              <div class="flex items-center">
                <input :id="'checkbox-table-' + (index + 2)" type="checkbox" class="checkbox"
                       v-model="tipologias.selected">
                <label class="sr-only">checkbox</label>
              </div>
            </td>
            <th scope="row"
                class="px-6 py-2 font-medium text-xs text-gray-900 whitespace-nowrap dark:text-white">
              {{ index + 1 }}
            </th>
            <td class="px-6 py-2 text-base">
              {{ tipologias.nome }}
            </td>
            <td class="px-6 py-2 text-base flex justify-center">
              <img :src="tipologias.imagem.url_documento" alt="imagem-tipologia" class="w-10 ">
            </td>
            <td class="px-6 py-2 text-base text-center">
              {{ tipologias.modelo.descricao }}
            </td>
            <td class="px-4 py-2 text-base text-center">
                <span v-for="(linhaSistema, linhaIndex) in tipologias.linhasistema" :key="linhaIndex">
                  {{ linhaSistema.linha + ' ' }}
                </span>
            </td>

            <template v-if="tipologias.ativo === true && isadmin === true">
              <td class="px-1 sm:px-6  py-1 sm:py-4 text-base text-center">
                <i class="fa-solid fa-circle-check  text-ativo"></i>
              </td>
            </template>
            <template v-if="tipologias.ativo === false && isadmin === true">
              <td class="px-1 sm:px-6  py-1 sm:py-4 text-base text-center">
                <i class="fa-solid fa-circle-xmark text-inativo"></i>
              </td>
            </template>

            <td v-if="isadmin === true">
              <editar-tipologia 
                :tipologia="tipologias" 
                :paginaAtual="paginaAtual"
              />
            </td>


          </tr>

        </template> -->

        </tbody>
      </table>

      <!-- <div class="paginacao">
        <button @click.prevent="paginaAnterior" class="btn-paginacao">
          <i class="fas fa-chevron-left"></i>
        </button>
        <span v-for="pagina in paginasExibidas" :key="pagina">
          <button @click.prevent="irParaPagina(pagina)"
                  :class="{ 'btn-paginacao': true, 'pagina-atual': pagina === paginaAtual }">
            {{ pagina }}
          </button>
        </span>
        <button @click.prevent="proximaPagina" class="btn-paginacao">
          <i class="fas fa-chevron-right"></i>
        </button>
      </div> -->

      <Pagination
        v-if="lista_tipologia.length > 0"
        :offset="paginaAtual"
        :total="countItens"
        :limit="5"
        @change-page="mudarPagina"
      />
    </div>
  </div>

</template>

<script>
import axios from "axios";
import Select2 from "vue3-select2-component";
import EditarTipologia from "@/components/midais/Editar/projeto/EditarTipologia";
import Pagination from "@/utils/Pagination.vue";

export default {
  name: "ListaTipologia",

  components: {
    Select2,
    EditarTipologia,
    Pagination
  },

  data() {
    return {
      lista_tipologia: [],
      tipologias: '',

      checkAll: false,
      // lista usada para salvar o retorno da requisição do get
      listaTipologias: [],
      // lista usada para salvar esse retorno depois de dividido
      listaTipologiasPaginada: [],
      // varival que inicializa o numero de paginas
      paginaAtual: 1,
      countItens: 0,
      next_page: null,
      totalDePaginas: 0,
      itemsPorPagina: 5,

      // filtros
      nome_projetista: '',
      modelo: '',
      nome_linha: '',

      error: [],
    }
  },

  computed: {
    minhaEmpresa() {
      return this.$store.state.minhaEmpresa;
    },

    isadmin() {
      return this.$store.state.isAdminUser
    },

    // paginasExibidas() {
    //   const paginas = [];
    //   const paginasVisiveis = 5; // Número de páginas visíveis de cada vez

    //   let inicio = Math.max(1, this.paginaAtual - Math.floor(paginasVisiveis / 2));
    //   let fim = inicio + paginasVisiveis - 1;

    //   if (inicio + paginasVisiveis > this.totalDePaginas) {
    //     inicio = Math.max(1, this.totalDePaginas - paginasVisiveis + 1);
    //     fim = this.totalDePaginas;
    //   }

    //   for (let i = inicio; i <= fim; i++) {
    //     paginas.push(i);
    //   }

    //   return paginas;
    // },
  },


  methods: {

    selectAll() {
      // Atualiza o estado dos demais checkboxes
      const checkboxes = document.querySelectorAll('.escopo-tabela input[type="checkbox"]');
      checkboxes.forEach((checkbox) => {
        checkbox.checked = this.checkAll;
      });
    },

    async getListaTipologias(pagina) {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get(`/projeto/tipologia/?linhasistema=${this.nome_projetista}&modelo=${this.modelo}&nome=${this.nome_linha}&page=${pagina}`, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            this.lista_tipologia = response.data.results
            this.countItens = response.data.count
          })
      // } else {
      //   await axios.get(`/projeto/tipologia/?linhasistema=${this.nome_projetista}&modelo=${this.modelo}&nome=${this.nome_linha}&page=${this.paginaAtual}`, {
      //     headers: {
      //       'Authorization': `Token ${token}`
      //     }
      //   })
      //       .then(response => {
      //         this.lista_tipologia = response.data.results
      //         console.log(this.lista_tipologia)
      //         this.totalDePaginas = response.data.count / 5
      //         console.log(this.totalDePaginas)
      //         this.paginaAtual += 1
      //       })
      // }

    },

    mudarPagina(pagina) {
      this.paginaAtual = pagina
      this.getListaTipologias(pagina)
    },

    proximaPagina() {
      this.getListaProdutos();
    },

    paginaAnterior() {
      this.paginaAtual -= 1
      this.getListaProdutos()
    },

    // async irParaPagina(pagina) {
    //   const token = await this.$store.dispatch('getAuthTokenCookie')
    //   console.log(pagina)
    //   this.paginaAtual = pagina
    //   await axios.get(`/projeto/tipologia/?linhasistema=${this.nome_projetista}&modelo=${this.modelo}&nome=${this.nome_linha}&page=${this.paginaAtual}`, {
    //       headers: {
    //         'Authorization': `Token ${token}`
    //       }
    //     })
    //         .then(response => {
    //           this.lista_tipologia = response.data.results
    //           console.log(this.lista_tipologia)
    //           this.totalDePaginas = response.data.count / 5
    //         })
    // }


  }
}
</script>

<style lang="scss"></style>