<template>

  <div class="container-formulario">

    <!-- TILO DO FORMULARIO + BOTAO DE VOLTAR -->
    <div class="cabecalho-formulario">
      <h1 class="text-xl text-corBase font-bold">Dados Unidade de Medida</h1>

      <router-link to="/produtos/unidade-medida" class="text-xl text-corBase font-bold">
        <i class="fas fa-chevron-left"></i>
      </router-link>
    </div>

    <!-- Escopo do formulario de cadastro mobile-->
    <form @submit.prevent="cadastrarUnidadeMedida">
      <!--        PARTE DE DADOS DO PRODUTO-->
      <div class="flex flex-col md:flex-row gap-3 my-6 w-full ">

        <div class="campo-chapa">
          <label class="font-bold text-corBase">Codigo</label>
          <input type="text" v-model="codigo" class="input-form-chapa" required>
        </div>

        <div class="campo-chapa">
          <label class="font-bold text-corBase">Descrição</label>
          <input type="text" v-model="nome" class="input-form-chapa" required autofocus>
        </div>

        <div class="campo-chapa">
          <label class="font-bold text-corBase">Produtos Permitidos</label>
          <Select2 v-model="produtos_pernmitidos"
                   class="w-full z-10"
                   :options="lista_produtos_sistema"
                   :settings="{ width: '100%', multiple:true}"
                   required
          />
        </div>

      </div>


      <div class="flex w-full justify-end">
        <button class="btn-enviar" type="submit">Salvar</button>
      </div>

    </form>
  </div>
  <MensagemSucesso v-if="sucesso==true" rotas="/produtos/unidade-medida"
                   mensagem="Unidade de Medida Cadastrada com Sucesso!"/>

</template>

<script>
import axios from "axios";
import MensagemSucesso from "@/components/toasts/toast_sucesso/MensagemSucesso";
import Select2 from "vue3-select2-component";

export default {
  name: "UnidadeMedida",

  components: {
    MensagemSucesso,
    Select2
  },


  data() {
    return {
      sucesso: false,
      nome: '',
      codigo: '',
      produtos_pernmitidos: [],
      lista_produtos_sistema: []
    }
  },

  created() {
    this.getListProdutosSistema()
  },

  computed: {
    minhaEmpresa() {
      return this.$store.state.minhaEmpresa;
    },

    isadmin() {
      return this.$store.state.isAdminUser
    },
  },

  methods: {


    async cadastrarUnidadeMedida() {
      const token = await this.$store.dispatch('getAuthTokenCookie')

      await this.$store.dispatch('fetchURLrequest')

      const data = {
        ativo: true,
        nome: this.nome,
        codigo: this.codigo,
        produtos_do_sistema: this.produtos_pernmitidos
      }
      // const data = new FormData();
      // data.append('ativo', 'True')
      // data.append('nome', this.nome)
      // data.append('codigo', this.codigo)
      // data.append('produtos_do_sistema', this.produtos_pernmitidos)
      console.log(data)
      this.sucesso = false
      this.$store.dispatch("setLoading", true);
      await axios.post('produtos/unidade-medida/', data, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            console.log(response.data);
            this.nome = ''
            this.codigo = ''
            this.produtos_pernmitidos = []
            this.sucesso = true
            this.$store.dispatch("setLoading", false);
          })
          .catch(error => {
            console.log(error);
            this.$store.dispatch("setLoading", false);
          })

    },

    async getListProdutosSistema() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get('/produtos/produtos-sistema/', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            this.lista_produtos_sistema = response.data.map(item => ({
              id: item.id,
              text: item.nome
            }))
          })
          .catch(error => {
            console.log(error)
          })
    }


  }

}
</script>

<style lang="scss"></style>