<template>
    <div>
        <Search
            v-model="produtoPesquisado"
            :options="produtosPesquisados"
            labelKey="codigo_fornecedor"
            valueKey="id"
            nameKey="nome_especifico"
            @select="onSelect"
            @shouldSearch="buscarProdutosPorFornecedor"
            class="w-80"
        />
    </div>
</template>

<script>
import Search from '@/utils/Search.vue'
import axios from 'axios'

export default {
    name: 'SearchProxy',

    props: {
        produtoPesquisadoProxy: {
            type: Object,
            default: ''
        },

        produtosPesquisadosProxy: {
            type: Array,
            default: () => []
        },

        produtoAtual: {
            type: Object,
            default: {}
        },
    },

    components: {
        Search
    },

    created() {
        this.produtoPesquisado = this.produtoPesquisadoProxy
        this.produtosPesquisados = this.produtosPesquisadosProxys
    },

    data() {
        return {
            produtoPesquisado: '',
            produtosPesquisados: [],
        }
    },

    methods: {  
        async buscarProdutosPorFornecedor(pesquisa) {
            const token = await this.$store.dispatch("getAuthTokenCookie");
            await this.$store.dispatch("fetchURLrequest");

            const response = await axios.get(`produtos/produto-por-fornecedor/?codigo_fornecedor=${pesquisa}&page_size=9999`, {
                headers: {
                    'Authorization': `Token ${token}`
                }
            })
            this.produtosPesquisados = response.data.results
        },

        onSelect(produto) {
            this.produtoAtual.codigo_fornecedor_vinculado = produto.codigo_fornecedor
            this.$emit('select', this.produtoAtual)
        },
    },
}
</script>

<style lang="scss" scoped>

</style>