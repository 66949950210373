<template>


  <div class="container-formulario">

    <!-- TILO DO FORMULARIO + BOTAO DE VOLTAR -->
    <div class="cabecalho-formulario">
      <h1 class="text-xl text-corBase font-bold">Dados Fornecedor:</h1>

      <router-link to="/fornecedor" class="text-xl text-corBase font-bold">
        <i class="fas fa-chevron-left"></i>
      </router-link>
    </div>

    <!-- Escopo do formulario de cadastro mobile-->
    <form @submit.prevent="cadastroFornecedor">
      <div class="flex md:flex-col w-full ">
        <div class="flex gap-24">
          <div class="campos-mobile">
            <label class="text-xl text-corBase font-bold">Pessoa:</label>
            <Select2 v-model="pessoa_juridica"
                     class="w-full z-10"
                     :options="lista_pessoas"
                     :settings="{ width: '100%'}"
            />
          </div>
          <div class="campos-mobile">
            <label class="text-xl text-corBase font-bold">Tipo Fornecedor:</label>
            <Select2 v-model="tipo_fornecedor"
                     class="w-full z-10"
                     :options="lista_tipo_fornecedor"
                     :settings="{ width: '100%'}"
            />
          </div>
        </div>
      </div>
      <div class="campos-mobile">
        <label class="text-base text-corBase font-bold mt-2">Codigo do Fornecedor:</label>
        <input type="text" required class="input-campos" v-model="codigo_fornecedor">
      </div>
      <div class="flex w-full justify-end mt-8">
        <button class="btn-enviar" type="submit">Cadastrar</button>
      </div>
    </form>
  </div>
  <mensagem-sucesso v-if="sucesso === true" mensagem="Fornecedor Cadastrado com Sucesso!" rotas="/fornecedor"/>

</template>

<script>
import axios from 'axios'
import Select2 from "vue3-select2-component";
import MensagemSucesso from "@/components/toasts/toast_sucesso/MensagemSucesso";

export default {
  name: "Fornecedor",

  components: {
    Select2,
    MensagemSucesso
  },

  data() {
    return {
      sucesso: false,
      lista_tipo_fornecedor: [],
      lista_pessoas: [],
      tipo_fornecedor: '',
      pessoa_juridica: '',
      codigo_fornecedor: '',
    }
  },

  computed: {
    isadmin() {
      return this.$store.state.isAdminUser
    },

    minhaEmpresa() {
      return this.$store.state.minhaEmpresa;
    },
  },

  created() {
    this.getListaPessoas()
    this.getListaTipoFornecedor()

  },

  methods: {

    async getListaPessoas() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get('pessoas', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            console.log(response.data)
            this.lista_pessoas = response.data.results.map(item => ({
              id: item.id,
              text: item.nome_razao
            }))
          })
          .catch(error => {
            console.log(error)
          })

    },

    async getListaTipoFornecedor() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get('pessoas/tipo-fornecedor', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            this.lista_tipo_fornecedor = response.data.results.map(tipo => ({
              id: tipo.id,
              text: tipo.nome
            }))
          })
          .catch(error => {
            console.log(error)
          })

    },


    async cadastroFornecedor() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      const data = new FormData
      data.append('ativo', 'true')
      data.append('id_tipo_fornecedor', this.tipo_fornecedor)
      data.append('pessoa', this.pessoa_juridica)
      data.append('codigo_fornecedor', this.codigo_fornecedor)

      this.$store.dispatch("setLoading", true);
      await axios.post('pessoas/fornecedor/', data, {
        headers: {
          "Authorization": `Token ${token}`
        }
      })
          .then(response => {
            console.log(response.data)
            this.$store.dispatch("setLoading", false);
            this.sucesso = true

          })
          .catch(error => {
            console.log(error)
            this.$store.dispatch("setLoading", false);
          })
    }
  },

}
</script>

<style scoped>

</style>