<template>

  <div class="mt-6 block md:hidden">
    <h1 class="text-corBase mb-2 text-xl">Navegar pelas</h1>
    <div class="relative" @click="dropdownDados = !dropdownDados">
      <button class="bg-screenCor px-3 py-1 text-corBase font-bold" v-if="!dropdownDados">
        Opções
      </button>
      <button class="bg-screenCor px-3 py-1 text-corBase font-bold" v-if="dropdownDados">
        Opções
      </button>

      <div class="absolute left-0 mt-1 w-40 bg-white border border-gray-300 shadow-lg" v-if="dropdownDados">
        <!-- Conteúdo do dropdown -->
        <button class="block w-full px-4 py-2 hover:bg-gray-100" @click.prevent="emitEvent('dados')">
          Dados
        </button>
        <button class="block w-full px-4 py-2 hover:bg-gray-100" @click.prevent="emitEvent('formulas_variaveis')">
          Formulas/Variaveis
        </button>
        <button class="block w-full px-4 py-2 hover:bg-gray-100" @click.prevent="emitEvent('perfis')">
          Perfis
        </button>
        <button class="block w-full px-4 py-2 hover:bg-gray-100" @click.prevent="emitEvent('vidros')">
          Vidros
        </button>
        <button class="block w-full px-4 py-2 hover:bg-gray-100" @click.prevent="emitEvent('componentes')">
          Componentes
        </button>
        <button class="block w-full px-4 py-2 hover:bg-gray-100" @click.prevent="emitEvent('servicos')">
          Serviços
        </button>
        <button class="block w-full px-4 py-2 hover:bg-gray-100" @click.prevent="emitEvent('fotos_variaveis')">
          Fotos Variaveis
        </button>
        <button class="block w-full px-4 py-2 hover:bg-gray-100" @click.prevent="emitEvent('validacoes')">
          Validações
        </button>
        <button class="block w-full px-4 py-2 hover:bg-gray-100" @click.prevent="emitEvent('tipo_vidro')">
          Tipo Vidro
        </button>

      </div>
    </div>
  </div>

  <!-- NAVEGAÇÃO PRINCIPAL DESKTOP -->
  <div class="nav-principal-projetos pt-6 pb-6">

    <button class="btn-nav-projeto-inativo" v-if="opcoes.dados === false" @click.prevent="emitEvent('dados')">
      Dados
    </button>
    <button class="btn-nav-projeto-ativo" v-if="opcoes.dados === true" @click.prevent="emitEvent('dados')">
      Dados
    </button>

    <button class="btn-nav-projeto-inativo" v-if="opcoes.formulas_variaveis === false"
            @click.prevent="emitEvent('formulas_variaveis')">Formulas/Variaveis
    </button>
    <button class="btn-nav-projeto-ativo" v-if="opcoes.formulas_variaveis === true"
            @click.prevent="emitEvent('formulas_variaveis')">Formulas/Variaveis
    </button>

    <button class="btn-nav-projeto-inativo" v-if="opcoes.perfis === false"
            @click.prevent="emitEvent('perfis')">Perfis
    </button>
    <button class="btn-nav-projeto-ativo" v-if="opcoes.perfis === true"
            @click.prevent="emitEvent('perfis')">Perfis
    </button>

    <button class="btn-nav-projeto-inativo" v-if="opcoes.vidros === false"
            @click.prevent="emitEvent('vidros')">Vidros
    </button>
    <button class="btn-nav-projeto-ativo" v-if="opcoes.vidros === true"
            @click.prevent="emitEvent('vidros')">Vidros
    </button>

    <button class="btn-nav-projeto-inativo" v-if="opcoes.componentes === false"
            @click.prevent="emitEvent('componentes')">Componentes
    </button>
    <button class="btn-nav-projeto-ativo" v-if="opcoes.componentes === true"
            @click.prevent="emitEvent('componentes')">Componentes
    </button>

    <button class="btn-nav-projeto-inativo" v-if="opcoes.servicos === false"
            @click.prevent="emitEvent('servicos')">Serviços
    </button>
    <button class="btn-nav-projeto-ativo" v-if="opcoes.servicos === true"
            @click.prevent="emitEvent('servicos')">Serviços
    </button>

    <button class="btn-nav-projeto-inativo" v-if="opcoes.fotos_variaveis === false"
            @click.prevent="navegarOpcoes('fotos_variaveis')">Fotos por Variaveis
    </button>
    <button class="btn-nav-projeto-ativo" v-if="opcoes.fotos_variaveis === true"
            @click.prevent="navegarOpcoes('fotos_variaveis')">Fotos por Variaveis
    </button>

    <button class="btn-nav-projeto-inativo" v-if="opcoes.validacoes === false"
            @click.prevent="navegarOpcoes('validacoes')">Validações
    </button>
    <button class="btn-nav-projeto-ativo" v-if="opcoes.validacoes === true"
            @click.prevent="navegarOpcoes('validacoes')">Validações
    </button>

    <button class="btn-nav-projeto-inativo" v-if="opcoes.tipo_vidro === true"
            @click.prevent="navegarOpcoes('tipo_vidro')">Tipos de Vidro
    </button>
    <button class="btn-nav-projeto-ativo" v-if="opcoes.tipo_vidro === true"
            @click.prevent="navegarOpcoes('tipo_vidro')">Tipos de Vidro
    </button>

  </div>


</template>

<script>

import axios from "axios";

export default {

  name: "NavgecacaoProjeto",

  data() {

    return {

      opcoes: [],
      dropdownDados: false,

    }

  },

  created() {

    // cria as opções com valores de false

    this.opcoes = {
      dados: false,
      formulas_variaveis: false,
      perfis: false,
      vidros: false,
      componentes: false,
      serviços: false,
      fotos_variaveis: false,
      validacoes: false,
      tipo_vidro: false,
      projeto: {},
    };

  },

  methods: {


    // função para navegar pelos botoes
    navegarOpcoes(nomeVariavel) {
      for (const key in this.opcoes) {
        if (key !== nomeVariavel) {
          this.opcoes[key] = false; // Define todas as opções como false
        }
      }
      this.opcoes[nomeVariavel] = !this.opcoes[nomeVariavel]; // Alterna a opção selecionada
    },

    // funcao para navegar nos botos dentro de formulas/variaveis
    navegarOpcoesFormulasVariaveis(variavel) {
      console.log('testado')
      this.formulas = false
      this.variaveis = false
      if (variavel == 'formulas') {
        this[variavel] = !this[variavel];
        this.getListaFormulasProjeto()
      }
      if (variavel == 'variaveis') {
        this[variavel] = !this[variavel];
        this.getListaVariaveisLocais()
      }

    },

    // busca a lista de perfis relacionados ao projeto
    async getListaPerfis() {
      this.navegarOpcoes('perfis')
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get(`projeto/projeto-perfil/?projeto_id=${this.projeto_id}`, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            this.lista_perfis = response.data
            console.log(this.lista_perfis)
          })
          .catch(error => {
            console.log(error)
          })
    },

    // busa a lista de vidros relacionados ao projeto
    async getListaVidros() {
      this.navegarOpcoes('vidros')
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get(`projeto/projeto-vidro/?projeto_id=${this.projeto_id}`, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            this.lista_vidros = response.data
          })
          .catch(error => {
            console.log(error)
          })
    },

    // busca a lista de componentes relacionados ao projeto
    async getListaComponentes() {
      this.navegarOpcoes('componentes')
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get(`projeto/projeto-componente/?projeto_id=${this.projeto_id}`, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            this.lista_componentes = response.data
          })
          .catch(error => {
            console.log(error)
          })
    },

    // busca a lista de serviços relacionados ao projeto
    async getListaServicos() {
      this.navegarOpcoes('serviços')
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get(`projeto/projeto-servico/?projeto_id=${this.projeto_id}`, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            this.lista_servicos = response.data
          })
          .catch(error => {
            console.log(error)
          })
    },

    // chamar evento no pai para trocar de aba
    emitEvent(opcao) {
      this.$emit('navegar-opcoes', opcao);
      console.log('Clicado com ' + opcao)
    }

  }

}
</script>

<style lang="scss">

</style>