<template>
  <div class="container-formulario">

    <!-- Cabeçalho lista de pessoas depois de 768 pixels-->
    <div class="container-cabecalho flex justify-between mb-4">
      <div>
        <h1 class="text-xl text-corBase font-bold">Chapas</h1>
      </div>

      <router-link to="/produtos" class="text-xl text-corBase font-bold">
        <i class="fas fa-chevron-left"></i>
      </router-link>

    </div>
    <div class="flex gap-8 items-end mb-6 z-10">
      <div class="w-full">
        <label class="text-corBase">Cor:</label>
        <Select2 v-model="cor_chapa"
                 class="w-full z-10"
                 :options="lista_cor_chapa"
                 :settings="{ width: '100%'}"
        />
      </div>
      <div class="w-full">
        <label class="text-corBase">Tipo:</label>
        <Select2 v-model="tipo_chapa"
                 class="w-full z-10"
                 :options="lista_tipo_chapa"
                 :settings="{ width: '100%'}"
        />
      </div>
      <div class="w-full">
        <label class="text-corBase">Grupo Aplicação:</label>
        <Select2 v-model="grupo_aplicacao_chapa"
                 class="w-full z-10"
                 :options="lista_grupo_aplicacao_chapa"
                 :settings="{ width: '100%'}"
        />
      </div>
      <div>
        <button class="bg-corBase hover:bg-screenCor border border-screenCor hover:border-corBase
  text-white hover:text-corBase py-2 px-4" @click="getListaChapas">Pesquisar
        </button>
      </div>
      <div>
        <button @click="$router.push(`/chapa/cadastro`)" class="bg-ativo hover:bg-screenCor border border-screenCor hover:border-ativo
  text-white hover:text-ativo py-2 px-4 ">Adicionar
        </button>
      </div>
    </div>

    <!-- Container da exibiçao da lista de pessoas -->
    <div class="container-modelo-tabela">
      <table class="container-tabela overflow-auto">
        <thead class="cabecalho-tabela">
        <tr>
          <th scope="col" class="p-4">
            <div class="flex items-center">
              <input type="checkbox" class="checkbox" v-model="checkAll" @change="selectAll">

              <label class="sr-only">checkbox</label>
            </div>
          </th>
          <th scope="col" class="px-6 py-3">
            #
          </th>
          <th class="px-6 py-3">
            Nome
          </th>
          <th class="px-6 py-3">
            Tipo
          </th>
          <th scope="col" class="px-6 py-3">
            Altura
          </th>
          <th scope="col" class="px-6 py-3">
            Largura
          </th>
          <th scope="col" class="px-6 py-3">
            Produto Base
          </th>
          <th scope="col" class="px-6 py-3">
            Grupo de Aplicacao
          </th>
          <th scope="col" class="px-6 py-3">
            Status
          </th>
          <th scope="col" class="px-6 py-3">
            Editar
          </th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(chapa, index) in lista_chapas" :key="index" class="escopo-tabela">
          <td class="w-4 p-4">
            <div class="flex items-center">
              <input :id="'checkbox-table-' + (index + 2)" type="checkbox" class="checkbox" v-model="chapa.selected">
              <label class="sr-only">checkbox</label>
            </div>
          </td>
          <th scope="row" class="px-6 py-2 font-medium text-xs text-gray-900 whitespace-nowrap dark:text-white">
            {{ index + 1 }}
          </th>
          <td class="px-6 py-2 text-xs">
            {{ chapa.nome_especifico }}
          </td>
          <td class="px-6 py-2 text-xs">
            {{ chapa.tipo_chapas.nome }}
          </td>
          <td class="px-6 py-2 text-xs">
            {{ chapa.altura_chapa }}
          </td>
          <td class="px-6 py-2 text-xs">
            {{ chapa.largura_chapa }}
          </td>
          <td class="px-6 py-2 text-xs">
            {{ chapa.produto_base.nome_base }}
          </td>
          <td class="px-6 py-2 text-xs">
            {{ chapa.grupo_aplicacao_chapa.nome }}
          </td>
          <template v-if="chapa.ativo === true">
            <td class="px-6 py-2 text-xs">
              <i class="fa-solid fa-circle-check  text-ativo ml-3"></i>
            </td>
          </template>
          <template v-if="chapa.ativo === false">
            <td class="px-6 py-2">
              <i class="fa-solid fa-circle-xmark text-inativo ml-3"></i>
            </td>
          </template>

          <td class="px-6 py-2 text-xs">
            <editar-chapas :dadosChapa="chapa"></editar-chapas>
          </td>

        </tr>
        </tbody>
      </table>
      <div class="paginacao">
        <button @click.prevent="paginaAnterior" class="btn-paginacao">
          <i class="fas fa-chevron-left"></i>
        </button>
        <span v-for="pagina in paginasExibidas" :key="pagina">
          <button @click.prevent="irParaPagina(pagina)"
                  :class="{ 'btn-paginacao': true, 'pagina-atual': pagina === paginaAtual }">
            {{ pagina }}
          </button>
        </span>
        <button @click.prevent="proximaPagina" class="btn-paginacao">
          <i class="fas fa-chevron-right"></i>
        </button>
      </div>
    </div>
  </div>
  <button @click.prevent="gerar_relatorio">gerar relatorio</button>


</template>

<script>
import axios from "axios";
import EditarChapas from "@/components/midais/Editar/produtos/EditarChapas";
import Select2 from "vue3-select2-component";

export default {
  name: "ListaChapas",

  components: {
    EditarChapas,
    Select2
  },

  data() {
    return {
      checkAll: false,
      // lista usada para salvar o retorno da requisição do get
      listaPessoas: [],
      // lista usada para salvar esse retorno depois de dividido
      listaPessoasPaginada: [],
      itemsPorPagina: 5,

      // filtros
      cor: '',
      tipo: '',
      produto_base: '',
      grupo_aplicacao: '',


      lista_chapas: [],
      lista_cor_chapa: [],
      cor_chapa: '',
      lista_tipo_chapa: [],
      tipo_chapa: '',
      lista_grupo_aplicacao_chapa: [],
      grupo_aplicacao_chapa: '',
      paginaAtual: 0,
      next_page: null,
      totalDePaginas: 0,

      error: []
    }
  },

  created() {
    this.getTipoChapas()
    this.getGrupoAplicacaoChapa()
    this.getCorChapas()
  },

  computed: {
    isadmin() {
      return this.$store.state.isAdminUser
    },

    minhaEmpresa() {
      return this.$store.state.minhaEmpresa;
    },

    paginasExibidas() {
      const paginas = [];
      const paginasVisiveis = 5; // Número de páginas visíveis de cada vez

      let inicio = Math.max(1, this.paginaAtual - Math.floor(paginasVisiveis / 2));
      let fim = inicio + paginasVisiveis - 1;

      if (inicio + paginasVisiveis > this.totalDePaginas) {
        inicio = Math.max(1, this.totalDePaginas - paginasVisiveis + 1);
        fim = this.totalDePaginas;
      }

      for (let i = inicio; i <= fim; i++) {
        paginas.push(i);
      }

      return paginas;
    },

  },

  methods: {
    async gerar_relatorio() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')
      const data = {
        model_name: "ModeloVariacao"
      }

      axios.post('relatorios/gerar-relatorio/', data, {
        headers: {
          'Authorization': `Token ${token}`,
        }
      }).then(response => {
        console.log(response.data)
      }).catch(error => {
        console.log(error)
      })
    },

    selectAll() {
      // Atualiza o estado dos demais checkboxes
      const checkboxes = document.querySelectorAll('.escopo-tabela input[type="checkbox"]');
      checkboxes.forEach((checkbox) => {
        checkbox.checked = this.checkAll;
      });
    },

    async getListaChapas() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')
      if (this.paginaAtual === 0) {
        await axios.get(`pprodutos/chapa?cor_chapas_id=${this.cor}&tipo_chapas_id=${this.tipo}&chapa_base_id=${this.produto_base}&grupo_aplicacao_chapa_id=${this.grupo_aplicacao}&page=1`, {
          headers: {
            'Authorization': `Token ${token}`
          }
        })
            .then(response => {
              this.lista_chapas = response.data.results
              console.log(this.lista_chapas)
              this.totalDePaginas = response.data.count / 5
              console.log(this.totalDePaginas)
              this.paginaAtual += 1
            })
      } else {
        await axios.get(`produtos/chapa?cor_chapas_id=${this.cor}&tipo_chapas_id=${this.tipo}&chapa_base_id=${this.produto_base}&grupo_aplicacao_chapa_id=${this.grupo_aplicacao}&page=${this.paginaAtual}`, {
          headers: {
            'Authorization': `Token ${token}`
          }
        })
            .then(response => {
              this.lista_chapas = response.data.results
              console.log(this.lista_chapas)
              this.totalDePaginas = response.data.count / 5
              console.log(this.totalDePaginas)
              this.paginaAtual += 1
            })
      }

    },

    proximaPagina() {
      this.getListaProdutos();
    },

    paginaAnterior() {
      this.paginaAtual -= 1
      this.getListaProdutos()
    },

    async irParaPagina(pagina) {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      console.log(pagina)
      this.paginaAtual = pagina
      await axios.get(`produtos/chapa?cor_chapas_id=${this.cor}&tipo_chapas_id=${this.tipo}&chapa_base_id=${this.produto_base}&grupo_aplicacao_chapa_id=${this.grupo_aplicacao}&page=${this.paginaAtual}`, {
          headers: {
            'Authorization': `Token ${token}`
          }
        })
            .then(response => {
              this.lista_chapas = response.data.results
              console.log(this.lista_chapas)
              this.totalDePaginas = response.data.count / 5
            })
    },

    async getCorChapas() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')
      await axios.get('produtos/cor-chapa', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            console.log(response.data)
            this.lista_cor_chapa = response.data.map(item => ({
              id: item.id,
              text: item.nome
            }))
          })
          .catch(error => {
            console.log(error)
          })
    },

    async getTipoChapas() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')
      await axios.get('produtos/tipo-chapa', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            console.log(response.data)
            this.lista_tipo_chapa = response.data.map(item => ({
              id: item.id,
              text: item.nome
            }))
          })
          .catch(error => {
            console.log(error)
          })
    },

    async getGrupoAplicacaoChapa() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')
      await axios.get('produtos/grupo-aplicacao-chapa', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            console.log(response.data)
            this.lista_grupo_aplicacao_chapa = response.data.map(item => ({
              id: item.id,
              text: item.nome
            }))
          })
          .catch(error => {
            console.log(error)
          })
    },

  }
}
</script>

<style lang="scss">

</style>