<template>
  <cadastro-chapas-component />
</template>

<script>
import CadastroChapasComponent from "@/components/cadastros_produtos_componentes/CadastroChapasComponent";

export default {
  name: "CadastroChapa",

  components: {
    CadastroChapasComponent
  }

}
</script>

<style lang="scss">

</style>