<template>
  <div>

    <!--Cabeçalho-->
    <header>
      <Menu v-bind:class="menuCabecalho"></Menu>
      <carrossel class="carrossel"></carrossel>
    </header >
    
    <!--Conteudo-->
    <conteudo class="cont"></conteudo>
    
    <!--Gráfico-->
    <grafico></grafico>

    <!--Vantagens do sistema-->
    <vantagens-sistema></vantagens-sistema>

    <!--Rodape-->
    <rodape></rodape>
  </div>
</template>

<script>

import Menu from "@/components/landing_page/Menu";
import Carrossel from "@/components/landing_page/Carrossel";
import Conteudo from "@/components/landing_page/Conteudo";
import VantagensSistema from "@/components/landing_page/VantagensSistema";
import Rodape from "@/components/landing_page/Rodape";

export default {
  name: 'LandingPage',
  components: {
    Menu,
    Carrossel,
    Conteudo,
    VantagensSistema,
    Rodape
  },
  data(){
    return{
      /*Classe para menu*/
      menuCabecalho:'menu-cabecalho'
    }
  },
  mounted(){
    /*Evento para adicionar fundo ao menu quando scroll*/
    addEventListener('scroll', this.menuScroll)
  },
  beforeUnmount() {
    /*Remover evento para adicionar fundo ao menu quando scroll*/
    removeEventListener('scroll', this.menuScroll)
  },
  methods:{
    /*Teste para ocultar/exibir fundo do menu quando scroll*/
    menuScroll(){scrollY > 0 ? this.menuCabecalho = 'menu-cabecalho bg-menu-cabecalho' : this.menuCabecalho = 'menu-cabecalho'}
  }
}
</script>

<style scoped>
div{
  @apply bg-white
}
/*Cabeçalho*/
header{
  @apply mx-auto overflow-hidden
  sm:w-full sm:h-screen sm:m-auto
}
/*Estilo padrão para menu*/
.menu-cabecalho{
  @apply fixed z-20 w-full py-2
}
/*Estilo para menu quando scroll*/
.bg-menu-cabecalho{
  @apply  backdrop-blur-md bg-gray-900/20
}
/*Estilo para diminuir div conteudo quando mobile*/
.cont{
  @apply px-6 mt-6
  sm:px-0 sm:mt-0
}
</style>
