<template>

  <div class="container-formulario">

    <!-- TILO DO FORMULARIO + BOTAO DE VOLTAR -->
    <div class="cabecalho-formulario">
      <h1 class="text-xl text-corBase">Dados Tipo Uso Consumo:</h1>

      <router-link to="/tipo-uso-consumo" class="text-xl text-corBase font-bold">
        <i class="fas fa-chevron-left"></i>
      </router-link>
    </div>

    <!-- Escopo do formulario de cadastro mobile-->
    <form @submit.prevent="cadastrarTipoConsumo">
      <!--        PARTE DE DADOS DO PRODUTO-->
      <div class="div-produtos">
        <div class="campo-chapa">
          <label class="font-bold text-corBase">Nome</label>
          <input type="text" v-model="nome" class="input-form-chapa" required autofocus>
        </div>
      </div>


      <div class="flex w-full justify-end">
        <button class="btn-enviar" type="submit">Cadastrar</button>
      </div>
    </form>

  </div>
  <MensagemSucesso v-if="sucesso==true" rotas="/tipo-uso-consumo" mensagem="Tipo consumo cadastrado com sucesso!"/>

</template>

<script>
import axios from "axios";
import MensagemSucesso from "@/components/toasts/toast_sucesso/MensagemSucesso";

export default {
  name: "TipoConsumo",


  data() {
    return {
      sucesso: false,
      nome: '',
    }
  },

  components: {
    MensagemSucesso
  },

  created() {
  },

  computed: {
    minhaEmpresa() {
      return this.$store.state.minhaEmpresa;
    },

    isadmin() {
      return this.$store.state.isAdminUser
    },
  },

  methods: {


    async cadastrarTipoConsumo() {
      const token = await this.$store.dispatch('getAuthTokenCookie')

      await this.$store.dispatch('fetchURLrequest')

      const data = new FormData();
      data.append('nome', this.nome)
      data.append('ativo', 'true')
      this.sucesso = false
      await axios.post('produtos/tipo-uso-consumo/', data, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            console.log(response.data.results);
            this.sucesso = true
          })
          .catch(error => {
            console.log(error);
          })

    },


  }

}
</script>

<style lang="scss">

</style>