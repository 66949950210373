<template>
  <div class="container-formulario">

    <!-- Cabeçalho lista de pessoas depois de 768 pixels-->
    <div class="container-cabecalho flex justify-between mb-4">
      <div>
        <h1 class="text-xl text-corBase font-bold">Reservas</h1>
      </div>

      <router-link to="/estoque" class="text-xl text-corBase font-bold">
        <i class="fas fa-chevron-left"></i>
      </router-link>

    </div>

    <div class="flex items-center gap-3 mb-10">

      <div class="flex gap-8">
        <div>
          <button @click.prevent="getListaReservas" class="bg-corBase hover:bg-screenCor border border-screenCor hover:border-corBase
  text-white hover:text-corBase py-2 px-4">Pesquisar
          </button>
        </div>
      </div>
      <div>
        <button
          @click="$router.push('/estoque/reservas/adicionar')" 
          class="bg-ativo hover:bg-screenCor border border-screenCor hover:border-ativo
  text-white hover:text-ativo py-2 px-4 ">Adicionar
        </button>
      </div>
    </div>

    <!-- Container da exibiçao da lista de pessoas -->
    <div class="container-modelo-tabela">
      <table class="container-tabela overflow-auto">
        <thead class="cabecalho-tabela">
        <tr>
          <th scope="col" class="p-4">
            <div class="flex items-center">
              <input type="checkbox" class="checkbox" v-model="checkAll" @change="selectAll">

              <label class="sr-only">checkbox</label>
            </div>
          </th>
          <th scope="col" class="px-6 py-3">
            #
          </th>
          <th scope="col" class="px-10 py-3" v-if="isadmin === true">
            Ação
          </th>
          <th scope="col" class="px-10 py-3">
            Título
          </th>
          <th scope="col" class="px-10 py-3">
            Data
          </th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(reserva, index) in listaReservas" :key="index" class="escopo-tabela">

          <td class="w-4 p-4">
            <div class="flex items-center">
              <input :id="'checkbox-table-' + (index + 2)" type="checkbox" class="checkbox"
                     v-model="reserva.selected">
              <label class="sr-only">checkbox</label>
            </div>
          </td>
          <th scope="row"
              class="px-6 py-2 font-medium text-xs text-gray-900 whitespace-nowrap dark:text-white">
            {{ index + 1 }}
          </th>
          <td class="px-6 py-2 text-xs">
            {{ reserva.titulo_reserva }}
          </td>
          <td class="px-6 py-2 text-xs">
            {{ reserva.data_reserva }}
          </td>
          <td class="px-6 py-2 text-xs">
            <router-link :to="{ name: 'reserva', params: {id: reserva.id}}">
              <button class="bg-corBase hover:bg-screenCor border border-screenCor hover:border-corBase
              text-white hover:text-corBase py-2 px-8">
                Abrir
              </button>
            </router-link>
          </td>

        </tr>
        </tbody>
      </table>

      <div class="paginacao">
        <button @click.prevent="paginaAnterior" class="btn-paginacao">
          <i class="fas fa-chevron-left"></i>
        </button>
        <template v-for="pagina in paginasExibidas" :key="pagina">
      <span
          @click.prevent="irParaPagina(pagina)"
          :class="{'numero-paginacao': true, 'numero-paginacao-ativa': pagina === paginaAtual}"
      >
        {{ pagina }}
      </span>
        </template>
        <button @click.prevent="proximaPagina" class="btn-paginacao">
          <i class="fas fa-chevron-right"></i>
        </button>
      </div>
    </div>
  </div>

</template>

<script>
import axios from "axios";

export default {
  name: "ReservaDeEstoqueComponent",

  data() {
    return {
      listaReservas: [],
      listaReservasPaginada: [],
      paginaAtual: 1,
      itemsPorPagina: 5,
      checkAll: false,

    }
  },

  computed: {
    totalPaginas() {
      return Math.ceil(this.listaReservas.length / this.itemsPorPagina);
    },
    minhaEmpresa() {
      return this.$store.state.minhaEmpresa;
    },

    isadmin() {
      return this.$store.state.isAdminUser
    },

    paginasExibidas() {
      const paginas = [];
      const paginasVisiveis = 5; // Número de páginas visíveis de cada vez

      let inicio = Math.max(1, this.paginaAtual - Math.floor(paginasVisiveis / 2));
      let fim = inicio + paginasVisiveis - 1;

      if (inicio + paginasVisiveis > this.totalPaginas) {
        inicio = Math.max(1, this.totalPaginas - paginasVisiveis + 1);
        fim = this.totalPaginas;
      }

      for (let i = inicio; i <= fim; i++) {
        paginas.push(i);
      }

      return paginas;
    },
  },

  created() {
    this.getListaReservas()
  },

  methods: {
    irParaPagina(pagina) {
      console.log("esta é a pagina" + pagina)
      if (pagina >= 1 && pagina <= this.totalPaginas) {
        this.paginaAtual = pagina;
        this.paginarTabela();
      }
    },
    selectAll() {
      // Atualiza o estado dos demais checkboxes
      const checkboxes = document.querySelectorAll('.escopo-tabela input[type="checkbox"]');
      checkboxes.forEach((checkbox) => {
        checkbox.checked = this.checkAll;
      });
    },
    paginaAnterior() {
      if (this.paginaAtual > 1) {
        this.paginaAtual--;
        this.paginarTabela();
      }
    },
    proximaPagina() {
      if (this.paginaAtual < this.totalPaginas) {
        this.paginaAtual++;
        this.paginarTabela();
      }
    },

    // exibe apenas 5 items da tabela e salva o resto
    paginarTabela() {
      const startIndex = (this.paginaAtual - 1) * this.itemsPorPagina;
      const endIndex = startIndex + this.itemsPorPagina;
      this.listaReservasPaginada = this.listaReservas.slice(startIndex, endIndex);
    },

    async getListaReservas() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get('/produtos/reserva-de-estoque/', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            this.listaReservas = response.data.results
            console.log(this.listaReservas)
          })
          .catch(error => {
            console.log(error)
          })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>