<template>
  <footer  class=" ">
    <!--Div da logo e redes sociais-->
    <div class="rodape">
      <div class="col-span-6 pt-0">
        <div class="flex gap-3 md:pl-6">
          <div class="w-12 -mt-4">
            <a href="#">
            <img src="../../assets/imagens/logo.png">
            </a>
          </div>
          <div class="w-48">
            <a href="#">
            <img src="../../assets/imagens/tipografia.png">
            </a>
          </div>
        </div>
      </div>
      <div class="col-span-6 grid-cols-6 md:col-span-1 md:col-start-1 pt-4 pb-8 w-full h-full md:pl-6">
        <ul class="flex flex-row md:flex-col space-x-4 md:space-x-0 md:space-y-2">
        <li class="icones"><a href="#"><i class="fab fa-facebook"></i></a></li>
        <li class="icones"><a href="#"><i class="fab fa-instagram"></i></a></li>
        <li class="icones"><a href="#"><i class="fab fa-linkedin"></i></a></li>
        </ul>
      </div>
        <div class="conteudo_geral ">
          <h3 class="titulo">Título</h3>
          <div class="linha_titulo"></div>
          <ul class="flex flex-col">
            <li class="conteudo">
              <a href="#">Conteudo</a>
            </li>
            <li class="conteudo">
              <a href="#">Conteudos</a>
            </li>
            <li class="conteudo">
              <a href="#">Conteudo</a>
            </li>
            <li class="conteudo">
              <a href="#">Conteudo</a>
            </li>
          </ul>
        </div>
        <div class="conteudo_geral ">
          <h3 class="titulo">Título</h3>
          <div class="linha_titulo"></div>
          <ul class="flex flex-col">
            <li class="conteudo">
              <a href="#">Conteudo</a>
            </li>
            <li class="conteudo">
              <a href="#">Conteudos</a>
            </li>
            <li class="conteudo">
              <a href="#">Conteudo</a>
            </li>
            <li class="conteudo">
              <a href="#">Conteudo</a>
            </li>
          </ul>
        </div>
        <div class="conteudo_geral ">
          <h3 class="titulo">Título</h3>
          <div class="linha_titulo"></div>
          <ul class="flex flex-col">
            <li class="conteudo">
              <a href="#">Conteudo</a>
            </li>
            <li class="conteudo">
              <a href="#">Conteudos</a>
            </li>
            <li class="conteudo">
              <a href="#">Conteudo</a>
            </li>
            <li class="conteudo">
              <a href="#">Conteudo</a>
            </li>
          </ul>
        </div>
        <div class="conteudo_geral ">
          <h3 class="titulo">Título</h3>
          <div class="linha_titulo"></div>
          <ul class="flex flex-col">
            <li class="conteudo">
              <a href="#">Conteudo</a>
            </li>
            <li class="conteudo">
              <a href="#">Conteudos</a>
            </li>
            <li class="conteudo">
              <a href="#">Conteudo</a>
            </li>
            <li class="conteudo">
              <a href="#">Conteudo</a>
            </li>
          </ul>
        </div>
        <div class="conteudo_geral ">
          <h3 class="titulo">Título</h3>
          <div class="linha_titulo"></div>
          <ul class="flex flex-col">
            <li class="conteudo">
              <a href="#">Conteudo</a>
            </li>
            <li class="conteudo">
              <a href="#">Conteudos</a>
            </li>
            <li class="conteudo">
              <a href="#">Conteudo</a>
            </li>
            <li class="conteudo">
              <a href="#">Conteudo</a>
            </li>
          </ul>
        </div>
      
      <div class="col-span-6 pt-2 -mx-10 border-t-2 text-center border-gray-400 text-screenCor">
        <p>&copy;ManagerGlass</p>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Rodape"
}
</script>

<style scoped>

.rodape{
  @apply md:grid md:grid-cols-6 bg-[#164E63] md:grid-cols-6 px-10 pb-4 pt-10 flex flex-col
}

.titulo{
  @apply uppercase font-bold mb-5 text-xs tracking-widest text-screenCor
}

.conteudo{
  @apply mb-2 transition w-10 hover:translate-x-2 hover:duration-500 text-screenCor
}
.conteudo_geral{
  @apply md:col-span-1 pt-7 md:pt-7 md:pl-3
}

.icones{
  @apply flex flex-row justify-center items-center my-2 text-2xl bg-gray-400 w-12 h-12 rounded-full text-screenCor hover:bg-slate-300 hover:translate-x-1.5 transition duration-500
}

.linha_titulo{
  @apply -mt-4 mb-2.5 h-[1px] bg-red-500 w-8
}
</style>