<template>

  <section class=" flex justify-center md:block md:justify-end md:mr-10">

    <div class="container-formulario">
      <!-- TILO DO FORMULARIO + BOTAO DE VOLTAR -->
      <div class="cabecalho-formulario">
        <h1 class="text-xl text-corBase font-bold">Alterar Projeto:</h1>

        <router-link 
          class="btn-salvar"
          :to="'/incluir-item-orcamento/' + projeto_id"
        >
          Validar
        </router-link>

        <router-link to="/lista-projetos" class="text-xl text-corBase font-bold">
          <i class="fas fa-chevron-left"></i>
        </router-link>
      </div>

      <navgecacao-projeto @navegar-opcoes="navegarOpcoes"/>

      <template v-if="opcoes.dados === true">
        <div>
          <projeto-dados-compornent :dadosProjeto="dadosProjeto"/>
        </div>
      </template>

      <template v-if="opcoes.formulas_variaveis === true">
        <div class="flex w-full items-center justify-start mt-8 p-4 gap-3">
          <button class="px-2 py-1 bg-corBase text-white"
                  @click.prevent="navegarOpcoesFormulasVariaveis('formulas')"
          >
            Formulas
          </button>
          <button class="px-2 py-1 bg-corBase text-white"
                  @click.prevent="navegarOpcoesFormulasVariaveis('variaveis')"
          >Varaveis
          </button>
        </div>
        <template v-if="formulas === true">
          <formula-projeto 
            :lista_formulas="lista_formulas"
            :projeto_id="projeto_id"
            @atualizarFormulas="putListaFormulasProjeto"
            @getListaFormulasProjeto="getListaFormulasProjeto"
          />
        </template>

        <template v-if="variaveis === true">
          <variaveis-projeto 
            :lista_variaveis_locais="lista_variaveis_locais" 
            :projeto_id="projeto_id"
            @atualizarVariaveis="putlistaVariaveis"
            @getListaVariaveisLocais="getListaVariaveisLocais"
          />
        </template>
      </template>

      <template v-if="opcoes.perfis === true">
        <perfil-projeto 
          :perfis="perfis"
          :projeto_id="projeto_id"
          @atualizarPerfis="putListaPerfis"
          @getListaPerfisProjeto="getListaPerfisProjeto"
        />
      </template>

      <template v-if="opcoes.vidros === true">
        <div>
          <vidro-projeto 
            :vidros="vidros" 
            :projeto_id="projeto_id"
            @atualizarVidros="putListaVidros"
            @getListaVidrosProjeto="getListaVidrosProjeto"
          />
        </div>
      </template>

      <template v-if="opcoes.componentes === true">
        <div>
          <componente-projeto 
            :componentes="componentes" 
            :projeto_id="projeto_id"
            @atualizarComponentes="putListaComponentes"
            @getListaComponentesProjeto="getListaComponentesProjeto"  
          />
        </div>
      </template>

      <template v-if="opcoes.servicos === true">
        <div>
          <servico-projeto 
            :servicos="servicos" 
            :projeto_id="projeto_id"
            @atualizarServicos="putListaServicos"
          />
        </div>
      </template>

    </div>

  </section>

  <SucessoRapido v-if="sucesso" mensagem="Dados do projeto atualizados com sucesso"/>

</template>

<script>

import Select2 from "vue3-select2-component";
import axios from "axios";
import ModalFormulaProjeto from "@/components/midais/alterar_projeto/ModalFormulaProjeto";
import ProjetoDadosCompornent from "@/components/cadastros_projetos_componentes/alterar_projeto/ProjetoDadosCompornent";
import NavgecacaoProjeto from "@/components/cadastros_projetos_componentes/alterar_projeto/NavgecacaoProjeto";
import FormulaProjeto from "@/components/cadastros_projetos_componentes/alterar_projeto/FormulaProjeto";
import VariaveisProjeto from "@/components/cadastros_projetos_componentes/alterar_projeto/VariaveisProjeto";
import PerfilProjeto from "@/components/cadastros_projetos_componentes/alterar_projeto/PerfilProjeto";
import VidroProjeto from "@/components/cadastros_projetos_componentes/alterar_projeto/VidroProjeto";
import ComponenteProjeto from "@/components/cadastros_projetos_componentes/alterar_projeto/ComponenteProjeto";
import ServicoProjeto from "@/components/cadastros_projetos_componentes/alterar_projeto/ServicoProjeto";
import SucessoRapido from "@/components/toasts/toast_sucesso/SucessoRapido.vue";

export default {
  name: "AlterarProjeto",

  components: {
    Select2,
    ModalFormulaProjeto,
    // componente navegacao
    NavgecacaoProjeto,
    // componentes de cada aba
    ProjetoDadosCompornent,
    FormulaProjeto,
    VariaveisProjeto,
    PerfilProjeto,
    VidroProjeto,
    ComponenteProjeto,
    ServicoProjeto,
    SucessoRapido
  },

  data() {
    return {
      // variaveis dados dos produtos
      dadosProjeto: '',
      tipologia: '',
      cod_produto: '',
      descricao_fixa: '',
      descricao_variavel: '',
      unidade: '',
      ncm: '',
      lib_venda: '',
      kit: '',
      kit_cor_vidro: '',
      kit_cor_perfil: '',
      kit_cor_acessorio: '',
      cfop_saida: '',
      cfop_fora_uf: '',
      temp_producao: '',
      temp_instalacao: '',
      status_projeto: '',
      perfis: [],
      vidros: [],
      servicos: [],
      componentes: [],
      altura_minima: '',
      altura_maxima: '',
      largura_minima: '',
      largura_maxima: '',
      espessuras: [],

      opcoes: [],

      formulas: false,
      variaveis: false,

      // variaveis para salvar as listas
      lista_formulas: [],
      lista_variaveis_locais: [],
      lista_tipologias: [],
      lista_componentes: [],
      lista_perfis: [],
      lista_vidros: [],
      lista_servicos: [],
      lista_espessuras: [],

      // variaveis para dados do produto
      activeSection: 1,
      checkAll: false,
      sucesso: false

    }
  },


  created() {

    // faz as requisições das listas e projeto para poder usar nas alterações
    this.getDadosProjeto()
    this.getListaPerfisProjeto()
    this.getListaVidrosProjeto()
    this.getListaComponentesProjeto()
    this.getListaStatus()
    this.getListaTipologia()
  },

  computed: {

    // pega o id passado como parametro na rota
    projeto_id() {
      return this.$route.params.id
    },

    // pega a empresa que esta logada de dentro da store
    minhaEmpresa() {
      return this.$store.state.minhaEmpresa;
    },
  },

  watch: {
    sucesso(){
      if(this.sucesso) {
        setTimeout(() => {
          this.sucesso = false
        }, 2000)
      }
    }
  },


  methods: {

    // evento que é ativado pelo filho
    navegarOpcoes(nomeVariavel) {
      for (const key in this.opcoes) {
        if (key !== nomeVariavel) {
          this.opcoes[key] = false; // Define todas as opções como false
        }
      }
      this.opcoes[nomeVariavel] = !this.opcoes[nomeVariavel]; // Alterna a opção selecionada
    },

    // funcao para navegar nos botos dentro de formulas/variaveis
    navegarOpcoesFormulasVariaveis(variavel) {
      console.log('testado')
      this.formulas = false
      this.variaveis = false
      if (variavel == 'formulas') {
        this[variavel] = !this[variavel];
        this.getListaFormulasProjeto()
      }
      if (variavel == 'variaveis') {
        this[variavel] = !this[variavel];
        this.getListaVariaveisLocais()
      }

    },

    // seleciona todos os items das listas
    selectAll() {
      // Atualiza o estado dos demais checkboxes
      const checkboxes = document.querySelectorAll('.escopo-tabela input[type="checkbox"]');
      checkboxes.forEach((checkbox) => {
        checkbox.checked = this.checkAll;
      });
    },

    async getDadosProjeto() {
      try {
        const token = await this.$store.dispatch('getAuthTokenCookie')
        await this.$store.dispatch('fetchURLrequest')

        const response = await axios.get(`projeto/projeto/${this.projeto_id}`, {
          headers: {
            Authorization: `Token ${token}`
          }
        })

        this.dadosProjeto = response.data
      }catch(error) {
        console.error(error)
      }
    },

    // pesquisas no banco
    // busca a lista de formulas relacionadas ao projeto
    async getListaFormulasProjeto() {
      const token = await this.$store.dispatch('getAuthTokenCookie')

      await this.$store.dispatch('fetchURLrequest')

      await axios.get(`projeto/formula-projeto/?projeto=${this.projeto_id}&page_size=999`, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            this.lista_formulas = response.data.results
          })
          .catch(error => {
            console.log(error)
          })

    },

    // altera formulas relacionadas ao projeto
    async putListaFormulasProjeto() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      const data = this.lista_formulas.map((formula) => {
        formula.projeto ? formula.projeto = this.projeto_id : ''

        return formula
      })

      await axios.put(`projeto/formula-projeto-edit/bulk-update/`, data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            this.getListaFormulasProjeto()
            this.sucesso = true
          })
          .catch(error => {
            console.log(error)
          })

    },

    // busca a lista de variaveis relacionadas ao projeto
    async getListaVariaveisLocais() {
      const token = await this.$store.dispatch('getAuthTokenCookie')

      await this.$store.dispatch('fetchURLrequest')

      console.log(this.projeto)
      await axios.get(`projeto/variavel-local/?projeto=${this.projeto_id}&page_size=999`, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            this.lista_variaveis_locais = response.data.results
          })
          .catch(error => {
            console.log(error)
          })

    },

    async putlistaVariaveis(lista_variaveis_locais) {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      const data = this.lista_variaveis_locais.map(variavel => {
        variavel.projeto ? variavel.projeto = this.projeto_id : ''

        return variavel
      })

      await axios.put(`projeto/variavel-local-bulk-update/`, data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            this.getListaVariaveisLocais()
            this.sucesso = true
          })
          .catch(error => {
            console.log(error)
          })

    },

    // busca as tipologias
    async getListaTipologia() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get('projeto/tipologia', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            this.lista_tipologias = response.data.results.map(item => ({
              id: item.id,
              text: item.nome
            }));
            console.log(this.lista_tipologias)
          })
          .catch(error => {
            console.log(error)
          })

    },

    // atualiza os perfeis do projeto
    async putListaPerfis() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      const data = this.perfis.map((perfil) => {
        perfil.perfil_id ? perfil.perfil_id = perfil.perfil_id.id : ''
        perfil.projeto_id ? perfil.projeto_id = this.projeto_id : ''

        return perfil
      })

      await axios.put(`projeto/projeto-perfil/bulk-update/`, data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            this.getListaPerfisProjeto()
            this.sucesso = true
          })
          .catch(error => {
            console.log(error)
          })

    },

    // atualiza os vidros do projeto
    async putListaVidros() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      const data = this.lista_vidros
      console.log(data)

      await axios.put(`projeto/projeto-vidro/bulk-update/`, data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            this.getListaVidrosProjeto()
            this.sucesso = true
          })
          .catch(error => {
            console.log(error)
          })

    },

    // atualiza os componentes do projeto
    async putListaComponentes() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      const data = this.componentes.map(componente => {
        componente.componente_id ? componente.componente_id = componente.componente_id.id : ''
        componente.projeto_id ? componente.projeto_id = this.projeto_id : ''

        return componente
      })

      await axios.put(`projeto/projeto-componente/bulk-update/`, data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            this.getListaComponentesProjeto()
            this.sucesso = true
          })
          .catch(error => {
            console.log(error)
          })

    },

    // atualiza os servico do projeto
    async putListaServicos() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      const data = this.lista_servicos
      console.log(data)

      await axios.put(`projeto/projeto-servico/bulk-update/`, data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            this.getListaServicosProjeto()
            this.sucesso = true
          })
          .catch(error => {
            console.log(error)
          })

    },


    // busca as listas
    async getListaStatus() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get('projeto/status-projeto', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            this.lista_status = response.data.results.map(item => ({
              id: item.id,
              text: item.descricao
            }));
            console.log(this.lista_status)
          })
          .catch(error => {
            console.log(error)
          })
    },

    async getListaPerfisCompleta() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get('produtos/perfil', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            this.lista_perfis = response.data.results.map(item => ({
              id: item.id,
              text: item.nome_especifico
            }));
            console.log(this.lista_perfis)
          })
          .catch(error => {
            console.log(error)
          })
    },

    async getListaPerfisProjeto() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get(`projeto/projeto-perfil/?projeto_id=${this.projeto_id}&page_size=999`, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            this.perfis = response.data.results
          })
          .catch(error => {
            console.log(error)
          })
    },

    async getListaVidrosCompleta() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get('produtos/vidro', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            this.lista_vidros = response.data.results.map(item => ({
              id: item.id,
              text: item.vidro_base.nome_especifico
            }));
            console.log(this.lista_vidros)
          })
          .catch(error => {
            console.log(error)
          })
    },

    async getListaVidrosProjeto() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get(`projeto/projeto-vidro/?projeto_id=${this.projeto_id}`, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            this.vidros = response.data.results
          })
          .catch(error => {
            console.log(error)
          })
    },

    async getListaComponentesCompleta() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get('produtos/componente-acessorio', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            this.lista_componentes = response.data.results.map(item => ({
              id: item.id,
              text: item.nome_especifico
            }));
            console.log(this.lista_componentes)
          })
          .catch(error => {
            console.log(error)
          })
    },

    async getListaComponentesProjeto() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get(`projeto/projeto-componente/?projeto_id=${this.projeto_id}&page_size=999`, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            this.componentes = response.data.results
            console.log(this.componentes)
          })
          .catch(error => {
            console.log(error)
          })
    },

    async getListaServicosCompleta() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get('produtos/servico', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            this.lista_servicos = response.data.results.map(item => ({
              id: item.id,
              text: item.nome_especifico
            }));
            console.log(this.lista_servicos)
          })
          .catch(error => {
            console.log(error)
          })
    },

    async getListaServicosProjeto() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get(`produtos/servico/?projeto=${this.projeto_id}`, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            this.servicos = response.data.results
          })
          .catch(error => {
            console.log(error)
          })
    },

    async getListaEspessurasCompleta() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get('produtos/espessura', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            this.lista_espessuras = response.data.results.map(item => ({
              id: item.id,
              text: item.espessura
            }));
            console.log(this.lista_espessuras)
          })
          .catch(error => {
            console.log(error)
          })
    },

  }

}


</script>


<style lang="scss">

.nav-principal-projetos {
  @apply md:block hidden md:flex md:w-full md:overflow-auto
}

.btn-nav-projeto-inativo {
  @apply md:flex md:items-center md:px-2 md:py-1 md:text-xs md:w-44 md:text-center md:justify-center
}

.btn-nav-projeto-ativo {
  @apply md:flex md:items-center md:px-2 md:py-1 md:border-x md:border-corBase md:border-t  md:text-xs md:w-44 md:text-center md:justify-center
}

// style mobile
.nav-principal-projetos-mobile {
  @apply flex gap-4 w-full flex-wrap mt-8
}

.btn-nav-projeto-mobile {
  @apply flex items-center bg-corBase/10 text-corBase px-1 py-0.5
}

</style>