<template>

  <div class="container-formulario">

    <!-- Cabeçalho lista de pessoas depois de 768 pixels-->
    <div class="container-cabecalho flex justify-between mb-4">
      <div>
        <h1 class="text-xl text-corBase font-bold">Lista de Pessoas Juridicas</h1>
      </div>

      <router-link to="/pessoas" class="text-xl text-corBase font-bold">
        <i class="fas fa-chevron-left"></i>
      </router-link>

    </div>
    <div class="flex gap-8 items-end mb-8">
      <div class="w-full">
        <label class="text-xl text-corBase font-bold">Nome ou Razão:</label>
        <input type="text" v-model="nome_razao" class="input-campos">
      </div>
      <div class="w-full">
        <label class="text-xl text-corBase font-bold">Nome Fantasia:</label>
        <input type="text" v-model="nome_fantasia" class="input-campos">
      </div>
      <div class="w-full">
        <label class="text-xl text-corBase font-bold">Tipo Pessoa:</label>
        <Select2 v-model="tipo_pessoa"
                 class="w-full z-10"
                 :options="lista_tipo_pessoa"
                 :settings="{ width: '100%'}"
        />
      </div>
      <div class="w-full">
        <label class="text-xl text-corBase font-bold">Status:</label>
        <input type="text" v-model="status" class="input-campos">
      </div>
      <div class="w-full">
        <button class="bg-corBase hover:bg-screenCor border border-screenCor hover:border-corBase
  text-white hover:text-corBase py-2 px-4" @click="getListaPessoas">Pesquisar
        </button>
      </div>
    </div>
    <div class="mb-8">
      <button @click="$router.push(`/pessoa/pessoa-juridica`)" class="bg-ativo hover:bg-screenCor border border-screenCor hover:border-ativo
  text-white hover:text-ativo py-2 px-4 mt-2 mr-2">Adicionar
      </button>
    </div>

    <!-- Container da exibiçao da lista de pessoas -->
    <div class="container-modelo-tabela">
      <table class="container-tabela">
        <thead class="cabecalho-tabela">
        <tr>
          <th scope="col" class="p-4">
            <div class="flex items-center">
              <input type="checkbox" class="checkbox" v-model="checkAll" @change="selectAll">

              <label class="sr-only">checkbox</label>
            </div>
          </th>
          <th scope="col" class="px-6 py-3">
            #
          </th>
          <th class="px-6 py-3">
            Razão
          </th>
          <th class="px-6 py-3">
            Nome Fantasia
          </th>
          <th class="px-6 py-3">
            CNPJ
          </th>
          <th scope="col" class="px-6 py-3">
            Status
          </th>
          <th scope="col" class="px-6 py-3">
            Ações
          </th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(pessoa, index) in listaPessoasJuridicaPaginada" :key="index" class="escopo-tabela">
          <template v-if="pessoa.pessoa_juridica !== null ">
            <td class="w-4 p-4">
              <div class="flex items-center">
                <input :id="'checkbox-table-' + (index + 2)" type="checkbox" class="checkbox" v-model="pessoa.selected">
                <label class="sr-only">checkbox</label>
              </div>
            </td>
            <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
              {{ index + 1 }}
            </th>
            <td class="px-6 py-4">
              {{ pessoa.nome_razao }}
            </td>
            <td class="px-6 py-4">
              {{ pessoa.pessoa_juridica.nome_fantasia }}
            </td>
            <template v-if="pessoa.pessoa_juridica !== null">
              <td class="px-6 py-4">
                {{ pessoa.pessoa_juridica.cnpj }}
              </td>
            </template>
            <template v-if="pessoa.pessoa_juridica !== null">
              <template v-if="pessoa.pessoa_juridica.ativo === true">
                <td class="px-6 py-4">
                  <i class="fa-solid fa-circle-check  text-ativo  ml-3"></i>
                </td>
              </template>
              <template v-if="pessoa.pessoa_juridica.ativo === false">
                <td class="px-6 py-4">
                  <i class="fa-solid fa-circle-xmark text-inativo  ml-3"></i>
                </td>
              </template>
            </template>

            <td class="px-6 py-4">
              <editar-dados-pessoa-juridica :dados_pessoa_juridica="pessoa"></editar-dados-pessoa-juridica>
            </td>
          </template>

        </tr>
        </tbody>
      </table>
      <div class="paginacao">
        <button @click.prevent="paginaAnterior" class="btn-paginacao"><i class="fas fa-chevron-left"></i></button>
        <template v-for="pagina in totalPaginas" :key="pagina">
          <span @click.prevent="irParaPagina(pagina)"
                :class="{'numero-paginacao': true, 'numero-paginacao-ativa': pagina === paginaAtual}">{{
              pagina
            }}</span>
        </template>
        <button @click="proximaPagina" class="btn-paginacao"><i class="fas fa-chevron-right"></i></button>
      </div>
    </div>
  </div>

</template>

<script>
import axios from "axios";
import EditarDadosPessoaJuridica from "@/components/midais/Editar/EditarDadosPessoaJuridica";
import Select2 from "vue3-select2-component";

export default {
  name: "ListaPessoaJuridica",

  components: {
    EditarDadosPessoaJuridica,
    Select2
  },

  data() {
    return {
      checkAll: false,
      listaPessoas: [],
      lista_tipo_pessoa: [],
      listaPessoasJuridicaPaginada: [],
      paginaAtual: 1,
      nome_razao: '',
      nome_fantasia: '',
      tipo_pessoa: '',
      status: '',
    }
  },

  created() {
    this.getListaTipoPessoa()
  },

  computed: {
    totalPaginas() {
      return Math.ceil(this.listaPessoas.length / 5);
    },
    isadmin() {
      return this.$store.state.isAdminUser
    },
    minhaEmpresa() {
      return this.$store.state.minhaEmpresa;
    },
  },

  methods: {

    selectAll() {
      const checkboxes = document.querySelectorAll('.escopo-tabela input[type="checkbox"]');
      checkboxes.forEach((checkbox) => {
        checkbox.checked = this.checkAll;
      });
    },

    paginaAnterior() {
      if (this.paginaAtual > 1) {
        this.paginaAtual--;
        this.paginarTabela();
      }
    },

    proximaPagina() {
      if (this.paginaAtual < this.totalPaginas) {
        this.paginaAtual++;
        this.paginarTabela();
      }
    },

    paginarTabela() {
      const itemsPerPage = 5;
      const startIndex = (this.paginaAtual - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      this.listaPessoasJuridicaPaginada = this.listaPessoas.slice(startIndex, endIndex);
    },

    irParaPagina(pagina) {
      if (pagina >= 1 && pagina <= this.totalPaginas) {
        this.paginaAtual = pagina;
        this.paginarTabela();
      }
    },

    // busca a lista de pessoas cadastrada no banco de dados
    async getListaPessoas() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      this.$store.dispatch("setLoading", true);
      axios.get(`/pessoas/juridicas/?nome_razao=${this.nome_razao}&nome_fantasia=${this.nome_fantasia}&tipo_pessoa=${this.tipo_pessoa}&ativo=${this.status}`, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            this.listaPessoas = response.data;
            this.paginaAtual = 1;
            this.paginarTabela();
            this.$store.dispatch("setLoading", false);
            console.log(this.listaPessoas)
          })
          .catch(error => {
            console.log(error)
            this.$store.dispatch("setLoading", false);
          })
    },

    async getListaTipoPessoa() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get('pessoas/tipo-pessoas', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            this.lista_tipo_pessoa = response.data.map(item => ({
              id: item.id,
              text: item.nome
            }))
          })
          .catch(error => {
            console.log(error)
          })

    }
  }
}
</script>

<style scoped>

</style>