<template>

  <div class="container-formulario">
    <div class="cabecalho-formulario">
      <h1 class="text-xl font-bold text-corBase">Importação de Perfis:</h1>

      <router-link to="/produtos/perfil" class="text-xl text-corBase font-bold">
        <i class="fas fa-chevron-left"></i>
      </router-link>
    </div>

    <importarcao-perfil />


  </div>

</template>

<script>
import ImportarcaoPerfil from "@/components/midais/importacoes/ImportarcaoPerfil";
export default {
  name: "ImportacaoPerfilPage",
  components: {
    ImportarcaoPerfil
  }
}
</script>

<style scoped lang="scss">

</style>