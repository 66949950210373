<template>

  <div class="container-formulario">
    <galeria v-if="galeria === true" @fechar_galeria="abrir_galeria" @objetoClicado="atualizarFileInput"/>
    <!-- TILO DO FORMULARIO + BOTAO DE VOLTAR -->
    <div class="cabecalho-formulario">
      <h1 class="text-xl text-corBase font-bold">Dados Vidro:</h1>

      <router-link to="/produtos/vidro" class="text-xl text-corBase font-bold">
        <i class="fas fa-chevron-left"></i>
      </router-link>
    </div>

    <!-- Escopo do formulario de cadastro mobile-->
    <form @submit.prevent="cadastrarVidro" enctype="multipart/form-data">
      <!--        PARTE DE DADOS DO PRODUTO-->

      <template class="flex flex-col gap-3 my-3 w-full">

        <div class="div-produtos">

          <div class="flex flex-col w-[40vw]">
            <label class="text-corBase text-xl font-bold">Adicione uma imagem:</label>
            <div class="imagePreviewWrapper" :style="{ 'background-image': `url(${previewImage.url_documento})` }">
            </div>
            <div class="flex gap-3 mt-4">
              <button class="selecionar-arquivo" @click.prevent="abrir_galeria">
                Pesquisar
              </button>

            </div>

          </div>


          <div class="flex flex-col w-full gap-[14px] ml-4 mr-1">
            <div class="campo-chapa">
              <label class="text-xl font-bold text-corBase">Nome Especifico:</label>
              <input type="text" v-model="nome_especifico" class="input-form-chapa" required autofocus>
            </div>
            <div class="div-produtos ">
              <div class="flex flex-col gap-1 w-full">
                <label class="text-xl font-bold text-corBase">Produto Base:</label>
                <Select2 v-model="produto_base"
                         class="w-full z-10"
                         :options="lista_produto_por_fornecedor"
                         :settings="{ width: '100%'}"
                />
              </div>
            </div>

            <div class="flex gap-3 w-full mt-3">
              <div class="campo-chapa">
                <label>Tipo vidro:</label>
                <Select2 v-model="tipo_vidro"
                         class="w-full z-10"
                         :options="lista_tipo_vidro"
                         :settings="{ width: '100%'}"
                />
              </div>
              <div class="campo-chapa">
                <label>Espessura:</label>
                <Select2 v-model="espessura"
                         class="w-full z-10"
                         :options="lista_espessura"
                         :settings="{ width: '100%'}"
                />
              </div>
            </div>

            <div class="flex gap-3 w-full mt-3">
              <div class="campo-chapa">
                <label>Cor Vidro:</label>
                <Select2 v-model="cor_vidro"
                         class="w-full z-10"
                         :options="lista_cor_vidro"
                         :settings="{ width: '100%'}"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="div-produtos">

          <div class="flex flex-col w-full gap-[12px] ml-4 mr-1">

            <div class="flex gap-3 w-full mt-3">
              <div class="campo-chapa">
                <label>Fator Solar:</label>
                <input type="number" class="input-form-chapa" v-model="fator_solar">
              </div>

              <div class="campo-chapa">
                <label>Transmitância Térmica:</label>
                <input type="number" class="input-form-chapa" v-model="transmitancia_termica">
              </div>

              <div class="campo-chapa">
                <label>Arredondamento:</label>
                <input type="number" class="input-form-chapa" v-model="arredondamento">
              </div>

              <div class="campo-chapa">
                <label>Metragem Minima:</label>
                <input type="number" class="input-form-chapa" v-model="metragem_minima">
              </div>
            </div>


          </div>

        </div>
        <div class="flex justify-between ">
          <div class="flex w-full justify-end">
            <button class="btn-enviar" type="submit">Salvar</button>
          </div>
        </div>
      </template>
    </form>

  </div>


  <mensagem-sucesso v-if="sucesso === true" mensagem="Vidro Cadastrado com Sucesso!"
                    rotas="/produtos/vidro"></mensagem-sucesso>


</template>

<script>
import axios from "axios";
import Select2 from "vue3-select2-component";
import MensagemSucesso from "@/components/toasts/toast_sucesso/MensagemSucesso";
import Galeria from "@/components/midais/ged/Galeria";

export default {
  name: "CadastroVidro",

  components: {
    Select2,
    MensagemSucesso,
    Galeria
  },

  data() {
    return {
      galeria: false,
      sucesso: false,
      previewImage: {},

      lista_produto_por_fornecedor: [],
      lista_cor_vidro: [],
      lista_tipo_vidro: [],
      lista_espessura: [],

      nome_especifico: '',
      produto_base: '',
      cor_vidro: '',
      tipo_vidro: '',
      espessura: '',
      fator_solar: '',
      transmitancia_termica: '',
      arredondamento: '',
      metragem_minima: '',

      proximo: false,

    }
  },

  created() {
    this.getFornecedor()
    this.getProdutoPorFornecedor()
    this.getEspessura()
    this.getCorVidro()
    this.getTipoVidro()
  },

  computed: {
    minhaEmpresa() {
      return this.$store.state.minhaEmpresa;
    },
  },

  methods: {
    atualizarFileInput(objeto) {
      console.log('objeto que chegou:' + objeto)
      console.log(objeto)
      this.previewImage = objeto; // ou a propriedade correta do objeto que deseja atribuir
    },
    abrir_galeria() {
      this.galeria = !this.galeria
    },
    trocar_de_pagina() {
      this.proximo = !this.proximo
    },

    handleImagemUpload(event) {
      this.fileInput = event.target.files[0];
    },

    async cadastrarVidro() {
      const token = await this.$store.dispatch('getAuthTokenCookie')

      await this.$store.dispatch('fetchURLrequest')

      const data = new FormData();
      data.append('ativo', 'True')
      data.append('nome_especifico', this.nome_especifico)
      data.append('vidro_base', this.produto_base)
      data.append('cor_vidro', this.cor_vidro)
      data.append('tipo_vidro', this.tipo_vidro)
      data.append('espessura', this.espessura)
      data.append('fator_solar', this.fator_solar)
      data.append('transmitancia_termica', this.transmitancia_termica)
      data.append('arredondamento', this.arredondamento)
      data.append('metragem_minima', this.metragem_minima)
      data.append('imagem', this.previewImage.id)

      this.$store.dispatch("setLoading", true);
      await axios.post('produtos/vidro/', data, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            console.log(response.data);
            this.sucesso = true
            this.$store.dispatch("setLoading", false);
          })
          .catch(error => {
            console.log(error);
            this.$store.dispatch("setLoading", false);
          })

    },


    async getProdutoPorFornecedor() {
      const token = await this.$store.dispatch('getAuthTokenCookie')

      await this.$store.dispatch('fetchURLrequest')

      await axios.get('produtos/produto-por-fornecedor', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            console.log(response.data)
            this.lista_produto_por_fornecedor = response.data.map(item => ({
              id: item.id,
              text: `${item.produto_base.nome_base} - ${item.produto_base.codigo_base}`,
              img: item.produto_base.imagem
            }))

          })
          .catch(error => {
            console.log(error)
          })

    },

    async getFornecedor() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get('pessoas/fornecedor', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            console.log(response.data)
            console.log("AQUI");
            this.lista_fornecedor = response.data.map(item => ({
              id: item.id,
              text: item.nome_razao
            }))
          })
          .catch(error => {
            console.log(error)
          })
    },

    async getCorVidro() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get('produtos/cor-vidro', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            console.log(response.data)
            this.lista_cor_vidro = response.data.map(item => ({
              id: item.id,
              text: item.nome
            }))
          })
          .catch(error => {
            console.log(error)
          })
    },

    async getTipoVidro() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get('produtos/tipo-vidro', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            console.log(response.data)
            this.lista_tipo_vidro = response.data.map(item => ({
              id: item.id,
              text: item.nome
            }))
          })
          .catch(error => {
            console.log(error)
          })
    },

    async getEspessura() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get('produtos/espessura', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            console.log(response.data)
            this.lista_espessura = response.data.map(item => ({
              id: item.id,
              text: item.espessura
            }))
          })
          .catch(error => {
            console.log(error)
          })
    },

  }
}
</script>

<style lang="scss">

</style>