<template>

  <div class="posicao-toast">
    <div class="container-toast">
      <i class="fas fa-circle-check icone-toast"></i>
      <h2 class="texto-toast">
        Pessoa Física cadastrada com sucesso!
      </h2>
    </div>
  </div>

</template>

<script>

export  default {
  name: 'SucessoCadastroPessoaFisica',

}
</script>

<style lang="scss">
.posicao-toast {
  @apply absolute right-0 bottom-0 m-5
}

.container-toast {
  @apply flex items-center bg-green-500 border-l-4 border-green-700 py-2 px-3 shadow-md mb-2
}

.texto-toast {
  @apply text-white max-w-xs
}

.icone-toast {
  @apply text-white mr-2
}
</style>