<template>
  <a @click="alterarModalEditar()" href="#" class="font-medium text-corBase hover:underline">
    <i class="fa-solid fa-pen-to-square mr-2"></i>Editar
  </a>
  <div v-if="statusModal">
    <div class="container-modal-editar">
      <div class="sombra-modal">
        <!--        <galeria v-if="showGaleria === true" @fechar_galeria="abrir_galeria" @objetoClicado="atualizarFileInput"/>-->
        <!--        <modal-add-imagem v-if="ged === true" @fechar_ged="addImageGed" @objetoClicado="atualizarFileInput"/>-->

        <div class="conteudo-modal-editar">
          <form class="form-editar text-white">
            <div class="flex justify-end">
              <button @click.prevent="alterarModalEditar()">
                <i class="fas fa-close text-2xl text-corBase"></i>
              </button>
            </div>
            <h2 class="text-lg text-corBase font-bold mb-2">Editar Produto</h2>
            <!--Campos da Pessoa-->

            <div class="flex flex-row gap-4">
              <!--              <div class="flex flex-col w-full md:w-[35%] mt-6">-->
              <!--                <label class="text-corBase text-xl font-bold">Adicione uma imagem:</label>-->
              <!--                <div class="imagePreviewWrapper" :style="{ 'background-image': `url(${produto.imagem.url_documento})` }"-->
              <!--                     @click.prevent="abrir_galeria">-->
              <!--                </div>-->
              <!--                <div class="flex gap-3 mt-4">-->
              <!--                  <button class="selecionar-arquivo" @click.prevent="addImageGed">Adicionar</button>-->
              <!--                  <button class="selecionar-arquivo" @click.prevent="abrir_galeria">Pesquisar</button>-->
              <!--                </div>-->

              <!--              </div>-->

              <div class="flex flex-col pt-5 w-full">
                <div>
                  <div class="container-campos">
                    <div class="campos campos-mobile label-campos-popup">
                      <label class="label-campos label-campos-popup">Nome Base:</label>
                      <input type="text" required class="input-campos" v-model="produto.nome_especifico">
                    </div>

                    <div class="campos campos-mobile label-campos-popup">
                      <label class="label-campos label-campos-popup">Código Base:</label>
                      <input type="text" required class="input-campos" v-model="produto.codigo_fornecedor">
                    </div>

                  </div>


                  <div class="flex flex-col mt-2 w-full ">
                    <label class="label-campos">Produto:</label>
                    <Select2 v-model="produto.produto_base.id"
                             class="w-full z-10"
                             :options="lista_produto_base"
                             :settings="{ width: '100%'}"
                    />
                  </div>

                  <div class="flex flex-col mt-2 w-full ">
                    <label class="label-campos">Fornecedor:</label>
                    <Select2 v-model="produto.fornecedor.id"
                             class="w-full z-10"
                             :options="lista_fornecedor"
                             :settings="{ width: '100%'}"
                    />
                  </div>

                </div>
                <!--Campos de endereço-->
                <div>

                  <div class="container-campos w-full">

                    <div class="flex flex-col mt-2 w-full justify-start ">
                      <label class="text-base text-corBase font-bold mt-2">Ativo:</label>

                      <span @click.prevent="toggleAtivo" class="text-start pt-1 text-xl">
                        <i v-if="produto.ativo === true" class="fa-solid fa-circle-check text-ativo"></i>
                        <i v-else class="fa-solid fa-circle-xmark text-inativo"></i>
                      </span>
                    </div>

                  </div>

                </div>


              </div>

            </div>


            <!--Botão-->
            <div class="btn-formulario-modal">
              <button class="btn-formulario-salvar" @click.prevent="putDadosEditado">
                Salvar
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Select2 from "vue3-select2-component";
import Galeria from "@/components/midais/ged/Galeria";
import ModalAddImagem from "@/components/ged/ModalAddImagem";

export default {
  name: "EditarProdutoFornecedor",

  components: {
    Select2,
    Galeria,
    ModalAddImagem
  },
  data() {
    return {
      statusModal: false,
      showGaleria: false,
      ged: false,
      lista_produto_base: [],
      lista_cores: [],
      lista_fornecedor: []

    }
  },
  props: ["produto", "paginaAtual"],
  created() {
    this.getListaProdutoBase()
    this.getListaFornecedor()

  },
  mounted() {
    window.addEventListener('keydown', this.onEscKeyPressed);

  },

  beforeDestroy() {
    window.removeEventListener('keydown', this.onEscKeyPressed);
  },

  watch: {
    statusModal(newStatus) {
      if (newStatus) {
        window.addEventListener('keydown', this.onEnterKeyPressed);
      } else {
        window.removeEventListener('keydown', this.onEnterKeyPressed);
      }
    }
  },
  methods: {
    toggleAtivo(event) {
      event.preventDefault()
      this.produto.ativo = !this.produto.ativo
    },

    alterarModalEditar() {
      this.statusModal = !this.statusModal;

      this.$parent.getListaProdutos()
    },

    onEscKeyPressed(event) {
      if (event.key === 'Escape') {
        this.$parent.getListaProdutos()
        this.statusModal = false
      }
    },

    onEnterKeyPressed(event) {
      if (event.key === 'Enter') {
        this.putDadosEditado()
      }
    },
    atualizarFileInput(objeto) {
      console.log('objeto que chegou:' + objeto)
      console.log(objeto)
      this.produto.imagem = objeto;
      console.log(this.produto.imagem)
    },

    addImageGed() {
      this.ged = !this.ged
    },

    abrir_galeria() {
      this.showGaleria = !this.showGaleria
    },


    async putDadosEditado() {
      const dadosAtualizados = new FormData();
      dadosAtualizados.append('ativo', this.produto.ativo)
      dadosAtualizados.append('nome_base', this.produto.nome_base)
      dadosAtualizados.append('codigo_base', this.produto.codigo_base)
      dadosAtualizados.append('codigo_mercado', this.produto.codigo_mercado)
      dadosAtualizados.append('codigo_auxiliar', this.produto.codigo_auxiliar)
      dadosAtualizados.append('peso_base', this.produto.peso_base)
      dadosAtualizados.append('tipo_produto', this.produto.tipo_produto.id)
      dadosAtualizados.append('cor_base', this.produto.cor_base.id)
      dadosAtualizados.append('unidade_medida', this.produto.unidade_medida.id)
      dadosAtualizados.append('imagem', this.produto.imagem.id)

      try {
        const token = await this.$store.dispatch('getAuthTokenCookie');
        await this.$store.dispatch('fetchURLrequest')
        const response = await axios.put(`produtos/produto/${this.produto.id}/`, dadosAtualizados, {
          headers: {
            'Authorization': `Token ${token}`,
            'Content-Type': 'application/json'
          }
        });
        console.log(response);
        this.$parent.irParaPagina(this.paginaAtual)
        this.statusModal = false;

      } catch (error) {
        console.log(error)
      }
    },

    async getListaProdutoBase(url = 'produtos/produto/', results = []) {
      try {
        const token = await this.$store.dispatch('getAuthTokenCookie');
        await this.$store.dispatch('fetchURLrequest')
        const response = await axios.get(url, {
          headers: {
            'Authorization': `Token ${token}`,
          }
        });
        results.push(...response.data.results);
        if (response.data.next) {
          await this.getListaFornecedor(response.data.next, results);
        } else {
          this.lista_produto_base = response.data.map(item => ({
            id: item.id,
            text: item.nome_base
          }));
        }
      } catch (error) {
        console.log(error)
      }
    },

    async getListaFornecedor(url = 'passoas/fornecedor/', results = []) {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      const response = await axios.get(url, {
        headers: {
          'Authorization': `Token ${token}`,
        }
      });
      results.push(...response.data.results);
      if (response.data.next) {
        await this.getListaFornecedor(response.data.next, results);
      } else {
        this.lista_fornecedor = response.data.map(item => ({
          id: item.id,
          text: item.pessoa.nome_razao
        }))
      }
    },


  }
}
</script>

<style>
.container-modal-editar {
  @apply fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full h-full
}

.conteudo-modal-editar {
  @apply bg-screenCor p-6 w-[75%]  shadow-xl shadow-screenCor/30 fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 h-[80vh] overflow-y-scroll scrollbar-thin scrollbar-thumb-screenCor/40
}

.form-editar {
  @apply text-corBase
}
</style>