<template>
   <div class="container-formulario">

    <div class="cabecalho-dashboard">
      <h1>Estoque</h1>

      <button @click="$router.push(`/dashboard`)" class="text-2xl">
        <i class="fas fa-chevron-left mr-1 "></i>
      </button>
    </div>

    <div class="flex flex-wrap">

      <button class="escopo-dashboard-desktop" @click="$router.push(`/estoque/lista-entrada-nota-fiscal`)">

        <div class="opcao-dashboard">
          <div class="cacebalho-opcao">
            <h2 class="titulo-opcao">Entrada Nota Fiscal</h2>
            <i class="fas fa-user"></i>
          </div>

          <div class="descricao-opcao">
            <p class="mt-6">Cadastre aqui as imagens usadas em seu sistema!</p>
          </div>
        </div>

      </button>

      <button class="escopo-dashboard-desktop" @click="$router.push(`/estoque/entrada-saida`)">

        <div class="opcao-dashboard">
          <div class="cacebalho-opcao">
            <h2 class="titulo-opcao">Entrada/Saida</h2>
            <i class="fa-solid fa-industry"></i>
          </div>

          <div class="descricao-opcao">
            <p class="mt-6">Cadastre as Categorias Para os Documentos!</p>
          </div>
        </div>

      </button>

      <button class="escopo-dashboard-desktop" @click="$router.push(`/estoque/inventarios`)">

        <div class="opcao-dashboard">
          <div class="cacebalho-opcao">
            <h2 class="titulo-opcao">Inventário</h2>
            <i class="fas fa-users"></i>
          </div>

          <div class="descricao-opcao">
            <p class="mt-6">Cadastre as Subcategorias dos Documentos!</p>
          </div>
        </div>

      </button>

      <button class="escopo-dashboard-desktop" @click="$router.push(`/estoque/ficha-fisica-financeira`)">

        <div class="opcao-dashboard">
          <div class="cacebalho-opcao">
            <h2 class="titulo-opcao">Ficha Fisíca Financeira</h2>
            <i class="fas fa-user-friends"></i>
          </div>

          <div class="descricao-opcao">
            <p class="mt-6">Veja os relatórios em tempo real!</p>
          </div>
        </div>

      </button>

      <button class="escopo-dashboard-desktop" @click="$router.push(`/tipos`)">

        <div class="opcao-dashboard">
          <div class="cacebalho-opcao">
            <h2 class="titulo-opcao">Manuais</h2>
            <i class="fas fa-user-friends"></i>
          </div>

          <div class="descricao-opcao">
            <p class="mt-6">Veja os relatórios em tempo real!</p>
          </div>
        </div>

      </button>

      <button class="escopo-dashboard-desktop" @click="$router.push(`/estoque/reservas`)">

        <div class="opcao-dashboard">
          <div class="cacebalho-opcao">
            <h2 class="titulo-opcao">Reservas</h2>
            <i class="fas fa-user-friends"></i>
          </div>

          <div class="descricao-opcao">
            <p class="mt-6">Veja os relatórios em tempo real!</p>
          </div>
        </div>

      </button>

    </div>


  </div>


</template>

<script>
export default {
  name: "DashboardEstoque",

}
</script>

<style scoped>

</style>