<template>

  <div class="container-formulario">

    <!-- Cabeçalho lista de pessoas depois de 768 pixels-->
    <div class="container-cabecalho flex justify-between">
      <div>
        <h1 class="text-xl text-corBase font-bold">Categorias</h1>
      </div>

      <router-link to="/projetos" class="text-2xl text-corBase font-bold">
        <i class="fas fa-chevron-left"></i>
      </router-link>

    </div>

    <div class="flex gap-4 items-end mb-2 w-[40%]">
      <div class="w-full">
        <input 
          type="text" 
          class="w-full bg-white border-2 border-corBase/5 border-b-corBase/20 focus:border-b-corBase/60 px-1 py-1 text-corBase shadow-sm shadow-corBase/20
              text-corBase focus:border-b-corBase/80 outline-none focus:transition focus:duration-500" 
          v-model="nome_modelo"
          placeholder="Nome" 
        >
      </div>
      <div>
        <button class="btn-add-fisica" @click="getListaModeloVariacao(1)">Pesquisar</button>
      </div>
      <div v-if="isadmin === true">
        <button @click="$router.push(`/modelo-variacao/cadastro`)" class="bg-ativo hover:bg-screenCor border border-screenCor hover:border-ativo
  text-white hover:text-ativo py-1 rounded-sm px-4">Adicionar
        </button>
      </div>
    </div>


    <!-- Container da exibiçao da lista de pessoas -->
    <div class="container-modelo-tabela">
      <table class="container-tabela overflow-auto">
        <thead class="cabecalho-tabela">
        <tr>
<!--          <th scope="col" class="p-4">-->
<!--            <div class="flex items-center">-->
<!--              <input type="checkbox" class="checkbox" v-model="checkAll" @change="selectAll">-->

<!--              <label class="sr-only">checkbox</label>-->
<!--            </div>-->
<!--          </th>-->
          <th scope="col" class="px-6 py-2 text-xs">
            #
          </th>
          <th class=" px-6 text-start w-full text-xs">
            Nome
          </th>
          <th v-if="isadmin === true" class="text-center px-10 text-xs">
            Codigo Auxiliar
          </th>
          <th v-if="isadmin === true" scope="col" class="text-center text-xs px-10">
            Status
          </th>
          <th v-if="isadmin === true" scope="col" class=" px-10 text-xs text-start">
            Ações
          </th>
        </tr>
        </thead>
        <tbody>

        <template v-if="isadmin === true">
          <tr v-for="(modelo_variacao, index) in lista_modelo_variacao" :key="index" class="escopo-tabela">
<!--            <td class="w-4 p-2">-->
<!--              <div class="flex items-center">-->
<!--                <input :id="'checkbox-table-' + (index + 2)" type="checkbox" class="checkbox"-->
<!--                       v-model="modelo_variacao.selected">-->
<!--                <label class="sr-only">checkbox</label>-->
<!--              </div>-->
<!--            </td>-->
            <th scope="row"
                class="px-6 py-1 font-medium text-sm text-gray-900 whitespace-nowrap dark:text-white">
              {{ index + 1 }}
            </th>
            <td class="px-6 py-1 text-xs">
              {{ modelo_variacao.descricao }}
            </td>
            <td v-if="isadmin === true" class="px-6 py-1 text-xs text-center">
              {{ modelo_variacao.codigo_aux }}
            </td>
            <template v-if="modelo_variacao.ativo === true && isadmin === true">
              <td class="px-1 sm:px-6  py-1 sm:py-4 text-center">
                <i class="fa-solid fa-circle-check  text-ativo"></i>
              </td>
            </template>
            <template v-if="modelo_variacao.ativo === false && isadmin === true">
              <td class="px-1 sm:px-6  py-1 sm:py-4 text-center">
                <i class="fa-solid fa-circle-xmark text-inativo"></i>
              </td>
            </template>
            <td v-if="isadmin === true" class="px-6 py-1 text-xs">
              <editar-modelo-variacao :pagina-atual="paginaAtual"
                                      :modeloVariacao="modelo_variacao"></editar-modelo-variacao>
            </td>


          </tr>
        </template>

        <template v-else>
          <tr v-for="(modelo_variacao, index) in lista_modelo_variacao" :key="index" class="escopo-tabela">

            <template v-if="modelo_variacao.ativo === true">

              <td class="w-4 p-4">
                <div class="flex items-center">
                  <input :id="'checkbox-table-' + (index + 2)" type="checkbox" class="checkbox"
                         v-model="modelo_variacao.selected">
                  <label class="sr-only">checkbox</label>
                </div>
              </td>
              <th scope="row"
                  class="px-6 py-2 font-medium text-sm text-gray-900 whitespace-nowrap dark:text-white">
                {{ index + 1 }}
              </th>
              <td class="px-6 py-2 text-sm">
                {{ modelo_variacao.descricao }}
              </td>
              <td v-if="isadmin === true" class="px-6 py-2 text-sm text-center">
                {{ modelo_variacao.codigo_aux }}
              </td>
              <template v-if="modelo_variacao.ativo === true && isadmin === true">
                <td class="px-1 sm:px-6  py-1 sm:py-4 text-center">
                  <i class="fa-solid fa-circle-check  text-ativo"></i>
                </td>
              </template>
              <template v-if="modelo_variacao.ativo === false && isadmin === true">
                <td class="px-1 sm:px-6  py-1 sm:py-4 text-center">
                  <i class="fa-solid fa-circle-xmark text-inativo"></i>
                </td>
              </template>
              <td v-if="isadmin === true" class="px-6 py-2 text-xs">
                <editar-modelo-variacao :modeloVariacao="modelo_variacao"
                                        :paginaAtual="paginaAtual"></editar-modelo-variacao>
              </td>

            </template>


          </tr>
        </template>
        </tbody>
      </table>

      <!-- <div class="paginacao">
        <button @click.prevent="paginaAnterior" class="btn-paginacao">
          <i class="fas fa-chevron-left"></i>
        </button>
        <span v-for="pagina in paginasExibidas" :key="pagina">
          <button @click.prevent="irParaPagina(pagina)"
                  :class="{ 'btn-paginacao': true, 'pagina-atual': pagina === paginaAtual }">
            {{ pagina }}
          </button>
        </span>
        <button @click.prevent="proximaPagina" class="btn-paginacao">
          <i class="fas fa-chevron-right"></i>
        </button>
      </div> -->

      <Pagination
        v-if="lista_modelo_variacao.length > 0"
        :offset="paginaAtual"
        :total="countItens"
        :limit="5"
        @change-page="mudarPagina"
      />
    </div>
  </div>
  <toast-error v-if="mensagemErro==true" mensagem="Modelo Não Encontrado ou Sem Cadastro! Tente Novamente"/>

</template>

<script>
import axios from "axios";
import EditarModeloVariacao from "@/components/midais/Editar/projeto/EditarModeloVariacao";
import ToastError from "@/components/toasts/toast_erro/ToastError";
import Pagination from "@/utils/Pagination.vue";

export default {
  name: "ListaModelos",
  components: {
    EditarModeloVariacao,
    ToastError,
    Pagination
  },

  data() {
    return {
      lista_modelo_variacao: [],
      modelo_variacao: '',
      nome_modelo: '',

      checkAll: false,
      // lista usada para salvar o retorno da requisição do get
      listaModeloVariacao: [],
      // lista usada para salvar esse retorno depois de dividido
      listaModeloVariacaoPaginada: [],
      // varival que inicializa o numero de paginas
      paginaAtual: 1,
      countItens: 0,
      next_page: null,
      totalDePaginas: 0,
      itemsPorPagina: 5,

      error: [],
      mensagemErro: false,
    }
  },


  computed: {

    minhaEmpresa() {
      return this.$store.state.minhaEmpresa;
    },

    isadmin() {
      return this.$store.state.isAdminUser;
    },

  //  paginasExibidas() {
  //     const paginas = [];
  //     const paginasVisiveis = 5; // Número de páginas visíveis de cada vez

  //     let inicio = Math.max(1, this.paginaAtual - Math.floor(paginasVisiveis / 2));
  //     let fim = inicio + paginasVisiveis - 1;

  //     if (inicio + paginasVisiveis > this.totalDePaginas) {
  //       inicio = Math.max(1, this.totalDePaginas - paginasVisiveis + 1);
  //       fim = this.totalDePaginas;
  //     }

  //     for (let i = inicio; i <= fim; i++) {
  //       paginas.push(i);
  //     }

  //     return paginas;
  //   },
  },


  methods: {

    selectAll() {
      // Atualiza o estado dos demais checkboxes
      const checkboxes = document.querySelectorAll('.escopo-tabela input[type="checkbox"]');
      checkboxes.forEach((checkbox) => {
        checkbox.checked = this.checkAll;
      });
    },

    async getListaModeloVariacao(pagina) {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get(`/projeto/modelo-variacao/?descricao=${this.nome_modelo}&page=${pagina}`, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            this.lista_modelo_variacao = response.data.results
            this.countItens = response.data.count
          })
      // } else {
      //   await axios.get(`/projeto/modelo-variacao/?descricao=${this.nome_modelo}&page=${this.paginaAtual}`, {
      //     headers: {
      //       'Authorization': `Token ${token}`
      //     }
      //   })
      //       .then(response => {
      //         this.lista_modelo_variacao = response.data.results
      //         console.log(this.lista_modelo_variacao)
      //         this.totalDePaginas = response.data.count / 5
      //         console.log(this.totalDePaginas)
      //         this.paginaAtual += 1
      //       })
      // }

    },

    mudarPagina(pagina) {
      this.paginaAtual = pagina
      this.getListaModeloVariacao(pagina)
    },

    proximaPagina() {
      this.getListaModeloVariacao();
    },

    paginaAnterior() {
      this.paginaAtual -= 1
      this.getListaModeloVariacao()
    },

    // async irParaPagina(pagina) {
    //   const token = await this.$store.dispatch('getAuthTokenCookie')
    //   console.log(pagina)
    //   this.paginaAtual = pagina
    //   await axios.get(`/projeto/modelo-variacao/?descricao=${this.nome_modelo}&page=${this.paginaAtual}`, {
    //       headers: {
    //         'Authorization': `Token ${token}`
    //       }
    //     })
    //         .then(response => {
    //           this.lista_modelo_variacao = response.data.results
    //           console.log(this.lista_modelo_variacao)
    //           this.totalDePaginas = response.data.count / 5
    //         })
    // }


  }
}
</script>

<style lang="scss">

</style>