<template>

  <div class="fundo-midal">
    <div class="midal">

      <!--      <div class="secao-icone-fechar">-->
      <!--        <router-link  class="fa-solid fa-xmark icone-fechar" @click="fecharMidal()"></router-link>-->
      <!--      </div>-->

      <div class="secao-mensagem-sucesso-midal">
        <p class="mensagem-sucesso-midal">Projeto Cadastrado com Sucesso!</p>
        <div class="fundo-icone-deu-certo"><i class="fa-solid fa-check icone-deu-certo"></i></div>
        <p class="mensagem-sucesso-midal pt-8">Deseja Continuar ou Voltar a Lista de Projetos?</p>
      </div>

      <div class="secao-botao">
        <router-link class="botao-midal botao-midal-voltar" to="/lista-projetos">Voltar</router-link>
        <router-link type="button" class="botao-midal botao-midal-continuar" :to="'/alterar-projeto/' + projeto_id">
          Continuar
        </router-link>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "ContinuarCriarProjeto",

  data() {
    return {
      projeto_id: '',
    }
  },

  props: ['projeto'],

  created() {
    this.projeto_id = this.projeto
  }
}
</script>

<style lang="scss">

</style>