<template>
  <div class="container-formulario">

    <div class="container-cabecalho flex justify-between mb-4">
      <div>
        <h1 class="text-xl text-corBase font-bold">Ficha Fisica Financeira</h1>
      </div>

      <router-link to="/estoque" class="text-xl text-corBase font-bold">
        <i class="fas fa-chevron-left"></i>
      </router-link>

    </div>

    <div class="flex justify-start items-center p-4 gap-8">
      <div class="flex gap-4 items-center w-[40%]">
        <label>Produto</label>
        <v-select
            class="w-full"
            label="nome_especifico"
            :options="lista_produto_fornecedor"
            v-model="produto_fornecedor"
            @input="fetchProdutoBase"

        >
          <template #option="{nome_especifico, codigo_fornecedor}">
            <strong>{{ codigo_fornecedor }}</strong> - <span>{{ nome_especifico }}</span>
          </template>
          <template #append>
            <div class="flex justify-around">
              <button>Prev</button>
              <button>Next</button>
            </div>
          </template>

        </v-select>

        <!--        <Select2 v-model="produto_fornecedor"-->
        <!--                 class="w-full z-10"-->
        <!--                 :options="lista_produto_fornecedor"-->
        <!--                 :settings="{ width: '80%'}"-->
        <!--        />-->
      </div>
      <button
          class="px-4 py-2 bg-ativo text-white border-2 border-white hover:text-ativo hover:bg-white hover:border-ativo"
          @click.prevent="gerarFichaFisica(produto_fornecedor)">
        Gerar Ficha
      </button>
    </div>

    <div v-if="ficha_fisica_financeira" class="p-6 w-full bg-white flex ">
      <div>
        <div class="text-xl font-medium text-black">Produto:
          {{ ficha_fisica_financeira.produto_por_fornecedor?.nome_especifico }}
        </div>
        <p class="text-gray-500">Preço médio: {{ ficha_fisica_financeira.produto_por_fornecedor?.preco_medio }}</p>
        <p class="text-gray-500">Quantidade: {{ ficha_fisica_financeira.produto_por_fornecedor?.quantidade }}</p>
        <div class="text-xl font-medium text-black mt-4">Movimentações:</div>
        <table class="w-[80vw]">
          <thead>
          <th>Data</th>
          <th>Tipo Movimentação</th>
          <th>Origem Movimentação</th>
          <th>Quantidade</th>
          <th>Preço de Custo</th>
          <th>Motivo Saída</th>
          </thead>
          <tbody>
          <tr 
            v-for="(mov, index) in ficha_fisica_financeira.movimentacoes" 
            :key="mov.id"
            :class="index % 2 === 0 ? 'bg-white' : 'bg-gray-100'"
          >
            <th class="text-gray-500">{{ mov.movimentacao.data_movimentacao }}</th>
            <th class="text-gray-500">{{ mov.movimentacao.tipo_movimentacao }}</th>
            <th class="text-gray-500">{{ mov.movimentacao.motivo_movimentacao }}</th>
            <th class="text-gray-500">{{ mov.qtd_produtos }}</th>
            <th class="text-gray-500">{{ mov.preco_custo }}</th>
            <th class="text-gray-500">{{ mov.movimentacao.motivo_saida }}</th>
          </tr>
          </tbody>
        </table>

<!--        <div v-for="mov in ficha_fisica_financeira.movimentacoes" :key="mov.id">-->
<!--          <p class="text-gray-500">ID: {{ mov.id }}</p>-->
<!--          <p class="text-gray-500">Quantidade de produtos: {{ mov.qtd_produtos }}</p>-->
<!--          <p class="text-gray-500">Preço de custo: {{ mov.preco_custo }}</p>-->
<!--        </div>-->
      </div>
    </div>


  </div>
</template>

<script>
import axios from 'axios'
import vSelect from "vue-select";
import debounce from "lodash/debounce";

export default {
  name: "FichaFisicaFinanceira",

  components: {
    vSelect
  },

  data() {
    return {
      listaProdutosBase: [],
      produto_base: {},
      lista_produto_fornecedor: [],
      produto_fornecedor: {},
      ficha_fisica_financeira: null,
    }
  },

  created() {
  },

  methods: {
    async fetchProdutoBase(searchInput) {
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest');

      if (!searchInput) {
        this.lista_produto_fornecedor = [];
        return;
      }
      const filtro = searchInput.target.value
      debounce(() => {
        axios.get(`produtos/produto-por-fornecedor?filtro_base=${filtro}`, {
          headers: {
            'Authorization': `Token ${token}`
          }
        })
            .then(response => {
              this.lista_produto_fornecedor = response.data.results
            })
            .catch(error => {
              console.error(error)
            })
      }, 1000)();
    },
    async getListaProdutosFornecedor() {
      this.$store.dispatch('fetchURLrequest');
      const token_local = localStorage.getItem('token');
      this.$store.dispatch("setLoading", true);
      const result = await axios.get(`produtos/produto-por-fornecedor`, {
        headers: {
          'Authorization': `Token ${token_local}`
        }
      })

      this.$store.dispatch("setLoading", false);

      this.lista_produto_fornecedor = result.data.results.map(item => ({
        id: item.id,
        text: `${item.produto_base.codigo_base} - ${item.nome_especifico}`
      }))
    },

    async gerarFichaFisica(produto) {
      this.$store.dispatch('fetchURLrequest');
      const token_local = localStorage.getItem('token');

      this.$store.dispatch("setLoading", true);
      const result = await axios.get(`produtos/ficha-fisica-financeira/${produto.id}`, {
        headers: {
          'Authorization': `Token ${token_local}`
        }
      })
      this.$store.dispatch("setLoading", false);
      this.ficha_fisica_financeira = result.data
      console.log(result.data)

    }


  }

}
</script>

<style scoped>

</style>