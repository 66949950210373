<template>
  <div class="container-campos">
    <div class="campos-mobile">
      <label class="text-base text-corBase font-bold mt-2">CPF:</label>
      <input type="text" required class="input-campos" autofocus placeholder="xxx.xxx.xxx-xx" v-mask="'###.###.###-##'"
        v-model="dados.cpf">
    </div>

    <div class="campos-mobile">
      <label class="text-base text-corBase mt-2">Nascimento:</label>
      <input type="date" class="input-campos" v-model="dados.nascimento">
    </div>

    <div class="campos-mobile">
      <label class="text-base text-corBase mt-2">RG:</label>
      <input type="text" class="input-campos" placeholder="xx.xxx.xxx-x" v-mask="'##.###.###-#'"
        v-model="dados.rg">
    </div>

    <div class="campos-mobile">
      <label class="text-base text-corBase mt-2">Órgão expeditor:</label>
      <input type="text" class="input-campos" v-model="dados.orgao_expeditor">
    </div>

  </div>

  <div class="container-campos">
    <div class="campos campos-mobile">
      <label class="label-campos">Nome:</label>
      <input type="text" required
             class="input-campos"
             v-on:input="dados.nome_razao = dados.nome_razao.toUpperCase()"
             v-model="dados.nome_razao">
    </div>
    <div class="campos campos-mobile campos-dasktop-select">
      <label class="text-base text-corBase mt-2 font-bold">Tipo pessoa geral:</label>
      <div class="flex items-center">
        <select class="input-campos w-full" v-model="dados.tipo_pessoa_geral"
          @input="$emit('update:tipo_pessoa_geral', $event.target.value)">
          <option>------</option>
          <option v-for="item in tipo_pessoa" :value="item.id" :key="item.id">{{ item.descricao }}
          </option>
        </select>
        <tipo-pessoa-geral-modal />
      </div>
    </div>
  </div>
</template>

<script>
import TipoPessoaGeralModal from "@/components/midais/modaisCadastro/TipoPessoaGeralModal";

export default {
  name: "PessoasCampos",

  components: {
    TipoPessoaGeralModal
  },

  props: {
    value: {
      type: Object,
      required: true
    },
    tipo_pessoa: {
      type: Array,
      required: true
    },
    dados: {},
  },

  computed: {
    tipo_pessoa() {
      return this.tipo_pessoa;
    }
  },

  watch: {
    dados: {
      deep: true,
      handler() {
        this.$emit('atualizar-dados', this.dados);
      }
    },
  },

  methods: {}
}
</script>

<style lang="scss"></style>