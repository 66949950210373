<template>
  <a @click="alterarModalEditar()" href="#" class="font-medium text-corBase hover:underline">
    <i class="fa-solid fa-pen-to-square mr-2"></i>Editar
  </a>
  <div v-if="statusModal">
    <div class="container-modal-editar">
      <div class="sombra-modal">
        <div class="conteudo-modal-editar">
          <form class="form-editar text-white">
            <div class="flex justify-end">
              <button @click.prevent="alterarModalEditar()">
                <i class="fas fa-close text-2xl text-corBase"></i>
              </button>
            </div>
            <h2 class="text-lg text-corBase font-bold mb-2">Editar Dados Chapa</h2>
            <!--Campos da Pessoa-->
            <div>
              <div class="container-campos">
                <div class="campos campos-mobile label-campos-popup">
                  <label class="label-campos label-campos-popup">Produto Base:</label>
                  <input type="text" required class="input-campos" v-model="produtoBase.nome_base" >
                </div>

                <div class="campos campos-mobile label-campos-popup">
                  <label class="label-campos label-campos-popup">Fornecedor:</label>
                  <input type="text" required class="input-campos" v-model="fornecedorChapa.nome_razao" readonly>
                </div>

                <div class="campos campos-mobile label-campos-popup">
                  <label class="label-campos label-campos-popup">Código Fornecedor:</label>
                  <input type="text" required class="input-campos" v-model="chapa.codigo_fornecedor" >
                </div>

                <div class="campos campos-mobile label-campos-popup">
                  <label class="label-campos label-campos-popup">Código de Barras:</label>
                  <input type="text" required class="input-campos" v-model="chapa.codigo_barras" >
                </div>

              </div>

              <div class="container-campos">
                <div class="campos campos-mobile label-campos-popup">
                  <label class="label-campos label-campos-popup">Nome Especifico:</label>
                  <input type="text" required class="input-campos" v-model="chapa.nome_especifico">
                </div>
                <div class="campos-mobile">
                  <label class="label-campos">Grupo de Aplicação Chapa:</label>
                  <input type="text" required class="input-campos" placeholder="" v-model="grupoaplicacao.nome">
                </div>
              </div>
            </div>
            <!--Campos de endereço-->
            <div>

              <div class="container-campos">
                <div class="flex flex-col mt-2 w-full md:w-60">
                  <label class="label-campos">Altura:</label>
                  <input type="text" required placeholder="" class="input-campos" v-model="chapa.altura_chapa">
                </div>

                <div class="campos campos-mobile">
                  <label class="label-campos">Largura:</label>
                  <input type="text" required class="input-campos" placeholder="" v-model="chapa.largura_chapa">
                </div>

                <div class="flex flex-col mt-2 w-full md:w-60">
                  <label class="label-campos">Cor Chapa:</label>
                  <input type="text" required class="input-campos" v-model="corchapas.nome">
                </div>
                <div class="flex flex-col mt-2 w-full md:w-[500px]">
                  <label class="label-campos">Tipo Chapa:</label>
                  <div class="">
                    <input type="text" required class="input-campos" placeholder="" v-model="tipochapa.nome">
                  </div>
                </div>
              </div>

              <div class="container-campos  ">


              </div>

              <div class=" container-campos ">

                <div class="campos-mobile">
                  <label class="label-campos">Peso por Metro:</label>
                  <input type="text" required class="input-campos" placeholder="" v-model="chapa.peso">
                </div>
                <div class="campos-mobile">
                  <label class="label-campos">Preço Médio:</label>
                  <input type="text" required class="input-campos" placeholder="" v-model="chapa.preco_medio">
                </div>

                <div class="campos-mobile">
                  <label class="label-campos">Preço Base:</label>
                  <div class="flex">
                    <input type="text" required class="input-campos" placeholder="" v-model="chapa.preco_base">
                  </div>
                </div>

                <div class="campos-mobile">
                  <label class="label-campos">Transporte:</label>
                  <div class="flex">
                    <input type="text" required class="input-campos" placeholder="" v-model="chapa.transporte">
                  </div>
                </div>
              </div>

              <div class=" container-campos ">
                <div class="campos-mobile">

                </div>
              </div>

            </div>

            <!--Botão-->
            <div class="btn-formulario-modal">
              <button class="btn-formulario-salvar" @click.prevent="putDadosEditado">
                Salvar
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "EditarChapas",
  data() {
    return {
      statusModal: false,
      chapa: {
        nome_especifico: '',
        altura_chapa: '',
        largura_chapa: '',
        ativo: '',
        chapa_base: '',
        codigo_barras: '',
        data_atualizacao_preco_base: '',
        fornecedor: '',
        grupo_aplicacao_chapa: '',
        icms: '',
        id: '',
        imagem: '',
        imagemVetor: '',
        ipi: '',
        margem_adicional: '',
        peso: '',
        preco_base: '',
        preco_custo: '',
        preco_medio: '',
        produto_base: '',
        tipo_chapas: '',
        transporte: '',
        ultimo_preco_compra: '',
        codigo_fornecedor: '',
        cor_chapas: '',
      },
      produtoBase: {},
      fonecedorChapa: {},
      grupoaplicacao: {},
      corchapas: {},
      tipochapa: {}

    }
  },
  props: ["dadosChapa"],
  created() {
    this.chapa.nome_especifico = this.dadosChapa.nome_especifico
    this.chapa.altura_chapa = this.dadosChapa.altura_chapa
    this.chapa.largura_chapa = this.dadosChapa.largura_chapa
    this.chapa.ativo = this.dadosChapa.ativo
    this.chapa.chapa_base = this.dadosChapa.chapa_base
    this.chapa.codigo_barras = this.dadosChapa.codigo_barras
    this.chapa.data_atualizacao_preco_base = this.dadosChapa.data_atualizacao_preco_base
    this.chapa.fornecedor = this.dadosChapa.fornecedor
    this.chapa.grupo_aplicacao_chapa = this.dadosChapa.grupo_aplicacao_chapa
    this.chapa.icms = this.dadosChapa.icms
    this.chapa.id = this.dadosChapa.id
    this.chapa.imagem = this.dadosChapa.imagem
    this.chapa.imagemVetor = this.dadosChapa.imagemVetor
    this.chapa.ipi = this.dadosChapa.ipi
    this.chapa.margem_adicional = this.dadosChapa.margem_adicional
    this.chapa.peso = this.dadosChapa.peso
    this.chapa.preco_base = this.dadosChapa.preco_base
    this.chapa.preco_custo = this.dadosChapa.preco_custo
    this.chapa.preco_medio = this.dadosChapa.preco_medio
    this.chapa.produto_base = this.dadosChapa.produto_base
    this.chapa.tipo_chapas = this.dadosChapa.tipo_chapas
    this.chapa.transporte = this.dadosChapa.transporte
    this.chapa.ultimo_preco_compra = this.dadosChapa.ultimo_preco_compra
    this.chapa.codigo_fornecedor = this.dadosChapa.codigo_fornecedor
    this.chapa.cor_chapas = this.dadosChapa.cor_chapas
    console.log(this.chapa)

    this.getProdutoBase()
    this.getFornecedor()
    this.getGrupoAplicacao()
    this.getCorChapa()
    this.getTipoChapa()
  },
  methods: {
    alterarModalEditar() {
      this.statusModal = !this.statusModal;
    },

    async putDadosEditado() {
      const dadosAtualizados = new FormData();
      dadosAtualizados.append('ativo', this.chapa.ativo)
      dadosAtualizados.append('nome_especifico', this.chapa.nome_especifico)
      dadosAtualizados.append('codigo_fornecedor', this.chapa.codigo_fornecedor)
      dadosAtualizados.append('codigo_barras', this.chapa.codigo_barras)
      dadosAtualizados.append('peso', this.chapa.peso)
      dadosAtualizados.append('preco_medio', this.chapa.preco_medio)
      dadosAtualizados.append('preco_base', this.chapa.preco_base)
      dadosAtualizados.append('ultimo_preco_compra', this.chapa.ultimo_preco_compra)
      dadosAtualizados.append('preco_custo', this.chapa.preco_custo)
      dadosAtualizados.append('data_atualizacao_preco', this.chapa.data_atualizacao_preco_base)
      dadosAtualizados.append('ipi', this.chapa.ipi)
      dadosAtualizados.append('icms', this.chapa.icms)
      dadosAtualizados.append('transporte', this.chapa.transporte)
      dadosAtualizados.append('margem_adicional', this.chapa.margem_adicional)
      dadosAtualizados.append('altura_chapa', this.chapa.altura_chapa)
      dadosAtualizados.append('largura_chapa', this.chapa.largura_chapa)
      dadosAtualizados.append('produto_base', this.chapa.produto_base.id)
      dadosAtualizados.append('fornecedor', this.chapa.fornecedor.id)
      dadosAtualizados.append('cor_chapas', this.chapa.cor_chapas.id)
      dadosAtualizados.append('tipo_chapas', this.chapa.tipo_chapas.id)
      dadosAtualizados.append('chapa_base', this.chapa.chapa_base.id)
      dadosAtualizados.append('grupo_aplicacao_chapa', this.chapa.grupo_aplicacao_chapa.id)
      dadosAtualizados.append('imagem', this.chapa.imagem)
      dadosAtualizados.append('imagemVetor', this.chapa.imagemVetor)


      try {
        const token = await this.$store.dispatch('getAuthTokenCookie');
        await this.$store.dispatch('fetchURLrequest')
        const response = await axios.put(`produtos/chapa/${this.chapa.id}/`, dadosAtualizados, {
          headers: {
            'Authorization': `Token ${token}`,
            'Content-Type': 'application/json'
          }
        });
        console.log(response);
        this.sucesso = true;
        await this.delay(2000);
        this.sucesso = false;

      } catch (error) {
        console.log(error)
      }
    },

    async getProdutoBase() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get(`produtos/produto/${this.chapa.produto_base.id}/`, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          this.produtoBase = response.data
          console.log(this.produtoBase)
        })
        .catch(error => {
          console.log(error)
        })

    },

    async getFornecedor() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get(`pessoas/fornecedor/${this.chapa.fornecedor.id}/`, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          this.fornecedorChapa = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    async getGrupoAplicacao() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get(`produtos/grupo-aplicacao-chapa/${this.chapa.grupo_aplicacao_chapa.id}/`, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          this.grupoaplicacao = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    async getCorChapa() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get(`produtos/cor-chapa/${this.chapa.cor_chapas.id}/`, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          this.corchapas = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    async getTipoChapa() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get(`produtos/tipo-chapa/${this.chapa.tipo_chapas.id}`, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          this.tipochapa = response.data
        })
        .catch(error => {
          console.log(error)
        })
    }

  }
}
</script>

<style>
.container-modal-editar {
  @apply fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full h-full
}

.conteudo-modal-editar {
  @apply bg-screenCor p-6 w-[75%]  shadow-xl shadow-screenCor/30 fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 h-[80vh] overflow-y-scroll scrollbar-thin scrollbar-thumb-screenCor/40
}

.form-editar {
  @apply text-corBase
}
</style>