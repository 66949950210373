<template>
  <div class="container-formulario">

    <!-- TILO DO FORMULARIO + BOTAO DE VOLTAR -->
    <div class="cabecalho-formulario">
      <h1 class="text-2xl text-corBase font-bold">Cadastro Subcategoria:</h1>

      <router-link to="/ged/lista-imagens" class="text-2xl text-corBase font-bold">
        <i class="fas fa-chevron-left"></i>
      </router-link>
    </div>

    <!-- Escopo do formulario de cadastro mobile-->
    <form @submit.prevent="cadastroCategoria">
      <!--        PARTE DE DADOS DO PRODUTO-->
      <div class="div-produtos flex flex-col md:flex md:flex-row">
        <div class="campo-chapa mt-8">
          <label class="text-xl font-bold text-corBase">Nome da Subcategoria:</label>
          <input type="text" v-model="subcategoria" class="input-form-chapa" required autofocus>
        </div>


      </div>


      <div class="flex w-full justify-end">
        <button class="btn-enviar" type="submit">Salvar</button>
      </div>

    </form>
  </div>
  <mensagem-sucesso v-if="sucesso === true" mensagem="Subcategoria Cadastrada com Sucesso!" rotas="/ged/subcategoria"/>

</template>

<script>
import axios from 'axios'
import MensagemSucesso from "@/components/toasts/toast_sucesso/MensagemSucesso";

export default {
  name: "SubcategoriasCadastro",
  components: {
    MensagemSucesso
  },

  data() {
    return {
      subcategoria: '',
      sucesso: false
    }
  },

  computed: {
    minhaEmpresa() {
      return this.$store.state.minhaEmpresa;
    },
  },

  methods: {
    async cadastroCategoria() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      this.$store.dispatch("setLoading", true);
      const data = new FormData()
      data.append('ativo', 'true')
      data.append('nome', this.subcategoria)

      await axios.post('ged/subcategoria/', data, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            console.log(response.data)
            this.sucesso = true
            this.$store.dispatch("setLoading", false);
          })
          .catch(error => {
            console.log(error)
            this.$store.dispatch("setLoading", false);
          })

    }
  }
}
</script>

<style lang="scss">

</style>