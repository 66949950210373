<template>
  <div className="flex flex-col gap-6 justify-center p-4 space-x-4">
    <div class="flex w-full">
      <button class="btn-enviar" @click.prevent="modalNovaColuna">Nova Coluna</button>
    </div>
    <div class="flex w-full gap-4">
      <ColunaComponente :columns="columns"/>
    </div>
  </div>
  <nova-coluna :quadro="quadro" v-if="novaColunaModal == true" @fechar="modalNovaColuna"/>
</template>

<script>
import ColunaComponente from "@/components/kanban/ColunaComponente";
import axios from "axios";
import NovaColuna from "@/components/kanban/Modais/NovaColuna";

export default {
  name: 'QuadroComponente',
  components: {
    ColunaComponente,
    NovaColuna
  },
  data() {
    return {
      columns: [],
      novaColunaModal: false
    }
  },
  props: {
    quadro: {
      type: Object,
      required: true
    }
  },
  created() {
    if (this.quadro && this.quadro.id) {
      this.getColunasQuadro();
    }
  },
  watch: {
    quadro: {
      handler(newQuadro, oldQuadro) {
        if (newQuadro && newQuadro.id) {
          this.getColunasQuadro();
        }
      },
      immediate: true
    }
  },
  methods: {
    modalNovaColuna() {
      this.novaColunaModal = !this.novaColunaModal
    },
    async getColunasQuadro() {
      try {
        this.$store.dispatch("setLoading", true);
        const token = await this.$store.dispatch('getAuthTokenCookie');
        await this.$store.dispatch('fetchURLrequest');

        const response = await axios.get(`kanban/coluna?quadro=${this.quadro.id}`, {
          headers: {
            'Authorization': `Token ${token}`
          }
        });
        this.columns = response.data.results;
        this.$store.dispatch("setLoading", false);
      } catch (error) {
        console.error('Erro ao obter colunas do quadro:', error);
      }
    }
  }
}
</script>

<style>
/* Estilos opcionais */
</style>
