<template>
  <div class="container-modelo-tabela">
    <table class="container-tabela overflow-auto">
      <thead class="cabecalho-tabela">
        <tr>
          <th scope="col" class="px-3 py-3">
            Código
          </th>
          <th scope="col" class="px-3 py-3">
            Descrição
          </th>
          <th scope="col" class="px-3 py-3">
            Fórmula
          </th>
        </tr>
      </thead>
      <tbody>
      <tr v-for="(formula, index) in formulas" :key="index" class="escopo-tabela">
        <td class="px-3 py-2 text-xs">
          {{ formula.codigo }}
        </td>
        <td class="px-3 w-60 py-2 text-xs">
          {{ formula.descricao }}
        </td>
        <td class="px-3 py-2 text-xs">
          {{ formula.formula }}
        </td>

      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'VisualizarFormulasOrcamento',

  data() {
    return{

    }
  },

  props: ['formulas']
}
</script>

<style scoped lang="scss">

</style>