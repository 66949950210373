<template>
  <div class="container-formulario">

  <div class="cabecalho-formulario">
      <h1 class="text-xl text-corBase font-bold">Orçamentos</h1>

      <router-link to="/orcamentos" class="text-xl text-corBase font-bold">
        <i class="fas fa-chevron-left"></i>
      </router-link>
    </div>


    <div class="container-modelo-tabela">
      <table class="container-tabela overflow-auto">
        <thead class="cabecalho-tabela">
          <tr>
            <th scope="col" class="px-3 py-3">
              #
            </th>
            <th scope="col" class="px-3 py-3">
              Editar
            </th>
            <th scope="col" class="px-3 py-3">
              Cliente
            </th>
            <th scope="col" class="px-3 py-3">
              Vendedor
            </th>
            <th scope="col" class="px-3 py-3">
              Data Cadastro
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(orcamento, index) in orcamentos" :key="index">
            <th scope="row" class="px-3 py-2 font-medium text-xs text-gray-900 whitespace-nowrap dark:text-white">
              {{ index + 1 }}
            </th>
            <td class="px-3 py-2 text-xs">
              <router-link :to="'/orcamento/' + orcamento.id">
                <i class="fa-solid fa-paperclip"></i>
              </router-link>
            </td>
            <td class="px-3 py-2 text-xs">
              {{ orcamento.cliente.nome_razao ? orcamento.cliente.nome_razao: '' }}
            </td>
            <td class="px-3 py-2 text-xs">
              {{ orcamento.vendedor.pessoa.nome_razao ? orcamento.vendedor.pessoa.nome_razao: '' }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'ListaOrcamento',

  data() {
    return {
      orcamentos: []
    }
  },

  created() {
    this.getOrcamentos()
  },

  methods: {
    async getOrcamentos() {
      try{
        const token = await this.$store.dispatch('getAuthTokenCookie');
        await this.$store.dispatch('fetchURLrequest');

        const response = await axios.get(`orcamentos/`, {
          headers: {
            'Authorization': `Token ${token}`
          }
        })

        this.orcamentos = response.data.results
      }catch(err){
        console.error(err.message)
      }
    },
  }
}
</script>

<style scoped lang="scss">

</style>