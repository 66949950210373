<template>

  <section class="container-descericao-tarefa-por-horario">
    <div class="cabecalho-descricao-horario">
      <h1>Descricao horario: </h1>
      <button> <i class="fas fa-chevron-left"></i> </button>
    </div>
  </section>

</template>

<script>
export default {
  name: "DescricaoPorHorario"
}
</script>

<style lang="scss">

.container-descericao-tarefa-por-horario {
  @apply absolute top-[45%] left-[6%] w-[90%] bg-white h-[55vh] rounded-lg shadow-lg shadow-corBase/10 flex
  p-4
}

.cabecalho-descricao-horario {
  @apply w-full flex justify-between border border-black h-10 text-2xl text-corBase px-2
}
</style>