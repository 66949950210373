<template>
    <div class="container-formulario">
        <!-- Cabeçalho -->
        <div class="cabecalho-formulario">
            <h1 class="text-xl text-corBase font-bold">Criar Nova Tabela de Componente</h1>
            <router-link to="/componentes" class="text-xl text-corBase font-bold">
                <i class="fas fa-chevron-left"></i>
            </router-link>
        </div>

        <!-- Formulário de dados da tabela -->
        <div>
            <label>Nome da Tabela</label>
            <input v-model="novaTabela.nome" type="text" class="input-form" />

            <label>Margem Lucro ML (%)</label>
            <input v-model="novaTabela.margem_lucro_ml" type="number" class="input-form" />

            <label>Margem Lucro Balcão (%)</label>
            <input v-model="novaTabela.margem_lucro_balcao" type="number" class="input-form" />

            <label>Desconto (%)</label>
            <input v-model="novaTabela.desconto" type="number" class="input-form" />

            <label>Fornecedor</label>
            <Select2 v-model="selectedFornecedor" class="w-full z-10" :options="listaFornecedores"
                :settings="{ width: '100%' }" />
        </div>

        <!-- Botões de Cancelar e Salvar -->
        <div class="flex justify-end mt-4">
            <button class="btn-cancelar" @click.prevent="$router.push('/componentes')">Cancelar</button>
            <button class="btn-salvar" @click.prevent="criarTabela">Criar Tabela</button>
        </div>
    </div>
    <SucessoRapido v-if="sucesso === true" mensagem="Tabela criada com sucesso!" />
</template>

<script>
import Select2 from "vue3-select2-component"
import axios from "axios";
import SucessoRapido from "@/components/toasts/toast_sucesso/SucessoRapido.vue";

export default {
    name: 'CriarTabelaComponente',
    components: {
        Select2,
        SucessoRapido
    },
    data() {
        return {
            sucesso: false,
            novaTabela: {
                nome: "",
                margem_lucro_ml: 0,
                margem_lucro_balcao: 0,
                desconto: 0,
                fornecedor: null,
            },
        };
    },
    methods: {
        async criarTabela() {
            const token = this.$store.state.token;
            await this.$store.dispatch('fetchURLrequest');
            try {
                const response = await axios.post("precificacao/tabelas-componente/",
                    { ...this.novaTabela, },
                    {
                        headers: {
                            Authorization: `Token ${token}`,
                        },
                    });
                this.sucesso = true;
                setTimeout(() => {
                    this.sucesso = false;
                    this.$router.push('/componentes');
                }, 3000);
            } catch (error) {
                console.error("Erro ao criar tabela de Componente:", error);
            }
        },
        async buscarFornedecores() {
            const token = this.$store.state.token;
            await this.$store.dispatch('fetchURLrequest');
            try {

            } catch (error) {
                console.error("Erro ao buscar fornecedores:", error);
            }
        }
    },
};
</script>

<style scoped>
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-container {
    background: white;
    padding: 20px;
    border-radius: 5px;
    width: 50%;
}

.input-form {
    border: 1px solid #ccc;
    padding: 8px;
    margin-bottom: 10px;
    width: 100%;
}
</style>