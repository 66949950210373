<template>
  <div class="fixed inset-0 flex bg-black/10">
    <div class="fixed right-0 top-0 bottom-0 w-3/5 bg-white shadow-l overflow-auto overflow-y-auto">
      <div class="flex justify-between px-8 py-4 border-b border-gray-200">
        <h1 class="text-2xl font-bold">Teste</h1>
        <button @click.prevent="fecharModal" class="text-gray-500 hover:text-red-500 focus:outline-none">
          <i class="fas fa-xmark"></i>
        </button>
      </div>
      <div class="flex px-12 py-4">
        <div class="w-full lg:w-7/10">
          <div class="flex flex-col">
            <label class="text-xl">Descrição:</label>
<!--            <textarea cols="50" rows="10" id="descricao" class="p-2 border border-gray-200 rounded-md"-->
<!--                      v-model="cartao.descricao"></textarea>-->
          </div>
          <div class="w-full lg:w-7/10 px-12 py-4">
            <h1 class="text-xl">Comentários</h1>
<!--            <template v-for="comentario in cartao.comentarios" :key="comentario.id">-->
<!--              <div class="bg-gray-100 p-2 rounded-md mb-2">-->
<!--                <p class="text-sm font-bold">{{ comentario.comentario }}</p>-->
<!--              </div>-->
<!--            </template>-->
          </div>
        </div>
        <div class="w-full lg:w-3/10 pl-4">
          <div class="grid grid-cols-1 gap-4">
            <div class="flex flex-col">
              <label class="text-xl">Prioridade:</label>
              <v-select v-model="selectedPropriedade" label="nome" :options="listaPrioridades" :filterable="false">
                <template #option="{propriedade}">
                  <span>{{ propriedade.nome }}</span>
                </template>
                <template v-slot:list-footer>
                  <li class="pagination">
                    <button :disabled="!hasPrevPagePropriedade" @click="prevPagePropriedade">{{ '<' }}</button>
                    <button :disabled="!hasNextPagePropriedade" @click="nextPagePropriedade">{{ '>' }}</button>
                  </li>
                </template>
              </v-select>
            </div>
            <div class="flex flex-col">
              <label class="text-xl">Status:</label>
              <v-select v-model="selectedStatus" label="nome" :options="listaStatusCartao" :filterable="false">
                <template #option="{status}">
                  <span>{{ status.nome }}</span>
                </template>
                <template v-slot:list-footer>
                  <li class="pagination">
                    <button :disabled="!hasPrevPageStatuses" @click="prevPage">{{ '<' }}</button>
                    <button :disabled="!hasNextPageStatuses" @click="nextPage">{{ '>' }}</button>
                  </li>
                </template>
              </v-select>
            </div>
            <div class="flex flex-col">
              <label class="text-xl">Etiqueta:</label>
              <v-select v-model="selectedEtiqueta" label="nome" :options="listaEtiquetas" :filterable="false">
                <template #option="{etiqueta}">
                  <span>{{ etiqueta.nome }}</span>
                </template>
                <template v-slot:list-footer>
                  <li class="pagination">
                    <button :disabled="!hasPrevPageEtiqueta" @click="prevPageEtiqueta">{{ '<' }}</button>
                    <button :disabled="!hasNextPageEtiqueta" @click="nextPageEtiqueta">{{ '>' }}</button>
                  </li>
                </template>
              </v-select>
            </div>
          </div>
        </div>
      </div>
      <div class="w-full lg:w-3/10 px-12 py-4">
        <h1 class="text-xl">Anexos</h1>
<!--        <template v-for="anexo in cartao.anexos" :key="anexo.id">-->
<!--          <button @click.prevent="openLink(anexo.anexo.url_documento)">-->
<!--            <img class="w-44 rounded-md mb-2" :src="anexo.anexo.url_documento" :alt="anexo.anexo.descricao"/>-->
<!--          </button>-->
<!--        </template>-->
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import vSelect from "vue-select";

export default {
  name: 'NovoCartao',

  components: {
    vSelect
  },

  data() {
    return {
      listaEtiquetas: [],
      listaPrioridades: [],
      listaResponsaveis: [],
      listaStatusCartao: [],
      anexos: [],
      comentarios: [],
      selectedStatus: null,
      selectedPropriedade: null,
      selectedEtiqueta: null,
      offset: 0,
      limit: 5
    }
  },

  computed: {
    filteredStatuses() {
      return this.listaStatusCartao.filter(status =>
          status.nome.toLowerCase().includes(this.search.toLowerCase())
      );
    },
    filteredEtiquetas() {
      return this.listaStatusCartao.filter(status =>
          status.nome.toLowerCase().includes(this.search.toLowerCase())
      );
    },
    paginatedStatuses() {
      return this.filteredStatuses.slice(this.offset, this.offset + this.limit);
    },
    paginatedPropriedade() {
      return this.filteredPropriedade.slice(this.offset, this.offset + this.limit);
    },
    paginatedEtiqueta() {
      return this.filteredEtiquetas.slice(this.offset, this.offset + this.limit);
    },
    hasNextPageStatuses() {
      return this.offset + this.limit < this.filteredStatuses.length;
    },
    hasPrevPageStatuses() {
      return this.offset > 0;
    },
    filteredPropriedade() {
      return this.listaPrioridades.filter(propriedade =>
          propriedade.nome.toLowerCase().includes(this.search.toLowerCase())
      );
    },
    hasNextPagePropriedade() {
      return this.offset + this.limit < this.filteredPropriedade.length;
    },
    hasPrevPagePropriedade() {
      return this.offset > 0;
    },
    hasNextPageEtiqueta() {
      return this.offset + this.limit < this.filteredEtiquetas.length;
    },
    hasPrevPageEtiqueta() {
      return this.offset > 0;
    },
  },
  mounted() {
    this.getStatus();
    this.getPrioridades();
    this.getEtiquetas();
    // this.getResponsaveis();
  },

  methods: {
    nextPage() {
      if (this.hasNextPageStatuses) {
        this.offset += this.limit;
      }
    },
    prevPage() {
      if (this.hasPrevPageStatuses) {
        this.offset -= this.limit;
      }
    },
    nextPagePropriedade() {
      if (this.hasNextPagePropriedade) {
        this.offset += this.limit;
      }
    },
    prevPagePropriedade() {
      if (this.hasPrevPagePropriedade) {
        this.offset -= this.limit;
      }
    },
    nextPageEtiqueta() {
      if (this.hasNextPageEtiqueta) {
        this.offset += this.limit;
      }
    },
    prevPageEtiqueta() {
      if (this.hasPrevPageEtiqueta) {
        this.offset -= this.limit;
      }
    },
    fecharModal() {
      this.$emit('fechar')
    },

    async getStatus() {
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest');

      try {
        this.$store.dispatch("setLoading", true);

        const response = await axios.get('kanban/status-cartao/', {
          headers: {
            'Authorization': `Token ${token}`
          }
        })
        this.listaStatusCartao = response.data.results
        this.$store.dispatch("setLoading", false);
      } catch (error) {
        console.error(error)
        this.$store.dispatch("setLoading", false);
      }
    },

    async getPrioridades() {
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest');

      try {
        this.$store.dispatch("setLoading", true);
        const response = await axios.get('kanban/prioridade/', {
          headers: {
            'Authorization': `Token ${token}`
          }
        })
        this.listaPrioridades = response.data.results
        this.$store.dispatch("setLoading", false);
      } catch (error) {
        console.error(error)
        this.$store.dispatch("setLoading", false);
      }
    },

    async getEtiquetas() {
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest');

      try {
        this.$store.dispatch("setLoading", true)
        const response = await axios.get('kanban/etiqueta/', {
          headers: {
            'Authorization': `Token ${token}`
          }
        })
        this.listaEtiquetas = response.data.results
        this.$store.dispatch("setLoading", false)
      } catch (error) {
        console.error(error)
        this.$store.dispatch("setLoading", false)
      }
    },

    /*async getResponsaveis() {*/
    /*  const token = await this.$store.dispatch('getAuthTokenCookie');*/
    /*  await this.$store.dispatch('fetchURLrequest');*/

    /*  try {*/
    /*    this.$store.dispatch('setLoading', true);*/
    /*    const response = await axios.get('empresa/colaborador/', {*/
    /*      headers: {*/
    /*        'Authorization': `Token ${token}`*/
    //       }
    //     })
    //
    //     this.listaResponsaveis = response.data.results
    //     this.$store.dispatch('setLoading', false)
    //   } catch (error) {
    //     console.error(error)
    //     this.$store.dispatch('setLoading', false)
    //   }
    // },
  }
}
</script>

<style scoped>
.pagination {
  display: flex;
  margin: 0.25rem 0.25rem 0;
}

.pagination button {
  flex-grow: 1;
}

.pagination button:hover {
  cursor: pointer;
}
</style>