<template>
  <button @click.prevent="fechar"
          class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
    Pesquisar
  </button>
  <div v-if="modal === true">
    <div class="bg-screenCor p-6 w-full z-[100000] shadow-xl shadow-screenCor/30 fixed top-[55%] left-1/2
      transform -translate-x-1/2 -translate-y-1/2 h-full overflow-y-scroll scrollbar-thin
      scrollbar-thumb-screenCor/40">

      <div class="cabecalho-galeria">
        <div>
          <button class="text-corBase text-2xl" @click="fechar"><i class="fas fa-xmark"></i></button>
        </div>
      </div>

      <form @submit.prevent="cadastrarProdutoBase" enctype="multipart/form-data">
        <!--        PARTE DE DADOS DO PRODUTO-->
        <div class="div-produtos">
          <div class="flex flex-col w-[40vw]">
            <label class="text-corBase text-xl font-bold">Adicione uma imagem:</label>
            <div class="imagePreviewWrapper" :style="{ 'background-image': `url(${previewImage.url_documento})` }">
            </div>
            <div class="flex gap-3 mt-4">
              <button class="selecionar-arquivo" @click.prevent="abrir_galeria">
                Pesquisar
              </button>

            </div>

          </div>

          <div class="flex flex-col w-full gap-[14px] ml-4 mr-1">
            <div class="campo-chapa">
              <label class="text-xl text-corBase font-bold">Nome Base:</label>
              <input type="text" v-model="nome_base" class="input-form-chapa" autofocus>
            </div>
            <div class="div-produtos ">

              <div class="flex flex-col gap-1 w-full">
                <label class="text-xl text-corBase font-bold">Codigo Base:</label>
                <input type="text" v-model="codigo_base" class="input-form-chapa" autofocus>

              </div>

              <div class="flex flex-col gap-1 w-full">
                <label class="text-xl text-corBase">Codigo Mercado:</label>
                <input type="text" v-model="codigo_mercado" class="input-form-chapa" autofocus>

              </div>
            </div>


            <div class="flex gap-3 w-full mt-3">

              <div class="campo-chapa">
                <label class="text-xl text-corBase font-bold">NCM:</label>
                <input type="text" v-model="ncm" class="input-form-chapa" autofocus>

              </div>

              <div class="campo-chapa">
                <label class="text-xl text-corBase">Passo:</label>
                <input type="text" v-model="passo" class="input-form-chapa" autofocus>

              </div>

            </div>

            <div class="flex items-center gap-3 w-full mt-3">
              <div class="campo-chapa">
                <label class="text-xl text-corBase">Unidade de Medida:</label>
                <select class="input-form-chapa" v-model="unidade_medida">
                  <option v-for="unidade in lista_unidade_medida" :key="unidade.id" :value="unidade.id">
                    {{unidade.text}}
                  </option>
                </select>
<!--                <Select2 v-model="unidade_medida"-->
<!--                         class="w-full select2-results "-->
<!--                         :options="lista_unidade_medida"-->
<!--                         :settings="{ width: '100%'}"-->
<!--                />-->
              </div>
<!--              <unidade-medida-modal/>-->

            </div>
          </div>
        </div>

        <div class="flex w-full justify-end">
          <button class="btn-enviar" type="submit">Cadastrar</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Select2 from "vue3-select2-component";

export default {
  name: "NovoProdutoBase",
  components: {
    Select2
  },
  data() {
    return {
      modal: false,
      sucesso: false,
      previewImage: {},

      lista_unidade_medida: [],
      unidade_medida: '',
      codigo_auxiliar: '',
      codigo_mercado: '',
      codigo_base: '',
      ncm: '',
      passo: '',
      nome_base: '',


      galeria: false
    }
  },

  created() {
    this.getUnidadeMedida()
  },

  // mounted() {
  //   this.getUnidadeMedida()
  // },

  computed: {
    minhaEmpresa() {
      return this.$store.state.minhaEmpresa;
    },

    isadmin() {
      return this.$store.state.isAdminUser
    },

  },

  methods: {
    fechar() {
      if(this.modal == false) {
        this.modal = true
      } else {
        this.modal = false
        this.$emit('getprodutos')
      }
    },

    atualizarFileInput(objeto) {
      console.log('objeto que chegou:' + objeto)
      console.log(objeto)
      this.previewImage = objeto;
    },
    abrir_galeria() {
      this.galeria = !this.galeria
    },


    handleImagemUpload(event) {
      this.fileInput = event.target.files[0];
    },

    async getUnidadeMedida() {
      const token = await this.$store.dispatch('getAuthTokenCookie')

      await this.$store.dispatch('fetchURLrequest')

      await axios.get('produtos/unidade-medida', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            console.log(response.data)
            this.lista_unidade_medida = response.data.map(item => ({
              id: item.id,
              text: item.nome
            }))
          })
          .catch(error => {
            console.log(error)
          })
    },

    async cadastrarProdutoBase() {
      const token = await this.$store.dispatch('getAuthTokenCookie')

      await this.$store.dispatch('fetchURLrequest')

      const data = new FormData
      data.append('codigo_mercado', this.codigo_mercado)
      data.append('codigo_base', this.codigo_base)
      data.append('ncm', this.ncm)
      data.append('passo', this.passo)
      data.append('nome_base', this.nome_base)
      data.append('unidade_medida', this.unidade_medida)
      if(this.previewImage.id == undefined) {
        data.append('imagem', '')
      } else {
        data.append('imagem', this.previewImage.id)
      }
      data.append('ativo', 'True')

      await axios.post('produtos/produto/', data, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            console.log(response.data)
            this.sucesso = true
          })
          .catch(error => {
            console.log(error)
          })
    }

  }
}
</script>

<style scoped>


</style>