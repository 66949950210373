<template>
  <div>
    <a
      @click="alterarModalEditar()"
      href="#"
      class="font-medium text-corBase pl-2 hover:underline"
    >
      <i class="fa-solid fa-pen-to-square mr-2"></i>Editar
    </a>

    <div v-if="statusModal === true">
      <div class="container-modal-editar">
        <div class="sombra-modal">
          <div class="conteudo-modal-editar">
            <form class="form-editar text-white">
              <div class="flex justify-end">
                <button @click.prevent="alterarModalEditar()">
                  <i class="fas fa-close text-2xl text-corBase"></i>
                </button>
              </div>
              <h2 class="text-lg text-corBase font-bold mb-2">
                Editar Inventario
              </h2>
              <!--Campos da Pessoa-->
              <div>
                <div class="flex mt-2 items-center w-full justify-start">
                  <label class="text-base text-corBase font-bold mt-2"
                    >Tipo Produto:</label
                  >
                  <p>{{ inventario.tipo_produto.nome }}</p>
                </div>
                <div class="flex mt-2 items-center w-full justify-start">
                  <label class="text-base text-corBase font-bold mt-2"
                    >Linha:</label
                  >
                  <p>{{ inventario.linha.nome }}</p>
                </div>
                <div class="md:flex md:gap-12">
                  <div class="mt-8">
                    <table class="container-tabela overflow-auto">
                      <thead class="cabecalho-tabela">
                        <tr>
                          <th class="px-6 py-3">COD. Fornecedor</th>
                          <th class="px-6 py-3">Descrição</th>
                          <th class="px-6 py-3">QTD. Atual</th>
                          <th scope="col" class="px-6 py-3">QTD. Inventario</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(produto, index) in inventario.produtos"
                          :key="index"
                          class="escopo-tabela"
                        >
                          <td class="px-6 py-2 text-xs">
                            {{ produto.codigo_fornecedor }}
                          </td>
                          <td class="px-6 py-2 text-xs">
                            {{ produto.nome_especifico }}
                          </td>
                          <td class="px-6 py-2 text-xs text-center">
                            {{ produto.quantidade }}
                          </td>
                          <td class="px-6 py-2 text-xs">
                            <input
                              class="px-4"
                              type="number"
                              v-model="produto.quantidade_inventario"
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <!--Botão-->
              <div class="flex justify-end gap-4">
                <div class="btn-formulario-modal">
                  <button
                    class="btn-formulario-salvar"
                    @click.prevent="salvarPreInventario"
                  >
                    Salvar Pré Inventario
                  </button>
                </div>
                <div class="btn-formulario-modal">
                  <button
                    class="btn-formulario-salvar"
                    @click.prevent="finalizarInventario"
                  >
                    Finalizar inventario
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "InventarioFinal",

  data() {
    return {
      statusModal: false,
      sucesso: false,
      erroNome: false,
    };
  },
  props: ["inventario", "paginaAtual"],

  mounted() {
    window.addEventListener("keydown", this.onEscKeyPressed);
  },

  beforeDestroy() {
    window.removeEventListener("keydown", this.onEscKeyPressed);
  },

  watch: {
    statusModal(newStatus) {
      if (newStatus) {
        window.addEventListener("keydown", this.onEnterKeyPressed);
      } else {
        window.removeEventListener("keydown", this.onEnterKeyPressed);
      }
    },
  },

  methods: {
    alterarModalEditar() {
      this.statusModal = !this.statusModal;
      this.$parent.getListaInventarios();
    },

    onEscKeyPressed(event) {
      if (event.key === "Escape") {
        this.statusModal = false;
        this.$parent.getListaInventarios();
      }
    },

    onEnterKeyPressed(event) {
      if (event.key === "Enter") {
        this.putDadosEditado();
      }
    },

    async finalizarInventario() {
      try {
        console.log(this.inventario);

        // Extrair os produtos do array de produtos do objeto inventario
        const produtos = this.inventario.produtos;

        // Mapear os produtos para extrair apenas os campos desejados e formatá-los
        const produtosFormatados = produtos.map((produto) => ({
          nome_especifico: produto.nome_especifico,
          quantidade_inventario: produto.quantidade_inventario,
        }));

        console.log(produtosFormatados);

        // Criar objeto para a requisição
        const requestData = {
          inventario_id: this.inventario.id, // Passar o ID do inventário final
        };

        // Realizar a requisição para finalizar o inventário
        const token = await this.$store.dispatch("getAuthTokenCookie");
        await this.$store.dispatch("fetchURLrequest");

        const result = await axios.post(
          "produtos/inventario-final/",
          requestData,
          {
            headers: {
              Authorization: `Token ${token}`,
              "Content-Type": "application/json", // Definindo o tipo de conteúdo como JSON
            },
          }
        );

        console.log(result);
      } catch (error) {
        console.error("Erro ao finalizar inventário:", error);
      }
    },

    async salvarPreInventario() {
      try {
        console.log(this.inventario);

        // Extrair os produtos do array de produtos do objeto inventario
        const produtos = this.inventario.produtos;

        // Mapear os produtos para extrair apenas os campos desejados e formatá-los
        const produtosFormatados = produtos.map((produto) => ({
          nome_especifico: produto.nome_especifico,
          quantidade_inventario: produto.quantidade_inventario,
        }));

        console.log(produtosFormatados);

        // Criar objeto para a requisição
        const inventario_id = this.inventario.id

        const requestData = {
          inventario_id: inventario_id,
          produtosFormatados: produtosFormatados
        }

        // Realizar a requisição para finalizar o inventário
        const token = await this.$store.dispatch("getAuthTokenCookie");
        await this.$store.dispatch("fetchURLrequest");

        const result = await axios.put(
          "produtos/pre-inventario/",
          {
            headers: {
              Authorization: `Token ${token}`,
              "Content-Type": "application/json", // Definindo o tipo de conteúdo como JSON
            },
            body: {
              inventario_id,
              produtosFormatados
            }
          }
        );

        console.log(result);
      } catch (error) {
        console.error("Erro ao finalizar inventário:", error);
      }
    },
  },
};
</script>

<style scoped>
</style>