<!-- CartaoComponente.vue -->
<template>
  <div class="cursor-pointer bg-white shadow rounded p-2 mb-2">
    <p>{{ cartao.nome }}</p>
    <p>{{ cartao.descricao }}</p>
  </div>

</template>

<script>

export default {
  name: 'CartaoComponente',
  components: [
  ],

  data() {
    return {
      isDetailOpen: false
    }
  },
  props: {
    cartao: {
      type: Object,
      required: true
    }
  },

  created() {

  },

  methods: {
    openDetailCard(){
      this.isDetailOpen = !this.isDetailOpen
    }


  }
};
</script>
