<template>
  <div class="container-modelo-tabela">
    <table class="container-tabela overflow-auto">
      <thead class="cabecalho-tabela">
      <tr>
        <th scope="col" class="px-6 py-3">
          #
        </th>
        <th scope="col" class="p-4">
          <div class="flex items-center">
            <input type="checkbox" class="checkbox" v-model="checkAll" @change="selectAll">

            <label class="sr-only">checkbox</label>
          </div>
        </th>
        <th class="px-6 py-3">
          Vidro
        </th>
        <th class="px-6 py-3">
          Descrição
        </th>
        <th scope="col" class=" py-3">
          Largura
        </th>
        <th scope="col" class=" py-3">
          Altura
        </th>
        <th scope="col" class=" py-3">
          QTDE/Fórmula
        </th>
        <th scope="col" class=" py-3">
          Fixação
        </th>
        <th scope="col" class=" py-3">
          Condição
        </th>
        <th scope="col" class=" py-3">
          Posição
        </th>
        <th scope="col" class=" py-3">
          lado
        </th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(vidro, index) in vidros" :key="index" class="escopo-tabela">

        <th scope="row" class="px-6 py-2 font-medium text-xs text-gray-900 whitespace-nowrap dark:text-white">
          {{ index + 1 }}
        </th>
        <td class="w-4 p-4">
          <div class="selec">
            <input :id="'checkbox-table-' + (index + 2)" type="checkbox" class="checkbox"
                    v-model="vidro.selected">
            <label class="sr-only">checkbox</label>
          </div>
        </td>
        <td class="px-6 py-2 text-xs">
          {{ vidro.vidro_id.codigo_base }}
        </td>
        <td class="px-6 py-2 text-xs">
          {{ vidro.vidro_id.nome_base }}
        </td>
        <td class=" py-2 text-xs">
          <input class="input-campos" v-model="vidro.largura">
        </td>
        <td class=" py-2 text-xs">
          <input class="input-campos" v-model="vidro.altura">
        </td>
        <td class=" py-2 text-xs">
          <input class="input-campos" v-model="vidro.qtd_formula">
          <!--                  {{ vidro.qtd_formula }}-->
        </td>
        <td class=" py-2 text-xs">
          <input class="input-campos" v-model="vidro.fixacao">
          <!--                  {{ vidro.fixacao }}-->
        </td>
        <td class=" py-2 text-xs">
          <input class="input-campos" v-model="vidro.condicao">
          <!--                  {{ vidro.condicao }}-->
        </td>
        <td class=" py-2 text-xs">
          <input class="input-campos" v-model="vidro.posicao">
          <!--                  {{ vidro.posicao }}-->
        </td>
        <td class=" py-2 text-xs">
          <input class="input-campos" v-model="vidro.lado">
          <!--                  {{ vidro.lado }}-->
        </td>

      </tr>
      </tbody>
    </table>
    <table class="container-tabela overflow-auto" v-if="novosVidros.length">
      <thead class="cabecalho-tabela">
        <th class="w-[28vw] px-6">
          Busque o Vidro:
        </th>
        <th scope="col" class=" py-3">
          Largura
        </th>
        <th scope="col" class=" py-3">
          Altura
        </th>
        <th scope="col" class=" py-3">
          QTDE/Fórmula
        </th>
        <th scope="col" class=" py-3">
          Fixação
        </th>
        <th scope="col" class=" py-3">
          Condição
        </th>
        <th scope="col" class=" py-3">
          Posição
        </th>
        <th scope="col" class=" py-3">
          Lado
        </th>
      </thead>
      <tbody>
        <tr v-for="(novoVidro, index) in novosVidros" :key="index" class="escopo-tabela">
          <td class="px-6 py-2 text-xs">
            <Search
              v-model="vidroSelecionado"
              :options="vidrosPesquisados"
              :labelKey="getLabelKey"
              valueKey="id"
              :nameKey="getNameKey"
              @select="onSelect"
              @shouldSearch="buscarVidros"
              class="w-[25vw]"
            />
          </td>
          <td class=" py-2 text-xs">
            <input class="input-campos" v-model="novoVidro.largura">
          </td>
          <td class=" py-2 text-xs">
            <input class="input-campos" v-model="novoVidro.altura">
          </td>
          <td class=" py-2 text-xs">
            <input class="input-campos" v-model="novoVidro.qtd_formula">
          </td>
          <td class=" py-2 text-xs">
            <input class="input-campos" v-model="novoVidro.fixacao" list="fixacao">
            <datalist id="fixacao">
              <option value="FIXO"></option>
              <option value="MOVEL"></option>
            </datalist>
          </td>
          <td class=" py-2 text-xs">
            <input class="input-campos" v-model="novoVidro.condicao">
          </td>
          <td class=" py-2 text-xs">
            <input class="input-campos" v-model="novoVidro.posicao">
          </td>
          <td class=" py-2 text-xs">
            <input class="input-campos" v-model="novoVidro.lado" list="lado">
            <datalist id="lado">
              <option value="A DEFINIR"></option>
              <option value="EXTERNO"></option>
              <option value="INTERNO"></option>
            </datalist>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="flex w-full justify-end itens-center mt-6 px-4 mb-5" v-if="novosVidros.length">
      <button 
        class="btn-salvar" 
        @click.prevent="postNovoVidro">
          Adicionar
      </button>
    </div>

    <div class="flex justify-center" v-if="!novosVidros.length">
      <button class="text-ativo" @click.prevent="adicionarVidro">
        <i class="fas fa-plus-circle"></i>
      </button>
    </div>
  </div>
  <div class="flex w-full justify-end itens-center mt-6">
    <button
      class="btn-cancelar" 
      @click.prevent="modal_confirmacao = true"
      v-if="checkSome || checkAll"
    >
      Excluir Selecionados
    </button>
    <button class="btn-cancelar" @click.prevent="getListaVidros">Cancelar</button>
    <button class="btn-salvar" @click.prevent="atualizarVidros">Salvar</button>
  </div>

  <MensagemConfirmacaoExclusao
    mensagem="Deseja mesmo excluir os itens selecionados?"
    :abrir="modal_confirmacao"
    @feharModal="feharModalConfirmacao"
    @confirmarExclusao="excluirSelecionados"
  />
</template>

<script>
import Search from '@/utils/Search.vue'
import axios from 'axios'
import MensagemConfirmacaoExclusao from '@/components/toasts/toast_notificacao/MensagemConfirmacaoExclusao.vue'

export default {
  name: "VidroProjeto",

  props: ['vidros', 'projeto_id'],

  data() {
    return {
      novosVidros: [],
      vidroSelecionado: [],
      vidrosPesquisados: [],

      checkAll: false,
      modal_confirmacao: false,
    }
  },

  components: {
    Search,
    MensagemConfirmacaoExclusao,
  },

  computed: {
    checkSome() {
      return this.vidros.some(vidro => vidro.selected)
    }
  },


  methods: {
    atualizarVidros() {
      this.$emit('atualizarVidros', this.vidros)
    },

    getLabelKey(option) {
      return option.codigo_base ? option.codigo_base : ''
    },

    getNameKey(option) {
      return option.nome_base ? option.nome_base : ''
    },

    onSelect(vidro) {
      this.vidroSelecionado = vidro
    },

    selectAll() {
      // Atualiza o estado dos demais checkboxes
      const checkboxes = document.getElementsByClassName('checkbox');
      checkboxes.forEach((checkbox) => {
        checkbox.checked = this.checkAll;
      });
    },

    excluirSelecionados() {
      if(this.checkAll) {
        this.deletarVidros(this.vidros)
        this.modal_confirmacao = false
        this.checkAll = false
        return
      }
      const vidrosADeletar = this.vidros.filter((vidro) => {
        if(vidro.selected){
          return vidro
        }
      })

      this.deletarVidros(vidrosADeletar)
      this.modal_confirmacao = false
    },

    async deletarVidros(vidrosADeletar) {
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest')

      const vidrosADeletarPromisses = []

      for(const vidro of vidrosADeletar){

        vidrosADeletarPromisses.push(
          axios.delete(`projeto/projeto-vidro/${vidro.id}/`, {
            headers: {
              Authorization: `Token ${token}`
            }
          })
        )
      }

      await Promise.all(vidrosADeletarPromisses)

      this.$emit('getListaVidrosProjeto')
    },

    async buscarVidros(pesquisa) {
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest')

      const response = await axios.get(`produtos/produto/?tipo_produto=Vidro&filtro_base=${pesquisa}&page_size=9999`, {
        headers: {
          Authorization: `Token ${token}`
        }
      })

      this.vidrosPesquisados = response.data.results
    }, 

    adicionarVidro() {
      this.novosVidros.push({
        projeto_id: this.projeto_id,
        vidro_id: '',
        qtd_formula: '',
        fixacao: '',
        condicao: '',
        posicao: '',
        lado: '',
      })
    },

    async postNovoVidro() {
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest')

      const novosVidrosPromisses = []

      for(const novoVidro of this.novosVidros){
        novoVidro.vidro_id = this.vidroSelecionado.id

        novosVidrosPromisses.push(
          axios.post('projeto/projeto-vidro/', novoVidro, {
            headers: {
              Authorization: `Token ${token}`
            }
          })
        )
      }

      await Promise.all(novosVidrosPromisses)

      this.novosVidros = []
      this.$emit('getListaVidrosProjeto')

    }
  }

}
</script>

<style scoped>


</style>