<template>


  <div class="container-formulario">

    <!-- Cabeçalho lista de pessoas depois de 768 pixels-->
    <div class="container-cabecalho flex justify-between mb-4">
      <div>
        <h1 class="text-xl text-corBase font-bold">Fornecedores</h1>
      </div>

      <router-link to="/pessoas" class="text-xl text-corBase font-bold">
        <i class="fas fa-chevron-left"></i>
      </router-link>

    </div>
    <div class="mb-8 flex items-end gap-8">
      <div class="campo-chapa">
        <label>Tipo Fornecedor</label>
        <Select2 v-model="tipo_fornecedor"
                 class="w-full"
                 :options="lista_tipo_fornecedor"
                 :settings="{ width: '100%'}"
        />
      </div>

      <div class="campo-chapa">
        <label>Status</label>
        <select v-model="status" class="input-campos">
          <option value="True">Ativo</option>
          <option value="False">Inativo</option>
        </select>
      </div>
      <div>
        <button class="bg-corBase hover:bg-screenCor border border-screenCor hover:border-corBase
  text-white hover:text-corBase py-2 px-4" @click="getListaFornecedor">Pesquisar
        </button>
      </div>
    </div>
    <div class="mb-8">
      <button @click="$router.push(`/cadastro-fornecedor`)" class="bg-ativo hover:bg-screenCor border border-screenCor hover:border-ativo
  text-white hover:text-ativo py-2 px-4 mt-2 mr-2">Adicionar
      </button>

    </div>


    <!-- Container da exibiçao da lista de pessoas -->
    <div class="container-modelo-tabela">
      <table class="container-tabela overflow-auto">
        <thead class="cabecalho-tabela">
        <tr>
          <th scope="col" class="p-4">
            <div class="flex items-center">
              <input type="checkbox" class="checkbox" v-model="checkAll" @change="selectAll">

              <label class="sr-only">checkbox</label>
            </div>
          </th>
          <th scope="col" class="px-6 py-3">
            #
          </th>
          <th class="px-6 py-3">
            Nome
          </th>
          <th class="px-6 py-3">
            CPF
          </th>
          <th scope="col" class="px-6 py-3">
            Tipo
          </th>
          <th scope="col" class="px-6 py-3">
            Status
          </th>
          <th scope="col" class="px-6 py-3">
            Ações
          </th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(fornecedor, index) in listaFornecedorPaginada" :key="index" class="escopo-tabela">
          <!--          <pre>{{ fornecedor }}</pre>-->
          <template v-if="fornecedor.pessoa_juridica !== null ">
            <td class="w-4 p-4">
              <div class="flex items-center">
                <input :id="'checkbox-table-' + (index + 2)" type="checkbox" class="checkbox"
                       v-model="fornecedor.selected">
                <label class="sr-only">checkbox</label>
              </div>
            </td>
            <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
              {{ index + 1 }}
            </th>
            <td class="px-6 py-4">
              {{ fornecedor.pessoa.nome_razao }}
            </td>
            <td class="px-6 py-4">
              {{ fornecedor.pessoa.nome_razao }}
            </td>
            <template v-if="fornecedor.pessoa.pessoa_juridica !== null">
              <td class="px-6 py-4">
                {{ fornecedor.pessoa.pessoa_juridica.cnpj }}
              </td>
            </template>
            <template v-if="fornecedor.pessoa.pessoa_juridica !== null">
              <template v-if="fornecedor.pessoa.pessoa_juridica.ativo === true">
                <td class="px-6 py-4">
                  <i class="fa-solid fa-circle-check  text-ativo  ml-3"></i>
                </td>
              </template>
              <template v-if="fornecedor.pessoa.pessoa_juridica.ativo === false">
                <td class="px-6 py-4">
                  <i class="fa-solid fa-circle-xmark text-inativo  ml-3"></i>
                </td>
              </template>
            </template>

            <td class="px-6 py-4">
              <!-- <editar-dados-pessoa-juridica :dados_pessoa_juridica="fornecedor"></editar-dados-pessoa-juridica> -->
            </td>
          </template>

        </tr>
        </tbody>
      </table>
      <div class="paginacao">
        <button @click.prevent="paginaAnterior" class="btn-paginacao"><i class="fas fa-chevron-left"></i></button>
        <template v-for="pagina in totalPaginas" :key="pagina">
          <span @click.prevent="irParaPagina(pagina)"
                :class="{'numero-paginacao': true, 'numero-paginacao-ativa': pagina === paginaAtual}">{{
              pagina
            }}</span>
        </template>
        <button @click="proximaPagina" class="btn-paginacao"><i class="fas fa-chevron-right"></i></button>
      </div>
    </div>
  </div>

</template>

<script>
import EditarDados from "@/components/midais/Editar/EditarDados";
import Select2 from "vue3-select2-component";
import axios from "axios";

export default {
  name: "Listafornecedor",

  components: {
    EditarDados,
    Select2
  },

  data() {
    return {
      checkAll: false,
      listaFornecedor: [],
      lista_tipo_fornecedor: [],
      tipo_fornecedor: '',
      listaFornecedorPaginada: [],
      paginaAtual: 1,
      filtros: false,
      status: ''
    }
  },

  created() {
    this.getListaTipoFornecedor()
  },

  computed: {
    totalPaginas() {
      return Math.ceil(this.listaFornecedor.length / 5);
    },
    minhaEmpresa() {
      return this.$store.state.minhaEmpresa;
    },
    isadmin() {
      return this.$store.state.isAdminUser
    },
  },

  methods: {
    async getListaTipoFornecedor() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get('pessoas/tipo-fornecedor', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            this.lista_tipo_fornecedor = response.data.results.map(tipo => ({
              id: tipo.id,
              text: tipo.nome
            }))
          })
          .catch(error => {
            console.log(error)
          })

    },
    selectAll() {
      const checkboxes = document.querySelectorAll('.escopo-tabela input[type="checkbox"]');
      checkboxes.forEach((checkbox) => {
        checkbox.checked = this.checkAll;
      });
    },

    paginaAnterior() {
      if (this.paginaAtual > 1) {
        this.paginaAtual--;
        this.paginarTabela();
      }
    },

    proximaPagina() {
      if (this.paginaAtual < this.totalPaginas) {
        this.paginaAtual++;
        this.paginarTabela();
      }
    },

    paginarTabela() {
      const itemsPerPage = 5;
      const startIndex = (this.paginaAtual - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      this.listaFornecedorPaginada = this.listaFornecedor.slice(startIndex, endIndex);
    },

    irParaPagina(pagina) {
      if (pagina >= 1 && pagina <= this.totalPaginas) {
        this.paginaAtual = pagina;
        this.paginarTabela();
      }
    },

    async getListaFornecedor() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      this.$store.dispatch("setLoading", true);
      await axios.get(`pessoas/fornecedor/?tipo_pessoa_geral_id=${this.tipo_fornecedor}&ativo=${this.status}`, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            this.listaFornecedor = response.data.results
            this.paginarTabela()
            this.$store.dispatch("setLoading", false);
            console.log(this.listaFornecedorPaginada)
          })
          .catch(error => {
            console.log(error)
            this.$store.dispatch("setLoading", false);
          })
    },

  }
}
</script>

<style scoped>

</style>