<template>

  <div class="container-formulario">

    <!-- TILO DO FORMULARIO + BOTAO DE VOLTAR -->
    <div class="cabecalho-formulario">
      <h1 class="text-xl text-corBase font-bold">Dados Cor Base:</h1>

      <router-link to="/lista-cores-perfil" class="text-xl text-corBase font-bold">
        <i class="fas fa-chevron-left"></i>
      </router-link>
    </div>

    <!-- Escopo do formulario de cadastro mobile-->
    <form @submit.prevent="cadastrarCorBase">
      <!--        PARTE DE DADOS DO PRODUTO-->
      <div class="flex flex-col md:flex-row gap-3 my-3 w-full ">

        <div class="campo-chapa">
          <label class="font-bold text-corBase">Nome</label>
          <input type="text" v-model="nome_cor" class="input-form-chapa" required autofocus>
        </div>

        <div class="campo-chapa">
          <label class="text-xl text-corBase">Produto:</label>
          <Select2 v-model="tipo_produto"
                   class="w-full z-10"
                   :options="lista_produtos_sistema"
                   :settings="{ width: '100%', multiple: true}"
          />
        </div>

      </div>


      <div class="flex w-full justify-end">
        <button class="btn-enviar" type="submit">Salvar</button>
      </div>

    </form>
  </div>
  <MensagemSucesso v-if="sucesso==true" rotas="/lista-cores-perfil" mensagem="Cor Base Cadastrada com Sucesso!"/>

</template>

<script>
import axios from "axios";
import MensagemSucesso from "@/components/toasts/toast_sucesso/MensagemSucesso";
import Select2 from "vue3-select2-component";

export default {
  name: "CadastroCoresProdutoBase",

  components: {
    MensagemSucesso,
    Select2
  },


  data() {
    return {
      sucesso: false,
      nome_cor: '',
      tipo_produto: '',
      lista_produtos_sistema: []
    }
  },

  created() {
    this.getProdutosSistema()
  },

  computed: {
    minhaEmpresa() {
      return this.$store.state.minhaEmpresa;
    },
    isadmin() {
      return this.$store.state.isAdminUser
    },
  },

  methods: {

    async getProdutosSistema() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get('produtos/produtos-sistema', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            console.log(response.data)
            this.lista_produtos_sistema = response.data.map(item => ({
              id: item.id,
              text: item.nome
            }))
          })
          .catch(err => {
            console.log(err)
          })
    },

    async cadastrarCorBase() {
      const token = await this.$store.dispatch('getAuthTokenCookie')

      await this.$store.dispatch('fetchURLrequest')

      const data = {
        'ativo': 'True',
        'nome': this.nome_cor,
        'produto_aplicado': this.tipo_produto
      }
      this.sucesso = false
      this.$store.dispatch("setLoading", true);
      await axios.post('produtos/cor-produto-base/', data, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            console.log(response.data);
            this.nome_cor = ''
            this.tipo_produto = ''
            this.sucesso = true
            this.$store.dispatch("setLoading", false);
          })
          .catch(error => {
            console.log(error);
            this.$store.dispatch("setLoading", false);
          })
    },
  }
}
</script>

<style lang="scss"></style>