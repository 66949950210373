<template>
  <div class="container-formulario">
    <!-- Cabeçalho -->
    <div class="cabecalho-formulario">
      <h1 class="text-xl text-corBase font-bold">Alterar Produtos da Tabela</h1>
      <router-link to="/vidros" class="text-xl text-corBase font-bold">
        <i class="fas fa-chevron-left"></i>
      </router-link>
    </div>

    <!-- Navegação entre Dados e Produtos da Tabela -->
    <nav class="flex gap-4 mb-6 items-end">
      <button class="px-2 py-1 bg-corBase text-white" @click="mostrarDados = true">Dados</button>
      <button class="px-2 py-1 bg-corBase text-white" @click="mostrarDados = false">Produtos da Tabela</button>
    </nav>

    <!-- Componente de Dados -->
    <DadosVidro v-if="mostrarDados" :dadosVidro="dadosVidro" />

    <!-- Componente de Produtos da Tabela -->
    <ProdutosTabela v-if="!mostrarDados" :produtosTabela="produtosTabela" :listaProdutos="listaProdutos"
      :adicionarProdutoAtivo="adicionarProdutoAtivo" :novoProduto="novoProduto" :paginaAtual="paginaAtual"
      :countItens="countItens" :produtoPreenchido="produtoPreenchido" :dadosVidro="dadosVidro"
      @abrirLinhaAdicionarProduto="abrirLinhaAdicionarProduto" @adicionarProduto="adicionarProduto"
      @mudarPagina="mudarPagina" @atualizarProdutosTabela="atualizarProdutosTabela" @deleteVidro="deleteVidro" />

    <!-- Botões de Cancelar e Salvar -->
    <div class="flex w-full justify-end itens-center mt-6">
      <button class="btn-cancelar" @click.prevent="$router.push('/vidros')">Cancelar</button>
      <button class="btn-salvar" @click.prevent="salvarDados">Salvar</button>
    </div>
  </div>
  <SucessoRapido v-if="sucesso === true" mensagem="Tabela alterada com sucesso!" />
  <ErroRapido v-if="erro === true" mensagem="Este produto já existe na tabela." />
</template>

<script>
import axios from 'axios';
import Select2 from "vue3-select2-component";
import SucessoRapido from "@/components/toasts/toast_sucesso/SucessoRapido.vue";
import ErroRapido from '@/components/toasts/toast_erro/ErroRapido.vue';
import Pagination from '@/utils/Pagination.vue';
import DadosVidro from './DadosVidro.vue';
import ProdutosTabela from './ProdutosTabela.vue';
import formatarData from '@/utils/FormatarData';

export default {
  components: {
    Select2,
    SucessoRapido,
    ErroRapido,
    Pagination,
    DadosVidro,
    ProdutosTabela
  },
  data() {
    return {
      mostrarDados: true,
      sucesso: false,
      erro: false,
      dadosVidro: {},
      produtosTabela: [],
      novoProduto: {
        custo: 0,
        vidro: null,
        margem_lucro_ml: 0,
        margem_lucro_balcao: 0
      },
      listaProdutos: [],
      adicionarProdutoAtivo: false,
      paginaAtual: 1,
      countItens: 0,
      next_page: null,
      itemsPorPagina: 5,
    };
  },
  computed: {
    produtoPreenchido() {
      return (
        this.novoProduto.vidro !== null &&
        this.novoProduto.custo > 0 &&
        this.novoProduto.margem_lucro_ml > 0 &&
        this.novoProduto.margem_lucro_balcao > 0
      );
    }
  },
  methods: {
    teste(produto) {
      console.log(produto);
    },

    formatarDataInput(dataISO) {
      const data = new Date(dataISO);
      const dia = String(data.getDate()).padStart(2, '0');
      const mes = String(data.getMonth() + 1).padStart(2, '0');
      const ano = data.getFullYear();
      return `${ano}-${mes}-${dia}`;
    },

    formatarDataProdutos(dataISO) {
      return formatarData(dataISO)
    },

    calcularValorFinal(produto) {
      const valor = parseFloat(produto.custo) + parseFloat(produto.custo) * (parseFloat(produto.margem_lucro_ml) / 100);
      return parseFloat(valor).toFixed(2);
    },

    calcularValorFinalBalcao(produto) {
      const valor = parseFloat(produto.custo) + parseFloat(produto.custo) * (parseFloat(produto.margem_lucro_balcao) / 100);
      return parseFloat(valor).toFixed(2);
    },

    calcularValorFinalProdutoExistente(produto) {
      const custo = parseFloat(produto.vidro.vidro_base.preco_custo) || 0;
      const margemLucroML = parseFloat(produto.margem_lucro_ml) || 0;
      const valor = custo + custo * (margemLucroML / 100);
      return valor.toFixed(2);
    },

    calcularValorFinalBalcaoProdutoExistente(produto) {
      const custo = parseFloat(produto.vidro.vidro_base.preco_custo) || 0;
      const margemLucroBalcao = parseFloat(produto.margem_lucro_balcao) || 0;
      const valor = custo + custo * (margemLucroBalcao / 100);
      return valor.toFixed(2);
    },

    buscarPrecoVidro(id) {
      const produtoSelecionado = this.listaProdutos.find(produto => produto.id === id);
      if (produtoSelecionado) {
        return parseFloat(produtoSelecionado.preco).toFixed(2);
      }
      return "0.00";
    },

    async buscarProdutosTabelaVidro(pagina) {
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest');
      try {
        const response = await axios.get(`precificacao/itens-tabelas-vidro/?id_tabela_preco=${this.$route.params.id}&page=${pagina}`, {
          headers: {
            Authorization: `Token ${token}`
          }
        });
        this.produtosTabela = response.data.results;
        this.countItens = response.data.count
      } catch (error) {
        console.error("Erro ao buscar produtos da tabela de vidro:", error);
      }
    },

    async buscarDadosVidro() {
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest');
      try {
        const response = await axios.get(`precificacao/tabelas-vidro/${this.$route.params.id}/`, {
          headers: {
            Authorization: `Token ${token}`
          }
        });
        this.dadosVidro = response.data;
        this.dadosVidro.criado_em_formatado = this.formatarDataInput(this.dadosVidro.criado_em);
      } catch (error) {
        console.error("Erro ao buscar dados da tabela de vidro:", error);
      }
    },

    async buscarListaProdutos() {
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest');
      try {
        const response = await axios.get('/produtos/vidro/', {
          headers: {
            Authorization: `Token ${token}`
          }
        });
        console.log(response.data.results)
        this.listaProdutos = response.data.results.map(produto => ({
          id: produto.id,
          text: produto.vidro_base.nome_especifico,
          preco: produto.vidro_base.preco_custo
        }));
      } catch (error) {
        console.error("Erro ao buscar lista de produtos:", error);
      }
    },

    abrirLinhaAdicionarProduto() {
      this.adicionarProdutoAtivo = true;
    },

    async adicionarProduto() {
      if (this.produtoPreenchido) {
        const token = await this.$store.dispatch('getAuthTokenCookie');
        await this.$store.dispatch('fetchURLrequest');
        try {
          const response = await axios.post(`precificacao/itens-tabelas-vidro/`, {
            tabela_preco_vidro: this.dadosVidro.id,
            vidro: this.novoProduto.vidro,
            margem_lucro_ml: this.novoProduto.margem_lucro_ml,
            margem_lucro_balcao: this.novoProduto.margem_lucro_balcao,
            custo: this.novoProduto.custo,
            valor_final_venda_ml: this.calcularValorFinal(this.novoProduto),
            valor_final_venda_balcao: this.calcularValorFinalBalcao(this.novoProduto)
          }, {
            headers: {
              Authorization: `Token ${token}`
            }
          });

          this.buscarProdutosTabelaVidro(1)

          console.log(response.data)

          this.novoProduto = {
            vidro: '',
            custo: 0,
            margem_lucro_ml: 0,
            margem_lucro_balcao: 0
          }

          this.adicionarProdutoAtivo = false;
        } catch (error) {
          if (error.response && error.response.data[0] === "Este item já existe para esta tabela de preço.") {
            this.erro = true
            setTimeout(() => {
              this.erro = false;
            }, 3000);
          } else {
            console.error("Erro ao adicionar produto:", error);
          }
        }
      }
    },

    async salvarDados() {
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest');
      try {
        await axios.put(`precificacao/tabelas-vidro/${this.$route.params.id}/`, this.dadosVidro, {
          headers: {
            Authorization: `Token ${token}`
          }
        });
        this.sucesso = true;
        setTimeout(() => {
          this.sucesso = false;
          this.$router.push('/vidros');
        }, 3000);
      } catch (error) {
        console.error("Erro ao salvar dados da tabela de vidro:", error);
      }
    },

    mudarPagina(pagina) {
      this.paginaAtual = pagina
      this.buscarProdutosTabelaVidro(pagina)
    },

    proximaPagina() {
      this.buscarProdutosTabelaVidro();
    },

    paginaAnterior() {
      this.paginaAtual -= 1
      this.buscarProdutosTabelaVidro()
    },

    atualizarProdutosTabela() {
      this.buscarProdutosTabelaVidro(this.paginaAtual);
    },
  },
  created() {
    this.buscarDadosVidro();
    this.buscarListaProdutos();
    this.buscarProdutosTabelaVidro(1);
  },
  watch: {
    'novoProduto.vidro': function (novoVidro) {
      this.novoProduto.custo = this.buscarPrecoVidro(novoVidro);
    }
  }
};
</script>

<style scoped>
.container-formulario {
  padding: 20px;
}

.cabecalho-formulario {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
