<template>
  <div class="fixed inset-0 flex bg-black/10">
    <div class="fixed right-0 top-0 bottom-0 w-3/5 bg-white shadow-l overflow-auto overflow-y-auto">
      <div class="flex justify-between px-8 py-4 border-b border-gray-200">
        <h1 class="text-2xl font-bold">{{ cartao.nome }}</h1>
        <button @click.prevent="fecharModal" class="text-gray-500 hover:text-red-500 focus:outline-none">
          <i class="fas fa-xmark"></i>
        </button>
      </div>
      <div class="flex px-12 py-4">
        <div class="w-full lg:w-7/10">
          <div class="flex flex-col">
            <label for="descricao" class="text-xl">Descrição:</label>
            <textarea cols="50" rows="10" id="descricao" class="p-2 border border-gray-200 rounded-md"
                      v-model="cartao.descricao"></textarea>
          </div>
          <div class="w-full lg:w-7/10 px-12 py-4">
            <h1 class="text-xl">Comentários</h1>
            <template v-for="comentario in cartao.comentarios" :key="comentario.id">
              <div class="bg-gray-100 p-2 rounded-md mb-2">
                <p class="text-sm font-bold">{{ comentario.comentario }}</p>
              </div>
            </template>
          </div>
        </div>
        <div class="w-full lg:w-3/10 pl-4">
          <div class="grid grid-cols-1 gap-4">
            <div class="flex flex-col">
              <label for="prioridade" class="text-xl">Prioridade:</label>
              <v-select id="prioridade" label="nivel" :options="propriedades" v-model="cartao.prioridade"></v-select>
            </div>
            <div class="flex flex-col">
              <label for="status" class="text-xl">Status:</label>
              <v-select id="status" label="status" :options="status" v-model="cartao.status"></v-select>
            </div>
            <div class="flex flex-col">
              <label for="etiqueta" class="text-xl">Etiqueta:</label>
              <v-select id="etiqueta" label="nome" :options="etiquetas" v-model="cartao.etiqueta"></v-select>
            </div>
          </div>
        </div>
      </div>
      <div class="w-full lg:w-3/10 px-12 py-4">
        <h1 class="text-xl">Anexos</h1>
        <template v-for="anexo in cartao.anexos" :key="anexo.id">
          <button @click.prevent="openLink(anexo.anexo.url_documento)">
            <img class="w-44 rounded-md mb-2" :src="anexo.anexo.url_documento" :alt="anexo.anexo.descricao"/>
          </button>
        </template>
      </div>
    </div>
  </div>
</template>


<script>
import axios from "axios";
import Select2 from "vue3-select2-component";
import vSelect from "vue-select";

export default {
  name: 'DetalhesCartaoComponente',
  components: {
    vSelect,
    Select2
  },

  data() {
    return {
      propriedades: [],
      status: [],
      etiquetas: [],
    }
  },

  props: {
    cartao: {
      type: Object,
      required: true
    }
  },

  created() {
    this.getEtiquetas()
    this.getPrioridades()
    this.getStatus()
  },

  methods: {
    openLink(link) {
      window.open(link, '_blank')
    },

    fecharModal() {
      this.$emit('fechar')
    },

    async getPrioridades() {
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest')
      try {
        this.$store.dispatch("setLoading", true);
        const response = await axios.get('kanban/prioridade', {
          headers: {
            'Authorization': `Token ${token}`
          }
        })
        this.propriedades = response.data.results
        this.$store.dispatch("setLoading", false);
      } catch (error) {
        console.error(error)
        this.$store.dispatch("setLoading", false);
      }
    },

    async getStatus() {
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest')
      try {
        this.$store.dispatch("setLoading", true);
        const response = await axios.get('kanban/status-cartao', {
          headers: {
            'Authorization': `Token ${token}`
          }
        })

        this.status = response.data.results

        this.$store.dispatch("setLoading", false);
      } catch (error) {
        console.error(error)
        this.$store.dispatch("setLoading", false);
      }
    },

    async getEtiquetas() {
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest')

      try {
        this.$store.dispatch("setLoading", true);
        const response = await axios.get('kanban/etiqueta', {
          headers: {
            'Authorization': `Token ${token}`
          }
        })

        this.etiquetas = response.data.results
        this.$store.dispatch("setLoading", false);
      } catch (error) {
        console.error(error)
        this.$store.dispatch("setLoading", false);
      }
    }

  }
}

</script>

<style scoped>
/* Fix potential layout issue and add spacing */
.v-select {
  width: 100%;
}

/* Add some visual enhancements */
.rounded-md {
  border-radius: 4px;
}

.mb-2 {
  margin-bottom: 0.5rem;
}
</style>