<template>
    <div class="relative">
        <div class="relative rounded-md">
            <input 
                type="text"
                :value="keyword"
                @input="onInput"
                :class="inputClassList"
            >
            <button
                v-if="keyword"
                type="button"
                class="absolute right-0 top-0 inset-y-0 pr-3 flex items-center cursor-pointer text-gray-400 focus:outline-none hover:text-gray-400"
                @click="onClear()"
            >
            <svg
                class="h-2 w-2"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 8 8"
            >
                <path
                    stroke-linecap="round"
                    stroke-width="1.5"
                    d="M1 1l6 6m0-6L1 7"
                />
            </svg>
        </button>
        </div>
        <div 
            v-show="mutableOptions.length"
            class="absolute right-0 mt-2 w-full rounded-md shadow-lg z-50 overflow-y-scroll" 
            style="max-height: 200px"
        >
            <ul class="py-1 rounded-md bg-white shadow-xs">
                <li 
                    class="search-item block px-4 py-2 text-sm leading-5 text-gray-700 cursor-pointer"
                    v-for="opt in mutableOptions"
                    :key="opt[valueKey]"
                    @click="onSelect(opt)"
                >
                    <span class="font-bold">{{ getOptionLabel(opt) }}</span>
                    - {{ getOptionName(opt) }}
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default	{
    name: 'Search',

    props: {
        value: {
            type: String,
            default: ''
        },

        options: {
            type: Array,
            default: () => []
        },  

        placeholder: {
            type: String,
            default: ''
        },

        labelKey: {
            type: [String, Function],
            default: 'label'
        },

        valueKey: {
            type: String,
            default: 'id'
        },

        nameKey: {
            type: [String, Function],
            default: 'name'
        },

        searchMinLength: {
            type: Number,
            default: 3
        },
    },
    
    data() {
        return{
            keyword: '',
            originalOptions: [],
            mutableOptions: [],

        }
    },

    created() {
        this.keyword = this.value

        if(this.options.length){
            this.cloneOptions()
        }
    },  

    watch: {
        value(vl) {
            this.keyword = vl
        },

        options() {
            this.cloneOptions()
        }
    },

    methods: {
        onInput(event) {
            const value = event.target.value;
            this.keyword = value;
            this.emitInput()

            if(value.length >= this.searchMinLength){
                if(!this.originalOptions.length){
                    this.$emit('shouldSearch', value);
                } else{
                    this.searchInternally()
                }
            } else{
                this.resetOptions()
            }
        },

        searchInternally() {
            const pesquisa = this.keyword
            this.mutableOptions = this.originalOptions.filter(o => o[this.labelKey].search(pesquisa.toLowerCase()) >= 0)
        },

        onSelect(opt) {
            this.$emit('select', opt)
            this.keyword = this.getOptionLabel(opt)
            this.emitInput()
            this.resetOptions()
        },

        emitInput() {
            this.$emit('input', this.keyword);
        },

        resetKeyword() {
            this.keyword = '';
            this.emitInput();
        },

        cloneOptions() {
            this.originalOptions = JSON.parse(JSON.stringify(this.options))
            this.mutableOptions = JSON.parse(JSON.stringify(this.options))
        },

        resetOptions() {
            this.originalOptions = []
            this.mutableOptions = []
        },  

        onClear() {
            this.$emit('select', null);
            this.resetKeyword();
            this.resetOptions();
        },

        getOptionLabel(option) {
          if (typeof this.labelKey === 'function') {
            return this.labelKey(option);
          }
          return option[this.labelKey];
        },

        getOptionName(option) {
          if (typeof this.nameKey === 'function') {
            return this.nameKey(option);
          }
          return option[this.nameKey];
        }
    },

    computed: {
        inputClassList() {
            return [
                'appearance-none rounded w-full transition duration-150 ease-in-out',
                this.getTextSizeClass,
                this.getTextColorClass,
                this.getBorderColorClass,
                // this.getPaddingClass,
            ]
        },

        getTextSizeClass(){
            return 'text-sm leading-5'
        },

        getTextColorClass() {
            return 'text-gray-800 placeholder-gray-400'
        },  

        getBorderColorClass() {
            return 'focus:outline-none border border-gray-40 focus:border-blue-400'
        },  
    }
}

</script>

<style lang="scss" scoped>

</style>
