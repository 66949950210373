<template>
  <a @click="alterarModalEditar()" href="#" class="font-medium text-corBase pl-2 hover:underline">
    <i class="fa-solid fa-pen-to-square mr-2"></i>Editar
  </a>
  <div v-if="statusModal === true">
    <div class="container-modal-editar">
      <div class="sombra-modal">
        <div class="conteudo-modal-editar">
          <form class="form-editar text-white" @submit.prevent="putDadosEditado">
            <div class="flex justify-end">
              <button @click.prevent="alterarModalEditar()">
                <i class="fas fa-close text-2xl text-corBase"></i>
              </button>
            </div>
            <h2 class="text-lg text-corBase font-bold mb-2">Editar Dados Projetista</h2>
            <!--Campos da Pessoa-->
            <div>
              <div class="md:flex md:gap-12">
                <div class="flex flex-col mt-2 w-full justify-start">
                  <label class="text-base text-corBase font-bold mt-2">Código:</label>
                  <input type="text" required class="input-campos text-base" v-model="projetistaSistemista.codigo">
                </div>
                <div class="flex flex-col mt-2 w-full justify-start">
                  <label class="text-base text-corBase font-bold mt-2">Nome:</label>
                  <input type="text" required class="input-campos text-base" v-model="projetistaSistemista.nome">
                </div>
                <!-- <div class="flex flex-col mt-2 w-full justify-start">
                  <label class="text-base text-corBase font-bold mt-2">Empresa:</label>
                  <Select2 v-model="projetistaSistemista.empresa"
                           class="w-full z-10"
                           :options="lista_empresa"
                           :settings="{ width: '100%'}"
                  />
                </div> -->

                <div class="flex flex-col mt-2 w-full justify-start ">
                  <label class="text-base text-corBase font-bold mt-2">Ativo:</label>

                  <span @click.prevent="toggleAtivo" class="text-start pt-1 text-xl">
                    <i v-if="projetistaSistemista.ativo === true" class="fa-solid fa-circle-check text-ativo"></i>
                    <i v-else class="fa-solid fa-circle-xmark text-inativo"></i>
                  </span>
                </div>


              </div>

            </div>

            <MensagemSucesso 
              v-if="sucesso==true" 
              rotas="/lista-projetista-sistemista"
              mensagem="Projetista Editado com Sucesso!"
            />

            <!--Botão-->
            <div class="btn-formulario-modal">
              <button class="btn-formulario-salvar" type="submit">
                Salvar
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <toast-error v-if="erroNome === true" mensagem="Projetista/Sistemista com Esse Nome já Existe"/>
  </div>
</template>

<script>
import axios from "axios";
import ToastError from "@/components/toasts/toast_erro/ToastError";
import Select2 from "vue3-select2-component";
import MensagemSucesso from "@/components/toasts/toast_sucesso/MensagemSucesso";

export default {
  name: "EditarProjetistaSistemista",
  components: {
    ToastError,
    Select2,
    MensagemSucesso
  },
  data() {
    return {
      statusModal: false,
      lista_empresa: [],
      sucesso: false,
      erroNome: false,

    }
  },
  props: ["projetistaSistemista", "paginaAtual"],

  mounted() {
    window.addEventListener('keydown', this.onEscKeyPressed);
    this.getListaEmpresa()

  },

  beforeDestroy() {
    window.removeEventListener('keydown', this.onEscKeyPressed);
  },

  watch: {
    statusModal(newStatus) {
      if (newStatus) {
        window.addEventListener('keydown', this.onEnterKeyPressed);
      } else {
        window.removeEventListener('keydown', this.onEnterKeyPressed);
      }
    }
  },

  methods: {
    toggleAtivo() {
      this.projetistaSistemista.ativo = !this.projetistaSistemista.ativo
    },
    alterarModalEditar() {
      this.statusModal = !this.statusModal;
      this.$parent.getListaProjetistaSistemista()
    },

    onEscKeyPressed(event) {
      if (event.key === 'Escape') {
        this.statusModal = false
        this.$parent.getListaProjetistaSistemista()
      }
    },

    onEnterKeyPressed(event) {
      if (event.key === 'Enter') {
        this.putDadosEditado()
      }
    },

    async putDadosEditado() {
      const dataUpdated = {
        codigo: this.projetistaSistemista.codigo,
        nome: this.projetistaSistemista.nome,
        ativo: this.projetistaSistemista.ativo,
        // empresa: [this.projetistaSistemista.empresa]
      }

      try {
        const token = await this.$store.dispatch('getAuthTokenCookie');
        await this.$store.dispatch('fetchURLrequest')
        const response = await axios.put(`projeto/projetista-sistemista/${this.projetistaSistemista.id}/`, dataUpdated, {
          headers: {
            'Authorization': `Token ${token}`,
            'Content-Type': 'application/json'
          }
        });
        // this.$parent.limpaLista()
        // this.$parent.irParaPagina(this.paginaAtual)
        this.statusModal = false;

      } catch (error) {
        console.log(error)
      }
    },
    delay(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },

    async getListaEmpresa() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get('pessoas', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
      .then(response => {
            this.lista_empresa = response.data.results.filter(objeto => {
              return objeto.pessoa_juridica !== null
            }).map(item => ({
              id: item.id,
              text: item.nome_razao
            }))
          })
          .catch(error => {
            console.log(error)
          })
    }

  }
}
</script>

<style lang="scss">

</style>