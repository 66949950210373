<template>
  <section class="sessao-cadastro">
    <div class="container-formulario">
      <div class="cabecalho-formulario">
        <h1 class="text-xl text-corBase font-bold">Reserva</h1>

        <router-link to="/estoque/reservas" class="text-xl text-corBase font-bold">
          <i class="fas fa-chevron-left"></i>
        </router-link>
      </div>

      <div class="flex flex-col mb-5 gap-3 md:flex md:flex-row md:gap-3">
        <div class="flex mt-2 w-full">
          <h1 class="text-xl text-corBase font-bold mt-2">Título:</h1>
          <p class="text-corBase text-xl">{{ reserva.titulo_reserva }}</p>
        </div>
      </div>

      <table class="w-[80vw]">
        <thead>
        <th>Código Fornecedor</th>
        <th>Nome</th>
        <th>Quantidade Reservada</th>
        <th>Nova quantidade</th>
        </thead>
        <tbody>
        <tr 
          v-for="(produtoReservado, index) in produtosReservados" 
          :key="produtoReservado.id"
          :class="index % 2 === 0 ? 'bg-white' : 'bg-gray-100'"
        >
          <th class="text-gray-500">{{ produtoReservado.produto.codigo_fornecedor }}</th>
          <th class="text-gray-500">{{ produtoReservado.produto.nome_especifico }}</th>
          <th class="text-gray-500">{{ produtoReservado.qtd_produto }}</th>
          <th class="text-gray-500">
            <input 
              type="number"
              class="border-y-8 border-x-8"
              v-model="produtoReservado.quantidadeAtualizada"
            >
          </th>
          <th class="text-gray-500">
            <button class="bg-corBase hover:bg-screenCor border border-screenCor hover:border-corBase
              text-white hover:text-corBase py-1 px-2"
              @click.prevent="atualizarQuantidadeReservada(produtoReservado)">
                Atualizar Quantidade
            </button>
          </th>
          <th class="text-gray-500">
            <button
              class="bg-ativo hover:bg-screenCor border border-screenCor hover:border-ativo
            text-white hover:text-ativo py-1 px-2 ">
                Dar Saída
            </button>
          </th>
        </tr>
        </tbody>
      </table>

      <div class="flex justify-end p-4">
        <button class="bg-corBase hover:bg-screenCor border border-screenCor hover:border-corBase
          text-white hover:text-corBase py-1 px-2">
            Atualizar Todos
        </button>

        <button
          class="bg-ativo hover:bg-screenCor border border-screenCor hover:border-ativo
        text-white hover:text-ativo py-1 px-2 ">
            Dar Saída em Todos
        </button>
      </div>
    </div>

  </section>
</template>

<script>
import axios from 'axios'

export default {
  name: 'ListaProdutosReservados',
  data() {
    return {
      reserva_id: this.$route.params.id,
      reserva: {},
      produtosReservados: [
        {
          id: '',
          qtd_produto: 0,
          reserva: '',
          produto: '',
          baixa_concluida: '',
          quantidadeAtualizada: 0
        }
      ],
    }
  },

  created() {
    this.getReserva()
  },

  methods: {
    async getProdutosReservados() {
      try{
        const token = await this.$store.dispatch('getAuthTokenCookie');
        await this.$store.dispatch('fetchURLrequest');

        const response = await axios.get(`/produtos/produto-reservado/?reserva_id=${this.reserva_id}`, {
          headers: {
            'Authorization': `Token ${token}`
          }
        })

        this.produtosReservados = response.data.results
      }catch(err){
        console.error(err.message)
      }
    },

    async getReserva() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get(`/produtos/reserva-de-estoque/?reserva_id=${this.reserva_id}`, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            this.reserva = response.data.results[0]
            console.log(this.reserva)
            this.getProdutosReservados()
          })
          .catch(error => {
            console.log(error)
          })
    },

    async atualizarQuantidadeReservada(produtoReservado) {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      const response = await axios.put(`/produtos/produto-reservado/${produtoReservado.id}`, {
        headers: {
          'Authorization': `Token ${token}`
        },
        data: {
          qtd_produto: produtoReservado.quantidadeAtualizada,
          reserva: produtoReservado.reserva,
          produto: produtoReservado.produto.id,
          baixa_concluida: ''
        }
      })
    }
  },
}
</script>

<style lang="scss" scoped>

</style>