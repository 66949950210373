<template>


  <div class="container-formulario">
    <galeria v-if="galeria === true" @fechar_galeria="abrir_galeria" @objetoClicado="atualizarFileInput"/>
    <!-- TILO DO FORMULARIO + BOTAO DE VOLTAR -->
    <div class="cabecalho-formulario">
      <h1 class="text-2xl text-corBase font-bold">Dados Componente:</h1>

      <router-link to="/produtos/componente-acessorio" class="text-2xl text-corBase font-bold">
        <i class="fas fa-chevron-left"></i>
      </router-link>
    </div>

    <!-- Escopo do formulario de cadastro mobile-->
    <form @submit.prevent="cadastrarComponente" enctype="multipart/form-data">

      <div class="flex flex-col gap-3 my-3 w-full">
        <div class="flex w-full gap-3">

          <div class="flex flex-col w-[40vw]">
            <label class="text-corBase text-xl font-bold">Adicione uma imagem:</label>
            <div class="imagePreviewWrapper" :style="{ 'background-image': `url(${previewImage.url_documento})` }">
            </div>
            <div class="flex gap-3 mt-4">
              <button class="selecionar-arquivo" @click.prevent="abrir_galeria">
                Pesquisar
              </button>

            </div>

          </div>

          <div class="flex flex-col w-full gap-[14px] ml-4 mr-1">
            <div class="campo-chapa">
              <label class="text-xl text-corBase font-bold">Nome Especifico:</label>
              <input type="text" v-model="nome_especifico" class="input-form-chapa" required autofocus>
            </div>

            <div class="div-produtos ">
              <div class="flex flex-col gap-1 w-full">
                <label class="text-xl text-corBase font-bold">Produto Base:</label>
                <Select2 v-model="produto_base"
                         class="w-full z-10"
                         :options="lista_produto_por_fornecedor"
                         :settings="{ width: '100%'}"
                />
              </div>

            </div>

            <div class="flex gap-3 w-full mt-3">
              <div class="campo-chapa">
                <label class="text-xl text-corBase">Tipo Componente:</label>
                <Select2 v-model="tipo_componente"
                         class="w-full z-10"
                         :options="lista_tipo_componente"
                         :settings="{ width: '100%'}"
                />
              </div>
              <div class="campo-chapa">
                <label class="text-xl text-corBase">Grupo Aplicação componente:</label>
                <Select2 v-model="grupo_aplicacao_componente"
                         class="w-full z-10"
                         :options="lista_grupo_aplicacao_componente"
                         :settings="{ width: '100%'}"
                />
              </div>
            </div>

            <div class="flex gap-3 w-full mt-3">
              <div class="campo-chapa">
                <label class="text-xl text-corBase">Cor Componente:</label>
                <Select2 v-model="cor_componente"
                         class="w-full z-10"
                         :options="lista_cor_componente"
                         :settings="{ width: '100%'}"
                />
              </div>
              <div class="campo-chapa">
                <label class="text-xl text-corBase">Medida Embalagem:</label>
                <Select2 v-model="unidade_medida_embalagem"
                         class="w-full z-10"
                         :options="lista_unidade_medida"
                         :settings="{ width: '100%'}"
                />
              </div>

              <div class="campo-chapa">
                <label class="text-xl text-corBase">Quantidade na Embalagem:</label>
                <input type="number" class="input-form-chapa" v-model="quantidade_embalagem">
              </div>
            </div>


          </div>

        </div>

        <div class="flex w-full justify-end">
          <button class="btn-enviar" type="submit">Salvar</button>
        </div>

      </div>

    </form>


  </div>


  <mensagem-sucesso v-if="sucesso === true" mensagem="Componente/Acessorio cdastrado com Sucesso!"
                    rotas="/produtos/componente-acessorio"/>

</template>

<script>
import axios from "axios";
import Galeria from "@/components/midais/ged/Galeria";
import Select2 from "vue3-select2-component";
import MensagemSucesso from "@/components/toasts/toast_sucesso/MensagemSucesso";


export default {
  name: "CadastroComponentesAcessorio",

  components: {
    Galeria,
    Select2,
    MensagemSucesso
  },

  data() {
    return {
      galeria: false,
      sucesso: false,
      previewImage: {},

      lista_produto_por_fornecedor: [],
      lista_cor_componente: [],
      lista_tipo_componente: [],
      lista_grupo_aplicacao_componente: [],

      nome_especifico: '',
      produto_base: '',
      cor_componente: '',
      tipo_componente: '',
      grupo_aplicacao_componente: '',
      lista_unidade_medida: [],
      unidade_medida_embalagem: '',
      quantidade_embalagem: '',

      proximo: false,

    }
  },

  created() {
    console.log(this.isadmin)
    console.log(this.minhaEmpresa)
    this.getFornecedor()
    this.getProdutoPorFornecedor()
    this.getGrupoAplicacaoComponente()
    this.getCorComponente()
    this.getTipoComponente()
    this.getListaUnidadeMedida()
  },

  computed: {
    isadmin() {
      return this.$store.state.isAdminUser
    },
    minhaEmpresa() {
      return this.$store.state.minhaEmpresa;
    },
  },

  methods: {
    atualizarFileInput(objeto) {
      console.log('objeto que chegou:' + objeto)
      console.log(objeto)
      this.previewImage = objeto; // ou a propriedade correta do objeto que deseja atribuir
    },
    abrir_galeria() {
      this.galeria = !this.galeria
    },
    trocar_de_pagina() {
      this.proximo = !this.proximo
    },
    handleImagemUpload(event) {
      this.fileInput = event.target.files[0];
    },

    async cadastrarComponente() {
      const token = await this.$store.dispatch('getAuthTokenCookie')

      await this.$store.dispatch('fetchURLrequest')

      const data = new FormData();
      data.append('ativo', 'True')
      data.append('nome_especifico', this.nome_especifico);
      data.append('cor_componentes', this.cor_componente)
      data.append('tipo_componentes', this.tipo_componente)
      data.append('componente_base', this.produto_base)
      data.append('grupo_aplicacao', this.grupo_aplicacao_componente)
      data.append('quantidade_embalagem', this.quantidade_embalagem)
      data.append('unidade_medida_embalagem', this.unidade_medida_embalagem)
      data.append('imagem', this.previewImage.id)

      this.$store.dispatch("setLoading", true);
      await axios.post('produtos/componente-acessorio/', data, {
        headers: {
          'Authorization': `Token ${token}`,
          'Content-Type': 'multipart/form-data'
        }
      })
          .then(response => {
            console.log(response.data.results);
            this.sucesso = true
            this.$store.dispatch("setLoading", false);
          })
          .catch(error => {
            console.log(error);
            this.$store.dispatch("setLoading", false);
          })

    },


    async getProdutoPorFornecedor() {
      const token = await this.$store.dispatch('getAuthTokenCookie')

      await this.$store.dispatch('fetchURLrequest')

      await axios.get('produtos/produto-por-fornecedor', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            console.log(response.data.results)
            this.lista_produto_por_fornecedor = response.data.results.map(item => ({
              id: item.id,
              text: `${item.produto_base.nome_base} - ${item.produto_base.codigo_base}`,
              img: item.produto_base.imagem
            }))

          })
          .catch(error => {
            console.log(error)
          })

    },

    async getFornecedor() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get('pessoas/fornecedor', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            console.log(response.data.results)
            console.log("AQUI");
            this.lista_fornecedor = response.data.results.map(item => ({
              id: item.id,
              text: item.nome_razao
            }))
          })
          .catch(error => {
            console.log(error)
          })
    },

    async getCorComponente() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get('produtos/cor-componente', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            console.log(response.data.results)
            this.lista_cor_componente = response.data.results.map(item => ({
              id: item.id,
              text: item.nome
            }))
          })
          .catch(error => {
            console.log(error)
          })
    },

    async getTipoComponente() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get('produtos/tipo-componente', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            console.log(response.data.results)
            this.lista_tipo_componente = response.data.results.map(item => ({
              id: item.id,
              text: item.nome
            }))
          })
          .catch(error => {
            console.log(error)
          })
    },

    async getGrupoAplicacaoComponente() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get('produtos/grupo-aplicacao-componente', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            console.log(response.data.results)
            this.lista_grupo_aplicacao_componente = response.data.results.map(item => ({
              id: item.id,
              text: item.nome
            }))
          })
          .catch(error => {
            console.log(error)
          })
    },

    async getListaUnidadeMedida() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get('produtos/unidade-medida', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            this.lista_unidade_medida = response.data.results.map(item => ({
              id: item.id,
              text: item.nome
            }))
          })
          .catch(error => {
            console.log(error)
          })
    }

  }

}
</script>

<style lang="scss">

</style>