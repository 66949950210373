<template>
  <button @click.prevent="abrir_modal"
          class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded w-full">
    Pesquisar
  </button>

  <div v-if="modal === true"
       class="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full h-full z-[100000]">
    <div class="w-full h-full bg-black/40 z-[100000]">
      <div class="bg-screenCor p-6 w-[75%] z-[100000] shadow-xl shadow-screenCor/30 fixed top-[55%] left-1/2
      transform -translate-x-1/2 -translate-y-1/2 h-[80vh] overflow-y-scroll scrollbar-thin
      scrollbar-thumb-screenCor/40">

        <div class="cabecalho-galeria">
          <div>
            <button class="text-corBase text-2xl" @click="fechar"><i class="fas fa-xmark"></i></button>
          </div>
        </div>

        <div class="max-w-3xl mx-auto p-4">
          <form @submit.prevent="submitForm">
            <div class="mb-4">
              <label for="descricao" class="block font-bold">Descrição do Produto:</label>
              <input v-model="produto.nome" type="text" id="descricao" class="border rounded p-2 w-full" required>
            </div>

            <div class="mb-4">
              <label for="codigoFornecedor" class="block font-bold">Código do Fornecedor:</label>
              <input v-model="produto.codigo_fornecedor" type="text" id="codigoFornecedor"
                     class="border rounded p-2 w-full"
                     required>
            </div>


            <div class="mb-4">
              <label class="block font-bold">Produto base:</label>
              <select class="border rounded p-2 w-full" v-model="produtoBase">
                <option v-for="produto in lista_produtos_base" :key="produto.id" :value="produto.id">
                  {{ produto.text }}
                </option>
              </select>
              <novo-produto-base @getprodutos="getListaProdutoBase"/>

            </div>

            <button type="submit" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
              Cadastrar
            </button>
          </form>


        </div>


      </div>
    </div>
  </div>

</template>

<script>
import axios from 'axios'
import Select2 from "vue3-select2-component";
import NovoProdutoBase from "@/components/midais/produto/NovoProdutoBase";

export default {
  name: 'NovoProdutoFornecedor',

  components: {
    Select2,
    NovoProdutoBase
  },

  data() {
    return {
      modal: false,
      lista_produtos_base: [],
      produtoBase: ''
    }
  },

  props: ['produto', 'fornecedor'],


  methods: {
    fechar() {
      this.modal = !this.modal
      this.$emit('getlista')
    },
    abrir_modal() {
      this.getListaProdutoBase()
          .then(() => {
            // Agora que a lista de produtos base foi carregada, podemos abrir o modal
            this.modal = !this.modal;
          })
          .catch(error => {
            console.error(error);
          });
    },

    async getListaProdutoBase() {
      try {
        const token = await this.$store.dispatch('getAuthTokenCookie');
        await this.$store.dispatch('fetchURLrequest');
        const response = await axios.get(`produtos/produto`, {
          headers: {
            'Authorization': `Token ${token}`
          }
        });
        this.lista_produtos_base = response.data.map(item => ({
          id: item.id,
          text: `${item.nome_base} - ${item.codigo_base}`
        }));
        console.log(this.lista_produtos_base);
      } catch (error) {
        console.error(error);
        throw error;
      }
    },

    async submitForm() {
      const token = await this.$store.dispatch('getAuthTokenCookie')

      await this.$store.dispatch('fetchURLrequest')

      const data = new FormData
      data.append('ativo', "True")
      data.append('nome_especifico', this.produto.nome)
      data.append('codigo_fornecedor', this.produto.codigo_fornecedor)
      data.append('produto_base', this.produtoBase)
      data.append('fornecedor', this.fornecedor.id)

      await axios.post('produtos/produto-por-fornecedor/', data, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
      .then(response => {
        console.log(response.data)
        this.fechar()
      })
      .catch(error => {
        console.log(error.message)
      })
    }

  }
}

</script>

<style scoped lang="scss">


</style>