<template>

  <div class="posicao-toast">
    <div class="container-toast">
      <i class="fas fa-x icone-toast"></i>
      <h2 class="texto-toast">
        {{ mensagem }}
      </h2>
    </div>
  </div>

</template>

<script>

export  default {
  name: 'SucessoRapido',

  props: ['mensagem']

}
</script>

<style scoped>

.posicao-toast {
  @apply absolute right-0 bottom-0 m-5 z-[10000]
}

.container-toast {
  @apply flex items-center bg-red-500 border-l-4 border-red-700 py-2 px-3 shadow-md mb-2
}

.texto-toast {
  @apply text-white max-w-xs
}

.icone-toast {
  @apply text-white mr-2
}

</style>