<template>


  <!-- TEMPLATE DASHBOARD TIPOS PRA CIMA DE 768 PIXELS -->
  <div class="container-formulario">
    <div class="cabecalho-dashboard">
      <h1>Tipos</h1>

      <button @click="$router.push(`/cadastros`)" class="text-2xl">
        <i class="fas fa-chevron-left mr-1 "></i>
      </button>
    </div>

    <div class="flex flex-wrap h-[66vh] overflow-y-scroll scrollbar-thin scrollbar-thumb-corBase/40 pb-8">

      <button class="escopo-dashboard-desktop" @click="$router.push(`/tipo-pessoa`)">

        <div class="opcao-dashboard">
          <div class="cacebalho-opcao">
            <h2 class="titulo-opcao">Tipo Pessoa</h2>
            <i class="fas fa-user"></i>
          </div>

          <div class="descricao-opcao">
            <p class="mt-6">Cadastre os tipos de pessoas em seu sistema!</p>
          </div>
        </div>

      </button>

      <button class="escopo-dashboard-desktop" @click="$router.push(`/tipo-produto-base`)">

        <div class="opcao-dashboard">
          <div class="cacebalho-opcao">
            <h2 class="titulo-opcao">Tipo Produto Base</h2>
            <i class="fas fa-user"></i>
          </div>

          <div class="descricao-opcao">
            <p class="mt-6">Cadastre os tipos de pessoas em seu sistema!</p>
          </div>
        </div>

      </button>

      <button class="escopo-dashboard-desktop" @click="$router.push(`/tipo-endereco`)">

        <div class="opcao-dashboard">
          <div class="cacebalho-opcao">
            <h2 class="titulo-opcao">Tipo Endereco</h2>
            <i class="fa-solid fa-industry"></i>
          </div>

          <div class="descricao-opcao">
            <p class="mt-6">Cadastre as empresas presentes em seu sistema!</p>
          </div>
        </div>

      </button>

      <button class="escopo-dashboard-desktop" @click="$router.push(`/tipo-telefone`)">

        <div class="opcao-dashboard">
          <div class="cacebalho-opcao">
            <h2 class="titulo-opcao">Tipo Telefone</h2>
            <i class="fas fa-users"></i>
          </div>

          <div class="descricao-opcao">
            <p class="mt-6">Veja os relatórios em tempo real!</p>
          </div>
        </div>

      </button>

      <button class="escopo-dashboard-desktop" @click="$router.push(`/tipo-email`)">

        <div class="opcao-dashboard">
          <div class="cacebalho-opcao">
            <h2 class="titulo-opcao">Tipo Email</h2>
            <i class="fas fa-user-friends"></i>
          </div>

          <div class="descricao-opcao">
            <p class="mt-6">Veja os relatórios em tempo real!</p>
          </div>
        </div>

      </button>

      <button class="escopo-dashboard-desktop" @click="$router.push(`/lista-tipo-fornecedor`)">

        <div class="opcao-dashboard">
          <div class="cacebalho-opcao">
            <h2 class="titulo-opcao">Tipo Fornecedor</h2>
            <i class="fas fa-user-friends"></i>
          </div>

          <div class="descricao-opcao">
            <p class="mt-6">Veja os relatórios em tempo real!</p>
          </div>
        </div>

      </button>

      <button class="escopo-dashboard-desktop" @click="$router.push(`/tipo-chapa`)">

        <div class="opcao-dashboard">
          <div class="cacebalho-opcao">
            <h2 class="titulo-opcao">Tipo Chapa</h2>
            <i class="fas fa-user-friends"></i>
          </div>

          <div class="descricao-opcao">
            <p class="mt-6">Veja os relatórios em tempo real!</p>
          </div>
        </div>

      </button>

      <button class="escopo-dashboard-desktop" @click="$router.push(`/tipo-uso-consumo`)">

        <div class="opcao-dashboard">
          <div class="cacebalho-opcao">
            <h2 class="titulo-opcao">Tipo Uso Consumo</h2>
            <i class="fas fa-user-friends"></i>
          </div>

          <div class="descricao-opcao">
            <p class="mt-6">Veja os relatórios em tempo real!</p>
          </div>
        </div>

      </button>

      <button class="escopo-dashboard-desktop" @click="$router.push(`/tipo-perfil`)">

        <div class="opcao-dashboard">
          <div class="cacebalho-opcao">
            <h2 class="titulo-opcao">Tipo Perfil</h2>
            <i class="fas fa-user-friends"></i>
          </div>

          <div class="descricao-opcao">
            <p class="mt-6">Veja os relatórios em tempo real!</p>
          </div>
        </div>

      </button>

      <button class="escopo-dashboard-desktop" @click="$router.push(`/tipo-servico`)">

        <div class="opcao-dashboard">
          <div class="cacebalho-opcao">
            <h2 class="titulo-opcao">Tipo Serviço</h2>
            <i class="fas fa-user-friends"></i>
          </div>

          <div class="descricao-opcao">
            <p class="mt-6">Veja os relatórios em tempo real!</p>
          </div>
        </div>

      </button>

      <button class="escopo-dashboard-desktop" @click="$router.push(`/tipo-vidro`)">

        <div class="opcao-dashboard">
          <div class="cacebalho-opcao">
            <h2 class="titulo-opcao">Tipo Vidro</h2>
            <i class="fas fa-user-friends"></i>
          </div>

          <div class="descricao-opcao">
            <p class="mt-6">Veja os relatórios em tempo real!</p>
          </div>
        </div>

      </button>

      <button class="escopo-dashboard-desktop" @click="$router.push(`/tipo-componente`)">

        <div class="opcao-dashboard">
          <div class="cacebalho-opcao">
            <h2 class="titulo-opcao">Tipo Componente</h2>
            <i class="fas fa-user-friends"></i>
          </div>

          <div class="descricao-opcao">
            <p class="mt-6">Veja os relatórios em tempo real!</p>
          </div>
        </div>

      </button>
    </div>
  </div>


</template>

<script>

export default {
  name: "Tipos",

  computed: {
    isadmin() {
      return this.$store.state.isAdminUser
    },
  }


}

</script>

<style lang="scss">

</style>