<template>
  <nav>
    <!--Menu lateral para telas mobile-->
    <div class="menu-lateral" v-if="menuLateral">
      <ul class="lista-menu-lateral">
        <li>Home</li>
        <li>Conheça o sistema</li>
        <li>Sobre a empresa</li>
        <li>
          <button type="button" class="" @click="acessarLoginMobile ? acessarLoginMobile = false : acessarLoginMobile = true">Acessar</button>
          <div class="acessar-login-mobile" v-if="acessarLoginMobile">
            
            <input class="acessar-nome-empresa-mobile" placeholder="Nome da empresa" type="text" v-model="nomeEmpresa">
            <button class="botao-tela-login-mobile" type="submit" @click="irLogin"><i class="fa-sharp fa-solid fa-arrow-right"></i></button>
          </div>
        </li>
      </ul>
    </div>
    <!--Menu Padrão-->
    <ul class="menu">
      <!--Menu computador-->
      <li>
        <div class="div-logo">
          <img src="@/assets/imagens/logo/Ellipse1.png" class="logo">
          <img src="@/assets/imagens/logo/Frame.png" class="nome">
        </div>
      </li>
      <li class="menu-computador">HOME</li>
      <li class="menu-computador">CONHEÇA O SISTEMA</li>
      <li class="menu-computador">SOBRE A EMPRESA</li>
      <li>
        <button type="button" class="menu-computador botao" @click="acessarLoginComputador ? acessarLoginComputador = false : acessarLoginComputador = true">Acessar</button>
        <div class="acessar-login" v-if="acessarLoginComputador">
            <div class="w-8 overflow-hidden inline-block -mt-20 absolute left-[75%]">
              <div class=" h-6 w-6 rounded-md bg-corBarraLateral rotate-45 transform origin-bottom-left"></div>
            </div>
          <input class="acessar-nome-empresa" placeholder="Nome da empresa" type="text" v-model="nomeEmpresa">
          <button class="botao-tela-login" type="submit" @click="irLogin"><i class="fa-sharp fa-solid fa-arrow-right"></i></button>
        </div>
      </li>
      <!--Menu hamburguer mobile-->
      <li class="menu-mobile">
        <button type="button" @click="menuHamburguer()"><i v-bind:class="iconeMenuHamburguer"></i></button>
      </li>

    </ul>
  </nav>
</template>

<script>
export default {
  name: "Menu",
  data() {
    return {
      /*Teste menu mobile se esta aberto/fechado*/
      menuLateral: false,
      /*Alterar icone menu mobile*/
      iconeMenuHamburguer: 'menu-hamburger fa-solid fa-bars',
      acessarLoginComputador: false,
      acessarLoginMobile:false,
      nomeEmpresa:"",
    }
  },
  methods: {
    menuHamburguer() {
      /*Teste menu mobile se esta aberto/fechado*/
      !this.menuLateral ? this.menuLateral = true : this.menuLateral = false
      /*Alterar icone menu mobile*/
      !this.menuLateral ? this.iconeMenuHamburguer = 'menu-hamburger fa-solid fa-bars' : this.iconeMenuHamburguer = 'menu-hamburger fa-sharp fa-solid fa-xmark'
    },
    /*Funcao para redirecionar para tela de login*/
    irLogin(){
      location.href= `http://${this.nomeEmpresa}.managerglass.com.br/login`
    }
  },
}
</script>

<style scoped>

/*Estilos para mobile*/
.menu-mobile {
  @apply block
  md:hidden
}

/*Estilo icone menu hamburguer*/
.menu-hamburger {
  @apply text-2xl
}

/*Estilo menu lateral*/
.menu-lateral {
  @apply w-52 h-screen top-0 fixed flex bg-[#164E63]
  md:hidden
}

/*Estilo lista menu lateral*/
.lista-menu-lateral {
  @apply w-full flex flex-col justify-center text-left text-white p-6
}

/*Estilo item lista menu lateral*/
.lista-menu-lateral > li {
  @apply my-6
}

/*Estilos para computador*/

/*Menu*/
/*Lista menu computador*/
.menu {
  @apply flex justify-between my-2 px-6
}

/*Item lista menu computador*/
.menu > li {
  @apply text-white my-auto text-sm
  lg:text-base
}

/*Estilo para ocultar menu computador*/
.menu-computador {
  @apply hidden
  md:block
}

/*Logo completa*/
.div-logo {
  @apply flex
}

/*Logo*/
.logo {
  @apply h-8 mr-4
}

/*Logo nome empresa*/
.nome {
  @apply h-4 my-auto
}

/*Botão de login*/
.botao {
  @apply text-sm px-2 py-1 font-medium text-white border border-corBarraLateral relative
  hover:bg-corBarraLateral
  md:text-base md:px-4 md:py-1
}

.acessar-login{
  @apply items-center absolute right-6 mt-6 hidden md:bg-corBarraLateral md:p-4 md:rounded-md
  md:flex
}

.acessar-nome-empresa{
  @apply h-8 text-sm text-center p-1 text-black rounded-md 
}

.botao-tela-login{
  @apply ml-4 h-8 px-2 text-white border-2 border-corBarraLateral rounded-md
  hover:bg-corBarraLateral
}

.acessar-login-mobile{
  @apply absolute flex flex-col
}
.acessar-nome-empresa-mobile{
  @apply h-6 my-2 text-sm text-center text-black
}
.botao-tela-login-mobile{
}
</style>