<template>
  <ListaOrcamentos/>
</template>

<script>
import ListaOrcamentos from '@/components/lista_orcamentos_components/ListaOrcamentos.vue'

export default {
  name: 'ListaOrcamentosView',

  data() {
    return {

    }
  },

  components: {
    ListaOrcamentos
  }
 
}
</script>

<style scoped lang="scss">

</style>
