<template>

  <agenda-geral />

</template>

<script>
import AgendaGeral from "@/components/agenda_componentes/AgendaGeral";

export default {
  name: "AgendaView",

  components: {
    AgendaGeral
  }
}
</script>

<style scoped>

</style>