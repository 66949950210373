<template>
  <CadastroOrcamentos/>
</template>

<script>
import CadastroOrcamentos from '@/components/cadastros_orcamentos_componentes/CadastroOrcamentos.vue'

export default {
  name: 'CadastroORcamentoView',

  components: {
    CadastroOrcamentos
  }
 
}
</script>