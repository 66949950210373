<template>
  <lista-entrada-nota-fiscal-componente />
</template>

<script>
import ListaEntradaNotaFiscalComponente from "@/components/estoque/ListaEntradaNotaFiscalComponente";
export default {
  name: "EntradaNotaFiscal",
  components: {
    ListaEntradaNotaFiscalComponente
  }

}
</script>

<style scoped>

</style>