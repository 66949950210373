<!-- Loading.vue -->
<template>
  <div v-if="isLoading" class="loading-overlay">
    <img class="logo-landingpage " src="@/assets/imagens/logo/logo.png" alt="Logo"/>
    <img class="rotating-arrow" src="@/assets/imagens/logo/setalogo.png" alt="Rotating Arrow"/>
  </div>
</template>

<script>
export default {
  name: 'Loading',
  props: {
    isLoading: Boolean,
  },
};
</script>

<style>
.loading-overlay {
  /* Adicione estilos para cobrir a tela com a animação de carregamento */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  flex-direction: column; /* Empilhar elementos verticalmente */
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.logo-landingpage,
.rotating-arrow {
  /* Adicione estilos para o posicionamento das imagens */
  width: 60px; /* Ajuste conforme necessário */
  position: absolute;
}

.rotating-arrow {
  /* Adicione estilos para a animação de rotação */
  animation: rotate 2s linear infinite; /* Ajuste a duração conforme necessário */
  z-index: 1; /* Defina um valor maior para sobrepor a logo */
}

@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}
</style>
,
<!--&lt;!&ndash; Loading.vue &ndash;&gt;-->
<!--<template>-->
<!--  <div v-if="isLoading" class="loading-overlay">-->
<!--    <img class="w-28" src="@/assets/imagens/logo/logo.png" alt="Logo"/>-->
<!--    <img class="rotating-arrow w-28" src="@/assets/imagens/logo/setalogo.png" alt="Rotating Arrow"/>-->
<!--  </div>-->
<!--</template>-->

<!--<script>-->
<!--export default {-->
<!--  name: 'Loading',-->
<!--  props: {-->
<!--    isLoading: Boolean,-->
<!--  },-->
<!--};-->
<!--</script>-->

<!--<style>-->
<!--.loading-overlay {-->
<!--  /* Adicione estilos para cobrir a tela com a animação de carregamento */-->
<!--  position: fixed;-->
<!--  top: 0;-->
<!--  left: 0;-->
<!--  width: 100%;-->
<!--  height: 100%;-->
<!--  background: rgba(255, 255, 255, 0.8);-->
<!--  display: flex;-->
<!--  justify-content: center;-->
<!--  align-items: center;-->
<!--  z-index: 999;-->
<!--}-->

<!--.logo-loading {-->
<!--  /* Adicione estilos para o posicionamento da logo */-->
<!--  margin-right: 10px; /* Adapte conforme necessário */-->
<!--}-->

<!--.rotating-arrow {-->
<!--  /* Adicione estilos para a animação de rotação */-->
<!--  animation: rotate 2s linear infinite; /* Ajuste a duração conforme necessário */-->
<!--}-->

<!--@keyframes rotate {-->
<!--  to {-->
<!--    transform: rotate(360deg);-->
<!--  }-->
<!--}-->
<!--</style>-->
