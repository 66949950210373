<template>
  <div class="container-formulario">

    <div class="cabecalho-formulario">
      <h1 class="text-xl text-corBase font-bold">Alterar Orçamento</h1>

      <router-link to="/lista-orcamentos" class="text-xl text-corBase font-bold">
        <i class="fas fa-chevron-left"></i>
      </router-link>
    </div>

    <div>
      <h2 class=" text-corBase font-bold">Descrição Orçamento</h2>
    </div>

    <div class="container-modelo-tabela">
      <table class="container-tabela overflow-auto">
        <thead class="cabecalho-tabela">
          <tr>
            <th scope="col" class="px-3 py-3">
              #
            </th>
            <th scope="col" class="px-3 py-3">
              Editar
            </th>
            <th scope="col" class="px-3 py-3">
              Nome
            </th>
            <th scope="col" class="px-3 py-3">
              Largura
            </th>
            <th scope="col" class="px-3 py-3">
              Altura
            </th>
            <th scope="col" class="px-3 py-3">
              Quantidade
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(itemOrcamento, index) in listaItensOrcamento" :key="index" class="escopo-tabela">
            <th scope="row" class="px-3 py-2 font-medium text-xs text-gray-900 whitespace-nowrap dark:text-white">
              {{ index + 1 }}
            </th>
            <td class="px-3 py-2 text-xs">
              <router-link :to="'/item-orcamento/' + itemOrcamento.id">
                <i class="fa-solid fa-paperclip"></i>
              </router-link>
            </td>
            <td class="px-3 py-2 text-xs">
              {{ itemOrcamento.descricao }}
            </td>
            <td class="px-3 py-2 text-xs">
              {{ itemOrcamento.largura }}
            </td>
            <td class="px-3 py-2 text-xs">
              {{ itemOrcamento.altura }}
            </td>
            <td class="px-3 py-2 text-xs">
              {{ itemOrcamento.quantidade }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="flex gap-6 w-full justify-end itens-center mt-6">
      <router-link class="btn-salvar" to="/orcamento/selecionar-projeto/">Adicionar Projeto</router-link>
      <button class="btn-salvar" @click.prevent="calcularOrcamento">Calcular</button>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'AlterarOrcamento',

  data() {
    return {
      listaItensOrcamento: []
    }
  },

  computed: {
    orcamentoId() {
      this.$store.state.orcamentoIdAtual = this.$route.params.id
      return this.$route.params.id
    }
  },

  created() {
    this.getItensOrcamento()
  },

  methods: {

    async getItensOrcamento() {
      try{
        const token = await this.$store.dispatch('getAuthTokenCookie');
        await this.$store.dispatch('fetchURLrequest');

        const response = await axios.get(`orcamentos/item-orcamento/?orcamento_id=${this.orcamentoId}`, {
          headers: {
            'Authorization': `Token ${token}`
          }
        })

        this.listaItensOrcamento = response.data.results
      }catch(err){
        console.error(err.message)
      }
    },

    async calcularOrcamento() {
      try{
        const token = await this.$store.dispatch('getAuthTokenCookie');
        await this.$store.dispatch('fetchURLrequest');

        const response = await axios.get(`projeto/engine/${this.orcamentoId}`, {
          headers: {
            'Authorization': `Token ${token}`
          }
        })

        this.listaItensOrcamento = response.data.results
      }catch(err){
        console.error(err.message)
      }
    },
  }
}
</script>

<style scoped lang="scss">

</style>