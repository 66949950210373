<template>

  <cadastro-pessoa-juridica />

</template>

<script>
import CadastroPessoaJuridica from "@/components/tipos_de_cadastros/CadastroPessoaJuridica";

export default {
  name: 'HomeView',

  components: {
    CadastroPessoaJuridica,
  }

}
</script>

<style lang="scss"></style>