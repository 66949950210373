<template>

  <div class="container-modal-editar">
    <div class="sombra-modal">
      <div class="conteudo-modal-editar">

        <div class="flex justify-end mx-5 gap-5">
          <div>
            <button class="text-corBase text-2xl" @click="fechar_ged"><i class="fas fa-xmark"></i></button>
          </div>
        </div>

        <form @submit.prevent="cadastrarImagem">

          <div class="flex flex-col md:flex-row  px-4">

            <div class="flex flex-col w-full">
              <label class="text-xl text-corBase font-bold">Adicione uma imagem:</label>
              <div class="imagePreviewWrapper" :style="{ 'background-image': `url(${previewImage})` }"
                   @click="selectImage">
              </div>
              <div class="flex gap-3 mt-4">
                <label for="file-input" class="selecionar-arquivo">
                  Selecionar
                </label>
                <input id="file-input" type="file" class="hidden" ref="fileInput" @change="handleImagemUpload"
                       @input="pickFile">
              </div>

            </div>
            <div class="w-full flex flex-col gap-6 ml-4 pt-2">

              <div class="w-full">
                <label class="text-xl text-corBase font-bold">Descricao</label>
                <input type="text" class="input-form-chapa" v-model="descricao">
              </div>

              <div class="w-full">
                <label class="text-xl text-corBase font-bold">Categoria</label>
                <select class="input-campos" v-model="categoria">
                  <option value="">Escolha uma Categoria</option>
                  <option v-for="cat in lista_categoria" :key="cat.id"
                          :value="cat.id">
                    {{ cat.text }}

                  </option>
                </select>
              </div>

              <div class="w-full">
                <label class="text-xl text-corBase font-bold">SubCategoria</label>
                <select class="input-campos" v-model="subcategoria">
                  <option value="">Escolha uma Subcategoria</option>
                  <option v-for="sub in lista_subcategoria" :key="sub.id"
                          :value="sub.id">
                    {{ sub.text }}

                  </option>
                </select>
              </div>
            </div>


          </div>

          <div class="flex w-full justify-end px-4 mt-6">

            <button class="bg-ativo py-2 px-4 rounded-sm text-screenCor" type="submit">
              Salvar
            </button>
          </div>

        </form>


      </div>
    </div>
  </div>

</template>

<script>
import Select2 from "vue3-select2-component";
import axios from "axios";


export default {
  name: 'ModalAddImagem',
  components: {
    Select2,
  },

  data() {
    return {
      sucesso: false,
      previewImage: null,
      fileInput: null,
      imagem: '',
      lista_imagens: [],
      lista_categoria: [],
      categoria: '',
      lista_subcategoria: [],
      subcategoria: '',
      descricao: '',
    }
  },

  computed: {
    minhaEmpresa() {
      return this.$store.state.minhaEmpresa;
    },
  },

  created() {
    this.getListaGategoria()
    this.getListaSubcategoria()
  },

  methods: {
    fechar_ged() {
      this.$emit('fechar_ged')
    },

    handleImagemUpload(event) {
      this.fileInput = event.target.files[0];
    },

    selectImage() {
      this.$refs.fileInput.click()
    },

    pickFile() {
      let input = this.$refs.fileInput
      let file = input.files
      if (file && file[0]) {
        let reader = new FileReader
        reader.onload = e => {
          this.previewImage = e.target.result
        }
        reader.readAsDataURL(file[0])
        this.$emit('input', file[0])
      }
    },

    async getListaGategoria() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get('ged/categoria', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            this.lista_categoria = response.data.map(item => ({
              id: item.id,
              text: item.nome
            }))
          })
          .catch(error => {
            console.log(error)
          })
    },

    async getListaSubcategoria() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get('ged/subcategoria', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            this.lista_subcategoria = response.data.map(item => ({
              id: item.id,
              text: item.nome
            }))
          })
          .catch(error => {
            console.log(error)
          })
    },

    async cadastrarImagem() {
      const token = await this.$store.dispatch('getAuthTokenCookie')

      await this.$store.dispatch('fetchURLrequest')

      const data = new FormData();
      data.append('ativo', 'True')
      data.append('descricao', this.descricao)
      data.append('categoria', this.categoria)
      data.append('subcategoria', this.subcategoria)
      data.append('url_documento', this.$refs.fileInput.files[0]);
      this.$store.dispatch("setLoading", true);
      await axios.post('ged/documentos/', data, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            console.log(response.data);
            this.enviarObjeto(response.data)
            this.$store.dispatch("setLoading", false);
            this.sucesso = true
          })
          .catch(error => {
            console.log(error);
            this.$store.dispatch("setLoading", false);
          })

    },

    enviarObjeto(objeto) {
      this.$emit('objetoClicado', objeto)
      this.fechar_ged()
    }

  }

}

</script>

<style lang="scss">

.container-galeria {
  @apply absolute w-[98%] h-[75vh] rounded-xl bg-white border border-corBase z-[1000000]
}

.div-filho-imagem {
  @apply pt-2 h-28 w-full flex justify-center flex-col items-center
}

.img-icone {
  @apply w-20 h-16
}

.span-icone {
  @apply text-center text-sm mt-2
}

.cabecalho-galeria {
  @apply flex justify-end mx-5 gap-5 my-3
}

.container-imagens {
  @apply grid grid-cols-8 place-items-center w-full  h-auto divide-x divide-y
}

</style>