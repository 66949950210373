<template>


  <div class="container-formulario">

    <!-- TILO DO FORMULARIO + BOTAO DE VOLTAR -->
    <div class="cabecalho-formulario">
      <h1 class="text-xl text-corBase">Dados Linha Base:</h1>

      <router-link to="/produtos/linha-produto-base" class="text-xl text-corBase font-bold">
        <i class="fas fa-chevron-left"></i>
      </router-link>
    </div>

    <!-- Escopo do formulario de cadastro mobile -->
    <form @submit.prevent="cadastrarLinhaBase">
      <!--        PARTE DE DADOS DO PRODUTO-->
      <div class="div-produtos">
        <div class="campo-chapa">
          <label class="font-bold text-corBase">Nome</label>
          <input type="text" v-model="nome" class="input-form-chapa" required autofocus>
        </div>
      </div>
      <div class="div-produtos">
        <div class="campo-chapa">
          <label class="font-bold text-corBase">Produto Aplicado</label>
          <Select2 v-model="produto_aplicado"
                   class="w-full z-10"
                   :options="lista_produto_aplicado"
                   :settings="{ width: '100%', multiple: true}"
          />
        </div>
      </div>


      <div class="flex w-full justify-end">
        <button class="btn-enviar" type="submit">Cadastrar</button>
      </div>
    </form>

  </div>
  <MensagemSucesso v-if="sucesso==true" rotas="/produtos/linha-produto-base" mensagem="Linha produto base cadastrado com sucesso!"/>

</template>

<script>
import axios from "axios";
import MensagemSucesso from "@/components/toasts/toast_sucesso/MensagemSucesso";
import Select2 from "vue3-select2-component";

export default {
  name: "CadastroLinhaProdutoBase",

  data() {
    return {
      sucesso: false,
      nome: '',
      produto_aplicado: '',
      lista_produto_aplicado: []
    }
  },

  components: {
    MensagemSucesso,
    Select2
  },

  created() {
    this.getListProdutoSistema()
  },

  computed: {
    minhaEmpresa() {
      return this.$store.state.minhaEmpresa;
    },
    isadmin() {
      return this.$store.state.isAdminUser
    },
  },

  methods: {

    async getListProdutoSistema() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get('produtos/produtos-sistema/', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            console.log(response.data);
            this.lista_produto_aplicado = response.data.map(item => ({
              id: item.id,
              text: item.nome
            }))
          })
          .catch(error => {
            console.log(error);
          })
    },

    async cadastrarLinhaBase() {
      const token = await this.$store.dispatch('getAuthTokenCookie')

      await this.$store.dispatch('fetchURLrequest')

      const data = {
        'ativo': 'True',
        'nome': this.nome,
        'produto_aplicado': this.produto_aplicado
      }
      // const data = new FormData();
      // data.append('ativo', 'True')
      // data.append('nome', this.nome)
      // data.append('produto_aplicado', this.produto_aplicado)
      this.sucesso = false
      await axios.post('produtos/linha-produto-base/', data, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            console.log(response.data);
            this.sucesso = true
          })
          .catch(error => {
            console.log(error);
          })

    },


  }

}
</script>

<style lang="scss">

</style>