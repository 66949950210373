<template>


  <preview-image v-if="preview_imagem" @closed="closed_preview_image" :imagem="preview_imagem"/>
  <div class="container-formulario">

    <!-- Cabeçalho lista de pessoas depois de 768 pixels-->
    <div class="container-cabecalho flex justify-between mb-4">
      <div>
        <h1 class="text-xl text-corBase font-bold">Imagens</h1>
      </div>

      <router-link to="/tipos" class="text-xl text-corBase font-bold">
        <i class="fas fa-chevron-left"></i>
      </router-link>

    </div>
    <div class="flex gap-8 items-end mb-6 z-10">
      <div class="w-full">
        <label class="text-xl text-corBase">Categoria</label>
        <Select2 v-model="categoria"
                 class="w-full z-10"
                 :options="lista_categoria"
                 :settings="{ width: '100%'}"
        />
      </div>
      <div class="w-full">
        <label class="text-xl text-corBase">Subcategoria</label>
        <Select2 v-model="subcategoria"
                 class="w-full z-10"
                 :options="lista_subcategoria"
                 :settings="{ width: '100%'}"
        />
      </div>
      <div>
        <button class="btn-add-fisica" @click="getListaImagem">Pesquisar</button>
      </div>
    </div>
    <div class="mb-8">
      <button @click="$router.push(`/imagem/cadastro`)" class="btn-add-fisica mr-2">Adicionar</button>
    </div>

    <!-- Container da exibiçao da lista de pessoas -->
    <div class="container-modelo-tabela">
      <table class="container-tabela overflow-auto">
        <thead class="cabecalho-tabela">
        <tr>
          <th scope="col" class="p-4">
            <div class="flex items-center">
              <input type="checkbox" class="checkbox" v-model="checkAll" @change="selectAll">

              <label class="sr-only">checkbox</label>
            </div>
          </th>
          <th scope="col" class="px-6 py-3">
            #
          </th>
          <th class="px-6 py-3">
            Nome
          </th>
          <th class="px-6 py-3">
            Imagem
          </th>
          <th scope="col" class="px-6 py-3">
            Status
          </th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(imagem, index) in lista_imagens" :key="index" class="escopo-tabela">
          <td class="w-4 p-4">
            <div class="flex items-center">
              <input :id="'checkbox-table-' + (index + 2)" type="checkbox" class="checkbox"
                     v-model="imagem.selected">
              <label class="sr-only">checkbox</label>
            </div>
          </td>
          <th scope="row" class="px-6 py-2 font-medium text-xs text-gray-900 whitespace-nowrap dark:text-white">
            {{ index + 1 }}
          </th>
          <td class="px-6 py-2 text-xs">
            {{ imagem.descricao }}
          </td>
          <td class="px-6 py-2 text-xs">
            <img :src="imagem.url_documento" class="w-14" alt="imagem sistema">
          </td>
          <td class="px-6 py-2 text-xs">
            {{ imagem.ativo }}
          </td>

        </tr>
        </tbody>
      </table>

      <div class="paginacao">
        <button @click="paginaAnterior" class="btn-paginacao"><i class="fas fa-chevron-left"></i></button>
        <span class="numero-paginacao">{{ paginaAtual - 1 }}</span>
        <span class="numero-paginacao">{{ paginaAtual }}</span>
        <span class="numero-paginacao">{{ paginaAtual + 1 }}</span>
        <button @click="proximaPagina" class="btn-paginacao"><i class="fas fa-chevron-right"></i></button>
      </div>
    </div>
  </div>

</template>

<script>
import axios from "axios";
import Select2 from "vue3-select2-component";
import PreviewImage from "@/components/midais/ged/PreviewImage";

export default {
  name: "ListaImagem",

  components: {
    Select2,
    PreviewImage
  },

  data() {
    return {
      lista_imagens: [],
      imagem: '',

      checkAll: false,
      // lista usada para salvar o retorno da requisição do get
      listaImagem: [],
      lista_categoria: [],
      categoria: '',
      lista_subcategoria: [],
      subcategoria: '',
      // lista usada para salvar esse retorno depois de dividido
      listaImagemPaginada: [],
      // varival que inicializa o numero de paginas
      paginaAtual: 1,
      preview_imagem: '',

      error: [],
    }
  },

  computed: {
    minhaEmpresa() {
      return this.$store.state.minhaEmpresa;
    },
  },

  created() {
    this.getListaGategoria()
    this.getListaSubcategoria()
  },

  methods: {
    closed_preview_image() {
      this.preview_imagem = ''
    },
    openPreviewImage(url) {
      this.preview_imagem = url
    },
    selectAll() {
      // Atualiza o estado dos demais checkboxes
      const checkboxes = document.querySelectorAll('.escopo-tabela input[type="checkbox"]');
      checkboxes.forEach((checkbox) => {
        checkbox.checked = this.checkAll;
      });
    },


    async getListaImagem() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      this.$store.dispatch("setLoading", true);
      await axios.get(`/ged/documentos/?categoria_id=${this.categoria}&subcategoria_id=${this.subcategoria}`, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            this.lista_imagens = response.data
            console.log(this.lista_imagens)
            this.$store.dispatch("setLoading", false);
          })
          .catch(error => {
            console.log(error)
            this.$store.dispatch("setLoading", false);
          })
    },

    // atualiza o valor da pagina no botao de voltar ao anterior
    paginaAnterior() {
      if (this.paginaAtual > 1) {
        this.paginaAtual--;
        this.paginarTabela();
      }
    },

    // atualiza o valor da pagina no botao de ir pra proxima pagina
    proximaPagina() {
      if (this.paginaAtual < this.totalPaginas) {
        this.paginaAtual++;
        this.paginarTabela();
      }
    },

    // exibe apenas 10 items da tabela e salva o resto
    paginarTabela() {
      const itemsPerPage = 5;
      const startIndex = (this.paginaAtual - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      this.listaImagemPaginada = this.listaImagem.filter((imagem, index) => {
        return index >= startIndex && index < endIndex;
      });
    },

    async getListaGategoria() {
      try {
        const listaCategoria = await this.$store.dispatch('fetchListaCategoriasGed')
        this.lista_categoria = listaCategoria.map(item => ({
          id: item.id,
          text: item.nome
        }))

      } catch (error) {
        console.log(error)
      }
    },

    async getListaSubcategoria() {
      try {
        const listaSubcategoria = await this.$store.dispatch('fetchListaSubcategoriasGed')
        this.lista_subcategoria = listaSubcategoria.map(item => ({
          id: item.id,
          text: item.nome
        }))

      } catch (error) {
        console.log(error)
      }
    },

  }
}
</script>

<style lang="scss">


</style>