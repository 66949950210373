<template>
  <details>
    <summary class="mt-4">
      <h2 class="bg-screenCor hover:bg-screenCor border border-corBase hover:border-corBase
  text-corBase hover:text-corBase py-2 px-4 ">Emails
      </h2>
    </summary>

    <button @click.prevent="adicionarEmail" class="bg-ativo hover:bg-screenCor border border-screenCor hover:border-ativo
  text-white hover:text-ativo py-2 px-4 mt-2 mr-2">Adicionar
    </button>

    <div class="border border-gray-200 p-2 mt-3" v-for="(email, index) in dadosEmail" :key="index">
      <div class="container-campos ">
        <div class="campos campos-mobile campos-dasktop-select">
          <label class="text-base text-corBase mt-2">Tipo de Email:</label>
          <div class="flex">
            <select class="input-campos" v-model="email.tipo_email">
              <option value="">------</option>
              <option v-for="item in lista_tipo_email"
                      :key="item.id"
                      :value="item.id"
              >{{ item.descricao }}
              </option>
            </select>

            <tipo-email-modal/>
          </div>
        </div>
        <div class="campos campos-mobile">
          <label class="text-base text-corBase mt-2">Email:</label>
          <input type="email"

                 class="input-campos"
                 placeholder=""
                 v-model="email.email_pessoa"
          >
        </div>
      </div>

    </div>

  </details>
</template>

<script>
import axios from "axios";

export default {
  name: "EmailsCampos",

  props: ['dadosEmail'],

  data() {
    return {
      lista_tipo_email: []
    }
  },

  created() {
    this.getListaTipoEmail()
  },

  methods: {
    adicionarEmail() {
      this.dadosEmail.push({
        email_pessoa: '',
        tipo_email: '',
      });
    },
    removerEmail(index) {
      this.dadosEmail.splice(index, 1);
    },

    async getListaTipoEmail() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get('pessoas/email/tipo-email', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
      .then(response => {
        this.lista_tipo_email = response.data.results
      })
      .catch(error => {
        console.log(error)
      })
    }
  }

}
</script>

<style scoped>

</style>