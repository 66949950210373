<template>

  <button @click="AbrirFechar">
    <i class="fas fa-xmark"></i>
  </button>

  <div v-if="showModal">
    <div class="container-modal-editar">
      <div class="sombra-modal">
        <div class="conteudo-modal-editar">
          <CadastroProdutoBase/>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import CadastroProdutoBase from "@/components/cadastros_produtos_componentes/CadastroProdutoBase";

export default {
  name: "NovoProdutoBaseModal",
  components: [CadastroProdutoBase],

  data() {
    return {
      showModal: false,
    }
  },

  methods: {
    AbrirFechar() {
      this.showModal = !this.showModal
    }
  }


}
</script>

<style lang="scss">

</style>