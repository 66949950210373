<template>
  <div class="carrossel">
      <!--Exibir descricao dependendo da foto exibida-->
      <p class="descricao-carrossel">{{descricao[fotoExibida]}}</p>
      <!--Marcador de foto exibida mobile-->
      <div class="div-foto-exibida-mobile">
        <div class="foto-exibida-mobile"></div>
        <div class="foto-exibida-mobile"></div>
        <div class="foto-exibida-mobile"></div>
        <div class="foto-exibida-mobile"></div>
      </div>
      <!--Marcador foto exibida computador-->
      <transition>
        <ul class="numero-foto">
          <li><img class="indicador" src="@/assets/imagens/icones/seta-carrossel.png" v-if="fotoExibida == 0"><button type="button" @click="reiniciarTempoIntervalo(0)">01</button></li>
          <li><img class="indicador" src="@/assets/imagens/icones/seta-carrossel.png" v-if="fotoExibida == 1"><button type="button" @click="reiniciarTempoIntervalo(1)">02</button></li>
          <li><img class="indicador" src="@/assets/imagens/icones/seta-carrossel.png" v-if="fotoExibida == 2"><button type="button" @click="reiniciarTempoIntervalo(2)">03</button></li>
          <li><img class="indicador" src="@/assets/imagens/icones/seta-carrossel.png" v-if="fotoExibida == 3"><button type="button" @click="reiniciarTempoIntervalo(3)">04</button></li>
        </ul>
      </transition>
      <!--Exibidor de foto-->
      <item-carrossel v-for="(slide, index) in slides" :slide="slide" :key="`item-${index}`" :foto-exibida="fotoExibida" :index="index"/>
  </div>
</template>

<script>
import ItemCarrossel from './ItemCarrossel.vue'
export default {
    name:"Carrossel",
    components: { ItemCarrossel },
    data:() =>({
        /*Indice da foto exibida, padrao comeca 0*/
        fotoExibida:0,
        /*Definir intervalo entre fotos*/
        intervalo:null,
    }),
    /*Props Para pegar as urls das fotos e as descricao das fotos*/
    props:["slides","descricao"],

    methods:{
        /*Mudar numero foto exibida*/
        definirFotoExibida(index){
            this.fotoExibida = index;
        },
        /*Definir qual vai ser a proxima foto exibida*/
        tempoIntervalo(){
          const index = this.fotoExibida < this.slides.length -1 ? this.fotoExibida + 1 : 0;
          this.definirFotoExibida(index);
        },
        /*Reiniciar o tempo do intervalo para 0 quando selecionar o numero da foto*/
        reiniciarTempoIntervalo(num){
          clearInterval(this.intervalo)
          this.fotoExibida = num
          this.intervalo = setInterval(this.tempoIntervalo,5000)
        }
    },
    /*Iniciar intervalo quando montar a pagina*/
    mounted(){
        this.intervalo = setInterval(this.tempoIntervalo,5000)
    },
    /*Para intervalo quando desmontar a pagina*/
    beforeUnmount(){
        clearInterval(this.intervalo)
    }

}
</script>

<style scoped>

/*Mobile*/
.div-foto-exibida-mobile{
  @apply w-full absolute z-10 bottom-6 flex justify-center
  sm:hidden
}
/*Indicador de qual foto esta sendo exibida*/
.foto-exibida-mobile{
  @apply w-8 h-2 mx-2 bg-white rounded-lg
}

/*Computador*/

/*Descricao do carrossel que muda conforme a imagem*/
.descricao-carrossel{
  @apply absolute z-10 bottom-12 text-lg w-4/5 text-white font-bold text-center left-1/2 translate-x-[-50%]
  sm:w-3/5 sm:text-2xl sm:text-left sm:pl-6 sm:left-0 sm:translate-x-0
}

/*Numero da foto que esta sendo exibida computador*/
.numero-foto{
  @apply flex-col items-end absolute z-10 right-6 top-[calc((100%-192px)/2)] hidden
  sm:flex
}
/*item da lista foto exibida*/
.numero-foto>li{
  @apply flex text-white font-bold text-lg my-4
}
/*Seta que indica qual foto esta sendo exibida*/
.indicador{
  @apply w-4 mr-2
}

.indicador-enter-active, .indicador-leave-active{
    @apply transition-all duration-700 ease-in-out
}
.indicador-enter-from{
  opacity: 0%;
}

.indicador-leave-to{
  opacity: 100%;
}
</style>