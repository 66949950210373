<template>

  <!-- Lista de pessoas até 768 pixels-->
  <div class="container-formulario">
    <div class="container-cabecalho flex justify-between mb-4">
      <div>
        <h1 class="text-xl text-corBase font-bold">Movimentações</h1>
      </div>

      <router-link to="/estoque" class="text-xl text-corBase font-bold">
        <i class="fas fa-chevron-left"></i>
      </router-link>

    </div>
    <div class="flex items-end mb-3 gap-8">
      <div class="flex items-end gap-3">

        <div class="flex gap-8">
          <div>
            <button class="bg-corBase hover:bg-screenCor border border-screenCor hover:border-corBase
  text-white hover:text-corBase py-2 px-4" @click="getListaMovimentacoes">Pesquisar
            </button>
          </div>
        </div>
        <div>
          <button @click="$router.push(`/estoque/entrada-saida-estoque`)" class="bg-ativo hover:bg-screenCor border border-screenCor hover:border-ativo
  text-white hover:text-ativo py-2 px-4 ">Adicionar
          </button>
        </div>
      </div>
    </div>


    <!-- Container da exibiçao da lista de pessoas -->
    <div class="container-modelo-tabela">
      <table class="container-tabela overflow-auto">
        <thead class="cabecalho-tabela">
        <tr>
          <th scope="col" class="px-6 py-3">
            #
          </th>
          <th scope="col" class="px-6 py-3 text-center">
            Tipo
          </th>
          <th class="px-6 py-3">
            Data
          </th>

        </tr>
        </thead>
        <tbody>
        <tr v-for="(moviemntacao, index) in lista_movimentacoes" :key="index" class="escopo-tabela">

          <th scope="row" class="px-6 py-2 font-medium text-xs text-gray-900 whitespace-nowrap dark:text-white">
            {{ index + 1 }}
          </th>
          <td class="px-6 py-2 text-xs text-center">
            {{ moviemntacao.tipo_movimentacao }}
          </td>
          <td class="px-6 py-2 text-xs">
            {{ moviemntacao.data_movimentacao }}
          </td>

        </tr>
        </tbody>
      </table>
      <div class="paginacao">
        <button @click.prevent="paginaAnterior" class="btn-paginacao">
          <i class="fas fa-chevron-left"></i>
        </button>
        <span v-for="pagina in paginasExibidas" :key="pagina">
          <button @click.prevent="irParaPagina(pagina)"
                  :class="{ 'btn-paginacao': true, 'pagina-atual': pagina === paginaAtual }">
            {{ pagina }}
          </button>
        </span>
        <button @click.prevent="proximaPagina" class="btn-paginacao">
          <i class="fas fa-chevron-right"></i>
        </button>
      </div>
    </div>
  </div>
  <!-- Cabeçalho lista de pessoas depois de 768 pixels-->


</template>

<script>
import axios from "axios";
import Select2 from "vue3-select2-component";
import EditarProdutoBase from "@/components/midais/Editar/produtos/EditarProdutoBase";
import PreviewImage from "@/components/midais/ged/PreviewImage";

export default {
  name: "ListaProdutoBase",

  components: {
    EditarProdutoBase,
    Select2,
    PreviewImage
  },

  data() {
    return {
      checkAll: false,
      // lista usada para salvar o retorno da requisição do get
      lista_movimentacoes: [],
      // lista usada para salvar esse retorno depois de dividido
      listaMovimentacoesPaginada: [],
      // varival que inicializa o numero de paginas
      itemsPorPagina: 5,
      paginaAtual: 0,
      next_page: null,
      totalDePaginas: 0,


      error: []
    }
  },

  created() {
  },

  computed: {


    isadmin() {
      return this.$store.state.isAdminUser
    },

    minhaEmpresa() {
      return this.$store.state.minhaEmpresa;
    },

    paginasExibidas() {
      const paginas = [];
      const paginasVisiveis = 5; // Número de páginas visíveis de cada vez

      let inicio = Math.max(1, this.paginaAtual - Math.floor(paginasVisiveis / 2));
      let fim = inicio + paginasVisiveis - 1;

      if (inicio + paginasVisiveis > this.totalDePaginas) {
        inicio = Math.max(1, this.totalDePaginas - paginasVisiveis + 1);
        fim = this.totalDePaginas;
      }

      for (let i = inicio; i <= fim; i++) {
        paginas.push(i);
      }

      return paginas;
    },
  },

  methods: {
    async getListaMovimentacoes() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')
      if (this.paginaAtual === 0) {
        await axios.get(`produtos/movimentacao?page=1`, {
          headers: {
            'Authorization': `Token ${token}`
          }
        })
            .then(response => {
              this.lista_movimentacoes = response.data.results
              console.log(this.lista_movimentacoes)
              this.totalDePaginas = response.data.count / 5
              console.log(this.totalDePaginas)
              this.paginaAtual += 1
            })
      } else {
        await axios.get(`produtos/movimentacao?page=${this.paginaAtual}`, {
          headers: {
            'Authorization': `Token ${token}`
          }
        })
            .then(response => {
              this.lista_movimentacoes = response.data.results
              console.log(this.lista_movimentacoes)
              this.totalDePaginas = response.data.count / 5
              console.log(this.totalDePaginas)
              this.paginaAtual += 1
            })
      }

    },

    proximaPagina() {
      this.getListaProdutos();
    },

    paginaAnterior() {
      this.paginaAtual -= 1
      this.getListaProdutos()
    },

    async irParaPagina(pagina) {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      console.log(pagina)
      this.paginaAtual = pagina
      await axios.get(`produtos/movimentacao?page=${this.paginaAtual}`, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            this.lista_movimentacoes = response.data.results
            console.log(this.lista_movimentacoes)
            this.totalDePaginas = response.data.count / 5
          })
    }

  }
}
</script>

<style lang="scss">

</style>