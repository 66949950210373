<template>


  <button class="ml-3 text-ativo" @click.prevent="modalPessoa">
    <i class="fas fa-plus-circle"></i>
  </button>

  <div class="container-modal-cadastro" v-if="modalTipoPessoa">
    <div class="sombra-modal">
      <div class="conteudo-modal">
        <form class="formulario-modal" @submit.prevent="cadastarTipoPessoa">
          <div class="flex justify-end">
            <button @click.prevent="fecharModal">
              <i class="fas fa-close text-2xl"></i>
            </button>
          </div>
          <h2 class="titulo-formulario">Cadastro Tipo Pessoa Geral</h2>
          <label class="label-formulario-modal">Sigla:</label>
          <input type="text"
                 required
                 class="input-campos"
                 v-model="sigla"
          >

          <label class="label-formulario-modal">Descrição:</label>
          <input type="text"
                 required
                 class="input-campos"
                 v-model="descricao"
          >

          <div class="btn-formulario-modal">
            <button
                class="btn-formulario"
                type="submit"
            >
              Cadastrar
            </button>
          </div>
        </form>
      </div>
    </div>

  </div>

</template>

<script>
import axios from "axios";


export default {
  name: "TipoPessoaGeralModal",
  data() {
    return {
      modalTipoPessoa: false,
      sigla: '',
      descricao: '',
    }
  },

  methods: {
    modalPessoa() {
      this.modalTipoPessoa = !this.modalTipoPessoa
    },

    fecharModal() {
      this.modalTipoPessoa = !this.modalTipoPessoa
    },

    async cadastarTipoPessoa() {
      try {
        const tipo_pessoa_geral = {sigla: this.sigla, descricao: this.descricao}

        const token = await this.$store.dispatch('getAuthTokenCookie');
        await this.$store.dispatch('fetchURLrequest')
        const response = await axios.post('pessoas/tipo-pessoas', tipo_pessoa_geral, {
          headers: {
            'Authorization': `Token ${token}`
          }
        });
        this.fecharModal()
      } catch (error) {
        this.erro = error.response.data.erro;
        console.error(this.erro);
      }

    }

  }
}
</script>

<style lang="scss">

/* estilo mobile */
.container-modal-cadastro {
  @apply fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full h-full z-[1002]

}

.sombra-modal {
  @apply w-full h-full bg-black/40
}

.conteudo-modal {
  @apply bg-screenCor p-6 rounded-2xl w-80 z-[1002] shadow-xl shadow-screenCor/30
  fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2
}

.formulario-modal {
  @apply flex flex-col justify-center text-corBase
}

.titulo-formulario {
  @apply text-lg text-corBase font-bold mb-2
}

.label-formulario-modal {
  @apply text-base font-bold mb-2 mt-4 text-corBase
}

.campo-formulario-modal {
  @apply w-full rounded-lg text-corBase/70 p-2 shadow-md shadow-screenCor/20
}

.btn-formulario-modal {
  @apply flex justify-end text-screenCor mt-8
}

.btn-formulario {
  @apply bg-ativo py-1 px-3
}

</style>