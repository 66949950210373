<template>

  <div class="flex w-full">
    <button class="px-2 py-1 bg-corBase text-white" @click.prevent="fecharMidal">Adicionar</button>
  </div>

  <div class="fundo-midal top-0" v-if="midal==true">

    <div class="midal">

      <div class="secao-icone-fechar">
        <button class="fa-solid fa-xmark icone-fechar" @click.prevent="fecharMidal()"></button>
      </div>

      <form class="flex flex-col w-full gap-6" @submit.prevent="cadastroFormulaProjeto">

        <div>
          <label class="text-lg font-bold text-corBase">Código</label>
          <input type="text" class="input-campos" v-model="codigo"/>
        </div>

        <div>
          <label class="text-lg font-bold text-corBase">Descrição</label>
          <input type="text" class="input-campos" v-model="descricao"/>
        </div>

        <div>
          <label class="text-lg font-bold text-corBase">Formúla</label>
          <input type="text" class="input-campos" v-model="formula"/>
        </div>

        <div class="flex w-full justify-end itens-center">
          <button class="btn-cancelar" @click.prevent="fecharMidal">Cancelar</button>
          <button class="btn-salvar" type="submit">Salvar</button>
        </div>

      </form>


    </div>

    <!--    <mensagem-sucesso v-if="sucesso === true" :rotas="'/alterar-projeto/' + projeto" mensagem="Formúla do Projeto Cadastrada com Sucesso!" />-->

  </div>

  <div class="fundo-midal top-0" v-if="sucesso === true">
    <div class="midal">

      <div class="secao-icone-fechar">
        <router-link :to="'/alterar-projeto/' + projeto" class="fa-solid fa-xmark icone-fechar"
                     @click.prevent="sucessoModal()"></router-link>
      </div>

      <div class="secao-mensagem-sucesso-midal">
        <p class="mensagem-sucesso-midal">Formúla do Projeto Cadastrada com Sucesso!</p>
        <div class="fundo-icone-deu-certo"><i class="fa-solid fa-check icone-deu-certo"></i></div>
      </div>

      <div class="secao-botao">
        <router-link class="botao-midal botao-midal-voltar" :to="'/alterar-projeto/' + projeto"
                     @click.prevent="sucessoModal()">Voltar
        </router-link>
        <button type="button" class="botao-midal botao-midal-continuar" @click.prevent="sucessoModal()">Novo</button>
      </div>
    </div>
  </div>

</template>

<script>
import axios from "axios";

export default {
  name: "ModalFormulaProjeto",

  data() {
    return {
      midal: false,
      sucesso: false,
      codigo: '',
      descricao: '',
      formula: '',
    }
  },

  props: ["projeto"],

  created() {
    this.midal = false
  },

  computed: {
    // pega a empresa que esta logada de dentro da store
    minhaEmpresa() {
      return this.$store.state.minhaEmpresa;
    },
  },

  methods: {
    fecharMidal() {
      this.midal = !this.midal
    },
    sucessoModal() {
      this.sucesso = !this.sucesso
      this.fecharMidal()
    },

    async cadastroFormulaProjeto() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      const data = {
        projeto: this.projeto,
        codigo: this.codigo,
        descricao: this.descricao,
        formula: this.formula,

      }

      await axios.post(`/projeto/formula-projeto/`, data, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            console.log(response.data)
            this.sucesso = true
            this.$emit('buscarFormula')
          })
          .catch(error => {
            console.log(error)
          })

    }

  }
}
</script>

<style lang="scss">

</style>