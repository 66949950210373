<template>
  <div class="container-modelo-tabela">
    <table class="container-tabela overflow-auto">
      <thead class="cabecalho-tabela">
        <tr>
          <th scope="col" class="px-3 py-3">
            #
          </th>
          <th scope="col" class="px-3 py-3">
            Editar
          </th>
          <th class="px-3 py-3">
            Componente
          </th>
          <th scope="col" class="px-3 py-3">
            Descrição
          </th>
          <th scope="col" class="py-3">
            Quantidade
          </th>
          <th scope="col" class="py-3">
            Condição
          </th>
          <th scope="col" class="py-3">
            Aplicação
          </th>
        </tr>
      </thead>
      <tbody>
      <tr v-for="(componente, index) in componentes" :key="index" class="escopo-tabela">
        <th scope="row" class="px-3 py-2 font-medium text-xs text-gray-900 whitespace-nowrap dark:text-white">
          {{ index + 1 }}
        </th>
        <td class="px-3 py-2 text-xs">
          <button>
            <i class="fa-solid fa-paperclip"></i>
          </button>
        </td>
        <td class="px-3 py-2 text-xs">
          {{ componente.componente.codigo_base }}
        </td>
        <td class="px-3 w-60 py-2 text-xs">
          {{ componente.componente.nome_base }}
        </td>
        <td class="px-3 py-2 text-xs">
          {{ componente.qtd_formula }}
        </td>
        <td class="px-3 py-2 text-xs">
          {{ componente.condicao }}
        </td>
        <td class="px-3 py-2 text-xs">
          {{ componente.aplicacao }}
        </td>

      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>

export default {
  name: 'AlterarComponenteOrcamento',

  data() {
    return {

    }
  },

  props: ['componentes']
}
</script>

<style scoped lang="scss">

</style>