<template>

  <div class="container-formulario">

    <galeria v-if="showGaleria === true" @fechar_galeria="abrir_galeria" @objetoClicado="atualizarFileInput"/>
    <modal-add-imagem v-if="ged === true" @fechar_ged="addImageGed" @objetoClicado="atualizarFileInput"/>

    <!-- TILO DO FORMULARIO + BOTAO DE VOLTAR -->
    <div class="cabecalho-formulario">
      <h1 class="text-xl text-corBase font-bold">Dados Tipologia:</h1>

      <router-link to="/lista-tipologia" class="text-xl text-corBase font-bold">
        <i class="fas fa-chevron-left"></i>
      </router-link>
    </div>

    <!-- Escopo do formulario de cadastro mobile-->
    <form @submit.prevent="cadastrarTipologia">
      <!--        PARTE DE DADOS DO PRODUTO-->
      <div class="flex flex-col gap-3 my-3 w-full md:flex-row md:gap-10 ">

        <div class="flex flex-col w-full md:w-[50%] mt-6">
          <label class="text-corBase text-xl font-bold">Adicione uma imagem:</label>
          <div class="imagePreviewWrapper" :style="{ 'background-image': `url(${previewImage.url_documento})` }"
               @click.prevent="abrir_galeria">
          </div>
          <div class="flex gap-3 mt-4">
            <button class="selecionar-arquivo" @click.prevent="addImageGed">Adicionar</button>
            <button class="selecionar-arquivo" @click.prevent="abrir_galeria">Pesquisar</button>
          </div>

        </div>

        <div class=" flex flex-col w-full gap-4 mr-2 pt-6">

          <div class="campo-chapa">
            <label class="text-xl text-corBase font-bold">Nome</label>
            <input type="text" v-model="nome" class="input-form-chapa" required autofocus>
          </div>

          <div class="campo-chapa">
            <label class="text-xl text-corBase font-bold">Categoria</label>
            <Select2 v-model="modelo"
                     class="w-full z-10"
                     :options="lista_modelo"
                     :settings="{ width: '100%'}"
            />
          </div>

          <div class="campo-chapa">
            <label class="text-xl text-corBase font-bold">Linhas</label>
            <Select2 v-model="linhas_sistemas"
                     class="w-full z-10"
                     :options="lista_linhas_sistemas"
                     :settings="{ width: '100%', multiple:true}"
            />
          </div>
        </div>

      </div>


      <div class="flex w-full justify-end">
        <button class="btn-enviar" type="submit">Salvar</button>
      </div>

    </form>
  </div>

  <mensagem-sucesso v-if="sucesso === true" rotas="/lista-tipologia" mensagem="Tipologia cadastrada com sucesso!"/>
</template>

<script>
import Galeria from "@/components/midais/ged/Galeria";
import ModalAddImagem from "@/components/ged/ModalAddImagem";
import axios from "axios";
import MensagemSucesso from "@/components/toasts/toast_sucesso/MensagemSucesso";
import Select2 from "vue3-select2-component";

export default {
  name: "CadastroTipologia",

  components: {
    MensagemSucesso,
    Select2,
    Galeria,
    ModalAddImagem
  },


  data() {
    return {
      showGaleria: false,
      ged: false,
      sucesso: false,
      previewImage: {},
      nome: '',
      modelo: '',
      linhas_sistemas: [],
      lista_modelo: [],
      lista_linhas_sistemas: [],

    }
  },

  created() {
    this.getListaModelo()
    this.getListaLinhaSistema()

  },

  computed: {
    minhaEmpresa() {
      return this.$store.state.minhaEmpresa;
    },
    isadmin() {
      return this.$store.state.isAdminUser
    },
  },

  methods: {
    atualizarFileInput(objeto) {
      console.log('objeto que chegou:' + objeto)
      console.log(objeto)
      this.previewImage = objeto;
      console.log(this.previewImage)
    },

    addImageGed() {
      this.ged = !this.ged
    },

    abrir_galeria() {
      this.showGaleria = !this.showGaleria
    },


    async cadastrarTipologia() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')


      const data = {
        ativo: true,
        nome: this.nome,
        modelo: this.modelo,
        linhasistema: this.linhas_sistemas,
        imagem: this.previewImage.id
      }

      this.$store.dispatch("setLoading", true);
      this.sucesso = false
      await axios.post('projeto/tipologia/', data, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            console.log(response.data.results);
            this.nome = ''
            this.modelo = ''
            this.linhas_sistemas = []
            this.previewImage = {}
            this.sucesso = true;
            this.$store.dispatch("setLoading", false);
          })
          .catch(error => {
            console.log(error);
            this.$store.dispatch("setLoading", false);
          })

    },


    // get para modelo e linhas
    async getListaModelo() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get('projeto/modelo-variacao/', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            this.lista_modelo = response.data.results.map(item => ({
              id: item.id,
              text: item.descricao
            }));
            console.log(response.data.results)
          })
          .catch(error => {
            console.log(error)
          })
    },

    async getListaLinhaSistema() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get('projeto/linha-sistema/', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            this.lista_linhas_sistemas = response.data.results.map(item => ({
              id: item.id,
              text: item.linha
            }));
            console.log(response.data.results)
          })
          .catch(error => {
            console.log(error)
          })
    },


  }

}
</script>

<style lang="scss"></style>