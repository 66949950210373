<template>
  <button class="bg-ativo hover:bg-screenCor border border-screenCor hover:border-ativo
  text-white hover:text-ativo py-2 px-4 mt-2 mr-2" @click.prevent="abrirModal">Adicionar
  </button>

  <template v-if="modal === true">
    <div class="container-modal-atual">
      <div class="container-campos-endereco">
        <div class="cabecalho-formulario">
          <h1 class="text-xl text-corBase font-bold">Dados Endereço:</h1>

          <button @click.prevent="abrirModal" class="text-xl text-corBase font-bold">
            <i class="fas fa-xmark"></i>
          </button>
        </div>

        <div class="flex flex-col gap-3 md:flex md:flex-row md:gap-3 mb-6">
          <div class="flex flex-col mt-2 w-full">
            <label class="label-campos">CEP:</label>
            <input type="text" required placeholder="" class="input-campos" v-mask="'#####-###'" v-model="cep"
                   @input="buscarCepAutomaticamente">
          </div>

          <div class="flex flex-col mt-2 w-full">
            <label class="label-campos">Número:</label>
            <input type="number" required class="input-campos" placeholder="" v-model="numero">
          </div>

          <div class="flex flex-col mt-2 w-full">
            <label class="label-campos">UF:</label>
            <div class="">
              <select class="input-campos" v-model="uf">
                <option>------</option>
                <option value="AC">Acre</option>
                <option value="AL">Alagoas</option>
                <option value="AP">Amapá</option>
                <option value="AM">Amazonas</option>
                <option value="BA">Bahia</option>
                <option value="CE">Ceará</option>
                <option value="DF">Distrito Federal</option>
                <option value="ES">Espírito Santo</option>
                <option value="GO">Goiana</option>
                <option value="MA">Maranhão</option>
                <option value="MT">Mato Grosso</option>
                <option value="MS">Mato Grosso do Sul</option>
                <option value="MG">Minas Gerais</option>
                <option value="PA">Pará</option>
                <option value="PB">Paraíba</option>
                <option value="PR">Paraná</option>
                <option value="PE">Pernanbuco</option>
                <option value="PI">Piauí</option>
                <option value="RJ">Rio de Janeiro</option>
                <option value="RN">Rio Grande do Norte</option>
                <option value="RS">Rio Grande do Sul</option>
                <option value="RO">Rondônia</option>
                <option value="RR">Roraima</option>
                <option value="SC">Santa Catarina</option>
                <option value="SP">São Paulo</option>
                <option value="SE">Sergipe</option>
                <option value="TD">Tocantins</option>

              </select>
            </div>
          </div>
        </div>

        <div class="flex flex-col gap-3 md:flex md:flex-row md:gap-3 mb-6">
          <div class="flex flex-col mt-2 w-full">
            <label class="label-campos">Cidade:</label>
            <input type="text" required class="input-campos" placeholder="" v-model="cidade">
          </div>

          <div class="flex flex-col mt-2 w-full">
            <label class="label-campos">Bairro:</label>
            <input type="text" required class="input-campos" placeholder="" v-model="bairro">
          </div>

          <div class="flex flex-col mt-2 w-full">
            <label class="label-campos">Tipo de Endereço:</label>
            <div class="flex">
              <select class="input-campos" v-model="tipoEnderecoID">
                <option>------</option>
                <option v-for="item in tipo_endereco"
                        :value="item.id"
                        :key="item.id"
                >{{ item.descricao }}
                </option>
              </select>

              <tipo-endereco-modal/>
            </div>
          </div>
        </div>

        <div class="flex flex-col gap-3 md:flex md:flex-row md:gap-3 mb-6">
          <div class="flex flex-col mt-2 w-full">
            <label class="label-campos">Logradouro:</label>
            <input type="text" required class="input-campos" placeholder="" v-model="logadouro">
          </div>

        </div>
        <div class="flex flex-col gap-3 md:flex md:flex-row md:gap-3 mb-6">

          <div class="flex flex-col mt-2 w-full">
            <label class="label-campos">Complemento:</label>
            <input type="text" required class="input-campos" placeholder="" v-model="complemento">
          </div>

          <div class="flex flex-col mt-2 w-full">
            <label class="label-campos">Referência:</label>
            <input type="text" required class="input-campos" placeholder="" v-model="referencia">
          </div>

        </div>

        <div class="flex w-full justify-end">
          <button class="btn-enviar" @enivarDados="enivarDados">Salvar</button>
        </div>
      </div>


    </div>

  </template>
</template>

<script>
import axios from "axios";
import TipoEnderecoModal from "@/components/midais/modaisCadastro/TipoEnderecoModal";

export default {
  name: "Enderecos",
  components: {
    TipoEnderecoModal
  },

  data() {
    return {
      modal: false,
      cep: '',
      logadouro: '',
      numero: '',
      bairro: '',
      cidade: '',
      uf: '',
      tipoEnderecoID: '',
      referencia: '',
      logradouro: '',
      complemento: '',


    }
  },

  watch: {
    'cep': {
      handler(newCep) {
        if (newCep.length === 9) {
          this.buscarCep(newCep);
        }
      },
      deep: true
    },
  },

  methods: {
    enivarDados() {
      const dados = {
        cep: this.cep,
        logadouro: this.logadouro ,
        numero: this.numero,
        bairro: this.bairro,
        cidade: this.cidade,
        uf: this.uf,
        tipoEnderecoID: this.tipoEnderecoID,
        referencia: this.referencia,
        logradouro: this.logradouro,
        complemento: this.complemento,
      }
      this.$emit('salvar-dados', dados);
    },
    abrirModal() {
      this.modal = !this.modal
    },

    buscarCepAutomaticamente() {
      if (this.dados.cep.length === 9) {
        this.buscarCep();
      }
    },
    async buscarCep() {
      // Fazer a chamada à API de CEP aqui
      // Por exemplo:
      const cepSemMascara = this.dados.cep.replace(/[^0-9]/g, '');
      const token = await this.$store.state.token

      this.$store.dispatch('fetchURLrequest')
      await axios
          .get(`cep/${cepSemMascara}/`, {
            headers: {
              'Authorization': `Token ${token}` // Define o cabeçalho Authorization com o token
            }
          })
          .then(response => {
            console.log(response.data);
            // Processar a resposta da API e atualizar os campos de endereço
            this.dados.logadouro = response.data.logradouro;
            this.dados.bairro = response.data.bairro;
            this.dados.cidade = response.data.localidade;
            this.dados.uf = response.data.uf;
          })
          .catch(error => {
            console.error('Erro ao buscar CEP:', error);
          });
    }

  }

}
</script>

<style lang="scss">

.container-modal-atual {
  @apply fixed top-0 left-0 bg-black/30 w-full h-[100%] z-[100000]
}

.container-campos-endereco {
  @apply absolute top-1/2 left-1/2 bg-screenCor transform -translate-x-1/2 -translate-y-1/2 w-[70%] h-auto p-4
}


</style>