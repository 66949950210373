<template>
  <div>
    <div>
      <button @click="downloadProductReport">Baixar Relatório de Produtos</button>
    </div>
    <div>
      <button @click="downloadFichaFisicaFinanceiraReport">Baixar Relatório de Ficha Física Financeira</button>
    </div>

  </div>
</template>

<script>
import axios from 'axios';

export default {
  methods: {
    async downloadProductReport() {
      try {
        await this.$store.dispatch('fetchURLrequest')
        const response = await axios.get('relatorios/reports/products/', {
          responseType: 'blob'  // Importante para tratar o retorno como arquivo
        });

        // Cria um link temporário para download
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'relatorio_produtos.pdf');  // Nome do arquivo baixado
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

      } catch (error) {
        console.error("Erro ao baixar o relatório:", error);
      }
    },
    async downloadFichaFisicaFinanceiraReport() {
      try {
        // Chama a ação do Vuex, se necessário
        await this.$store.dispatch('fetchURLrequest');

        // Faz a requisição para obter o PDF como blob
        const response = await axios.get('relatorios/reports/ficha-fisica/', {
          responseType: 'blob'  // Importante para tratar o retorno como arquivo binário (blob)
        });

        // Cria um objeto URL para o blob retornado
        const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));

        // Abre o PDF em uma nova aba
        const pdfWindow = window.open(url);
        if (pdfWindow) {
          pdfWindow.document.title = 'Relatório Fichas Físicas Financeiras';  // Opcional: define o título da aba
        } else {
          console.error('Não foi possível abrir o PDF em uma nova aba');
        }

      } catch (error) {
        console.error("Erro ao gerar o relatório:", error);
      }
    }

  }
}
</script>