<template>
  <section>
    <h2>Vantagens do ManagerGlass</h2>
    <!--Lista vantagens do sistema-->
    <ul class="lista-vantegem">
      <li class="item-lista-vantagem" v-for="(icone, index) in icones">
        <img class="icone" :src="require(`@/assets/imagens/icones/${icone}.png`)">
        <p class="descricao" v-text="descricaos[index]"></p>
      </li>
    </ul>
  </section>
</template>

<script>
export default {
  name: "VantagensSistema",
  data(){
    return{
     icones:["produtividade","projeto","estoque","ok","financeiro","atualizacao"],
     descricaos:[
     "Aumento da produtividade",
     "Melhoria no gerenciamento de projetos",
     "Controle preciso do estoque",
     "Simplificação de orçamentos e faturamento",
     "Controle financeiro aprimorado",
     "Suporte e atualizações regulares"
     ],
    }
  },
}
</script>

<style scoped>
/*Titulo vantagens do sistema*/
h2{
  @apply px-6 mt-16 mb-16 text-center text-2xl font-bold text-[#164E63]
  md:mt-4 md:text-xl
}
/*Lista de vantagens*/
.lista-vantegem{
  @apply w-full px-6 flex items-center flex-row flex-wrap justify-center

}
/*Item da lista*/
.item-lista-vantagem{
  @apply w-3/4 py-4 px-2 mb-10 flex items-center rounded-lg shadow-lg
  md:w-1/3 md:flex-col md:mb-16 md:shadow-none md:p-0
}
/*Icone do item*/
.item-lista-vantagem>.icone{
  @apply w-14 mr-6
  md:w-24 md:mr-0
}
/*Descricao do item*/
.item-lista-vantagem>.descricao{
  @apply font-medium text-[#164E63] text-center
}

</style>