<template>
  <div> 
    <FormularioVinculoEmEtapas
      :produtosXml="produtosXml"
      :produtosVinculados="produtosVinculados"
      :listaProdutoFornecedor="lista_produtos_fornecedor"
      :abrir="modal_vincular"
      @addProdutosVinculados="addProdutosVinculados"
      @fecharRelacao="fechar"
      @fecharSucesso="mensagemSucesso"
      v-if="produtosXml.length > 0"
    />

    <sucesso-rapido
      v-if="sucessoMensagem !== false"
      :mensagem="'Produtos Vinculados com Sucesso!'"
    />

    <div class="container-formulario">
      <!-- Cabeçalho lista de pessoas depois de 768 pixels-->
      <div class="container-cabecalho flex justify-between mb-4">
        <div>
          <h1 class="text-xl text-corBase font-bold">Entrada Xml</h1>
        </div>

        <router-link
          to="/estoque/lista-entrada-nota-fiscal"
          class="text-xl text-corBase font-bold"
        >
          <i class="fas fa-chevron-left"></i>
        </router-link>
      </div>

      <div class="flex flex-col">
        <label>Import XML</label>
        <input type="file" @change="handleFileChange" ref="fileInputRef" />
      </div>

      <div class="w-full flex flex-col border p-2 mt-6">
        <div class="flex w-full gap-10">
          <div class="flex flex-col">
            <label class="font-bold text-corBase">N° NF-e</label>
            <input
              v-model="n_nfe"
              class="border-b outline-none focus:border-b-corBase"
            />
          </div>
          <div class="flex flex-col">
            <label class="font-bold text-corBase">Modelo</label>
            <input
              v-model="modelo"
              class="border-b outline-none focus:border-b-corBase"
            />
          </div>
          <div class="flex flex-col">
            <label class="font-bold text-corBase">Série</label>
            <input
              v-model="serie"
              class="border-b outline-none focus:border-b-corBase"
            />
          </div>
          <div class="flex flex-col">
            <label class="font-bold text-corBase">Data Emissão</label>
            <input
              v-model="dataEmissao"
              class="border-b outline-none focus:border-b-corBase"
            />
          </div>
        </div>
        <div class="flex w-full gap-10 mt-2">
          <div class="flex flex-col">
            <label class="font-bold text-corBase">Chave Acesso</label>
            <input
              v-model="chave_aceeso"
              class="border-b outline-none w-[420px] focus:border-b-corBase"
            />
          </div>
        </div>
      </div>

      <div class="w-full flex flex-col border p-2 mt-6">
        <div class="flex w-full gap-10">
          <div class="flex flex-col w-[30%]">
            <label class="font-bold text-corBase">Razão</label>
            <input
              v-model="fornecedor.nome_razao"
              class="border-b outline-none w-full focus:border-b-corBase"
            />
          </div>
          <div class="flex flex-col">
            <label class="font-bold text-corBase">Cnpj</label>
            <input
              v-model="fornecedor.cnpj"
              class="border-b outline-none focus:border-b-corBase"
            />
          </div>
          <div class="flex flex-col">
            <label class="font-bold text-corBase">Inscrição Estadual</label>
            <input
              v-model="fornecedor.inscricao_estadual"
              class="border-b outline-none focus:border-b-corBase"
            />
          </div>
        </div>
      </div>

      <div class="flex w-full mt-6">
        <div class="overflow-x-auto w-full">
          <table class="w-full border border-collapse">
            <thead>
              <tr class="bg-gray-200">
                <th class="py-1 px-2 border text-sm">COD. Fornecedor [XML]</th>
                <th class="py-1 px-2 border text-sm">Nome Produto [XML]</th>
                <th class="py-1 px-2 border text-sm">Produto [FORNECEDOR]</th>
                <th class="py-1 px-2 border text-sm">Unidade de medida [XML]</th>
                <th class="py-1 px-2 border text-sm">Quantidade [XML]</th>
                <th class="py-1 px-2 border text-sm">Preço Custo</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(produto, index) in produtosXml"
                :key="produto.id"
                :class="index % 2 === 0 ? 'bg-white' : 'bg-gray-100'"
              >
                <td class="p-2 border text-xs font-bold">
                  {{ produto.codigo_fornecedor_XML }}
                </td>
                <td class="p-2 border text-xs font-bold">{{ produto.nome }}</td>
                <td class="p-2 border text-center text-xs font-bold">
                  {{ produto.codigo_fornecedor_vinculado }}
                </td>
                <td class="p-2 border text-xs font-bold">
                  <div>
                    {{ produto.unidade_medida }}
                  </div>
                </td>
                <td class="p-2 border text-center text-xs font-bold">
                  <div>
                    {{ produto.estoque }}
                  </div>
                </td>
                <td class="p-2 border text-center text-xs font-bold">
                  <div>
                    {{ produto.preco_custo }}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          <div class="flex w-full justify-end">
            <button
              @click.prevent="addEntrada()"
              v-if="produtosVinculados.length == produtosXml || produtosVinculados.length > 0"
              class="btn-enviar"
            >
              Adicionar Entrada
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VincularEstoqueProduto from "@/components/importacao/xml_produtos/VincularEstoqueProduto";
import axios from "axios";
import SucessoRapido from "@/components/toasts/toast_sucesso/SucessoRapido";
import FormularioVinculoEmEtapas from './FormularioVinculoEmEtapas.vue';

export default {
  name: "ImprotacaoXmlEntrada",

  components: {
    VincularEstoqueProduto,
    SucessoRapido,
    FormularioVinculoEmEtapas
  },

  data() {
    return {
      fileInput: null,
      fornecedor: {},
      produtosVinculados: [],
      n_nfe: "",
      chave_aceeso: "",
      modelo: "",
      serie: "",
      dataEmissao: "",
      id_fornecedor: "",
      lista_produtos_fornecedor: [],
      modal_vincular: false,
      sucessoMensagem: false,
      produtosXml: []
    };
  },

  // created() {
  //   this.getListaProdutoPorFornecedor();
  // },
  methods: {
    delay(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },

    mensagemSucesso() {
      this.modal_vincular = false;
      console.log("agora to aqui");
      this.sucessoMensagem = true;

      (async () => {
        await this.delay(4000);
        this.sucessoMensagem = false;
      })();
    },
    fechar() {
      this.modal_vincular = false;
    },

    handleFileChange(event) {
      this.fileInput = event.target; // Atualize o estado do input file

      const file = event.target.files[0];
      const reader = new FileReader();

      reader.onload = async () => {
        await this.parseXML(reader.result);
      };

      reader.readAsText(file);
    },

    parseXML(xmlData) {
      this.$store.dispatch('setLoading', true)

      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(xmlData, "text/xml");
      // Seleciona o elemento com o seletor "infNFe"
      const infNFeElement = xmlDoc.querySelector("infNFe");

      if (infNFeElement) {
        // Obtém o valor do atributo "id" do elemento
        const idDoElemento = infNFeElement.getAttribute("Id");

        // Atribui o valor à variável n_nfe
        this.chave_aceeso = idDoElemento;
        console.log(this.chave_aceeso);

        // Agora, this.n_nfe contém o valor do atributo "id" do elemento infNFe
      } else {
        console.error("Elemento infNFe não encontrado");
      }

      const modelo_nfe = xmlDoc.querySelector("mod");
      this.modelo = modelo_nfe.textContent;

      const serie_nfe = xmlDoc.querySelector("serie");
      this.serie = serie_nfe.textContent;

      const numero_nfe = xmlDoc.querySelector("nNF");
      this.n_nfe = numero_nfe.textContent;

      const data_emissao = xmlDoc.querySelector("dhEmi");

      this.dataEmissao = data_emissao.textContent;

      const emitenteCnpjElement = xmlDoc.querySelector("CNPJ");
      const emitenteNomeElement = xmlDoc.querySelector("xNome");

      if (emitenteNomeElement) {
        this.emitente = {
          cnpj: emitenteCnpjElement.textContent,
        };
      }
      this.getFornecedor(this.emitente.cnpj);

      const produtoElements = xmlDoc.querySelectorAll("prod");

      const produtos = [];

      produtoElements.forEach((produtoElement, index) => {
        const codigoElement = produtoElement.querySelector("cProd");
        const nomeElement = produtoElement.querySelector("xProd");
        const unidadeMedidaElement = produtoElement.querySelector("uCom");
        let QuantidadeElement = produtoElement.querySelector("qCom");
        let ValorProdutoElement = produtoElement.querySelector("vUnCom");
        const Valorncm = produtoElement.querySelector("NCM");
        ValorProdutoElement = Number(ValorProdutoElement.textContent);
        QuantidadeElement = Number(QuantidadeElement.textContent);
        if (nomeElement) {
          produtos.push({
            produto_id: '',
            nome_especifico: '',
            codigo_fornecedor_XML: codigoElement.textContent,
            codigo_fornecedor_vinculado: '',
            nome: nomeElement.textContent,
            unidade_medida: unidadeMedidaElement.textContent,
            estoque: QuantidadeElement.toFixed(3),
            preco_custo: ValorProdutoElement.toFixed(2),
            ncm: Valorncm.textContent,
          });
        }
      });
      this.produtosXml = produtos;
      this.getVinculoXml(this.produtosXml)
      this.modal_vincular = true;
    },

    async getVinculoXml(produtos){
      const token = await this.$store.dispatch("getAuthTokenCookie");
      await this.$store.dispatch("fetchURLrequest");

      const vinculoXmlPromises = []

      for(const produto of produtos){
        vinculoXmlPromises.push(
          axios.get(`/produtos/vinculo-xml-produto-fornecedor/?codigo_fornecedor_XML=${produto.codigo_fornecedor_XML}&page_size=999`, { // a lentidão tá aqui
            headers: {
              Authorization: `Token ${token}`,
            },
          })
        )
      }

      const response = await Promise.all(vinculoXmlPromises)
      const result = response.map(response => response.data.results[0])
      this.produtosVinculados = result.filter(result => result !== undefined)

      for(let i = 0; i < this.produtosXml.length; i++){
        for(let j = 0; j < this.produtosVinculados.length; j++){
          if(this.produtosXml[i].codigo_fornecedor_XML === this.produtosVinculados[j].codigo_fornecedor_XML){
            this.produtosXml[i].codigo_fornecedor_vinculado = this.produtosVinculados[j].codigo_fornecedor_produto_por_fornecedor
            this.produtosXml[i].produto_id = this.produtosVinculados[j].produto_por_fornecedor.id
          }
        }
      }
    },

    addProdutosVinculados(novosVinculos) {
      for(let i = 0; i < this.produtosXml.length; i++){
        for(let j = 0; j < novosVinculos.length; j++){
          if(this.produtosXml[i].codigo_fornecedor_XML === novosVinculos[j].codigo_fornecedor_XML){
            this.produtosXml[i].produto_id = novosVinculos[j].produto_por_fornecedor.id
          }
        }
      }
      
      this.produtosVinculados = [...this.produtosVinculados, novosVinculos]
    },

    async getFornecedor(cnpj) {
      const token = await this.$store.dispatch("getAuthTokenCookie");
      await this.$store.dispatch("fetchURLrequest");
      axios
        .get(`pessoas/fornecedor?pessoa=${cnpj}`, {
          headers: {
            Authorization: `Token ${token}`,
          },
        })
        .then((response) => {
          this.fornecedor = response.data.results[0].pessoa.pessoa_juridica;
          this.id_fornecedor = response.data.results[0].id;
        })
        .catch((error) => {
          console.log(error.message);
        });
        this.$store.dispatch('setLoading', false)
    },

    async addEntrada() {
      console.log('chegou')
      const token = await this.$store.dispatch("getAuthTokenCookie");
      await this.$store.dispatch("fetchURLrequest");

      const totalQuantidade = this.produtosXml.reduce((total, produto) => {
        return total + parseFloat(produto.preco_custo);
      }, 0);

      const data = {
        tipo_movimentacao: "ENTRADA",
        motivo_movimentacao: 'ENTRADA NF-E',
        numero_nf: this.n_nfe,
        modelo_nf: this.modelo,
        serie_nf: this.serie,
        chave_acesso_nf: this.chave_aceeso,
        data_emissao_nf: this.dataEmissao,
        fornecedor: this.id_fornecedor,
        produtos: this.produtosXml.map((item) => ({
          produto: item.produto_id,
          qtd_produtos: item.estoque,
          preco_custo: item.preco_custo,
        })),
        total_nf: totalQuantidade.toFixed(2),
      };

      const teste = JSON.stringify(data);
      console.log(teste);

      this.$store.dispatch("setLoading", true);
      await axios
        .post("/produtos/movimentacao/", data, {
          headers: {
            Authorization: `Token ${token}`,
          },
        })
        .then((response) => {
          console.log(response.data);
          this.$store.dispatch("setLoading", false);
        })
        .catch((err) => {
          console.error(err);
          this.$store.dispatch("setLoading", false);
        });
    },
  },
};
</script>

<style scoped lang="scss">
</style>