<template>

  <div class="container-formulario">

    <div class="container-cabecalho flex justify-between mb-4">
      <div>
        <h1 class="text-xl text-corBase font-bold">Pessoas Físicas</h1>
      </div>

      <router-link to="/pessoas" class="text-xl text-corBase font-bold">
        <i class="fas fa-chevron-left"></i>
      </router-link>

    </div>

    <div class="mb-8 flex items-end gap-8">
      <div class="campo-chapa">
        <label>Tipo Pessoa</label>
        <Select2 v-model="tipo_pessoa"
                 class="w-full"
                 :options="lista_tipo_pessoa"
                 :settings="{ width: '100%'}"
        />
      </div>

      <div class="campo-chapa">
        <label>Status</label>
        <select v-model="status" class="input-campos">
          <option value="True">Ativo</option>
          <option value="False">Inativo</option>
        </select>
      </div>
      <div>
        <button class="bg-corBase hover:bg-screenCor border border-screenCor hover:border-corBase
  text-white hover:text-corBase py-2 px-4" @click="getListaPessoas">Pesquisar
        </button>
      </div>
    </div>
    <div class="mb-8">
      <button @click="$router.push(`/pessoa/pessoa-fisica`)" class="bg-ativo hover:bg-screenCor border border-screenCor hover:border-ativo
  text-white hover:text-ativo py-2 px-4 mt-2 mr-2">Adicionar
      </button>
    </div>


    <!-- Container da exibiçao da lista de pessoas -->
    <div class="container-modelo-tabela">
      <table class="container-tabela overflow-auto">
        <thead class="cabecalho-tabela">
        <tr>
          <th scope="col" class="p-4">
            <div class="flex items-center">
              <input type="checkbox" class="checkbox" v-model="checkAll" @change="selectAll">

              <label class="sr-only">checkbox</label>
            </div>
          </th>
          <th scope="col" class="px-6 py-3">
            #
          </th>
          <th class="px-6 py-3">
            Nome
          </th>
          <th class="px-6 py-3">
            CPF
          </th>
          <th scope="col" class="px-6 py-3">
            Tipo
          </th>
          <th scope="col" class="px-6 py-3">
            Status
          </th>
          <th scope="col" class="px-6 py-3">
            Ações
          </th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(pessoa, index) in listaPessoasFisicasPaginada" :key="index" class="escopo-tabela">
          <template v-if="pessoa.pessoa_fisica !== null ">
            <td class="w-4 p-4">
              <div class="flex items-center">
                <input :id="'checkbox-table-' + (index + 2)" type="checkbox" class="checkbox" v-model="pessoa.selected">
                <label class="sr-only">checkbox</label>
              </div>
            </td>
            <th scope="row" class="px-6 py-2 font-medium text-xs text-gray-900 whitespace-nowrap dark:text-white">
              {{ index + 1 }}
            </th>
            <td class="px-6 py-2 text-xs">
              {{ pessoa.nome_razao }}
            </td>
            <td class="px-6 py-2 text-xs">
              {{ pessoa.pessoa_fisica.cpf }}
            </td>
            <template v-if="pessoa.pessoa_fisica !== null">
              <td class="px-6 py-2 text-xs">
                {{ pessoa.tipo_pessoa_geral_descricao }}
              </td>
            </template>
            <template v-if="pessoa.pessoa_fisica !== null">
              <template v-if="pessoa.pessoa_fisica.ativo === true">
                <td class="px-6 py-2 text-xs">
                  <i class="fa-solid fa-circle-check  text-ativo ml-3"></i>
                </td>
              </template>
              <template v-if="pessoa.pessoa_fisica.ativo === false">
                <td class="px-6 py-2">
                  <i class="fa-solid fa-circle-xmark text-inativo ml-3"></i>
                </td>
              </template>
            </template>

            <td class="px-6 py-2 text-xs">
              <EditarDados :dadosPessoa="pessoa"></EditarDados>
            </td>
          </template>

        </tr>
        </tbody>
      </table>
      <div class="paginacao">
        <button @click.prevent="paginaAnterior" class="btn-paginacao"><i class="fas fa-chevron-left"></i></button>
        <template v-for="pagina in totalPaginas" :key="pagina">
          <span @click.prevent="irParaPagina(pagina)"
                :class="{'numero-paginacao': true, 'numero-paginacao-ativa': pagina === paginaAtual}">{{
              pagina
            }}</span>
        </template>
        <button @click="proximaPagina" class="btn-paginacao"><i class="fas fa-chevron-right"></i></button>
      </div>
    </div>
  </div>

</template>

<script>
import EditarDados from "@/components/midais/Editar/EditarDados";
import Select2 from "vue3-select2-component";
import axios from "axios";

export default {
  name: "ListaPessoaFisica",

  components: {
    EditarDados,
    Select2
  },

  data() {
    return {
      checkAll: false,
      listaPessoas: [],
      listaPessoasFisicasPaginada: [],
      paginaAtual: 1,
      filtros: false,
      lista_tipo_pessoa: [],
      tipo_pessoa: '',
      status: ''
    }
  },

  created() {
    this.getListaTipoPessoaGeral()
  },

  computed: {
    totalPaginas() {
      return Math.ceil(this.listaPessoas.length / 5);
    },
    minhaEmpresa() {
      return this.$store.state.minhaEmpresa;
    },
    isadmin() {
      return this.$store.state.isAdminUser
    },
  },

  methods: {
    selectAll() {
      const checkboxes = document.querySelectorAll('.escopo-tabela input[type="checkbox"]');
      checkboxes.forEach((checkbox) => {
        checkbox.checked = this.checkAll;
      });
    },

    paginaAnterior() {
      if (this.paginaAtual > 1) {
        this.paginaAtual--;
        this.paginarTabela();
      }
    },

    proximaPagina() {
      if (this.paginaAtual < this.totalPaginas) {
        this.paginaAtual++;
        this.paginarTabela();
      }
    },

    paginarTabela() {
      const itemsPerPage = 5;
      const startIndex = (this.paginaAtual - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      this.listaPessoasFisicasPaginada = this.listaPessoas.slice(startIndex, endIndex);
    },

    irParaPagina(pagina) {
      if (pagina >= 1 && pagina <= this.totalPaginas) {
        this.paginaAtual = pagina;
        this.paginarTabela();
      }
    },

    async getListaTipoPessoaGeral() {
      const token = await this.$store.state.token

      await this.$store.dispatch('fetchURLrequest')
      await axios.get('pessoas/tipo-pessoas', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            this.lista_tipo_pessoa = response.data.map(item => ({
              id: item.id,
              text: item.descricao
            }))
          })
          .catch(error => {
            if (error.response) {
              for (const property in error.response.data) {
                this.errors.push(`${property}: ${error.response.data[property]}`)
              }
            } else if (error.message) {
              this.errors.push('Something went wrong. Please try again!')
            }
          })
    },

    async getListaPessoas() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      this.$store.dispatch("setLoading", true);
      await axios.get(`/pessoas/fisicas/?tipo_pessoa_geral_id=${this.tipo_pessoa}&ativo=${this.status}`, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            this.listaPessoas = response.data
            this.$store.dispatch("setLoading", false);

          })

    },

  }
}
</script>

<style scoped>

</style>