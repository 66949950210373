<template>

  <div class="container-formulario">

    <!-- TILO DO FORMULARIO + BOTAO DE VOLTAR -->
    <div class="cabecalho-formulario">
      <h1 class="text-xl text-corBase font-bold">Dados Projetista:</h1>

      <router-link to="/lista-projetista-sistemista" class="text-xl text-corBase font-bold">
        <i class="fas fa-chevron-left"></i>
      </router-link>
    </div>

    <!-- Escopo do formulario de cadastro mobile-->
    <form @submit.prevent="cadastrarProjetista">
      <!--        PARTE DE DADOS DO PRODUTO-->
      <div class="flex flex-col md:flex-row gap-3 my-3 w-full ">

        <div class="campo-chapa">
          <label class="text-xl text-corBase font-bold">Código</label>
          <input type="text" v-model="codigo" class="input-form-chapa" required autofocus>
        </div>

        <div class="campo-chapa">
          <label class="text-xl text-corBase font-bold">Nome</label>
          <input type="text" v-model="nome" class="input-form-chapa" required autofocus>
        </div>

        <div class="campo-chapa">
          <label class="text-xl text-corBase ">Empresa</label>
          <Select2 v-model="empresa"
                   class="w-full z-10"
                   :options="lista_pessoas"
                   :settings="{ width: '100%'}"
          />
        </div>
      </div>


      <div class="flex w-full justify-end">
        <button class="btn-enviar" type="submit">Salvar</button>
      </div>

    </form>
  </div>
  <MensagemSucesso v-if="sucesso==true" rotas="/lista-projetista-sistemista"
                   mensagem="Projetista Cadastrada com Sucesso!"/>

</template>

<script>
import axios from "axios";
import MensagemSucesso from "@/components/toasts/toast_sucesso/MensagemSucesso";
import Select2 from "vue3-select2-component";

export default {
  name: "CadastroProjetistaSistemista",

  components: {
    MensagemSucesso,
    Select2
  },


  data() {
    return {
      sucesso: false,
      codigo: '',
      nome: '',
      lista_pessoas: [],
      empresa: '',
      projetistas_sistemistas: []
    }
  },

  created() {
    this.getListaPessoas()
  },

  computed: {
    minhaEmpresa() {
      return this.$store.state.minhaEmpresa;
    },
    isadmin() {
      return this.$store.state.isAdminUser
    },
  },

  methods: {


    async cadastrarProjetista() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      const data = {
        ativo: true,
        codigo: this.codigo,
        nome: this.nome,
      }

      this.empresa ? data.empresa = [ this.empresa ] : ''

      this.sucesso = false
      this.$store.dispatch("setLoading", true);
      await axios.post('projeto/projetista-sistemista/', data, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            console.log(response.data.results);
            this.nome = ''
            this.empresa = ''
            this.sucesso = true
            this.$store.dispatch("setLoading", false);
          })
          .catch(error => {
            console.log(error);
            this.$store.dispatch("setLoading", false);
          })

    },

    async getListaPessoas() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get('pessoas', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            this.lista_pessoas = response.data.results.filter(objeto => {
              return objeto.pessoa_juridica !== null
            }).map(item => ({
              id: item.id,
              text: item.nome_razao
            }))
          })
          .catch(error => {
            console.log(error)
          })
    },


  }

}
</script>

<style lang="scss"></style>