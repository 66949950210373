<template>
  <a @click="alterarModalEditar()" href="#" class="font-medium text-corBase hover:underline">
    <i class="fa-solid fa-pen-to-square mr-2"></i>Editar
  </a>
  <div v-if="statusModal">
    <div class="container-modal-editar">
      <div class="sombra-modal">
        <div class="conteudo-modal-editar">
          <form class="form-editar text-white">
            <div class="flex justify-end">
              <button @click.prevent="alterarModalEditar()">
                <i class="fas fa-close text-2xl text-corBase"></i>
              </button>
            </div>
            <h2 class="text-lg text-corBase font-bold mb-2">Editar Dados Serviço</h2>
            <!--Campos da Pessoa-->
            <div>
              <div class="container-campos">
                <div class="campos campos-mobile label-campos-popup">
                  <label class="label-campos label-campos-popup">Produto Base:</label>
                  <input type="text" required class="input-campos" v-model="produtoBase.nome_base" >
                </div>

                <div class="campos campos-mobile label-campos-popup">
                  <label class="label-campos label-campos-popup">Fornecedor:</label>
                  <input type="text" required class="input-campos" v-model="fornecedorChapa.nome_razao" readonly>
                </div>

                <div class="campos campos-mobile label-campos-popup">
                  <label class="label-campos label-campos-popup">Código Fornecedor:</label>
                  <input type="text" required class="input-campos" v-model="perfil.codigo_fornecedor" >
                </div>

                <div class="campos campos-mobile label-campos-popup">
                  <label class="label-campos label-campos-popup">Código de Barras:</label>
                  <input type="text" required class="input-campos" v-model="perfil.codigo_barras" >
                </div>

              </div>

              <div class="container-campos">
                <div class="campos campos-mobile label-campos-popup">
                  <label class="label-campos label-campos-popup">Nome Especifico:</label>
                  <input type="text" required class="input-campos" v-model="perfil.nome_especifico">
                </div>
                <div class="campos-mobile">
                  <label class="label-campos">Grupo de Aplicação Chapa:</label>
                  <input type="text" required class="input-campos" placeholder="" v-model="grupoaplicacao.nome">
                </div>
              </div>
            </div>
            <!--Campos de endereço-->
            <div>

              <div class="container-campos">
                <div class="flex flex-col mt-2 w-full md:w-60">
                  <label class="label-campos">Altura:</label>
                  <input type="text" required placeholder="" class="input-campos" v-model="perfil.altura_chapa">
                </div>

                <div class="campos campos-mobile">
                  <label class="label-campos">Largura:</label>
                  <input type="text" required class="input-campos" placeholder="" v-model="perfil.largura_chapa">
                </div>

                <div class="flex flex-col mt-2 w-full md:w-60">
                  <label class="label-campos">Cor Chapa:</label>
                  <input type="text" required class="input-campos" v-model="corchapas.nome">
                </div>
                <div class="flex flex-col mt-2 w-full md:w-[500px]">
                  <label class="label-campos">Tipo Chapa:</label>
                  <div class="">
                    <input type="text" required class="input-campos" placeholder="" v-model="tipochapa.nome">
                  </div>
                </div>
              </div>

              <div class="container-campos  ">


              </div>

              <div class=" container-campos ">

                <div class="campos-mobile">
                  <label class="label-campos">Peso por Metro:</label>
                  <input type="text" required class="input-campos" placeholder="" v-model="perfil.peso">
                </div>
                <div class="campos-mobile">
                  <label class="label-campos">Preço Médio:</label>
                  <input type="text" required class="input-campos" placeholder="" v-model="perfil.preco_medio">
                </div>

                <div class="campos-mobile">
                  <label class="label-campos">Preço Base:</label>
                  <div class="flex">
                    <input type="text" required class="input-campos" placeholder="" v-model="perfil.preco_base">
                  </div>
                </div>

                <div class="campos-mobile">
                  <label class="label-campos">Transporte:</label>
                  <div class="flex">
                    <input type="text" required class="input-campos" placeholder="" v-model="perfil.transporte">
                  </div>
                </div>
              </div>

              <div class=" container-campos ">
                <div class="campos-mobile">

                </div>
              </div>

            </div>

            <!--Botão-->
            <div class="btn-formulario-modal">
              <button class="btn-formulario-salvar" @click.prevent="putDadosEditado">
                Salvar
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "EditarPerfil",
  data() {
    return {
      statusModal: false,
      perfil: {
        nome_especifico: '',
        altura_chapa: '',
        largura_chapa: '',
        ativo: '',
        chapa_base: '',
        codigo_barras: '',
        data_atualizacao_preco_base: '',
        fornecedor: '',
        grupo_aplicacao_chapa: '',
        icms: '',
        id: '',
        imagem: '',
        imagemVetor: '',
        ipi: '',
        margem_adicional: '',
        peso: '',
        preco_base: '',
        preco_custo: '',
        preco_medio: '',
        produto_base: '',
        tipo_chapas: '',
        transporte: '',
        ultimo_preco_compra: '',
        codigo_fornecedor: '',
        cor_chapas: '',
      },
      produtoBase: {},
      fonecedorChapa: {},
      grupoaplicacao: {},
      corchapas: {},
      tipochapa: {}

    }
  },
  props: ["dadosPerfil"],
  created() {
    this.perfil.nome_especifico = this.dadosPerfil.nome_especifico
    this.perfil.altura_chapa = this.dadosPerfil.altura_chapa
    this.perfil.largura_chapa = this.dadosPerfil.largura_chapa
    this.perfil.ativo = this.dadosPerfil.ativo
    this.perfil.chapa_base = this.dadosPerfil.chapa_base
    this.perfil.codigo_barras = this.dadosPerfil.codigo_barras
    this.perfil.data_atualizacao_preco_base = this.dadosPerfil.data_atualizacao_preco_base
    this.perfil.fornecedor = this.dadosPerfil.fornecedor
    this.perfil.grupo_aplicacao_chapa = this.dadosPerfil.grupo_aplicacao_chapa
    this.perfil.icms = this.dadosPerfil.icms
    this.perfil.id = this.dadosPerfil.id
    this.perfil.imagem = this.dadosPerfil.imagem
    this.perfil.imagemVetor = this.dadosPerfil.imagemVetor
    this.perfil.ipi = this.dadosPerfil.ipi
    this.perfil.margem_adicional = this.dadosPerfil.margem_adicional
    this.perfil.peso = this.dadosPerfil.peso
    this.perfil.preco_base = this.dadosPerfil.preco_base
    this.perfil.preco_custo = this.dadosPerfil.preco_custo
    this.perfil.preco_medio = this.dadosPerfil.preco_medio
    this.perfil.produto_base = this.dadosPerfil.produto_base
    this.perfil.tipo_chapas = this.dadosPerfil.tipo_chapas
    this.perfil.transporte = this.dadosPerfil.transporte
    this.perfil.ultimo_preco_compra = this.dadosPerfil.ultimo_preco_compra
    this.perfil.codigo_fornecedor = this.dadosPerfil.codigo_fornecedor
    this.perfil.cor_chapas = this.dadosPerfil.cor_chapas
    console.log(this.perfil)

    this.getProdutoBase()
    this.getFornecedor()
    this.getGrupoAplicacao()
    this.getCorChapa()
    this.getTipoChapa()
  },
  methods: {
    alterarModalEditar() {
      this.statusModal = !this.statusModal;
    },

    async putDadosEditado() {
      const dadosAtualizados = new FormData();
      dadosAtualizados.append('ativo', this.perfil.ativo)
      dadosAtualizados.append('nome_especifico', this.perfil.nome_especifico)
      dadosAtualizados.append('codigo_fornecedor', this.perfil.codigo_fornecedor)
      dadosAtualizados.append('codigo_barras', this.perfil.codigo_barras)
      dadosAtualizados.append('peso', this.perfil.peso)
      dadosAtualizados.append('preco_medio', this.perfil.preco_medio)
      dadosAtualizados.append('preco_base', this.perfil.preco_base)
      dadosAtualizados.append('ultimo_preco_compra', this.perfil.ultimo_preco_compra)
      dadosAtualizados.append('preco_custo', this.perfil.preco_custo)
      dadosAtualizados.append('data_atualizacao_preco', this.perfil.data_atualizacao_preco_base)
      dadosAtualizados.append('ipi', this.perfil.ipi)
      dadosAtualizados.append('icms', this.perfil.icms)
      dadosAtualizados.append('transporte', this.perfil.transporte)
      dadosAtualizados.append('margem_adicional', this.perfil.margem_adicional)
      dadosAtualizados.append('altura_chapa', this.perfil.altura_chapa)
      dadosAtualizados.append('largura_chapa', this.perfil.largura_chapa)
      dadosAtualizados.append('produto_base', this.perfil.produto_base.id)
      dadosAtualizados.append('fornecedor', this.perfil.fornecedor.id)
      dadosAtualizados.append('cor_chapas', this.perfil.cor_chapas.id)
      dadosAtualizados.append('tipo_chapas', this.perfil.tipo_chapas.id)
      dadosAtualizados.append('chapa_base', this.perfil.chapa_base.id)
      dadosAtualizados.append('grupo_aplicacao_chapa', this.perfil.grupo_aplicacao_chapa.id)
      dadosAtualizados.append('imagem', this.perfil.imagem)
      dadosAtualizados.append('imagemVetor', this.perfil.imagemVetor)


      try {
        const token = await this.$store.dispatch('getAuthTokenCookie');
        await this.$store.dispatch('fetchURLrequest')
        const response = await axios.put(`produtos/chapa/${this.perfil.id}/`, dadosAtualizados, {
          headers: {
            'Authorization': `Token ${token}`,
            'Content-Type': 'application/json'
          }
        });
        console.log(response);
        this.sucesso = true;
        await this.delay(2000);
        this.sucesso = false;

      } catch (error) {
        console.log(error)
      }
    },

    async getProdutoBase() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get(`produtos/produto/${this.perfil.produto_base.id}/`, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          this.produtoBase = response.data
          console.log(this.produtoBase)
        })
        .catch(error => {
          console.log(error)
        })

    },

    async getFornecedor() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get(`pessoas/fornecedor/${this.perfil.fornecedor.id}/`, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          this.fornecedorChapa = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    async getGrupoAplicacao() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get(`produtos/grupo-aplicacao-chapa/`, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          this.grupoaplicacao = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    async getCorChapa() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get(`produtos/cor-chapa/`, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          this.corchapas = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    async getTipoChapa() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get(`produtos/tipo-chapa/`, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          this.tipochapa = response.data
        })
        .catch(error => {
          console.log(error)
        })
    }

  }
}
</script>

<style>
</style>