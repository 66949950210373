<template>

  <div class="container-formulario">

    <!-- Cabeçalho lista de pessoas depois de 768 pixels-->
    <div class="container-cabecalho flex justify-between mb-4">
      <div>
        <h1 class="text-xl text-corBase font-bold">Lista de Pessoas</h1>
      </div>

      <router-link to="/cadastros" class="text-xl text-corBase font-bold">
        <i class="fas fa-chevron-left"></i>
      </router-link>

    </div>
    <div class="mb-8">
      <span class="text-corBase mr-2">Adicionar: </span>
      <button @click="$router.push(`/pessoa/pessoa-fisica`)" class="btn-add-fisica mr-2">Física</button>
      <button @click="$router.push(`/pessoa/pessoa-juridica`)" class="btn-add-juridica">Jurídica</button>
    </div>


    <!-- Filtros -->
    <div class="flex items-end gap-8 mb-6">
      <div>
        <!--        <input type="text" v-model="cor" class="input-campos">-->
        <select class="input-campos" v-model="documentacao">
          <option value="fisica">Física</option>
          <option value="juridica">Juridica</option>
        </select>
      </div>
      <div>
        <input type="text" v-model="tipo_pessoa" class="input-campos">
      </div>
      <div>
        <input type="text" v-model="produto_base" class="input-campos">
      </div>
      <div>
        <input type="text" v-model="grupo_aplicacao" class="input-campos">
      </div>
      <div>
        <button class="btn-add-fisica" @click="getListaComponente">Pesquisar</button>
      </div>
    </div>

    <!-- Container da exibiçao da lista de pessoas -->
    <div class="container-modelo-tabela">
      <table class="container-tabela">
        <thead class="cabecalho-tabela">
        <tr>
          <th scope="col" class="p-4">
            <div class="flex items-center">
              <input type="checkbox"
                     class="checkbox">
              <label for="checkbox-all" class="sr-only">checkbox</label>
            </div>
          </th>
          <th scope="col" class="px-6 py-3">
            #
            <button><i class="fa-solid fa-sort"></i></button>
          </th>
          <th scope="col" class="px-6 py-3">
            Nome/Razão
            <button><i class="fa-solid fa-sort"></i></button>
          </th>
          <th scope="col" class="px-6 py-3">
            Tipo
            <button><i class="fa-solid fa-sort"></i></button>
          </th>
          <th scope="col" class="px-6 py-3">
            Status
            <button><i class="fa-solid fa-sort"></i></button>
          </th>
          <th scope="col" class="px-6 py-3">
            Ações
          </th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(pessoa, index) in listaPessoasPaginada" :key="index" class="escopo-tabela">
          <td class="w-4 p-4">
            <div class="flex items-center">
              <input id="checkbox-table-1" type="checkbox"
                     class="checkbox">
              <label for="checkbox-table-1" class="sr-only">checkbox</label>
            </div>
          </td>
          <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
            {{ index + 1 }}
          </th>
          <td class="px-6 py-4">
            {{ pessoa.nome_razao }}
          </td>
          <template v-if="pessoa.pessoa_fisica !== null">
            <td class="px-6 py-4">
              <i class="fas fa-user"></i>
            </td>
          </template>
          <template v-if="pessoa.pessoa_juridica !== null">
            <td class="px-6 py-4">
              <i class="fa-solid fa-industry"></i>
            </td>
          </template>
          <template v-if="pessoa.pessoa_fisica !== null">
            <template v-if="pessoa.pessoa_fisica.ativo === true">
              <td class="px-6 py-4">
                <i class="fa-solid fa-circle-check  text-ativo"></i>
              </td>
            </template>
            <template v-if="pessoa.pessoa_fisica.ativo === false">
              <td class="px-6 py-4">
                <i class="fa-solid fa-circle-xmark text-inativo"></i>
              </td>
            </template>
          </template>

          <template v-if="pessoa.pessoa_juridica !== null">
            <template v-if="pessoa.pessoa_juridica.ativo === true">
              <td class="px-6 py-4">
                <i class="fa-solid fa-circle-check  text-ativo"></i>
              </td>
            </template>
            <template v-if="pessoa.pessoa_juridica.ativo === false">
              <td class="px-6 py-4">
                <i class="fa-solid fa-circle-xmark  text-inativo"></i>
              </td>
            </template>
          </template>

          <td class="px-6 py-4">
            <EditarDados :dadosPessoa="pessoa"></EditarDados>
          </td>
        </tr>
        </tbody>
      </table>
      <div class="paginacao">
        <button @click="paginaAnterior" class="btn-paginacao"><i class="fas fa-chevron-left"></i></button>
        <span class="numero-paginacao">{{ paginaAtual - 1 }}</span>
        <span class="numero-paginacao">{{ paginaAtual }}</span>
        <span class="numero-paginacao">{{ paginaAtual + 1 }}</span>
        <button @click="proximaPagina" class="btn-paginacao"><i class="fas fa-chevron-right"></i></button>
      </div>
    </div>
  </div>


</template>

<script>
import axios from "axios";
import EditarDados from "@/components/midais/Editar/EditarDados";

export default {
  name: 'ListaDePessoas',

  components: {
    EditarDados
  },

  data() {
    return {
      // lista usada para salvar o retorno da requisição do get
      listaPessoas: [],
      // lista usada para salvar esse retorno depois de dividido
      listaPessoasPaginada: [],
      // varival que inicializa o numero de paginas
      paginaAtual: 1,
      // variavel que manipula os estados dos modais
      documentacao: '',
      tipo_pessoa: '',
    }
  },

  created() {
    // faz a requisição de buscar a lista de pessoas quando a pagina é criada
    this.getListaPessoas()
  },
  computed: {
    // faz a divisao das paginas na exibição para que sejam até 10 items
    totalPaginas() {
      return Math.ceil(this.listaPessoas.length / 10);
    },
    isadmin() {
      return this.$store.state.isAdminUser
    },
    minhaEmpresa() {
      return this.$store.state.minhaEmpresa;
    },
  },

  methods: {
    // abre o modal de filtros
    abrirListaFiltros() {
      this.filtros = !this.filtros
    },


    // atualiza o valor da pagina no botao de voltar ao anterior
    paginaAnterior() {
      if (this.paginaAtual > 1) {
        this.paginaAtual--;
        this.paginarTabela();
      }
    },

    // atualiza o valor da pagina no botao de ir pra proxima pagina
    proximaPagina() {
      if (this.paginaAtual < this.totalPaginas) {
        this.paginaAtual++;
        this.paginarTabela();
      }
    },

    // exibe apenas 10 items da tabela e salva o resto
    paginarTabela() {
      const itemsPerPage = 10;
      const startIndex = (this.paginaAtual - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      this.listaPessoasPaginada = this.listaPessoas.filter((pessoa, index) => {
        return index >= startIndex && index < endIndex;
      });
    },

    // busca a lista de pessoas cadastrada no banco de dados
    async getListaPessoas() {

      // pega o token do localStoreage e monta a url padrao para a requisição
      // de acordo com o subdominio
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      this.$store.dispatch("setLoading", true);
      // requisição axios no endpoint pessoas passando o token de autorização
      axios.get(`/pessoas/`, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            // atriu a resposta do servido na variavel listaPessoas
            // define a panigaAtual com valor de 1
            // chama o metodo de paginarTabela para separa em paginas com 10 items
            this.listaPessoas = response.data;
            this.paginaAtual = 1;
            this.paginarTabela();
            this.$store.dispatch("setLoading", false);
          })
          .catch(error => {
            if (error.response) {
              for (const property in error.response.data) {
                this.errors.push(`${property}: ${error.response.data[property]}`)
              }
            } else if (error.message) {
              this.errors.push('Something went wrong. Please try again!')
            }

            this.$store.dispatch("setLoading", false);
          })
    },


  }
}
</script>

<style lang="scss">
//estilo paginacao
.paginacao {
  @apply flex w-full justify-end py-2 px-2
}

.btn-paginacao {
  @apply flex px-3 py-1 border border-corBase/30 items-center text-corBase/50
}

.numero-paginacao {
  @apply flex px-3 py-1 border border-corBase/10 text-corBase
}

.numero-paginacao-ativa {
  @apply flex px-3 py-1 border border-corBase/80 text-corBase
}

// estilo da lista até 768 pixels
.tela-lista-pessoas {
  @apply w-[95%] mt-6 bg-screenCor rounded-3xl p-4
}

.botoes-cabecalho {
  @apply flex flex-col gap-3 mt-6
}

.btn-add-fisica {
  @apply py-1 px-3 bg-corBase rounded-sm text-screenCor border border-corBase
  hover:bg-screenCor hover:border hover:border-corBase hover:text-corBase
  hover:transition hover:duration-300
}

.btn-add-juridica {
  @apply py-1 px-3 bg-screenCor rounded-sm text-corBase border border-corBase
  hover:bg-corBase hover:border hover:border-screenCor hover:text-screenCor
}

.btn-filtros {
  @apply px-4 py-2 bg-corBase rounded-lg text-screenCor md:bg-screenCor md:text-corBase
}


// estilo lista de pessoas acima de 768 pixels
.container-lista-pessoas768 {
  @apply ml-24 mt-24 absolute w-[90%] p-4 bg-white rounded-3xl xl:w-[88%]
}


// modelo tabela estilo
.container-modelo-tabela {
  @apply relative overflow-x-auto shadow-md sm:rounded-lg rounded-lg ;
}

.container-tabela {
  @apply w-full text-sm text-left text-gray-500 dark:text-gray-400;
}

.cabecalho-tabela {
  @apply text-xs text-gray-700 bg-gray-50 dark:bg-gray-700 dark:text-gray-400;
}

.checkbox {
  @apply w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600;
}

.escopo-tabela {
  @apply bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600;
}

</style>


