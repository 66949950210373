<template>


  <div class="container-formulario">
    <!--    <importarcao-perfil v-if="importacao === true" @fechar_importacao="imoportar"/>-->
    <galeria v-if="galeria === true" @fechar_galeria="abrir_galeria" @objetoClicado="atualizarFileInput"/>

    <!-- TILO DO FORMULARIO + BOTAO DE VOLTAR -->
    <div class="cabecalho-formulario">
      <h1 class="text-xl font-bold text-corBase">Dados Perfil:</h1>

      <router-link to="/produtos/perfil" class="text-xl text-corBase font-bold">
        <i class="fas fa-chevron-left"></i>
      </router-link>
    </div>

    <!-- Escopo do formulario de cadastro mobile-->
    <form @submit.prevent="cadastrarPerfil" enctype="multipart/form-data">
      <!--        PARTE DE DADOS DO PRODUTO-->

      <template class="flex flex-col gap-3 my-3 w-full">
        <div class="div-produtos">
          <div class="flex flex-col w-[40vw]">
            <label class="text-xl font-bold text-corBase">Adicione uma imagem:</label>
            <div class="imagePreviewWrapper" :style="{ 'background-image': `url(${previewImage.url_documento})` }">
            </div>
            <div class="flex gap-3 mt-4">
              <button class="selecionar-arquivo" @click.prevent="abrir_galeria">
                Pesquisar
              </button>

            </div>

          </div>

          <div class="flex flex-col w-full gap-[14px] ml-4 mr-1">
            <div class="campo-chapa">
              <label class="text-xl font-bold text-corBase">Nome Especifico:</label>
              <input type="text" v-model="nome_especifico" class="input-form-chapa" required autofocus>
            </div>

            <div class="div-produtos ">
              <div class="flex flex-col gap-1 w-full">
                <label class="text-xl font-bold text-corBase">Produto Fornecedor:</label>
                <Select2 v-model="produto_por_fornecedor"
                         class="w-full z-10"
                         :options="lista_produto_por_fornecedor"
                         :settings="{ width: '100%'}"
                />

              </div>

            </div>

            <div class="flex gap-3 w-full mt-3">
              <div class="campo-chapa">
                <label class="text-xl text-corBase">Tipo Perfil:</label>
                <Select2 v-model="tipo_perfil"
                         class="w-full z-10"
                         :options="lista_tipo_perfil"
                         :settings="{ width: '100%'}"
                />
              </div>
              <div class="campo-chapa">
                <label class="text-xl text-corBase">Linha Aplicação componente:</label>
                <Select2 v-model="linha_aplicacao"
                         class="w-full z-10"
                         :options="lista_linha_aplicacao"
                         :settings="{ width: '100%'}"
                />
              </div>
            </div>

            <div class="flex gap-3 w-full mt-3">
              <div class="campo-chapa">
                <label class="text-xl text-corBase">Cor Perfil:</label>
                <Select2 v-model="cor_perfil"
                         class="w-full z-10"
                         :options="lista_cor_perfil"
                         :settings="{ width: '100%'}"
                />
              </div>

              <div class="campo-chapa">
                <label class="text-xl text-corBase">Comprimento:</label>
                <input class="input-form-chapa" type="text" v-model="comprimento">
              </div>
              <div class="campo-chapa">
                <label class="text-xl text-corBase">Estoque:</label>
                <input class="input-form-chapa" type="text" v-model="estoque">
              </div>
            </div>
          </div>
        </div>
        <div class="div-produtos">

          <div class="flex flex-col w-full gap-[12px] ml-4 mr-1">

            <div class="flex gap-3 w-full mt-3">
              <div class="campo-chapa">
                <label class="text-xl text-corBase">Sucata Maxima:</label>
                <input type="number" class="input-form-chapa" v-model="sucata_maxima_tamanho">
              </div>

              <div class="campo-chapa">
                <label class="text-xl text-corBase">Perda Tratamento:</label>
                <input type="number" class="input-form-chapa" v-model="perda_por_tratamento">
              </div>
              <div class="campo-chapa">
                <label class="text-xl text-corBase">Jx:</label>
                <input type="number" class="input-form-chapa" v-model="jx">
              </div>

              <div class="campo-chapa">
                <label class="text-xl text-corBase">Jy:</label>
                <input type="number" class="input-form-chapa" v-model="jy">
              </div>

              <div class="campo-chapa">
                <label class="text-xl text-corBase">Wx:</label>
                <input type="number" class="input-form-chapa" v-model="wy">
              </div>

              <div class="campo-chapa">
                <label class="text-xl text-corBase">Wy:</label>
                <input type="number" class="input-form-chapa" v-model="wx">
              </div>
            </div>

          </div>
        </div>
        <div class="flex justify-between ">
          <div class="flex w-full justify-end">
            <button class="btn-enviar" type="submit">Salvar</button>
          </div>
        </div>
      </template>

    </form>
  </div>


  <mensagem-sucesso v-if="sucesso === true" mensagem="Perfil Cadastrado com Sucesso!"
                    rotas="/produtos/perfil"></mensagem-sucesso>
</template>

<script>
import axios from "axios";
import Galeria from "@/components/midais/ged/Galeria";
import MensagemSucesso from "@/components/toasts/toast_sucesso/MensagemSucesso";
import Select2 from "vue3-select2-component";
import ImportarcaoPerfil from "@/components/midais/importacoes/ImportarcaoPerfil";

export default {
  name: "CadastroPerfil",

  components: {
    Select2,
    Galeria,
    MensagemSucesso,
    ImportarcaoPerfil,
  },

  data() {
    return {
      importacao: false,
      sucesso: false,
      galeria: false,
      previewImage: {},

      lista_produto_por_fornecedor: [],
      lista_fornecedor: [],
      lista_tipo_pessoa_servico: [],
      lista_tipo_perfil: [],
      lista_cor_perfil: [],
      lista_grupo_aplicacao_vidro: [],
      lista_linha_aplicacao: [],

      nome_especifico: '',
      produto_por_fornecedor: '',
      fornecedor: '',
      tipo_perfil: '',
      comprimento: '',
      estoque: '',
      sucata_maxima_tamanho: '',
      perda_por_tratamento: '',
      linha_aplicacao: '',
      cor_perfil: '',
      jy: '',
      jx: '',
      wy: '',
      wx: '',
      proximo: false,
      imagem_base: '',

    }
  },

  created() {
    this.getFornecedor()
    this.getProdutoPorFornecedor()
    this.getTipoPerfil()
    this.getCorPerfil()
    this.getLinhaAplicacao()
  },

  computed: {
    isadmin() {
      return this.$store.state.isAdminUser
    },
    minhaEmpresa() {
      return this.$store.state.minhaEmpresa;
    },
  },

  methods: {
    atualizarFileInput(objeto) {
      console.log('objeto que chegou:' + objeto)
      console.log(objeto)
      this.previewImage = objeto; // ou a propriedade correta do objeto que deseja atribuir
    },
    abrir_galeria() {
      this.galeria = !this.galeria
    },
    imoportar() {
      this.importacao = !this.importacao
    },
    trocar_de_pagina() {
      this.proximo = !this.proximo
    },
    handleImagemUpload(event) {
      this.fileInput = event.target.files[0];
    },

    async cadastrarPerfil() {
      const token = await this.$store.dispatch('getAuthTokenCookie')

      await this.$store.dispatch('fetchURLrequest')

      const data = new FormData();
      data.append('ativo', true)
      data.append('nome_perfil_especifico', this.nome_especifico)
      data.append('perfil_base_fornecedor', this.produto_por_fornecedor)
      data.append('tipo_perfil', this.tipo_perfil)
      data.append('cor_perfil', this.cor_perfil)
      data.append('comprimento_padrao', this.comprimento)
      data.append('estoque', this.estoque)
      data.append('sucata_maxima', this.sucata_maxima_tamanho)
      data.append('perda_tratamento', this.perda_por_tratamento)
      data.append('linha_aplicacao', this.linha_aplicacao)
      data.append('Jy', this.jy)
      data.append('Jx', this.jx)
      data.append('Wy', this.wy)
      data.append('Wx', this.wx)
      data.append('imagem', this.imagem_base)

      this.$store.dispatch("setLoading", true);
      await axios.post('produtos/perfil/', data, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            console.log(response.data.results);
            this.sucesso = true
            this.$store.dispatch("setLoading", false);
          })
          .catch(error => {
            console.log(error);
            this.$store.dispatch("setLoading", false);
          })

    },


    async getProdutoPorFornecedor() {
      const token = await this.$store.dispatch('getAuthTokenCookie')

      await this.$store.dispatch('fetchURLrequest')

      axios.get(`produtos/produto-por-fornecedor`, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            this.lista_produto_por_fornecedor = response.data.results.map(item => ({
              id: item.id,
              text: `${item.codigo_fornecedor} - ${item.nome_especifico}`
            }))
          })

          .catch(error => {
            console.error('Erro ao filtrar produtos:', error);
          });

    },

    async getFornecedor() {
      const token = await this.$store.dispatch('getAuthTokenCookie')

      await this.$store.dispatch('fetchURLrequest')

      await axios.get('pessoas/fornecedor', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            console.log(response.data.results)
            console.log("AQUI");
            this.lista_fornecedor = response.data.results.map(item => ({
              id: item.id,
              text: item.pessoa.nome_razao
            }))
          })
          .catch(error => {
            console.log(error)
          })
    },

    async getTipoPerfil() {
      const token = await this.$store.dispatch('getAuthTokenCookie')

      await this.$store.dispatch('fetchURLrequest')

      await axios.get('produtos/tipo-perfil', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            console.log(response.data.results)
            this.lista_tipo_perfil = response.data.results.map(item => ({
              id: item.id,
              text: item.nome
            }))
          })
          .catch(error => {
            console.log(error)
          })
    },

    async getCorPerfil() {
      const token = await this.$store.dispatch('getAuthTokenCookie')

      await this.$store.dispatch('fetchURLrequest')

      await axios.get('produtos/cor-perfil', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            console.log(response.data.results)
            this.lista_cor_perfil = response.data.results.map(item => ({
              id: item.id,
              text: item.nome
            }))
          })
          .catch(error => {
            console.log(error)
          })
    },

    async getLinhaAplicacao() {
      const token = await this.$store.dispatch('getAuthTokenCookie')

      await this.$store.dispatch('fetchURLrequest')

      await axios.get('produtos/linha-aplicacao', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            this.lista_linha_aplicacao = response.data.results.map(item => ({
              id: item.id,
              text: item.nome
            }))
          })
          .catch(error => {
            console.log(error)
          })
    },

  }
}
</script>

<style lang="scss"></style>