<template>

  <div class="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full h-full z-[100000]">
    <div class="w-full h-full bg-black/40 z-[100000]">
      <div class="bg-screenCor p-6 w-[75%] z-[100000] shadow-xl shadow-screenCor/30 fixed top-[55%] left-1/2
      transform -translate-x-1/2 -translate-y-1/2 h-[80vh] overflow-y-scroll scrollbar-thin
      scrollbar-thumb-screenCor/40">

        <div class="cabecalho-galeria">
          <div>
            <button class="text-corBase text-2xl" @click="fechar_galeria"><i class="fas fa-xmark"></i></button>
          </div>
        </div>
        <h1 class="font-bold text-corBase text-xl ml-4 mb-2">Filtros para Imagens</h1>
        <div class="w-full flex gap-6 ml-4 items-end">

          <div class="w-full">
            <label>Categoria</label>
            <!--            <Select2 v-model="categoria"-->
            <!--                     class="w-full z-10"-->
            <!--                     :options="lista_categoria"-->
            <!--                     :settings="{ width: '100%'}"-->
            <!--            />-->
            <select class="input-campos" v-model="categoria">
              <option value="">Escolha uma Categoria</option>
              <option v-for="cat in lista_categoria" :key="cat.id"
                      :value="cat.id">
                {{ cat.text }}

              </option>
            </select>
          </div>

          <div class="w-full">
            <label>SubCategoria</label>
            <select class="input-campos" v-model="subcategoria">
              <option value="">Escolha uma Subcategoria</option>
              <option v-for="sub in lista_subcategoria" :key="sub.id"
                      :value="sub.id">
                {{ sub.text }}

              </option>
            </select>
          </div>

          <div class="w-full">
            <label>Descrição Imagem</label>
            <input class="input-campos" v-model="descricao_image" />
          </div>

          <button class="py-1 px-3 bg-corBase rounded-sm text-screenCor border border-corBase
  hover:bg-screenCor hover:border hover:border-corBase hover:text-corBase
  hover:transition hover:duration-300" @click="filtrarFotos">Pesquisar
          </button>

        </div>

        <div class="container-imagens">
          <div v-for="(imagen, index) in lista_imagens" :key="index">
            <button class="div-filho-imagem" @click="enviarObjeto(imagen)">
              <img class="img-icone" :src="imagen.url_documento" alt="imagem">
              <span class="span-icone">{{ imagen.descricao }}</span>
            </button>
          </div>

        </div>

      </div>
    </div>
  </div>

</template>

<script>
import Select2 from "vue3-select2-component";
import axios from "axios";

export default {
  name: 'Galeria',
  components: {
    Select2
  },

  data() {
    return {
      descricao_image: '',
      imagem: '',
      lista_imagens: [],
      lista_categoria: [],
      categoria: '',
      lista_subcategoria: [],
      subcategoria: '',
    }
  },

  computed: {
    minhaEmpresa() {
      return this.$store.state.minhaEmpresa;
    },
  },

  created() {
    this.getListaGategoria()
    this.getListaSubcategoria()
  },

  methods: {
    fechar_galeria() {
      this.$emit('fechar_galeria')
      console.log('fui clicado')
    },

    async filtrarFotos() {
      try {
        const token = await this.$store.dispatch('getAuthTokenCookie')
        const listaImagens = await axios.get('http://localhost:8000/api/v1/ged/documentos', {
          headers: {
            'Authorization': `Token ${token}`
          }
        })
        this.lista_imagens = listaImagens.data
        console.log(listaImagens)
      }catch(err) {
        console.error(err)
      }
    },
    // async filtrarFotos() {
    //   try {
    //     const listaImagens = await this.$store.dispatch('fetchListaDocumentos', {
    //       descricao: this.descricao_image,
    //       subcategoria: this.subcategoria,
    //       categoria: this.categoria,
    //     })
    //     this.lista_imagens = listaImagens
    //
    //   } catch (error) {
    //     console.log(error)
    //   }
    // },

    async getListaGategoria() {
      try {
        const listaCategoria = await this.$store.dispatch('fetchListaCategoriasGed')
        this.lista_categoria = listaCategoria.map(item => ({
          id: item.id,
          text: item.nome
        }))

      } catch (error) {
        console.log(error)
      }
    },

    async getListaSubcategoria() {
      try {
        const listaSubcategoria = await this.$store.dispatch('fetchListaSubcategoriasGed')
        this.lista_subcategoria = listaSubcategoria.map(item => ({
          id: item.id,
          text: item.nome
        }))

      } catch (error) {
        console.log(error)
      }
    },

    enviarObjeto(objeto) {
      this.$emit('objetoClicado', objeto)
      this.fechar_galeria()
    }

  }

}

</script>

<style lang="scss">

.container-galeria {
  @apply absolute w-[98%] h-[75vh] rounded-xl bg-white border border-corBase z-[1000000]
}

.div-filho-imagem {
  @apply pt-2 h-28 w-full flex justify-center flex-col items-center
}

.img-icone {
  @apply w-20 h-16
}

.span-icone {
  @apply text-center text-sm mt-2
}

.cabecalho-galeria {
  @apply flex justify-end mx-5 gap-5 my-3
}

.container-imagens {
  @apply grid grid-cols-8 place-items-center w-full  h-auto divide-x divide-y
}

</style>