<template>


  <div class="container-formulario">

      <div class="cabecalho-dashboard">
        <h1>Cadastros</h1>

        <button @click="$router.push(`/dashboard`)" class="text-2xl">
          <i class="fas fa-chevron-left mr-1 "></i>
        </button>
      </div>

      <div class="flex flex-wrap">

        <button @click="$router.push('/pessoas')" class="escopo-dashboard-desktop">

        <div class="opcao-dashboard">
          <div class="cacebalho-opcao">
            <h2 class="titulo-opcao">Pessoas</h2>
            <i class="fas fa-user"></i>
          </div>

          <div class="descricao-opcao">
            <p class="mt-6">Cadastre as pessoas físicas presentes em seu sistema!</p>
          </div>
        </div>

      </button>

      <button class="escopo-dashboard-desktop" @click="$router.push({ name: 'produtos' })">

        <div class="opcao-dashboard">
          <div class="cacebalho-opcao">
            <h2 class="titulo-opcao">Produtos</h2>
            <i class="fa-solid fa-industry"></i>
          </div>

          <div class="descricao-opcao">
            <p class="mt-6">Cadastre as empresas presentes em seu sistema!</p>
          </div>
        </div>

      </button>

        <button class="escopo-dashboard-desktop" @click="$router.push(`/cores`)">

          <div class="opcao-dashboard">
            <div class="cacebalho-opcao">
              <h2 class="titulo-opcao">Cores</h2>
              <i class="fas fa-users"></i>
            </div>

            <div class="descricao-opcao">
              <p class="mt-6">Veja os relatórios em tempo real!</p>
            </div>
          </div>

        </button>

        <button class="escopo-dashboard-desktop" @click="$router.push(`/grupo-aplicacao`)">

          <div class="opcao-dashboard">
            <div class="cacebalho-opcao">
              <h2 class="titulo-opcao">Grupos de Aplicação</h2>
              <i class="fas fa-user-friends"></i>
            </div>

            <div class="descricao-opcao">
              <p class="mt-6">Veja os relatórios em tempo real!</p>
            </div>
          </div>

        </button>

        <button class="escopo-dashboard-desktop" @click="$router.push(`/tipos`)">

          <div class="opcao-dashboard">
            <div class="cacebalho-opcao">
              <h2 class="titulo-opcao">Tipos</h2>
              <i class="fas fa-user-friends"></i>
            </div>

            <div class="descricao-opcao">
              <p class="mt-6">Veja os relatórios em tempo real!</p>
            </div>
          </div>

        </button>

        <button class="escopo-dashboard-desktop" @click="$router.push(`/outros`)">

          <div class="opcao-dashboard">
            <div class="cacebalho-opcao">
              <h2 class="titulo-opcao">Outros</h2>
              <i class="fas fa-user-friends"></i>
            </div>

            <div class="descricao-opcao">
              <p class="mt-6">Veja os relatórios em tempo real!</p>
            </div>
          </div>

        </button>

      </div>


    </div>


</template>

<script>
export default {
  name: "Cadastros",
}
</script>

<style lang="scss">

</style>