<template>
  <!-- Erro ao realizar login-->
  <div class="posicao-toast-erro" value="erro_login">
    <div class="container-toast-erro">
      <i class="fas fa-xmark-circle icone-toast-erro"></i>
      <h2 class="texto-toast-erro">
        Erro ao realizar o login!
        Sua empresa não foi encontrada em nossa base de dados... Para solicitar uma inscrição entre em contato com o
        nosso suporte.
      </h2>
    </div>
  </div>

  <!-- Erro ao achar schema-->

</template>

<script>
export default {
  name: "ErroLoginSchema"
}
</script>

<style lang="scss">

.posicao-toast-erro {
  @apply absolute right-0 bottom-0 m-5
}

.container-toast-erro {
  @apply flex items-center bg-red-500 border-l-4 border-red-700 py-2 px-3 shadow-md mb-2
}

.texto-toast-erro {
  @apply text-white max-w-xs
}

.icone-toast-erro {
  @apply text-white mr-2
}

</style>